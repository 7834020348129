import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';

export const GET_REPORTS = 'GET_REPORTS';
export const Find_REPORTS = 'Find_REPORTS';
export const ON_HISTORICAL_REPORTS_RECEIVED = 'ON_HISTORICAL_REPORTS_RECEIVED';
export const ON_HISTORICAL_REPORTS_SEARCH_CHANGED = 'ON_HISTORICAL_REPORTS_SEARCH_CHANGED';
export const ON_HISTORICAL_REPORTS_SEARCH_START = 'ON_HISTORICAL_REPORTS_SEARCH_START';
export const ON_HISTORICAL_REPORTS_SEARCH_FINISHED = 'ON_HISTORICAL_REPORTS_SEARCH_FINISHED';
export const SET_NON_SUBMISSION_REPORTS_RESPONSE = 'SET_NON_SUBMISSION_REPORTS_RESPONSE';
export const RESET_NON_SUBMISSION_REPORTS_RESPONSE = 'RESET_NON_SUBMISSION_REPORTS_RESPONSE';
export const getHistoricalReports = (
  searchText: string,
  page: number,
  recordPerPage: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_HISTORICAL_REPORTS_SEARCH_START });
    // Call API
    const url = `${await API_URL()}/find-reports/history/search`;
    const result = await post(url, {
      limit: recordPerPage,
      offset: recordPerPage * page - recordPerPage,
      searchText,
    });
    if (result) {
      dispatch({ type: ON_HISTORICAL_REPORTS_RECEIVED, payload: result });
    }
  } catch (error) {
    dispatch({
      type: ON_HISTORICAL_REPORTS_RECEIVED,
      payload: null,
    });
  }
  dispatch({ type: ON_HISTORICAL_REPORTS_SEARCH_FINISHED });
};

export const onHistoricalReportsSearchChanged = (
  searchText: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: ON_HISTORICAL_REPORTS_SEARCH_CHANGED,
    payload: searchText,
  });
};

export const getGenerateReports = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const url = await API_URL() + '/generate-reports';
    const result = await get(url);
    if (result) {
      dispatch({ type: SET_NON_SUBMISSION_REPORTS_RESPONSE });
      dispatch({ type: ON_LOADING_FINISHED });
      dispatch(findReports(result.id));
    }
    dispatch({ type: GET_REPORTS, payload: result });
  } catch (error) {
    dispatch({
      type: GET_REPORTS,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const findReports = (id): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = await API_URL() + `/find-reports/${id}`;
    const result = await get(url);
    dispatch({ type: Find_REPORTS, payload: result });
  } catch (error) {
    dispatch({
      type: Find_REPORTS,
      payload: null,
    });
  }
};

export const resetResponseForNonSubmissionReports = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({ type: RESET_NON_SUBMISSION_REPORTS_RESPONSE });
};
