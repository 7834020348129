import {EmailOutlined} from '@material-ui/icons';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';
import '../index.scss';

const CustomNodeTitle3 = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: #b0f60026;
  color: black;
  font-size: 20px;
  padding: 37px;
  text-align: center;
`;

export const EmailDelivery=()=> {
 
 
  return (
    <div>
      <Handle type="target" id="delivery_email" position={Position.Top} />
     
          <CustomNodeTitle3>
          <EmailOutlined style={{ fontSize: '29px' }} />
            &nbsp;&nbsp;Delivery via Email
          </CustomNodeTitle3>
        </div>
  );
}
