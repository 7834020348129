import React, { useState, useEffect } from 'react';
import TickAnimation from '../../../../components/animated/tick-animation';
import BlockAnimation from '../../../../components/animated/block-animation';
import styled from 'styled-components';
import { theme } from '../../../../theme';
import { ReactComponent as LoadingAnimation } from '../../../../components/animated/loader-animation.svg';
import { useParams } from 'react-router';
import Page from '../../../../components/Page';
import { getUserDetails, markAttendance } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { ATTENDANCE_RESULTS } from '../../../../util/enums';

const MarkAttendance = styled.div`
  #main-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin: ${theme.spacing(3)}px;
    }

    #animation-container {
      margin: ${theme.spacing(3)}px;
      height: 160px;
      width: 160px;

      #loader {
        margin-top: 68px;
        display: block;
      }
    }

    h2 {
      margin: ${theme.spacing(3)}px;
    }

    h6 {
      margin-top: ${theme.spacing(3)}px;
      font-weight: bold;
      font-style: italic;
    }

    #footer {
      position: absolute;
      text-align: center;
      font-size: 0.8em;
      font-weight: bold;
      bottom: ${theme.spacing(2)}px;
    }
  }
`;
const MarkComponent: React.FC = () => {
  const dispatch = useDispatch();
  const attendanceResult = useSelector((state: RootState) => state.events.attendanceResult);
  const userDetails = useSelector((state: RootState) => state.users.userDetails);

  const { stateInfo = '', title = '', info = '' } = attendanceResult;

  const { id } = useParams<{ id: string }>();

  const { oktaAuth } = useOktaAuth();
  const getAvatar = async () => {
    const user = await oktaAuth.getUser();
    dispatch(getUserDetails(user));
  };

  useEffect(() => {
    const { email = '' } = userDetails;
    if (!email) {
      getAvatar();
    }
    if (id && email) {
      dispatch(markAttendance(id, email));
    }
  }, [dispatch, id, userDetails]);

  return (
    <MarkAttendance>
      <Page title="CAPRIS: Mark Attendance">
        <div id="main-container">
          <h4>{title}</h4>
          <h2>{info}</h2>
          <div id="animation-container">
            {stateInfo === 'LOADED' && <TickAnimation />}
            {stateInfo === 'LOADING' && <LoadingAnimation />}
            {stateInfo === 'ERROR' && <BlockAnimation />}
          </div>
          {stateInfo !== ATTENDANCE_RESULTS.LOADING && <h6>You can close this window now</h6>}
          <span id="footer">Powered by EdFibre™</span>
        </div>
      </Page>
    </MarkAttendance>
  );
};

export default MarkComponent;
