import { post } from './common';
import { ON_LOADING_FINISHED, ON_LOADING_START, GET_TIME_TABLE, ON_ANALYSIS_SEARCH_CHANGED, TIME_TABLE_REPORT_START, SET_TIME_TABLE_REPORT_RESPONSE, TIME_TABLE_REPORT_FINISHED } from "../actionTypes"
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { API_URL } from '../config/constants'
import { AnalysisFilters } from '../util/types';
import { findInstitutionalReports } from './institutional-reports';
import { reportTypes } from '../util/enums';


export const getTimeTable = (
  week: string,
  weekStartDate: Date,
  weekEndDate: Date, page: number,
  recordPerPage: number, searchText: string,
  filters: AnalysisFilters): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await post(`${await API_URL()}/events/timetable`, {
        weekStartDate, weekEndDate,
        limit: recordPerPage * page,
        offset: recordPerPage * page - recordPerPage,
        searchText,
        filters
      });
      dispatch({ type: GET_TIME_TABLE, payload: { result, week } });
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      dispatch({
        type: GET_TIME_TABLE,
        payload: { result: [], week }
      });
      dispatch({ type: ON_LOADING_FINISHED });
    }
  };

export const generateTimeTableReports = (weekStartDate: Date,
  weekEndDate: Date): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {

    try {
      dispatch({ type: TIME_TABLE_REPORT_START });
      const url = `${await API_URL()}/events/timetable/report/generate`
      const result = await post(url, { type: "type", weekStartDate, weekEndDate });
      if (result) {
        dispatch({ type: SET_TIME_TABLE_REPORT_RESPONSE, payload: "" });
        dispatch(findInstitutionalReports(result.id, reportTypes.TIMETABLE));
      }
    } catch (error) {
      dispatch({
        type: SET_TIME_TABLE_REPORT_RESPONSE,
        payload: "",
      });
      dispatch({ type: TIME_TABLE_REPORT_FINISHED });
    }

  };

export const setTimeTableResponse = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {

  try {
    dispatch({ type: SET_TIME_TABLE_REPORT_RESPONSE, payload: "" });
  } catch (error) {
    dispatch({
      type: SET_TIME_TABLE_REPORT_RESPONSE,
      payload: "",
    });
  }

};

export const onAnalysisSearchChanged = (searchText: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: ON_ANALYSIS_SEARCH_CHANGED,
    payload: searchText,
  });
};