import { ActionType } from './types';
import * as actionTypes from '../actionTypes';

const initialState = {
  assignmentInfo: {},
  students: [],
  disableEdit: false,
  loader: false,
  sendInterventionLoader_Non_Submission: false,
  sendInterventionLoader_Grades_Fail: false,
  sendInterventionResponse: null,
  previousInterventionTriggers: [],
  logsLoader: false,
  logs: [],
  assignmentTableLoader: false,
  assignmentMailPreviewLoader: false,
  assignmentMailPreviewContent: null,
};

const assignmentDetails = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_ASSIGNMENT_INFO: {
      return {
        ...state,
        assignmentInfo: action.payload,
      };
    }
    case actionTypes.GET_ASSIGNMENT_DETAIL_RESULT: {
      return {
        ...state,
        students: action.payload.students,
        disableEdit: action.payload.disableEdit,
      };
    }
    case actionTypes.ON_ASSIGNMENT_DETAIL_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_ASSIGNMENT_DETAIL_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }

    case actionTypes.ON_ASSIGNMENT_TABLE_DETAIL_START: {
      return {
        ...state,
        assignmentTableLoader: true,
      };
    }
    case actionTypes.ON_ASSIGNMENT_TABLE_DETAIL_FINISHED: {
      return {
        ...state,
        assignmentTableLoader: false,
      };
    }

    case actionTypes.ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_START: {
      return {
        ...state,
        sendInterventionLoader_Non_Submission: true,
      };
    }
    case actionTypes.ON_INTERVENTIONS_SENT: {
      return {
        ...state,
        sendInterventionResponse: action.payload,
      };
    }
    case actionTypes.ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS: {
      return {
        ...state,
        previousInterventionTriggers: action.payload,
      };
    }
    case actionTypes.ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_FINISHED: {
      return {
        ...state,
        sendInterventionLoader_Non_Submission: false,
      };
    }
    case actionTypes.ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_START: {
      return {
        ...state,
        sendInterventionLoader_Grades_Fail: true,
      };
    }
    case actionTypes.ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_FINISHED: {
      return {
        ...state,
        sendInterventionLoader_Grades_Fail: false,
      };
    }
    case actionTypes.GET_ASSIGNMENT_LOGS_RESULT: {
      return {
        ...state,
        logs: action.payload,
      };
    }
    case actionTypes.ON_ASSIGNMENT_LOGS_START: {
      return {
        ...state,
        logsLoader: true,
      };
    }
    case actionTypes.ON_ASSIGNMENT_LOGS_FINISHED: {
      return {
        ...state,
        logsLoader: false,
      };
    }

    case actionTypes.GET_ASSIGNMENT_TEMPLATE_PREVIEW: {
      return {
        ...state,
        assignmentMailPreviewContent: action.payload,
      };
    }
    case actionTypes.ON_ASSIGNMENT_TEMPLATE_PREVIEW_START: {
      return {
        ...state,
        assignmentMailPreviewLoader: true,
      };
    }
    case actionTypes.ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED: {
      return {
        ...state,
        assignmentMailPreviewLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default assignmentDetails;
