import React, { FunctionComponent } from 'react';
import { makeStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '20px',
  },
  bg: {
    backgroundColor: '#fff',
    color: 'black',
  },
  panelBg: {
    backgroundColor: '#F1F1F1',
    color: 'black',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'black',
    margin: '10px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'black',
    margin: '10px',
  },
  teritaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: 'black',
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: '10px',
  },
  details: {
    display: 'block',
  },
  reducedPanelBg: {
    backgroundColor: '#F1F1F1',
    color: 'black',
    height: '10px',
  },
}));

type PanelProps = {
  heading: string;
  secondaryHeading?: string;
  teritaryHeading?: React.ReactNode;
  reduceHeight?: boolean;
};

const CustomExpansionPanel: FunctionComponent<PanelProps> = ({
  heading,
  secondaryHeading,
  teritaryHeading,
  children,
  reduceHeight = false,
}) => {
  const classes = useStyles();
  return (
    <div>
      <ExpansionPanel className={classes.bg}>
        <ExpansionPanelSummary
          className={reduceHeight ? classes.reducedPanelBg : classes.panelBg}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <div className={classes.heading}>
            <strong>{heading}</strong>
          </div>
          <div className={classes.secondaryHeading}>{secondaryHeading}</div>
          <div className={classes.teritaryHeading}>{teritaryHeading}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default CustomExpansionPanel;
