import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import {
  AccountBalance,
  Assessment,
  Beenhere,
  CloudUpload,
  KeyboardBackspace,
  NotificationImportant,
  PhoneCallback,
  Receipt,
  Search,
  TrendingUp,
} from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../reducer';
import { theme } from '../../../theme';
import { Title, TitleHeaderLink } from '../../Common/StyledComponents';
import NavItem from './NavItem';
import { NavBarProps } from './types';
const NavDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  width: 260px;
  height: 100%;
`;

const TopActionArea = styled.div`
  height: 64px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const NavContainer = styled.nav`
  flex-grow: 1;
`;

const Logo = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  padding-left: 0;
  justify-content: center;
`;

const BackButton = styled(IconButton)`
  margin-right: ${theme.spacing(1)}px;
  &:focus {
    outline: none;
  }
`;

const Navbar: React.FC<NavBarProps> = ({ open, onClose }) => {
  // Redux store
  const role = useSelector((state: RootState) => state.roles?.roles);

  return (
    <NavDrawer anchor="left" open={open} onClose={onClose} variant="temporary">
      <TopActionArea>
        <Title variant="h3">
          <TitleHeaderLink to="/">CAPRIS</TitleHeaderLink>
        </Title>
        <BackButton color="primary" onClick={onClose}>
          <KeyboardBackspace />
        </BackButton>
      </TopActionArea>
      <Divider />
      <NavContainer>
        <List>
          <NavItem onClose={onClose} href={'/'} title={'Dashboard'}>
            <AccountBalance color="primary" />
          </NavItem>
        </List>

        {role === 'LECTURER' && (
          <>
            <Divider />
            <List>
              <NavItem onClose={onClose} href={'/events'} title={'Mark Attendance'}>
                <Beenhere color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/reports/institutional'} title={'Reports'}>
                <Receipt color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/interventions'} title={'Interventions'}>
                <NotificationImportant color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/improvement-areas'} title={'Improvement Areas'}>
                <TrendingUp color="primary" />
              </NavItem>
            </List>
          </>
        )}

        {role === 'ADMIN' && (
          <>
            <Divider />
            <List>
              <NavItem onClose={onClose} href={'/search/courses'} title={'Search'}>
                <Search color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/events'} title={'Mark Attendance'}>
                <Beenhere color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/uploads'} title={'Bulk Upload'}>
                <CloudUpload color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/reports/institutional'} title={'Reports'}>
                <Receipt color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/interventions'} title={'Interventions'}>
                <NotificationImportant color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/improvement-areas'} title={'Improvement Areas'}>
                <TrendingUp color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/callbacks'} title={'Callbacks'}>
                <PhoneCallback color="primary" />
              </NavItem>
              <NavItem onClose={onClose} href={'/timetable/analysis'} title={'System Reconciliation Reports'}>
                <Assessment color="primary" />
              </NavItem>
            </List>
          </>
        )}
      </NavContainer>
      <Divider />
      <Logo>
        <img alt="Logo" src="/images/logos/edfibre-sidebar.png" style={{ height: 75 }} />
      </Logo>
    </NavDrawer>
  );
};

export default Navbar;
