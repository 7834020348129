import { get } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { GET_COMMENTS_FOR_ASSIGNMENT_DUE_DATE, GET_COMMENTS_LOADING_START, GET_COMMENTS_LOADING_FINISHED } from '../actionTypes';
export const getComments = (id: number): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_COMMENTS_LOADING_START })
    const result = await get(`${await API_URL()}/assignments/${id}/comments`);
    dispatch({ type: GET_COMMENTS_FOR_ASSIGNMENT_DUE_DATE, payload: { id, data: result } });
  } catch (error) {
    dispatch({
      type: GET_COMMENTS_FOR_ASSIGNMENT_DUE_DATE,
      payload: [],
    });
  }
  dispatch({ type: GET_COMMENTS_LOADING_FINISHED })
};