import { Button, Grid, Popover } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDownOutlined, ArrowDropUpOutlined, FilterList } from '@material-ui/icons';
import { ResponsiveLine } from '@nivo/line';
import React, { useState } from 'react';
import { IndicesGraphData } from '../../models/graphData';
import Details from './indiceDetails';
import IndicesGraphTooltip from './indices-graph-tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    item: {
      margin: '0px 20px',
      minWidth: '150px',
      textAlign: 'center',
    },
    itemPadding: {
      padding: '10px',
    },
    graph: {
      width: '100%',
      height: '550px',
    },
    alignCenter: {
      textAlign: 'center',
    },
    popoverContent: {
      margin: theme.spacing(2),
    },
  })
);

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
    <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
  </g>
);

interface indicesProps {
  data: IndicesGraphData[];
  xText: string;
  yText: string;
  dataText: string;
  min?: number;
  max?: number;
}

const LineGraph: React.FC<indicesProps> = ({ data, xText, yText, dataText, min, max }) => {
  const classes = useStyles();

  const [toggle, setToggle] = useState(
    data.reduce((acc, x) => {
      acc[x.id] = true;
      return acc;
    }, {})
  );
  const [graphData, setGraphData] = useState(data);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggle({ ...toggle, [e.target.name]: e.target.checked });
    setGraphData(
      data.filter((x) => {
        return x.id === e.target.name ? e.target.checked : !!toggle[x.id];
      })
    );
  };

  return (
    <Grid container className={classes.container}>
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        color="primary"
        endIcon={open ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
        onClick={handleClick}
      >
        Graph Filters
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popoverContent}>
          {data.map((x) => {
            const { id, data, color } = x;
            return (
              <Details
                dataText={dataText}
                key={id}
                id={id}
                data={data}
                color={color}
                toggle={toggle}
                handleChange={handleChange}
              />
            );
          })}
        </div>
      </Popover>

      <Grid item className={classes.graph}>
        <ResponsiveLine
          data={graphData}
          margin={{ top: 20, right: 70, bottom: 80, left: 80 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min, max }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          colors={graphData.map((x) => x.color)}
          pointSymbol={CustomSymbol}
          tooltip={IndicesGraphTooltip}
          curve="monotoneX"
          lineWidth={4}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xText,
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle',
            legend: yText,
          }}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              itemWidth: 240,
              itemHeight: 40,
              translateY: 80,
              symbolShape: 'circle',
            },
          ]}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </Grid>
    </Grid>
  );
};

export default LineGraph;
