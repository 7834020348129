import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { AccountCircleTwoTone, ExitToAppTwoTone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { UserService } from '../../../services/user.service';
import { getUserDetails } from '../../../actions';
import { Redirect } from 'react-router-dom';
import { removeLocalStorage } from '../../../util/common';

const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 200px;
  margin: 16px;
  span {
    margin-left: 16px;
    user-select: none;
  }

  &:focus {
    outline: none;
  }
`;

const AccountButton = styled(IconButton)`
  &:focus {
    outline: none;
  }
`;

const AccountMenu: React.FC = () => {
  // Hooks
  const { oktaAuth } = useOktaAuth();

  // State
  const [anchor, setAnchor] = useState<HTMLElement | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [avatarSrc, setAvatarSrc] = useState<string>('');

  // Redux store
  const dispatch = useDispatch();

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      dispatch(getUserDetails(user));
      setName(user.name);
      if (name) setAvatarSrc(await UserService.GetMyAvatarUrl(name));
    };
    getAvatar();
  }, [name, dispatch, oktaAuth]);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(undefined);
    return <Redirect to="/" />;
  };

  const logout = () => {
    removeLocalStorage();
    oktaAuth.signOut();
    closeMenu();
  };

  return (
    <>
      <AccountButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
      >
        <AccountCircleTwoTone />
      </AccountButton>
      <StyledMenu anchorEl={anchor} open={Boolean(anchor)} onClose={closeMenu}>
        <UserContainer>
          <Avatar color="primary" src={avatarSrc} />
          <span>{name}</span>
        </UserContainer>
        <Divider />
        {/* {role === 'ADMIN' && (
          <MenuItem onClick={() => history.push('/admin')}>
            <ListItemIcon>
              <SupervisorAccountTwoTone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="User management" />
          </MenuItem>
        )} */}
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppTwoTone fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AccountMenu;
