import React, { KeyboardEvent } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Paper, Button, Input, Theme, colors, InputBase, IconButton, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { HighlightOff } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

type SearchProps = {
  onSearch: (isClear?: boolean) => void;
  className?: string;
  onSearchTextChange: (searchText: string) => void;
  searchPlaceHolder?: string;
  searchText: string;
};

const Search: React.FC<SearchProps> = ({
  searchText,
  searchPlaceHolder,
  onSearch,
  onSearchTextChange,
  className,
  ...rest
}) => {
  const clear = () => {
    onSearchTextChange('');
    onSearch(true);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.keyCode === 13) {
      onSearch();
    }
  };

  const classes = useStyles();

  const finalSearch = () => {
    onSearch();
  };

  return (
    <Paper className={classes.root} onSubmit={finalSearch}>
      <InputBase
        className={classes.input}
        placeholder={searchPlaceHolder}
        value={searchText}
        onChange={(e) => onSearchTextChange(e.target.value)}
        inputProps={{
          onKeyDown: onKeyDown,
        }}
      />
      <IconButton className={classes.iconButton} type="submit" onClick={finalSearch}>
        <SearchIcon />
      </IconButton>
      <Divider orientation="vertical" className={classes.divider} />
      <IconButton onClick={clear} className={classes.iconButton} disabled={!searchText || searchText === ''}>
        <HighlightOff />
      </IconButton>
    </Paper>
  );
};

export default Search;
