import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getMyRole } from '../../actions';
import Navbar from './Navbar';
import Topbar from './Topbar';

const DashboardContainer = styled.div`
  padding-top: 64px;
`;

const Dashboard: React.FC = ({ children }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const { oktaAuth } = useOktaAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      dispatch(getMyRole(user));
    };
    getAvatar();
  }, [dispatch]);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  return (
    <>
      <Topbar toggleNavbar={toggleNavbar} />
      <Navbar open={navbarOpen} onClose={closeNavbar} />
      <DashboardContainer>{children}</DashboardContainer>
    </>
  );
};

export default Dashboard;
