import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import CustomModal from '../CustomModal';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { BuildSharp, FindInPage, Pageview, PageviewOutlined, RemoveRedEyeOutlined, Visibility } from '@material-ui/icons';
import { RootState } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getInterventionPreview } from '../../actions';
import Loader from '../Loader';
import ReactFlow, { Background, Controls, OnLoadParams, ReactFlowProvider } from 'react-flow-renderer';
import { nodeTypes } from '../../views/intervention/intervention_styles';
type ViewInterventionProps = {
  id: number;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CustomDiv = styled.div`
  body {
    overflow: unset !important;
  }
`;

const CustomTitle = styled.div`
    text-align: center;
    background: #02183a;
    color: white;
    padding: 13px;
    font-weight: bold;
`

const ViewIntervention: React.FC<ViewInterventionProps> = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setModalOpen] = useState(false);
  const interventionPreviewLoader = useSelector((state: RootState) => state.intervention?.interventionPreviewLoader);
  const interventionPreviewData = useSelector((state: RootState) => state.intervention?.interventionPreviewData);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    dispatch(getInterventionPreview(id));
    setModalOpen(true);
  };

  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | undefined>(undefined);

  const onLoad = (_reactFlowInstance) => {_reactFlowInstance.fitView(); setReactFlowInstance(_reactFlowInstance.fitView())};

  

  const renderModal = () => {
    return (
      <CustomModal
        maxWidth={'xl'}
        open={open}
        onClose={handleModalClose}
        headerText={'Preview of Intervention: ' + interventionPreviewData?.label}
      >
     {!interventionPreviewLoader ?   <CustomDiv> 
         <div style={{ display: 'grid', fontSize: '15px', color: 'grey' }}>
        <span>
          <b>Trigger:</b> {interventionPreviewData?.trigger}
        </span>
        <span>
          <b>For following units:</b> {interventionPreviewData?.units.split(',').join(', ')}
        </span>
      </div>
      <br/>
      <br/>
      <div style={{ display: 'flex',justifyContent: 'space-between' }}>
          <div style={{width:'49%'}}>
          <CustomTitle>
                  Flowchart
              </CustomTitle>
              <div className="dndflow">
            <ReactFlowProvider>
              <div className="reactflow-wrapper">
                <ReactFlow
                nodesDraggable={false}
                elementsSelectable={false}
                  elements={interventionPreviewData?.flowChart}
                  nodeTypes={nodeTypes}
                  onLoad={onLoad}
                 
                  snapToGrid={true}
                >
                  <Controls showInteractive={false}/>
                  {/* <MiniMap /> */}
                  <Background />
                </ReactFlow>
              </div>
            </ReactFlowProvider>
          </div>
          </div>
          <div style={{width:'49%'}}>
              <CustomTitle>
                  Preview of Intervention Mail
              </CustomTitle>
          {ReactHtmlParser(interventionPreviewData?.preview)}
          </div>
          </div>
    </CustomDiv> : <Loader />}
      </CustomModal>
    );
  };

  return (
    <>
   <Button
        onClick={handleModalOpen}
        color="secondary"
        variant="outlined"
        size="small"
        startIcon={<Visibility />}
      >
        <div className={classes.textMargin}>View</div>
      </Button> {renderModal()}
    </>
  );
};

export default ViewIntervention;
