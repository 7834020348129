import { combineReducers } from 'redux';
import coursesReducer from './courses';
import studentReducer from './student';
import unitReducer from './units';
import eventReducer from './events';
import userReducer from './users';
import roleReducer from './roles';
import bulkUploadReducer from './bulkUpload';
import reports from './reports';
import institutionalReports from './institutionalReports';
import intervention from './intervention';
import commLogReducer from './commLog';
import acadProgressReducer from './acadProgress';
import camReducer from './cam';
import issuesReducer from './issues';
import callbackReducer from './callbacks';
import assignmentDueDateReducer from './assignmentDueDate';
import commonReducer from './common';
import institutionReducer from './institution';
import assignmentDetailsReducer from './assignmentDetails';
import studentInterventionLogsReducer from './studentInterventionLogs';
import studentReminderLogsReducer from './studentReminderLogs';
import timeTableReducer from './timetable';
import ImprovementAreaReducer from './improvementArea';

const rootReducer = combineReducers({
  courses: coursesReducer,
  student: studentReducer,
  units: unitReducer,
  events: eventReducer,
  users: userReducer,
  roles: roleReducer,
  bulkUpload: bulkUploadReducer,
  reports,
  institutionalReports,
  intervention,
  commLog: commLogReducer,
  acadProgress: acadProgressReducer,
  cam: camReducer,
  issues: issuesReducer,
  callback: callbackReducer,
  assignmentDueDate: assignmentDueDateReducer,
  common: commonReducer,
  institution: institutionReducer,
  assignmentDetails: assignmentDetailsReducer,
  studentInterventionLogs: studentInterventionLogsReducer,
  timetable: timeTableReducer,
  studentReminderLogs: studentReminderLogsReducer,
  ImprovementArea: ImprovementAreaReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
