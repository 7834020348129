import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { EditOutlined, OpenInNewOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { generateAttendanceCode, getUpcomingMeetings } from '../../actions';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { MSGraphMeeting } from '../../models/ms-graph/meeting';
import { RootState } from '../../reducer';
import moment from 'moment-timezone';
import ManualAttendance from './manual-attendance';
import { roles } from '../../util/enums';

const ActionCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

export default function Events() {
  const history = useHistory();
  const dispatch = useDispatch();
  const upComingMeetings = useSelector((state: RootState) => state.events.upComingMeetings);
  const role = useSelector((state: RootState) => state.roles?.roles);
  const code = useSelector((state: RootState) => state.events?.code);
  const loading = useSelector((state: RootState) => state.events.loader);
  const [showManualAttendance, setShowManualAttendance] = useState(false);

  useEffect(() => {
    dispatch(getUpcomingMeetings());
  }, [dispatch]);

  const viewMeeting = (meeting: MSGraphMeeting) => {
    window.open(meeting.webLink, '__blank');
  };

  useEffect(() => {
    if (code && code.id) {
      history.push('/events/attendance_code/' + code.id);
    }
  }, [code, history]);

  const takeAttendance = (meeting: string) => {
    dispatch(generateAttendanceCode(meeting));
  };

  const markManualAttendance = (openManualAttendance) => {
    setShowManualAttendance(openManualAttendance);
  };

  return (
    <Page header="My Events">
      <div className="float-left">
        <div style={{ color: 'grey' }}>
          {showManualAttendance ? 'Mark Attendance' : "View/Take attendance of today's upcoming events"}
        </div>
      </div>
      {role === roles.LECTURER && (
        <div className="float-right">
          {showManualAttendance ? (
            <Button color="primary" variant="outlined" onClick={() => markManualAttendance(false)}>
              Back
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<EditOutlined />}
              onClick={() => markManualAttendance(true)}
            >
              Manual Attendance
            </Button>
          )}
        </div>
      )}
      <br />
      <br />
      {!showManualAttendance && (
        <Paper style={{ marginTop: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>Duration (min)</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {!loading && (
              <TableBody>
                {upComingMeetings.length === 0 && <div style={{ margin: 20 }}>No upcoming event is available for you today</div>}
                {upComingMeetings.length > 0 && upComingMeetings.map((m) => {
                  // const startDate = moment.tz(m.start.dateTime, m.start.timeZone).tz(moment.tz.guess());
                  // const endDate = moment.tz(m.end.dateTime, m.end.timeZone).tz(moment.tz.guess());
                  // const duration = moment.duration(endDate.diff(startDate)).asMinutes();
                  const start = moment(m.startDate);
                  return (
                    <TableRow key={m.timetableSlotId}>
                      <TableCell>{m.meetingSubject}</TableCell>
                      <TableCell>{start.format('Do MMMM, yy')}</TableCell>
                      <TableCell>{start.format('hh:mm a')}</TableCell>
                      <TableCell>{m.duration}</TableCell>
                      <ActionCell>
                        {/* <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          startIcon={<OpenInNewOutlined />}
                          onClick={() => viewMeeting(m)}
                        >
                          View
                        </Button> */}
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          startIcon={<EditOutlined />}
                          onClick={() => takeAttendance(m)}
                        >
                          Generate QR Code
                        </Button>
                      </ActionCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {loading && <Loader />}
        </Paper>
      )}
      {showManualAttendance && <ManualAttendance />}
    </Page>
  );
}
