import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { makeStyles, createStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Link, List, ListItem, ListItemIcon, ListItemText, Typography, colors, Theme } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from '../util/bytesToSize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dropZone: {
      border: `1px dashed ${theme.palette.divider}`,
      padding: theme.spacing(6),
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    dragActive: {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
    },
    image: {
      width: 130,
    },
    info: {
      marginTop: theme.spacing(1),
    },
    list: {
      maxHeight: 320,
    },
    actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);
type FilesDropzoneProps = {
  className?: string;
  onUploadFile: (file: any) => void;
  onRemoveFile?: () => void;
  uploadError?: string;
  setUploadError: (error: string) => void;
  fileLoadPercentage: number;
  onFileSelected: () => void;
  isUploading?: boolean;
  disableUpload?: boolean;
};
const FilesDropzone: React.FC<FilesDropzoneProps> = ({
  fileLoadPercentage,
  setUploadError,
  onFileSelected,
  uploadError,
  className,
  onUploadFile,
  isUploading,
  onRemoveFile,
  disableUpload = false,
  ...rest
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles((prevFiles) => [].concat(acceptedFiles));
      setUploadError('');
      onFileSelected();
    },
    [onFileSelected, setUploadError]
  );

  const handleRemoveAll = () => {
    setFiles([]);
    if (onRemoveFile) {
      onRemoveFile();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src="/images/undraw_add_file2_gvbb.svg" />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Typography className={classes.info} color="textSecondary" variant="body1">
            Drop files here or click <Link underline="always">browse</Link> thorough your machine
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <List className={classes.list}>
            {files.map((file: any, i) => (
              <>
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>
                        {file.name}
                        <Typography style={{ color: 'red' }}>{uploadError}</Typography>
                      </Typography>
                    }
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                </ListItem>
                <LinearProgress variant="determinate" value={fileLoadPercentage} />
              </>
            ))}
          </List>

          <div className={classes.actions}>
            <Button disabled={isUploading} onClick={handleRemoveAll} size="small">
              Remove
            </Button>
            <Button
              disabled={isUploading || disableUpload}
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => onUploadFile(files[0])}
            >
              Upload file
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FilesDropzone;
