import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Paper } from '@material-ui/core';
import { theme } from '../../theme';

export const ScrollBar = styled(PerfectScrollbar)`
  max-height: 600px;
`;

export const GraphContainer = styled.div`
  padding: 10px ${theme.spacing(2)}px;
`;

export const SearchContainer = styled.div`
  margin: ${theme.spacing(2)}px 0;
`;

export const TableContainer = styled(Paper)`
  margin: ${theme.spacing(1)}px 0;
`;

export const Title = styled.div`
  margin: ${theme.spacing(2)}px 0;
  font-weight: bolder;
  font-size: 1.2em;
  color: #444444;
`;

export const DataCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: ${theme.palette.text.secondary};
`;
