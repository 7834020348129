import { ON_LOADING_FINISHED, ON_LOADING_START } from '../actionTypes';
import { ActionType } from './types'

const initialState = {
  loading: false
};


const Common = (state = initialState, action: ActionType) => {

  switch (action.type) {
    case ON_LOADING_START: {
      return {
        ...state,
        loading: true
      }
    }

    case ON_LOADING_FINISHED: {
      return {
        ...state,
        loading: false
      }
    }

    default: {
      return { ...state }
    }

  }

}

export default Common