import { Button, Card, createStyles, Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ReCron, Tab } from '@sbzen/re-cron';
import cronstrue from 'cronstrue';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import '../../assets/scss/scheduler.scss';
import { recurrenceDataLabels } from '../../util/constants';
import { SchedulerContext } from '../../views/intervention/addIntervention1';
import { FlexRowDiv, StrongDiv } from '../Common';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import moment from 'moment';

const INITIAL_CRON_EXP = '0 0 0 ? * * *';

//const TABS = [Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR]
const TABS = [Tab.DAY, Tab.MONTH, Tab.YEAR];

const CustomExplain = styled.div`
  margin: 4px;
  margin-top: 50px;
  margin-bottom: 40px;
`;

const SchedulerForInterventionTrigger: React.FC = () => {
  const [open, setModalOpen] = useState(false);
  
  const { recurrenceValue, setRecurrenceValue } = useContext(SchedulerContext);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const getCronText = (input: string) => {
    if (input === 'At 12:00 AM') {
      return 'Every Day';
    } else {
      let newInput = input.replace('At 12:00 AM, ', '');
      return newInput.charAt(0).toUpperCase() + newInput.slice(1);
    }
  };

  const upcomingCron =
    recurrenceValue?.cronExpression && recurrenceValue?.cronExpression.length
      ? recurrenceValue?.cronExpression
      : INITIAL_CRON_EXP;
  const [quartzCron, setQuartzCron] = useState(upcomingCron);
  const [cronText, setCronText] = useState(getCronText(cronstrue.toString(upcomingCron)));
  const [endDate, setEndDate] = useState<Date>(recurrenceValue?.cronExpression ? new Date(recurrenceValue?.endDate) :  new Date());
  const handleRecurrenceData = (cronExpression, newEndDate) => {
    
      let cronText = getCronText(cronstrue.toString(cronExpression));
      let obj = { cronExpression, cronText, endDate:new Date(newEndDate).toISOString() };
      console.log('Recurrence data - ',obj)
      setEndDate(newEndDate);
      setQuartzCron(cronExpression);
      setCronText(cronText);
      setRecurrenceValue(obj);
   
   
  };

  const renderModal = () => {
    return (
      <div>
        <div className="my-cron">
          <ReCron
            value={quartzCron}
            tabs={TABS}
            onChange={(e) => {
              handleRecurrenceData(e, endDate);
            }}
          />
          <div className={'float-right'}>
            {' '}
            <Button
              color="default"
              size={'small'}
              variant="contained"
              onClick={() => {
                handleRecurrenceData(INITIAL_CRON_EXP, endDate);
              }}
              endIcon={<Close />}
            >
              Clear
            </Button>
          </div>
         
         
          <CustomExplain>
            <div style={{ color: '#169d16', fontWeight: 'bold', width: 623 }}>{cronText}</div>
          </CustomExplain>
          <div style={{ margin: 12}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  value={endDate}
                  format="MMMM d, yyyy h:mm a"
                  inputVariant="outlined"
                  label="End Date"
                  style={{ width: 600 }}
                  onChange={(newValue: any) => {
                    handleRecurrenceData(quartzCron,newValue)
                  }}
                />
              </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Grid item>
        <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-simple-select-labelTrigger">
          Scheduled for:
        </InputLabel>

        <div
          id="demo-simple-select-labelTrigger"
          style={{ margin: 12, width: 600, cursor: 'pointer' }}
          onClick={handleModalOpen}
        >
          {!open && Object.keys(recurrenceValue).length > 0 ? (
            <>
              {' '}
              <Card style={{ padding: '12px', cursor: 'pointer', paddingBottom: 0 }}>
                {Object.keys(recurrenceValue).map((y) => {
                  return (
                    <>
                      <FlexRowDiv>
                        <div style={{ fontSize: 14 }}>
                          {recurrenceDataLabels[y]}
                          {':'}
                        </div>{' '}
                        <StrongDiv>
                          {y === 'cronExpression' ? recurrenceValue[y].substring(2) : y === 'endDate' ? moment(recurrenceValue[y]).format('ddd, MMM DD YYYY H:mm A') : recurrenceValue[y]}
                        </StrongDiv>
                      </FlexRowDiv>
                      <br />
                    </>
                  );
                })}
              </Card>
             
            </>
          ) : (
            !open && (
              <Button variant="contained" color={'primary'}>
                Set Scheduler
              </Button>
            )
          )}
        </div>
      </Grid>
      {open && renderModal()}
    </>
  );
};

export default SchedulerForInterventionTrigger;
