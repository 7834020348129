import React, { FunctionComponent } from 'react';
import { makeStyles, Chip, Avatar } from '@material-ui/core';
import { colorCodes } from '../util/constants';

const useStyles = (type: string) =>
  makeStyles((theme) => ({
    wrapper: {
      '& > *': {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        color: '#232424',
        fontWeight: 'bold',
      },
    },
    chipColor: {
      borderColor: colorCodes[type],
      color: colorCodes[type],
    },
    avatarColor: {
      backgroundColor: `${colorCodes[type]} !important`,
    },
  }));

type ChipProps = {
  label: any;
  type?: string;
  onClick?: () => void;
  avatarLabel?: string;
  avatar?: boolean;
};

const CustomChip: FunctionComponent<ChipProps> = ({
  label,
  type = 'default',
  onClick,
  avatarLabel = '',
  avatar = true,
}) => {
  const classes = useStyles(type)();
  return (
    <div className={classes.wrapper}>
      <Chip
        avatar={avatar ? <Avatar className={classes.avatarColor}>{avatarLabel}</Avatar> : undefined}
        label={label}
        className={classes.chipColor}
        color="primary"
        onClick={onClick}
        variant="outlined"
      />
    </div>
  );
};

export default CustomChip;
