import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoricalReports, onHistoricalReportsSearchChanged } from '../../actions';
import SearchBar from '../../components/searchBar';
import { RootState } from '../../reducer';
import Result from './result';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);
const initialValues = {
  id: '',
  type: '',
  content: '',
  status: '',
  createdAt: '',
  updatedAt: '',
};

export default function HistoricalReports() {
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const historicalReports = useSelector((state: RootState) => state.reports?.historicalReports);
  const searchText = useSelector((state: RootState) => state.reports?.searchText);
  const historyLoader = useSelector((state: RootState) => state.reports?.historyLoader);

  useEffect(() => {
    dispatch(getHistoricalReports(searchText, page + 1, rowsPerPage));
  }, [dispatch, searchText, page, rowsPerPage]);

  const onSearchTextChanged = (newSearchText: string) => {
    setPage(0);
    dispatch(onHistoricalReportsSearchChanged(newSearchText));
  };

  const onSearch = () => {
    dispatch(getHistoricalReports(searchText, page + 1, rowsPerPage));
  };

  const onFilterClear = () => {
    dispatch(getHistoricalReports('', 1, rowsPerPage));
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getHistoricalReports(searchText, newPage + 1, rowsPerPage));
  };

  const onRowPerPageChanged = (newRowPerPage: number) => {
    setPage(0);
    setRowsPerPage(newRowPerPage);
    dispatch(getHistoricalReports(searchText, 1, newRowPerPage));
  };

  return (
    <div className={classes.container}>
      <SearchBar
        onSearch={onSearch}
        searchPlaceHolder={'Search by Report type..'}
        onSearchTextChanged={onSearchTextChanged}
        searchText={searchText}
        onFilterClear={onFilterClear}
        initialValues={initialValues}
        values={values}
        setValues={setValues}
      ></SearchBar>
      <Result
        reports={historicalReports?.rows || []}
        count={historicalReports?.count || 0}
        className={classes.results}
        page={page}
        setPage={onPageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onRowPerPageChanged}
        historyLoader={historyLoader}
      />
    </div>
  );
}
