import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { getInstitutionIndices, getUnitesByLecturer } from '../../actions';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { Link, useParams } from 'react-router-dom';
import { RootState } from '../../reducer';
import { LineGraph } from '../../components/LineGraph';
import * as Layout from '../dashboard-commons/Layout';
import {
  assessmentIndicesData,
  attendanceSplit,
  indicesData,
  legacyIndicesData,
  materialIndicesData,
} from '../../util/common';
import { indices } from '../../util/enums';
import { colorCodes } from '../../util/constants';
import { getImprovementAreas } from '../../actions/improvementArea';
import { useOktaAuth } from '@okta/okta-react';
import SearchBar from '../../components/search-bar';
import { UnitInfo } from '../../reducer/types';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const indexData = useSelector((state: RootState) => state.institution?.indices);
  const indicesLoader = useSelector((state: RootState) => state.institution?.indicesLoader);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const units = useSelector((state: RootState) => state.courses?.units);
  const [tableData, setTableData] = useState<UnitInfo[] | undefined>([]);
  const buCodes = useSelector((state: RootState) => state.users?.buCodes);
  const loader = useSelector((state: RootState) => state.courses?.loader);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const {
    startDate = new Date(),
    endDate = new Date(),
    preStartDate = new Date(),
    teachingEndDate = new Date(),
  } = semestersData;

  const { engagement = [], assessment = [], legacy = [], materialIndex = [] } = indexData;
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      if (user.email) {
        dispatch(getUnitesByLecturer(selectedBuCode, selectedSemester, user?.email));
      }
    };
    getAvatar();
  }, [dispatch, selectedBuCode, selectedSemester]);

  useEffect(() => {
    if (units && units.length > 0) {
      setTableData(units);
      const unitCodes = units.map((x) => x.unitCode);
      dispatch(getInstitutionIndices(unitCodes));
    }
  }, [dispatch, units]);

  useEffect(() => {
    dispatch(getImprovementAreas());
  }, [dispatch]);

  const onSearch = (searchText: string) => {
    let filtered_units: UnitInfo[] = [];
    if (units) {
      filtered_units = units.filter(
        (x) =>
          x.unitName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
          x.unitCode.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }
    setTableData(filtered_units);
  };

  const engagementData = useMemo(() => {
    const { mandatoryAttendance, nonMandatoryAttendance, employabilityAttendance } = attendanceSplit(engagement);
    const mandatoryEngagementIndex =
      mandatoryAttendance.length > 0
        ? indicesData(mandatoryAttendance, startDate, teachingEndDate, preStartDate)
        : mandatoryAttendance;
    const nonMandatoryEngagementIndex =
      nonMandatoryAttendance.length > 0
        ? indicesData(nonMandatoryAttendance, startDate, endDate, preStartDate)
        : nonMandatoryAttendance;
    const employabilityEngagementIndex =
      employabilityAttendance.length > 0
        ? indicesData(employabilityAttendance, startDate, endDate, preStartDate)
        : employabilityAttendance;
    return {
      mandatoryEngagementIndex:
        mandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.MANDATORY_ATTENDANCE,
                color: colorCodes.mandatory,
                data: mandatoryEngagementIndex,
              },
            ]
          : [],
      nonMandatoryEngagementIndex:
        nonMandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.NON_MANDATORY_ATTENDANCE,
                color: colorCodes.nonMandatory,
                data: nonMandatoryEngagementIndex,
              },
            ]
          : [],
      employabilityEngagementIndex:
        employabilityEngagementIndex.length > 0
          ? [
              {
                id: indices.EMPLOYABILITY,
                color: colorCodes.employability,
                data: employabilityEngagementIndex,
              },
            ]
          : [],
    };
  }, [engagement, startDate, endDate, preStartDate]);

  const assessmentData = useMemo(() => {
    const assessmentIndex = assessment.length > 0 ? assessmentIndicesData(assessment, startDate, endDate) : assessment;
    return assessmentIndex.length > 0
      ? [
          {
            id: indices.ASSESSMENT,
            color: colorCodes.assessment,
            data: assessmentIndex,
          },
        ]
      : [];
  }, [assessment, startDate, endDate]);

  const materialData = useMemo(() => {
    const materialIndexData =
      materialIndex.length > 0 ? materialIndicesData(materialIndex, startDate, endDate) : materialIndex;
    return materialIndexData.length > 0
      ? [
          {
            id: indices.MATERIAL_INDEX,
            color: colorCodes.material,
            data: materialIndexData,
          },
        ]
      : [];
  }, [materialIndex, startDate, endDate]);

  const legacyData = useMemo(() => {
    const legacyIndex = legacy.length > 0 ? legacyIndicesData(legacy, startDate, endDate) : legacy;
    return legacyIndex.length > 0
      ? [
          {
            id: indices.LEGACY_INDEX,
            color: colorCodes.legacy,
            data: legacyIndex,
          },
        ]
      : [];
  }, [legacy, startDate, endDate]);
  const bu = buCodes.find((x) => x.buCode === selectedBuCode);
  return (
    <Page header="Institution Dashboard" subHeader={bu?.name}>
      <div>
        {!indicesLoader ? (
          <>
            {engagement.length > 0 || assessment.length > 0 || materialIndex.length > 0 ? (
              <LineGraph
                min={0}
                max={100}
                data={[
                  ...(engagementData.mandatoryEngagementIndex || []),
                  ...(engagementData.nonMandatoryEngagementIndex || []),
                  ...(engagementData.employabilityEngagementIndex || []),
                  ...(assessmentData || []),
                  ...(legacyData || []),
                  ...(materialData || []),
                ]}
                dataText="%"
                xText="Week"
                yText="Index %"
              />
            ) : (
              <div style={{ textAlign: 'center' }}>No Indices to display</div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
      <Layout.Title>Units</Layout.Title>
      <Divider variant="fullWidth"></Divider>
      <Layout.SearchContainer>
        <SearchBar onSearch={onSearch} searchPlaceHolder="Search Units" />
      </Layout.SearchContainer>
      {tableData && loader && <Layout.DataCount>{tableData.length} Records found.</Layout.DataCount>}
      <Layout.TableContainer>
        {tableData && loader ? (
          <Layout.ScrollBar>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Unit Code</TableCell>
                  <TableCell align="center">Unit Name</TableCell>
                  <TableCell align="center">Academic Performance Index</TableCell>
                  <TableCell align="center">Engagement Index</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((unit: UnitInfo) => {
                  return (
                    <TableRow key={unit.unitCode}>
                      <TableCell align="center">{unit.unitCode}</TableCell>
                      <TableCell align="center">{unit.unitName}</TableCell>
                      <TableCell align="center">
                        {unit.indices?.academic ? unit.indices.academic.toFixed(2) : '-'}
                      </TableCell>
                      <TableCell align="center">
                        {unit.indices?.engagement ? unit.indices.engagement.toFixed(2) : '-'}
                      </TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        <Link
                          className="noUnderLine"
                          to={{
                            pathname: '/units/' + unit.unitCode,
                          }}
                        >
                          <Button color="secondary" style={{ marginLeft: 10 }} size="small" variant="outlined">
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Layout.ScrollBar>
        ) : (
          <Loader />
        )}
      </Layout.TableContainer>
    </Page>
  );
};

export default Dashboard;
