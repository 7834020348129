import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Add } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInterventionData } from '../../actions';
import { getImprovementAreas } from '../../actions/improvementArea';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import { Row } from './components/dashboardTableRow';
import { interventionDataFromJson } from './data/data';
import { dashboardUseStyles } from './intervention_styles';

interface Data {
  collapse: number;
  id: number;
  labels: string;
  units: string[];
  createdAt: string;
  type: string;
  status: string;
  trigger: string;
  semester: string;
}

const rows = interventionDataFromJson;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: false, label: 'Id' },
  { id: 'labels', numeric: true, disablePadding: false, label: 'Label' },
  { id: 'trigger', numeric: true, disablePadding: false, label: 'Trigger' },
  { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
  { id: 'units', numeric: true, disablePadding: false, label: 'Units' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Created At' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Action</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = dashboardUseStyles;

export default function Interventions() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const interventionData = useSelector((state: RootState) => state.intervention?.interventionData);

  const interventionLoader = useSelector((state: RootState) => state.intervention?.interventionLoader);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(getInterventionData());
    dispatch(getImprovementAreas());
  }, [dispatch]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Page header={'Interventions'} showBackButton={true}>
      <Paper className={classes.paper}>
        <div className="float-right">
          <Link
            className="noUnderLine"
            to={{ pathname: '/interventions/add-new', state: { labels: null, units: null, trigger: null } }}
          >
            <Button variant="contained" color="secondary" className={classes.button} startIcon={<Add />} size="small">
              Add Intervention
            </Button>
          </Link>
        </div>
        <br />
        <br />
        <TableContainer>
          {!interventionLoader ? (
            <Table>
              <EnhancedTableHead
                classes={classes}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={interventionData.length}
              />

              <TableBody>
                {stableSort(interventionData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return <Row key={row.id} row={row} labelId={labelId} />;
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Loader />
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={interventionData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Page>
  );
}
