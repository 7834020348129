import { post, get } from "./common";
import { PACE_API_URL, USERMGMT_API } from '../config/constants';
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { SET_BU_CODES, SET_SELECTED_BU_CODE, SET_SELECTED_SEMESTER, SET_SEMESTERS } from "../actionTypes";
import { storageCodes } from "../util/enums";
import { GET_MY_ROLES, MY_ROLE_URL, SEMESTER_CODE_URL } from ".";


export const getSemesters = (
  buCode: string
): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const paceUrl = await PACE_API_URL()
    const result = await post(`${paceUrl}/semester/find?buCode=${buCode}`, {
    });
    dispatch({ type: SET_SEMESTERS, payload: { buCode, semesters: result.rows || [] } });
  });
};


export const getBaseUrls = (
  emailId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const result = await get(`${USERMGMT_API}/user/getUserByEmail/${emailId}`);
    const { businessUnits = [], applications = [] } = result || {}
    const paceObj = {};
    const capriObj = {};
    const buCodes = [] as any
    businessUnits.sort((a, b) => (a.BUEntity.buCode > b.BUEntity.buCode) ? 1 : ((b.BUEntity.buCode > a.BUEntity.buCode) ? -1 : 0));
    businessUnits.forEach((x) => {
      const { BUEntity = {} } = x || {}
      const { PACE_URL = "", buCode = "", CAPRI_URL = "", name = "" } = BUEntity || {}
      const buAppRecord = applications.find((appRec) => appRec.ApplicationEntity.name === "CAPRI" && x.buId === appRec.buId);
      if (buAppRecord) {
        paceObj[buCode] = PACE_URL
        capriObj[buCode] = CAPRI_URL
        const obj = { buCode, name }
        buCodes.push(obj)
      }
    })
    await localStorage.setItem(
      storageCodes.PACE_API,
      JSON.stringify(paceObj)
    );
    await localStorage.setItem(
      storageCodes.CAPRI_API,
      JSON.stringify(capriObj)
    );
    localStorage.setItem(storageCodes.BU_CODES, JSON.stringify(buCodes || []));
    const code = (buCodes || [])[0] || {};
    const finalBuCode = getState().users?.selectedBuCode ? getState().users?.selectedBuCode : code['buCode'] || '';
    localStorage.setItem(storageCodes.BU_CODE_SELECTED, finalBuCode);
    dispatch({ type: SET_SELECTED_BU_CODE, payload: finalBuCode });
    // set new semester code
    const semesterCodeUrl = await SEMESTER_CODE_URL(finalBuCode);
    const semesterCode = await get(semesterCodeUrl);
    if (finalBuCode) {
      dispatch(getSemesters(finalBuCode))
    }
    localStorage.setItem(storageCodes.SEMESTER_SELECTED, semesterCode);
    dispatch({ type: SET_SELECTED_SEMESTER, payload: semesterCode });
    dispatch({ type: SET_BU_CODES, payload: buCodes });
    resolve(result);
  });
};