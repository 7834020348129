import { Divider } from '@material-ui/core';
import { Backspace, Star } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { CloseNodeButton } from '../../components/closeNodeButton';
import { DivRows } from '../../flowChartComponents/styled-framework-items';

const Outer = styled.div`
  padding: 30px;
  color: #605d1b;
`;
const CustomNodeTitle2 = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: #04337824;
  color: black;
  font-size: 20px;
  padding: 15px;
  text-align: center;
`;

const CustomBackSpaceSpan = styled.span`
border: 1px solid #543436;
padding: 8px;
border-radius: 8px;
color: #543436;
margin: 8px;
}
`;
export const Notes: React.FC = () => {

  return (
    <div>
      <CustomNodeTitle2>
        <Star style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Important
        <CloseNodeButton />
      </CustomNodeTitle2>
      <Divider />
      <Outer>
        <DivRows>
          <span>
            You can delete the node by selecting it with single click and then press{' '}
            <CustomBackSpaceSpan>
              Backspace <Backspace />
            </CustomBackSpaceSpan>{' '}
            on keyboard.
          </span>
        </DivRows>
      
      </Outer>
    </div>
  );
};
