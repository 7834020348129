import { Divider, Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  findAtRiskReports,
  getAssignment,
  getStudentByUnitId,
  getUnitAssessmentIndex,
  getUnitEngagementIndex,
  getUnitInfoById,
  getUnitMaterialIndex,
  resetStudentDetails,
} from '../../actions';
import { StyledTab } from '../../components/Common';
import { LineGraph } from '../../components/LineGraph';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { REPORT_REFRESH_TIME } from '../../config/constants';
import { RootState } from '../../reducer';
import { theme } from '../../theme';
import { assessmentIndicesData, attendanceSplit, indicesData, materialIndicesData } from '../../util/common';
import { colorCodes } from '../../util/constants';
import { indices } from '../../util/enums';
import EventsDashboard from '../events/events-dashboard';
import Assignment from './assignments/assignment';
import UnitStudents from './students/students';
import './units.scss';

const CustomDivider = styled(Divider)`
  margin-bottom: ${theme.spacing(2)}px;
`;

const tabs = [
  { value: 'progression', label: 'Progression' },
  { value: 'students', label: 'Students' },
  { value: 'assignments', label: 'Assignments' },
  { value: 'events', label: 'Events' },
];

const UnitDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const unitData = useSelector((state: RootState) => state.units?.unitData);
  const unitInfoLoader = useSelector((state: RootState) => state.units?.unitInfoLoader);
  const assignmentData = useSelector((state: RootState) => state.units?.assignmentData);
  const unitAssessmentIndex = useSelector((state: RootState) => state.units?.unitAssessmentIndex);
  const unitEngagementIndex = useSelector((state: RootState) => state.units?.unitEngagementIndex);
  const unitMaterialIndex = useSelector((state: RootState) => state.units?.unitMaterialIndex);
  const loading = useSelector((state: RootState) => state.common?.loading);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const {
    startDate = new Date(),
    endDate = new Date(),
    teachingEndDate = new Date(),
    preStartDate = new Date(),
  } = semestersData;

  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { unitId } = useParams<{ unitId: string; tab: string }>();
  const location = useLocation();

  const assessmentIndexData = unitAssessmentIndex[unitId] || [];
  const engagementIndexData = unitEngagementIndex[unitId] || [];
  const materialIndexData = unitMaterialIndex[unitId] || [];

  // Reports
  const [isReports, setReports] = useState(true);
  const [interval, setInt] = useState<any>();
  const reportData = useSelector((state: RootState) => state.units?.reportData);
  const reportId = useSelector((state: RootState) => state.units?.reportIdInfo);

  //Reports

  useEffect(() => {
    if (reportId && reportId.id && isReports) {
      const intervalId = setInterval(() => {
        dispatch(findAtRiskReports(reportId.id));
      }, REPORT_REFRESH_TIME);
      setInt(intervalId);
    }
    if (!isReports) {
      return () => clearInterval(interval);
    }
  }, [dispatch, reportId, isReports, interval]);

  useEffect(() => {
    if (reportData && reportData.content) {
      setReports(false);
      clearInterval(interval);
    }
  }, [reportData, interval]);

  useEffect(() => {
    dispatch(getUnitAssessmentIndex(unitId));
    dispatch(getUnitEngagementIndex(unitId));
    dispatch(getUnitMaterialIndex(unitId));
  }, [unitId]);

  useEffect(() => {
    dispatch(resetStudentDetails());
    dispatch(getUnitInfoById(unitId));
    dispatch(getStudentByUnitId(unitId));
    dispatch(getAssignment(unitId));
  }, [dispatch, unitId]);

  const handleTabsChange = ({}, tabUrl: string) => {
    history.push(tabUrl);
  };

  const engagementData = useMemo(() => {
    const { mandatoryAttendance, nonMandatoryAttendance, employabilityAttendance } =
      attendanceSplit(engagementIndexData);
    const mandatoryEngagementIndex =
      mandatoryAttendance.length > 0
        ? indicesData(mandatoryAttendance, startDate, teachingEndDate, preStartDate)
        : mandatoryAttendance;
    const nonMandatoryEngagementIndex =
      nonMandatoryAttendance.length > 0
        ? indicesData(nonMandatoryAttendance, startDate, endDate, preStartDate)
        : nonMandatoryAttendance;
    const employabilityEngagementIndex =
      employabilityAttendance.length > 0
        ? indicesData(employabilityAttendance, startDate, endDate, preStartDate)
        : employabilityAttendance;
    return {
      mandatoryEngagementIndex:
        mandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.MANDATORY_ATTENDANCE,
                color: colorCodes.mandatory,
                data: mandatoryEngagementIndex,
              },
            ]
          : [],
      nonMandatoryEngagementIndex:
        nonMandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.NON_MANDATORY_ATTENDANCE,
                color: colorCodes.nonMandatory,
                data: nonMandatoryEngagementIndex,
              },
            ]
          : [],
      employabilityEngagementIndex:
        employabilityEngagementIndex.length > 0
          ? [
              {
                id: indices.EMPLOYABILITY,
                color: colorCodes.employability,
                data: employabilityEngagementIndex,
              },
            ]
          : [],
    };
  }, [engagementIndexData, startDate, endDate, preStartDate]);

  const assessmentData = useMemo(() => {
    const assessmentIndex =
      assessmentIndexData.length > 0
        ? assessmentIndicesData(assessmentIndexData, startDate, teachingEndDate)
        : assessmentIndexData;
    return assessmentIndex.length > 0
      ? [
          {
            id: indices.ASSESSMENT,
            color: colorCodes.assessment,
            data: assessmentIndex,
          },
        ]
      : [];
  }, [assessmentIndexData, startDate, teachingEndDate]);

  const materialData = useMemo(() => {
    console.log();
    const materialIndex =
      materialIndexData.length > 0 ? materialIndicesData(materialIndexData, startDate, endDate) : materialIndexData;
    return materialIndex.length > 0
      ? [
          {
            id: indices.MATERIAL_INDEX,
            color: colorCodes.material,
            data: materialIndex,
          },
        ]
      : [];
  }, [materialIndexData, startDate, endDate]);

  // Search form
  return (
    <>
      {!unitInfoLoader ? (
        <>
          {!_.isEmpty(unitData) ? (
            <>
              <Page
                header={unitData.unitCode + ' - ' + unitData.unitName}
                showBackButton
                subHeader={location.pathname.split('/').pop()}
              >
                <Tabs
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  value={location.pathname}
                  // indicatorColor="primary"
                  textColor="primary"
                >
                  {tabs.map((tab) => (
                    <StyledTab key={tab.value} label={tab.label} value={url + '/' + tab.value} />
                  ))}
                </Tabs>
                <CustomDivider variant="fullWidth" />
                <Switch>
                  <Route path={path + '/progression'}>
                    {!loading ? (
                      <>
                        {engagementIndexData.length > 0 || assessmentIndexData.length > 0 || materialData.length > 0 ? (
                          <LineGraph
                            min={0}
                            max={100}
                            data={[
                              ...(engagementData.mandatoryEngagementIndex || []),
                              ...(engagementData.nonMandatoryEngagementIndex || []),
                              ...(assessmentData || []),
                              ...(materialData || []),
                            ]}
                            dataText="%"
                            xText="Week"
                            yText="Index %"
                          />
                        ) : (
                          <div style={{ textAlign: 'center' }}>No Indices to display</div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </Route>
                  <Route path={path + '/students'}>
                    <UnitStudents />
                  </Route>
                  <Route path={path + '/assignments'}>
                    <Assignment data={assignmentData} id={unitData.id} />
                  </Route>
                  <Route path={path + '/events'} component={EventsDashboard} />
                  <Route path={path} exact>
                    <Redirect to={url + '/progression'}></Redirect>
                  </Route>
                </Switch>
              </Page>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>Unit Info is not available</div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UnitDashboard;
