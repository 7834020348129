import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getComments } from '../../actions';
import { RootState } from '../../reducer';
import { Card, Grid, CardContent, Divider, CardHeader } from '@material-ui/core';
import Loader from '../../components/Loader';
import moment from 'moment';
import { CustomTable } from '../../components/Common';
import { AssignmentLogLabelsColorCodes, AssignmentLogTypes } from '../../util/constants';

const AssignmentLogs: React.FC<any> = ({ id, submissions, results }) => {
  const logs = useSelector((state: RootState) => state.assignmentDetails?.logs);
  const logsLoader = useSelector((state: RootState) => state.assignmentDetails?.logsLoader);
  const decoratedTableData = logs.map((log: any) => {
    const { createdAt, createdBy, type, others } = log;
    return [
      createdBy ? createdBy : 'NA',
      <div>
        <span
          style={{
            backgroundColor: AssignmentLogLabelsColorCodes[type].background,
            padding: '3px',
            borderRadius: '6px',
            fontSize: '13px',
          }}
        >
          {AssignmentLogTypes[type]}
        </span>
      </div>,
      moment(createdAt).format('llll'),
      others,
    ];
  });
  return (
    <>
      {logs && !logsLoader ? (
        <CustomTable
          tableHead={[
            { text: 'Created By' },
            { text: 'Type' },
            { text: 'Created At' },
            {
              text: 'Other Info',
            },
          ]}
          tableData={decoratedTableData}
          count={logs?.length}
          noPagination={true}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AssignmentLogs;
