import { post, put, get } from './common';
import { GET_ALL_CALLBACKS, ON_LOADING_FINISHED, ON_LOADING_START, CALLBACK_REPORT_FINISHED, CALLBACK_REPORT_START, SET_CALL_BACK_REPORT_RESPONSE, EDIT_CALL_BACK, GET_COMMENTS_LOADING_START, GET_COMMENTS_FOR_CALL_BACK, GET_COMMENTS_LOADING_FINISHED } from "../actionTypes"
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { API_URL, CAPRI_CALLBACK_API } from '../config/constants'
import { redirect } from "./user"
import { CallbackSearchFilters } from '../util/types';
import { findInstitutionalReports } from './institutional-reports';
import { reportTypes } from '../util/enums';


export const getAllCallbacks = (searchText: string,
  filters: CallbackSearchFilters,
  page: number,
  recordPerPage: number): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await post(`${await API_URL()}/call-back`, {
        limit: recordPerPage,
        offset: recordPerPage * page - recordPerPage,
        searchText,
        filters,
      });
      dispatch({ type: GET_ALL_CALLBACKS, payload: result });
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      dispatch({
        type: GET_ALL_CALLBACKS,
        payload: {}
      });
      dispatch({ type: ON_LOADING_FINISHED });
    }
  };

export const createCallback = (
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  callbackDate: Date,
  slot: string[], timeZone: string, reference: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
      dispatch({ type: ON_LOADING_START });
      const url = `${CAPRI_CALLBACK_API}/call-back/${email}`
      await post(url, {
        email,
        firstName,
        lastName,
        phoneNumber,
        callbackDate,
        slot,
        timeZone,
        reference
      });
      dispatch(redirect(`/callback_success`))
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  };


export const generateCallbackReports = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {

  try {
    dispatch({ type: CALLBACK_REPORT_START });
    const url = `${await API_URL()}/call-back/report/generate`
    const result = await post(url, { type: "type" });
    if (result) {
      dispatch({ type: SET_CALL_BACK_REPORT_RESPONSE, payload: "" });
      dispatch(findInstitutionalReports(result.id, reportTypes.CALLBACKS));
    }
  } catch (error) {
    dispatch({
      type: SET_CALL_BACK_REPORT_RESPONSE,
      payload: "",
    });
    dispatch({ type: CALLBACK_REPORT_FINISHED });
  }

};

export const editClosure = (id: number, comment: string, closed: boolean): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await API_URL()}/call-back`
    await put(url, { id, comment, closed });
    dispatch({ type: EDIT_CALL_BACK, payload: { id, closed } });
  } catch (error) {
    dispatch({
      type: EDIT_CALL_BACK,
      payload: {}
    });
  }
};

export const getCommentsForCallback = (id: number): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_COMMENTS_LOADING_START })
    const result = await get(`${await API_URL()}/call-back/${id}/comments`);
    dispatch({ type: GET_COMMENTS_FOR_CALL_BACK, payload: { id, data: result } });
  } catch (error) {
    dispatch({
      type: GET_COMMENTS_FOR_CALL_BACK,
      payload: {},
    });
  }
  dispatch({ type: GET_COMMENTS_LOADING_FINISHED })
};