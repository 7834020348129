import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Settings } from '@material-ui/icons';
import React, { useState } from 'react';
import { ConfigurationForNewIntervention } from './configurationForNewIntervention';
import { SidebarFooter } from './intervention_styles';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';


type ConfigurationSidebarProps = {
    
    label: string;
    setLabel: (label: any) => void;
    selectedAssignments: any[];
    handleChange: (handleChange: any) => void;
    setSelectedAssignments: (setSelectedAssignments: any) => void;
    interventionUnits: { code: string; label: string }[];
    selectedTriggerValue: string;
    handleTriggerChange: (handleTriggerChange: any) => void;
    recurrenceValue: any;
    setRecurrenceValue: (recurrenceValue: any) => void;
    selectedStudents : any[];
    setSelectedStudents : (selectedStudents: any) => void;
    interventionStudents : any[];
  };
  
  export const ConfigurationSidebar: React.FC<ConfigurationSidebarProps> = ({ label,
    setLabel,
    selectedAssignments,
    handleChange,
    setSelectedAssignments,
    interventionUnits,
    selectedTriggerValue,
    handleTriggerChange,
    recurrenceValue,
    setRecurrenceValue, 
    selectedStudents,
    setSelectedStudents,
    interventionStudents}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchor = 'left'
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

   setOpen(open)
  };

  return (
    <div>
     
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(true)} variant="contained" color={'primary'}  startIcon={<Settings/>}>Configuration</Button>
          <SwipeableDrawer
            anchor={anchor}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
          <ConfigurationForNewIntervention 
          label = {label}
  setLabel = {setLabel}
  selectedAssignments = {selectedAssignments}
  handleChange = {handleChange}
  setSelectedAssignments = {setSelectedAssignments}
  interventionUnits = {interventionUnits}
  selectedTriggerValue = {selectedTriggerValue}
  handleTriggerChange = {handleTriggerChange}
  recurrenceValue = {recurrenceValue}
  setRecurrenceValue = {setRecurrenceValue}
  selectedStudents = {selectedStudents}
  setSelectedStudents = {setSelectedStudents}
  interventionStudents = {interventionStudents}
  />
  <SidebarFooter>
  <Button style={{width:'50%'}} onClick={toggleDrawer(false)} variant="contained" color={'primary'}>Save</Button>
  </SidebarFooter>

          </SwipeableDrawer>
        </React.Fragment>
    
    </div>
  );
}