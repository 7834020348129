import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyRole } from '../../actions';
import { RootState } from '../../reducer';
import { useOktaAuth } from '@okta/okta-react';

export default function AuthorizedRoute({ requiredRoles, component: Component, ...rest }) {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.roles?.roles);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      dispatch(getMyRole(user));
    };
    getAvatar();
  }, [dispatch]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!role) {
          return <div>Loading...</div>;
        } else if (requiredRoles.includes(role)) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/403" />;
        }
      }}
    />
  );
}
