import { get } from './common';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { API_URL } from '../config/constants';
import { GET_UNIT_ASSESSMENT_INDEX, GET_UNIT_ENGAGEMENT_INDEX, GET_UNIT_MATERIAL_INDEX } from '../actionTypes';

export const getUnitAssessmentIndex =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const url = `${await API_URL()}/unit/assessment-indices?unitCode=${unitCode}`;
        const result = await get(url);
        dispatch({ type: GET_UNIT_ASSESSMENT_INDEX, payload: { data: result, unitCode } });
      } catch (err) { }
      dispatch({ type: ON_LOADING_FINISHED });
    };

export const getUnitEngagementIndex =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const url = `${await API_URL()}/unit/engagement-indices?unitCode=${unitCode}`;
        const result = await get(url);
        dispatch({ type: GET_UNIT_ENGAGEMENT_INDEX, payload: { data: result, unitCode } });
      } catch (err) { }
      dispatch({ type: ON_LOADING_FINISHED });
    };

export const getUnitMaterialIndex =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const url = `${await API_URL()}/unit/material-indices?unitCode=${unitCode}`;
        const result = await get(url);
        dispatch({ type: GET_UNIT_MATERIAL_INDEX, payload: { data: result, unitCode } });
      } catch (err) { }
      dispatch({ type: ON_LOADING_FINISHED });
    };
