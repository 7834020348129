import { Divider, Tabs, Table, TableBody, TableRow, TableHead, TableCell } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import { getAssignmentDetails, getAssignmentLogs } from '../../actions';
import { AssignmentDetailsRouteParams } from '../../util/types';
import styled from 'styled-components';
import AssignmentDetails from './assignmnetDetails';
import AssignmentLogs from './assignmentLogs';
import { theme } from '../../theme';

import { Route, Switch, useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom';

import { StyledTab } from '../../components/Common';
import SendIntervention from './sendIntervention';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'logs', label: 'Logs' },
];

const CustomDivider = styled(Divider)`
  margin-bottom: ${theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableRow: {
      borderBottom: 'none',
      fontSize: '15px',
      color: 'grey',
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    pagination: {
      position: 'relative',
      fontSize: '14px',
      marginLeft: '10px',
      marginBottom: '9px',
      fontWeight: 'bold',
      marginTop: '14px',
    },
  })
);

const AssignmentDetailsTabs: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id, unitId } = useParams<AssignmentDetailsRouteParams>();

  const history = useHistory();
  const assignmentInfo = useSelector((state: RootState) => state.assignmentDetails?.assignmentInfo);
  const loader = useSelector((state: RootState) => state.assignmentDetails?.loader);

  useEffect(() => {
    dispatch(getAssignmentDetails(id, unitId));
    dispatch(getAssignmentLogs(id, unitId));
  }, [dispatch, id, unitId]);

  const handleTabsChange = ({}, tabUrl: string) => {
    history.push(tabUrl);
  };

  const { path, url } = useRouteMatch();
  return (
    <>
      {!loader ? (
        <Page
          header={`${assignmentInfo?.taskName} (${assignmentInfo?.taskNumber}) `}
          subHeader={`${assignmentInfo?.unitName?.toUpperCase()} - ${assignmentInfo?.unitCode}`}
          showBackButton={true}
        >
          <div className={'float-right'}>
            <SendIntervention assignmentId={id} unitCode={unitId} />
          </div>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'dueDateDetails'}>
                <TableCell className={classes.tableRow} align="left">
                  <b>Due Date:</b>
                </TableCell>
                <TableCell className={classes.tableRow} align="left">
                  {`${moment(assignmentInfo?.dueDate, 'DD/MM/YYYY').format('MMM D, YYYY')} (Week - ${
                    assignmentInfo?.dueWeek
                  })`}
                </TableCell>
              </TableRow>
              <TableRow key={'labels'}>
                <TableCell className={classes.tableRow} align="left">
                  <b>Lecturer:</b>
                </TableCell>
                <TableCell className={classes.tableRow} align="left">
                  {`${assignmentInfo?.lecturerName?.toUpperCase()}  (${assignmentInfo?.lecturerEmail})`}
                </TableCell>
              </TableRow>

              <TableRow key={'values'}>
                <TableCell className={classes.tableRow} align="left">
                  <b>Weightage:</b>
                </TableCell>

                <TableCell className={classes.tableRow} align="left">
                  {assignmentInfo?.taskWeightage}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={location.pathname}
            // indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={url + '/' + tab.value} />
            ))}
          </Tabs>
          <CustomDivider variant="fullWidth" />
          <Switch>
            <Route path={path + '/details'}>
              <AssignmentDetails />
            </Route>
            <Route path={path + '/logs'}>
              <AssignmentLogs
                id={assignmentInfo.id}
                submissions={assignmentInfo.submissions || []}
                results={assignmentInfo.results || []}
              />
            </Route>
          </Switch>
        </Page>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AssignmentDetailsTabs;
