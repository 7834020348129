export const GET_INSTITUTIONAL_REPORTS = 'GET_INSTITUTIONAL_REPORTS';
export const FIND_INSTITUTIONAL_REPORTS = 'FIND_INSTITUTIONAL_REPORTS';
export const GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK = 'GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK';
export const FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK = 'FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK';
export const FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION = 'FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION';
export const FIND_INSTITUTIONAL_REPORTS_INTERIM = 'FIND_INSTITUTIONAL_REPORTS_INTERIM';
export const GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT = 'GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT';
export const GET_INSTITUTIONAL_INTERIM_REPORT = 'GET_INSTITUTIONAL_INTERIM_REPORT';
export const FIND_INSTITUTIONAL_REPORTS_ARCHIVES = 'FIND_INSTITUTIONAL_REPORTS_ARCHIVES';
export const GET_INSTITUTIONAL_ARCHIVES_REPORT = 'GET_INSTITUTIONAL_ARCHIVES_REPORT';
export const ON_INSTITUTIONAL_REPORT_LOADING_START = 'ON_LOADING_START';
export const ON_INTERVENTION_REPORT_LOADING_START = 'ON_INTERVENTION_REPORT_LOADING_START';
export const ON_FEEDBACK_REPORT_LOADING_START = 'ON_FEEDBACK_REPORT_LOADING_START';
export const ON_INTERVENTION_REPORT_LOADING_FINISHED = 'ON_INTERVENTION_REPORT_LOADING_FINISHED';
export const ON_FEEDBACK_REPORT_LOADING_FINISHED = 'ON_FEEDBACK_REPORT_LOADING_FINISHED';
export const ON_INSTITUTIONAL_REPORT_LOADING_FINISHED = 'ON_LOADING_FINISHED';
export const GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY = 'GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY';
export const FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY =
  'FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY';
export const ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_START =
  'ON_LOADING_ATTENDANCE_REPORT_WEEKLY_START';
export const ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED =
  'ON_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED';
export const SET_INSTITUTIONAL_REPORTS_RESPONSE = 'SET_INSTITUTIONAL_REPORTS_RESPONSE';
export const SET_INSTITUTIONAL_INTERIM_RESPONSE = 'SET_INSTITUTIONAL_INTERIM_RESPONSE';
export const RESET_INSTITUTIONAL_REPORTS_RESPONSE = 'RESET_INSTITUTIONAL_REPORTS_RESPONSE';
export const SET_INSTITUTIONAL_ARCHIVES_RESPONSE = 'SET_INSTITUTIONAL_ARCHIVES_RESPONSE';
export const ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START =
  'ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START';
export const ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED =
  'ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED';
export const ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START =
  'ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START';
export const ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED =
  'ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED';
export const ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START =
  'ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START';
export const ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED =
  'ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED';
export const SET_INTERVENTION_REPORT_RESPONSE = 'SET_INTERVENTION_REPORT_RESPONSE';
export const SET_FEEDBACK_REPORT_RESPONSE = 'SET_FEEDBACK_REPORT_RESPONSE';
export const FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED = 'FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED';
export const FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED =
  'FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED';
export const FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED =
  'FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED';
export const GET_INSTITUTIONAL_NON_SUBMISSION_REPORT = 'GET_INSTITUTIONAL_NON_SUBMISSION_REPORT';
export const FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION = 'FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION';
export const FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED = 'FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED';
export const ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START =
  'ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START';
export const ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED =
  'ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED';
export const FIND_INSTITUTIONAL_REPORTS_INTERIM_FAILED =
  'FIND_INSTITUTIONAL_REPORTS_INTERIM_FAILED';
export const FIND_INSTITUTIONAL_REPORTS_ARCHIVES_FAILED =
  'FIND_INSTITUTIONAL_REPORTS_ARCHIVES_FAILED';
