import { Watermark } from '@hirohe/react-watermark';
import { Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInterventionTriggerData, getStudentAssignmentInterventionDataById } from '../../../../actions';
import Loader from '../../../../components/Loader';
import Page from '../../../../components/PageV2';
import { RootState } from '../../../../reducer';
import { chooseRandom, getRandomForRange } from '../../../../util/array';
import { InterventionReportRouteParams } from '../../../../util/types';
import { interventionReportStyles } from '../../intervention_styles';
import LineGraphForIntervention from './../../reportGraph';


const useStyles =interventionReportStyles
type Order = 'asc' | 'desc';

export interface Data {
    id: number;
    email: string;
    status: string;
    content: string;
    index: number;
    action: number;
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }
  
  const headCells: HeadCell[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'email', numeric: true, disablePadding: true, label: 'Student ID' },
    { id: 'status', numeric: true, disablePadding: true, label: 'Email Status' },
    { id: 'content', numeric: true, disablePadding: false, label: '' },
  ];
  
    
interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  
export const EnhancedTableHead=(props: EnhancedTableProps) =>{
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell> */}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }