import React from 'react';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Card, CardHeader, CardContent, ListItem, ListItemText, colors, Typography, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      flexWrap: 'wrap',
    },
    listItemText: {
      marginLeft: theme.spacing(2),
    },
    listItemTextPrimary: {
      color: colors.yellow[900],
      wordBreak: 'break-word',
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    errorText: {
      color: colors.yellow[900],
      fontWeight: 600,
    },
  })
);

type ErrorDetailsProps = {
  className?: string;
  warningResponse: any;
};
const WarningDetails: React.FC<ErrorDetailsProps> = ({ warningResponse, className, ...rest }) => {
  const classes = useStyles();

  const errorRows = warningResponse;

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const record: any = errorRows[index] || { message: '' };
    return (
      <ListItem
        className={classes.listItem}
        disableGutters
        divider={index < errorRows.length - 1}
        key={index}
        style={style}
      >
        <ListItemText
          className={classes.listItemText}
          primary={<span className={classes.listItemTextPrimary}>{record}</span>}
        />
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Card style={{ boxShadow: 'none' }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title={<Typography className={classes.errorText}>Warnings in Upload Submission :</Typography>}
        />

        <CardContent style={{ paddingTop: 0 }}>
          <PerfectScrollbar>
            <FixedSizeList
              height={60}
              width={'100%'}
              itemSize={65}
              itemCount={(_.isArray(errorRows) ? errorRows : []).length}
            >
              {renderRow}
            </FixedSizeList>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default WarningDetails;
