import { Card, Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { generateTimeTableReports, getTimeTable, onAnalysisSearchChanged, setTimeTableResponse } from '../../actions';
import { ButtonContainer, CustomTable, GenerateReportButton, StyledContent } from '../../components/Common';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import SearchBar from '../../components/searchBar';
import { RootState } from '../../reducer';
import { theme } from '../../theme';
import { eventStatus } from '../../util/enums';
import { TimeTableUnit } from '../../util/types';
import Filters from './Filters';

const SearchContainer = styled.div`
  margin: ${theme.spacing(2)}px 0;
`;

const initialValues = {
  unitCode: '',
  day: '',
  status: eventStatus.CAPTURED,
  teamType:[],
};

export default function TimetableDashboard() {
  const dispatch = useDispatch();

  const reportLoader = useSelector((state: RootState) => state.timetable?.reportLoader);
  const reportData = useSelector((state: RootState) => state.timetable?.reportData);
  const loader = useSelector((state: RootState) => state.users.loader);
  const searchText = useSelector((state: RootState) => state.timetable?.searchText);
  const timetable = useSelector((state: RootState) => state.timetable?.timetable);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const { weekWiseData = {} } = semestersData;

  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [week, setWeek] = useState('1');

  const { rows = [], count = 0, totalCount = 0 } = timetable[week] || {};

  useEffect(() => {
    if (weekWiseData[week]) {
      dispatch(
        getTimeTable(
          week,
          weekWiseData[week].startDate,
          weekWiseData[week].endDate,
          page + 1,
          rowsPerPage,
          searchText,
          values
        )
      );
    }
  }, [dispatch, weekWiseData]);

  const onWeekChange = (e) => {
    const val = e.target.value;
    setWeek(val);
    setPage(0);
    dispatch(
      getTimeTable(val, weekWiseData[val].startDate, weekWiseData[val].endDate, 1, rowsPerPage, searchText, values)
    );
    dispatch(setTimeTableResponse());
  };

  const downloadReport = async () => {
    dispatch(generateTimeTableReports(weekWiseData[week].startDate, weekWiseData[week].endDate));
  };

  const onSearch = () => {
    setPage(0);
    dispatch(
      getTimeTable(week, weekWiseData[week].startDate, weekWiseData[week].endDate, 1, rowsPerPage, searchText, values)
    );
  };

  const onSearchTextChanged = (newSearchText: string) => {
    dispatch(onAnalysisSearchChanged(newSearchText));
  };

  const onFilter = (val = values) => {
    dispatch(
      getTimeTable(week, weekWiseData[week].startDate, weekWiseData[week].endDate, 1, rowsPerPage, searchText, val)
    );
  };

  const onFilterClear = () => {
    setValues({ ...initialValues });
    dispatch(
      getTimeTable(week, weekWiseData[week].startDate, weekWiseData[week].endDate, 1, rowsPerPage, searchText, {
        ...initialValues,
      })
    );
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    dispatch(
      getTimeTable(
        week,
        weekWiseData[week].startDate,
        weekWiseData[week].endDate,
        newPage + 1,
        rowsPerPage,
        searchText,
        values
      )
    );
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    dispatch(
      getTimeTable(week, weekWiseData[week].startDate, weekWiseData[week].endDate, 1, value, searchText, values)
    );
  };

  const decoratedTableData = useMemo(() => {
    return rows.map((unit: TimeTableUnit) => {
      const {
        unitCode = '',
        type = '',
        batchName = '',
        location = '',
        day = '',
        startTime = '',
        duration,
        teachingStaffName,
        enrolledStudents = [],
        tracked = false,
        event,
      } = unit;
      return [
        unitCode,
        type,
        batchName,
        location,
        day,
        startTime,
        duration,
        teachingStaffName,
        enrolledStudents.length,
        tracked ? 'CAPTURED' : 'NOT CAPTURED',
        (event || {}).engagementIndex?.toFixed(2) || 'N/A',
      ];
    });
  }, [rows]);
  return (
    <>
      <Page header="Events Reconciliation" subHeader="">
        <Grid container>
          <Grid item xs={4}>
            {weekWiseData && Object.keys(weekWiseData).length > 0 && (
              <TextField
                fullWidth
                label="week"
                margin="dense"
                name="week"
                select
                SelectProps={{
                  value: week,
                  onChange: onWeekChange,
                }}
                value={week}
                variant="outlined"
              >
                {Object.keys(weekWiseData).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={8}>
            <ButtonContainer>
              <GenerateReportButton
                reportName={'Timetable-Analysis.csv'}
                downloadAction={downloadReport}
                loading={reportLoader}
                csvData={reportData}
              />
            </ButtonContainer>
          </Grid>
        </Grid>
        <SearchContainer>
          <SearchBar
            filter
            onSearch={onSearch}
            searchPlaceHolder={'Search by Unit Code'}
            onSearchTextChanged={onSearchTextChanged}
            searchText={searchText}
            onFilterClear={onFilterClear}
            onFilter={onFilter}
          >
            <Filters values={values} setValues={setValues} />
          </SearchBar>
        </SearchContainer>
        <Card>
          <StyledContent>
            <PerfectScrollbar>
              {!loader ? (
                rows && rows.length > 0 ? (
                  <CustomTable
                    tableHead={[
                      {
                        text: 'Unit Code',
                      },
                      { text: 'Type' },
                      {
                        text: 'Batch Name',
                      },
                      { text: 'Location' },
                      {
                        text: 'Day',
                      },
                      {
                        text: 'Start Time',
                      },
                      { text: 'Duration' },
                      { text: 'Teaching Staff' },
                      { text: 'Enrolled' },
                      {
                        text: 'Event Status',
                      },
                      { text: 'Engagement Index' },
                    ]}
                    tableData={decoratedTableData}
                    count={count}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                ) : (
                  <div />
                )
              ) : (
                <Loader />
              )}
            </PerfectScrollbar>
          </StyledContent>
          <Grid container>
            <Grid style={{ textAlign: 'center' }} item xs={4} md={4}>
              Total Scheduled : {totalCount}
            </Grid>
            <Grid style={{ textAlign: 'center' }} item xs={4} md={4}>
              Total Captured : {values.status === eventStatus.UNCAPTURED ? totalCount - count : count}
            </Grid>
            <Grid style={{ textAlign: 'center' }} item xs={4} md={4}>
              Total Not Captured : {values.status === eventStatus.UNCAPTURED ? count : totalCount - count}
            </Grid>
          </Grid>
        </Card>
      </Page>
    </>
  );
}
