import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Input,
  InputLabel,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Theme,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Backspace from '@material-ui/icons/Backspace';
import Page from '../../../components/Page';
import { RootState } from '../../../reducer';

import MuiAlert from '@material-ui/lab/Alert';
import Files from '../../../components/Files';
import ErrorDetails from './ErrorDetails';
import { uploadStudentGroups, resetStudentGroupsUploadData } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: 0,
      maxHeight: '600px',
      overflowY: 'scroll',
    },
    button: {
      margin: theme.spacing(1),
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    snackbar: {
      top: '-300px',
      marginLeft: '175px',
    },
    download: {
      '& a': {
        color: '#c9696e',
        '&:hover': {
          textDecoration: 'none',
        },
      },
      margin: '5px',
      float: 'right',
      border: '1px solid',
      backgroundColor: '#ffff',
      borderColor: '#c9696e',
      '&:hover': {
        backgroundColor: '#ffff',
        borderColor: '#c9696e',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ffff',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: '#f5ecec',
      color: '#494949',
    },
  })
);

export default function StudentGroupsUpload({ unitId, assignments, handleModalClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadError, setUploadError] = useState('');
  const [noOfRowsSuccess, setNoOfRowsSuccess] = useState('');
  const [errorRows, setErrorRows] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);

  const fileLoadPercentage = useSelector((state: RootState) => state.units?.fileLoadPercentage);
  const response = useSelector((state: RootState) => state.units?.studentGroupsUploadResponse);
  const errorResponse = useSelector((state: RootState) => state.units?.errorResponse);
  const [selectedAssignments, setSelectedAssignments] = React.useState<any[]>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '300',
      },
    },
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAssignments(event.target.value as number[]);
  };
  const onFileSelected = () => {
    // @ts-ignore
    setErrorRows([]);
    dispatch(resetStudentGroupsUploadData());
    setNoOfRowsSuccess('');
  };

  const onRemoveFile = () => {
    setErrorRows([]);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpenSnackbar(false);
    setErrorOpenSnackbar(false);
    setNoOfRowsSuccess('');
  };

  const onUploadFile = async (file: any) => {
    try {
      setIsUploading(true);
      dispatch(uploadStudentGroups(file, selectedAssignments, unitId));
    } catch (error) {
      if (error.errors) {
        setErrorRows(error.errors);
      }
      setIsUploading(false);
      setUploadError(error.reason || error.message);
    }
  };

  useEffect(() => {
    if (response) {
      setNoOfRowsSuccess(response);
      setSuccessOpenSnackbar(true);
      setIsUploading(false);
    }
    if (!_.isEmpty(errorResponse)) {
      setErrorRows(errorResponse);
      setErrorOpenSnackbar(true);
      setIsUploading(false);
    }
  }, [response, errorResponse]);

  return (
    <Page className={classes.root} title="Assignment">
      <Container maxWidth={false} className={classes.container}>
        <Card style={{ boxShadow: 'none' }}>
          <CardHeader
            action={
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<Backspace />}
                onClick={handleModalClose}
              >
                Back
              </Button>
            }
            title={`Upload Student Groups `}
            subheader={
              noOfRowsSuccess &&
              noOfRowsSuccess !== '' && (
                <Typography style={{ color: 'green', fontWeight: 600 }}>
                  {`${noOfRowsSuccess}`}
                  <Typography></Typography>
                </Typography>
              )
            }
          />
          <Divider />
          {isUploading && <Loader />}
          <CardContent className={classes.content}>
            <br />
            <InputLabel style={{ margin: 12, marginBottom: 0, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
              Select Assigments
            </InputLabel>
            <Select
              className="justify-content-center"
              style={{ width: 600, maxWidth: 600, margin: 12 }}
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedAssignments}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as number[]).map((id) => (
                    <Chip
                      key={id}
                      label={assignments.filter((x) => x.id === id).map((x) => x.taskName)[0]}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {assignments.map((assignment: { taskName: string; taskNumber: string; id: number }) => (
                <MenuItem key={assignment.id} value={assignment.id}>
                  <Checkbox checked={selectedAssignments.indexOf(assignment.id) > -1} />
                  <ListItemText primary={assignment.taskName} />
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <Files
              isUploading={isUploading}
              onUploadFile={onUploadFile}
              onRemoveFile={onRemoveFile}
              uploadError={uploadError}
              setUploadError={setUploadError}
              fileLoadPercentage={fileLoadPercentage}
              onFileSelected={onFileSelected}
            />
            <Button
              variant="contained"
              color="default"
              size="small"
              className={classes.download}
              endIcon={
                <ArrowDownwardSharp fontSize="small" style={{ color: '#c9696e' }}>
                  Sample Download
                </ArrowDownwardSharp>
              }
            >
              <a href={process.env.PUBLIC_URL + '/csv/studentGroups.csv'} download="studentGroups.csv">
                Sample Download
              </a>
            </Button>
          </CardContent>
        </Card>
        {!_.isEmpty(errorRows) ? <ErrorDetails errorResponse={errorResponse} /> : null}
        <Snackbar className={classes.snackbar} onClose={handleClose} open={openErrorSnackbar}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
            Error in Uploaded File
          </MuiAlert>
        </Snackbar>
        <Snackbar className={classes.snackbar} onClose={handleClose} open={openSuccessSnackbar}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
            {`${noOfRowsSuccess}`}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Page>
  );
}
