import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { Elements, removeElements, useStoreState } from 'react-flow-renderer';
import { ElementsContext } from '../addIntervention1';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import styled from 'styled-components'

const CustomCloseButton = styled(HighlightOffOutlinedIcon)`
float: right;
    cursor: pointer;
    background-color: #d7dde6;
    color: #ff00006b;
    bottom: 34px;
    border-radius: 50%;
    left: 19px;
    font-size: 38px;
    position: relative;
`

 export const CloseNodeButton=() =>{    const {elements, setElements} = useContext(ElementsContext)
  const selectedElements = useStoreState((store) => store.selectedElements);

  const onElementsRemove = () => {
    console.log( selectedElements);
    const elementsToRemove: Elements | null = selectedElements;

    if (elementsToRemove) {
      setElements((els) => removeElements(elementsToRemove, els));
    }
  };
  return (
   <div style={{width:'100%', position:'absolute'}}>
          {/* <Button style={{float:'right'}} variant="contained" color={'primary'} onClick={onElementsRemove}>
            {' '}
            Close
          </Button> */}
<CustomCloseButton  onClick={onElementsRemove} />
        </div>
  )
}
