import { SimpleStorage } from '@okta/okta-auth-js';
import Cookies from 'js-cookie';

export const REDIRECT_URI = window.location.origin + '/implicit/callback';
export const BASE_URL = 'https://id.edfibre.com';
export const ISSUER = 'https://id.edfibre.com/oauth2/aus2yb8pz8DwlZvnH4x7';
export const PKCE = true;
export const CLIENT_ID = '0oaauzk9dZeBRplyN4x6';

const hostname = window.location.hostname.toLowerCase();
const domain = hostname.includes('edfibre.net')
  ? 'edfibre.net'
  : hostname.includes('edfibre.com')
  ? 'edfibre.com'
  : hostname;
const cookieOptions: Cookies.CookieAttributes = { expires: 1, secure: true, path: '/', domain };
export const OktaCustomStorage: SimpleStorage = {
  getItem: (key: string) => {
    if (key === 'okta-token-storage') {
      try {
        const idToken = JSON.parse(Cookies.get('okta-idToken')!);
        const accessToken = JSON.parse(Cookies.get('okta-accessToken')!);
        const refreshToken = JSON.parse(Cookies.get('okta-refreshToken')!);
        const data = JSON.stringify({ idToken, accessToken, refreshToken });
        return data;
      } catch (err) {
        return undefined;
      }
    } else {
      return Cookies.get(key);
    }
  },
  setItem: (key: string, val: any) => {
    if (key === 'okta-token-storage') {
      const tokens = JSON.parse(val);
      console.log('Set tokens', tokens);
      Cookies.set('okta-idToken', tokens.idToken, cookieOptions);
      Cookies.set('okta-accessToken', tokens.accessToken, cookieOptions);
      Cookies.set('okta-refreshToken', tokens.refreshToken, cookieOptions);
    }
  },

  removeItem: (key) => {
    if (key === 'okta-token-storage') {
      Cookies.remove('okta-idToken', cookieOptions);
      Cookies.remove('okta-accessToken', cookieOptions);
      Cookies.remove('okta-refreshToken', cookieOptions);
    } else {
      Cookies.remove(key);
    }
  },
};
