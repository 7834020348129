import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { StudentInterventionSearchFilters, StudentRemindersSearchFilters } from '../util/types';

export const ON_STUDENT_INTERVENTION_LOGS_LOADING_START = 'ON_STUDENT_INTERVENTION_LOGS_LOADING_START';
export const ON_STUDENT_INTERVENTION_LOGS_LOADING_FINISHED = 'ON_STUDENT_INTERVENTION_LOGS_LOADING_FINISHED';
export const GET_STUDENT_INTERVENTION_LOGS_DATA = 'GET_STUDENT_INTERVENTION_LOGS_DATA';
export const GET_STUDENT_REMINDER_LOGS_DATA = 'GET_STUDENT_REMINDER_LOGS_DATA';
export const ON_STUDENT_REMINDER_LOGS_LOADING_START = 'ON_STUDENT_REMINDER_LOGS_LOADING_START';
export const ON_STUDENT_REMINDER_LOGS_LOADING_FINISHED = 'ON_STUDENT_REMINDER_LOGS_LOADING_FINISHED';
export const GET_STUDENT_REMINDER_EMAIL_CONTENT_DATA = 'GET_STUDENT_REMINDER_EMAIL_CONTENT_DATA';
export const ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_START = 'ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_START';
export const ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_FINISHED = 'ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_FINISHED';
export const ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_START =
  'ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_START';
export const ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_FINISHED =
  'ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_FINISHED';
export const GET_STUDENT_INTERVENTION_EMAIL_CONTENT_DATA = 'GET_STUDENT_INTERVENTION_EMAIL_CONTENT_DATA';

export const INTERVENTION_URL = async (studentId) => {
  return `${await API_URL()}/intervention/search/student/${studentId}`
};
export const REMINDER_URL = async (studentId) => {
  return `${await API_URL()}/search/student/${studentId}/assignment-reminders`
};
export const REMINDER_CONTENT_URL = async (id) => {
  return `${await API_URL()}/search/assignment-reminders/${id}`
};
export const INTERVENTION_CONTENT_URL = async (id) => {
  return `${await API_URL()}/intervention/search/assignment-interventions/${id}`
};
export const getStudentInterventionData = (
  studentId: string,
  searchText: string,
  filters: StudentInterventionSearchFilters,
  page: number,
  recordPerPage: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = await INTERVENTION_URL(studentId);
    dispatch({ type: ON_STUDENT_INTERVENTION_LOGS_LOADING_START });
    const result = await post(url, {
      limit: recordPerPage,
      offset: recordPerPage * page - recordPerPage,
      searchText,
      filters,
    });
    dispatch({ type: GET_STUDENT_INTERVENTION_LOGS_DATA, payload: result });
    dispatch({ type: ON_STUDENT_INTERVENTION_LOGS_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_INTERVENTION_LOGS_DATA,
      payload: [],
    });
    dispatch({ type: ON_STUDENT_INTERVENTION_LOGS_LOADING_FINISHED });
  }
};

export const getStudentAssignmentRemindersData = (
  studentId: string,
  searchText: string,
  filters: StudentRemindersSearchFilters,
  page: number,
  recordPerPage: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = await REMINDER_URL(studentId);
    dispatch({ type: ON_STUDENT_REMINDER_LOGS_LOADING_START });
    const result = await post(url, {
      limit: recordPerPage,
      offset: recordPerPage * page - recordPerPage,
      searchText,
      filters,
    });
    dispatch({ type: GET_STUDENT_REMINDER_LOGS_DATA, payload: result });
    dispatch({ type: ON_STUDENT_REMINDER_LOGS_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_REMINDER_LOGS_DATA,
      payload: [],
    });
    dispatch({ type: ON_STUDENT_REMINDER_LOGS_LOADING_FINISHED });
  }
};

export const getStudentAssignmentRemindersDataById = (
  id: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = await REMINDER_CONTENT_URL(id);
    dispatch({ type: ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_STUDENT_REMINDER_EMAIL_CONTENT_DATA, payload: result.content });
    dispatch({ type: ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_REMINDER_EMAIL_CONTENT_DATA,
      payload: '',
    });
    dispatch({ type: ON_STUDENT_REMINDER_EMAIL_CONTENT_LOADING_FINISHED });
  }
};

export const getStudentAssignmentInterventionDataById = (
  id: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = await INTERVENTION_CONTENT_URL(id);
    dispatch({ type: ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_STUDENT_INTERVENTION_EMAIL_CONTENT_DATA, payload: result.content });
    dispatch({ type: ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_INTERVENTION_EMAIL_CONTENT_DATA,
      payload: '',
    });
    dispatch({ type: ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_FINISHED });
  }
};
