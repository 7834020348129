import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Card, CardContent, Theme } from '@material-ui/core';
import FilesDropzone from '../../components/FilesDropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
  })
);

type FilesProps = {
  className?: string;
  onUploadFile: (file: any) => void;
  uploadError?: string;
  setUploadError: (error: string) => void;
  fileLoadPercentage: number;
  onFileSelected: () => void;
  isUploading?: boolean;
  onRemoveFile?: () => void;
  disableUpload?: boolean;
};
const Files: React.FC<FilesProps> = ({
  fileLoadPercentage,
  setUploadError,
  onFileSelected,
  uploadError,
  onUploadFile,
  onRemoveFile,
  className,
  isUploading,
  disableUpload = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card style={{ boxShadow: 'none' }}>
        <CardContent>
          <FilesDropzone
            isUploading={isUploading}
            disableUpload={disableUpload}
            onUploadFile={onUploadFile}
            onRemoveFile={onRemoveFile}
            uploadError={uploadError}
            setUploadError={setUploadError}
            fileLoadPercentage={fileLoadPercentage}
            onFileSelected={onFileSelected}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default Files;
