import { Divider, TextField } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { CloseNodeButton } from '../../components/closeNodeButton';
import { CustomHandle1, CustomNodeTitle1, DivRows, LabelSpan, MainDiv1 } from '../../flowChartComponents/styled-framework-items';
import '../index.scss';

const Outer = styled.div`
  padding: 30px;
`;




export const GradesCheck = ({data})=> {
  const dispatch = useDispatch();
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);
  const [selectedGradesCheckValue, setSelectedGradesCheckValue] = React.useState(data.indexOperator || '<');

  const [indexValue, setIndexValue] = React.useState(data['indexValue'] || 0);

  const updateInitialState=()=>{
    let updateObj = { ...flowChartData };
    updateObj.indexOperator = selectedGradesCheckValue;
    updateObj.indexValue = indexValue;
    data.indexOperator = selectedGradesCheckValue;
    data.indexValue = indexValue;
    dispatch(setFlowChartData(updateObj));
  }

  useEffect(() => {
    updateInitialState()
  }, []);

  const handleGradesCheckChange = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.indexOperator = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setSelectedGradesCheckValue(event.target.value);
    data.indexOperator = event.target.value
  };

  const updateData = (e) => {
    console.log('Updating grade marks ........ ')
    let updateObj = { ...flowChartData };
    updateObj['indexValue'] = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setIndexValue(e.target.value);
    data['indexValue'] = e.target.value
  };

  return (
    <MainDiv1>
        <CustomHandle1 type="source" id="condition_gradeCheck" position={Position.Bottom} />
      <CustomNodeTitle1>
        <AssignmentTurnedInIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Grades
      <CloseNodeButton/>
      </CustomNodeTitle1>
      <Divider />
      <Outer>
        <DivRows>
          <LabelSpan>is: </LabelSpan>

          <div style={{ width: 300 }}>
            <ToggleButtonGroup
              value={selectedGradesCheckValue}
              exclusive
              onChange={handleGradesCheckChange}
              aria-label="text alignment"
              className={'toggleBtnGroupCustom'}
            >
              <ToggleButton value=">">
                <span style={{ fontSize: 13, width: 125 }}>greater than</span>
              </ToggleButton>
              <ToggleButton value="<" className="toggleBtnCustom">
                <span style={{ fontSize: 13, width: 125 }}>less than</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DivRows>

        <br />
        <DivRows>
          <LabelSpan>
            Grade (%):{' '}
          </LabelSpan>
          <div style={{ width: 300 }}>
            <TextField
              style={{ padding: '10px', width: 300 }}
              id="custom-grademarks"
              type="number"
              placeholder="Enter marks for the conditon"
              value={indexValue}
              onChange={(e: any) => {
                updateData(e);
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </DivRows>
       
      </Outer>{' '}
    </MainDiv1>
  );
}
