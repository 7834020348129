import Axios from 'axios';
import { API_URL } from '../config/constants';

export const USERS_URL = async () => {
  return `${await API_URL()}/users`
};
export const ME_URL = async () => {
  return `${await USERS_URL()}/me`
};
export const MY_EVENTS_URL = async () => {
  return `${await ME_URL()}/events`
};
export const MY_UNITS_URL = async () => {
  return `${await ME_URL()}/units`
};
const AVATAR_URL = (name: string) => `https://ui-avatars.com/api/?background=043378&name=${name}&color=fff&bold=true`;

export const UserService = {
  async GetMe() {
    const url = await ME_URL();
    const res = await Axios.get(url);
    return res.data;
  },

  async GetMyEvents() {
    const url = await MY_EVENTS_URL();
    const res = await Axios.get(url);
    return res.data;
  },

  async GetMyUnits() {
    const url = await MY_UNITS_URL();
    const res = await Axios.get<any[]>(url);
    return res.data;
  },

  async GetMyAvatarUrl(name: string) {
    const url = AVATAR_URL(name);
    return url;
  },
};
