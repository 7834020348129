import { ActionType } from './types';

import {
  FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION,
  GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
  GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
  GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT,
  ON_FEEDBACK_REPORT_LOADING_FINISHED,
  ON_FEEDBACK_REPORT_LOADING_START,
  ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_START,
  ON_INSTITUTIONAL_REPORT_LOADING_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_START,
  ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START,
  ON_INTERVENTION_REPORT_LOADING_FINISHED,
  ON_INTERVENTION_REPORT_LOADING_START,
  RESET_INSTITUTIONAL_REPORTS_RESPONSE,
  SET_FEEDBACK_REPORT_RESPONSE,
  SET_INSTITUTIONAL_REPORTS_RESPONSE,
  SET_INTERVENTION_REPORT_RESPONSE,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED,
  FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED,
  FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION,
  GET_INSTITUTIONAL_NON_SUBMISSION_REPORT,
  FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED,
  ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START,
  ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED,
  FIND_INSTITUTIONAL_REPORTS_INTERIM,
  FIND_INSTITUTIONAL_REPORTS_INTERIM_FAILED,
  ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START,
  GET_INSTITUTIONAL_INTERIM_REPORT,
  FIND_INSTITUTIONAL_REPORTS_ARCHIVES,
  FIND_INSTITUTIONAL_REPORTS_ARCHIVES_FAILED,
  ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START,
  GET_INSTITUTIONAL_ARCHIVES_REPORT
} from '../actionTypes';

const initialState = {
  reportData_student_at_risk: null,
  reportIdInfo_student_at_risk: null,
  reportData_student_progression: null,
  reportIdInfo_student_progression: null,
  loader: false,
  reportData_attendance_report_weekly: null,
  reportIdInfo_attendance_report_weekly: null,
  loader_attendance_report_weekly: false,
  loader_intervention_report: false,
  loader_feedback_report: false,
  reportData_intervention_report: null,
  reportData_feedback_report: null,
  response: false,
  loading_student_progression: false,
  reportData_non_submission: null,
  reportIdInfo_non_submission: null,
  loader_non_submission: false,
  reportData_interim: null,
  loading_interim: false,
  reportIdInfo_interim: null,
  reportData_archives: null,
  loading_archives: false,
  reportIdInfo_archives: null,
};

const institutionalReports = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK: {
      return {
        ...state,
        reportIdInfo_student_at_risk: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_student_at_risk: action.payload,
          loader: false,
        };
      }
      return {
        ...state,
        reportData_student_at_risk: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED: {
      return {
        ...state,
        reportData_student_at_risk: action.payload,
        loader: false,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }

    case ON_INTERVENTION_REPORT_LOADING_START: {
      return {
        ...state,
        loader_intervention_report: true,
      };
    }
    case ON_INTERVENTION_REPORT_LOADING_FINISHED: {
      return {
        ...state,
        loader_intervention_report: false,
      };
    }

    case ON_FEEDBACK_REPORT_LOADING_START: {
      return {
        ...state,
        loader_feedback_report: true,
      };
    }
    case ON_FEEDBACK_REPORT_LOADING_FINISHED: {
      return {
        ...state,
        loader_feedback_report: false,
      };
    }

    case GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY: {
      return {
        ...state,
        reportIdInfo_attendance_report_weekly: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_attendance_report_weekly: action.payload,
          loader_attendance_report_weekly: false,
        };
      }
      return {
        ...state,
        reportData_attendance_report_weekly: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED: {
      return {
        ...state,
        reportData_attendance_report_weekly: action.payload,
        loader_attendance_report_weekly: false,
      };
    }
    case SET_INTERVENTION_REPORT_RESPONSE: {
      return {
        ...state,
        reportData_intervention_report: action.payload,
      };
    }
    case SET_FEEDBACK_REPORT_RESPONSE: {
      return {
        ...state,
        reportData_feedback_report: action.payload,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_START: {
      return {
        ...state,
        loader_attendance_report_weekly: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED: {
      return {
        ...state,
        loader_attendance_report_weekly: false,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START: {
      return {
        ...state,
        loading_student_progression: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED: {
      return {
        ...state,
        loading_student_progression: false,
      };
    }

    case ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START: {
      return {
        ...state,
        loading_interim: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED: {
      return {
        ...state,
        loading_interim: false,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START: {
      return {
        ...state,
        loading_archives: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED: {
      return {
        ...state,
        loading_archives: false,
      };
    }
    case SET_INSTITUTIONAL_REPORTS_RESPONSE: {
      return {
        ...state,
        response: true,
      };
    }

    case RESET_INSTITUTIONAL_REPORTS_RESPONSE: {
      return {
        ...state,
        response: false,
      };
    }

    case GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT: {
      return {
        ...state,
        reportIdInfo_student_progression: action.payload,
      };
    }
    case GET_INSTITUTIONAL_INTERIM_REPORT: {
      return {
        ...state,
        reportIdInfo_interim: action.payload,
      };
    }
    case GET_INSTITUTIONAL_ARCHIVES_REPORT: {
      return {
        ...state,
        reportIdInfo_archives: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_student_progression: action.payload,
          loading_student_progression: false,
        };
      }
      return {
        ...state,
        reportData_student_progression: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED: {
      return {
        ...state,
        reportData_student_progression: action.payload,
        loading_student_progression: false,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_INTERIM: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_interim: action.payload,
          loading_interim: false,
        };
      }
      return {
        ...state,
        reportData_interim: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_ARCHIVES: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_archives: action.payload,
          loading_archives: false,
        };
      }
      return {
        ...state,
        reportData_archives: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_INTERIM_FAILED: {
      return {
        ...state,
        reportData_interim: action.payload,
        loading_interim: false,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_ARCHIVES_FAILED: {
      return {
        ...state,
        reportData_archives: action.payload,
        loading_archives: false,
      };
    }
    case GET_INSTITUTIONAL_NON_SUBMISSION_REPORT: {
      return {
        ...state,
        reportIdInfo_non_submission: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION: {
      if (action.payload && action.payload.content) {
        return {
          ...state,
          reportData_non_submission: action.payload,
          loader_non_submission: false,
        };
      }
      return {
        ...state,
        reportData_non_submission: action.payload,
      };
    }
    case FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED: {
      return {
        ...state,
        reportData_non_submission: action.payload,
        loader_non_submission: false,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START: {
      return {
        ...state,
        loader_non_submission: true,
      };
    }
    case ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED: {
      return {
        ...state,
        loader_non_submission: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default institutionalReports;
