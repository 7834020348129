import { Divider, MenuItem, Select, TextField } from '@material-ui/core';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect } from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData, SET_INVALID_BOOKING_CONSULTATION_STATUS } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { distinctValues, isASubset } from '../../../../util/common';
import { ImprovementArea, INTERVENTION_CONSULTATIONWITH, StaffMemberOfIA } from '../../../../util/types';
import { ElementsContext } from '../../addIntervention1';
import { CloseNodeButton } from '../../components/closeNodeButton';
import {
  CustomHandle2,
  CustomNodeTitle2,
  DivRows,
  LabelSpan,
  LabelSpanForSelect,
  MainDiv2,
} from '../../flowChartComponents/styled-framework-items';

const Outer = styled.div`
  padding: 30px;
`;


export const BookConsultation = ({ data }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = React.useState(data.improvementAreas ? true : false)
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);
  const [selectedBookConsultationValue, setSelectedBookConsultationValue] = React.useState<string | null>(
    data.consultationWith || null
  );
  const improvementAreasData = useSelector((state: RootState) => state.ImprovementArea?.data);
  const [links, setLinks] = React.useState(data.links);
  const { selectedAssignments } = useContext(ElementsContext);
  const inValidBookingConsultation = useSelector((state: RootState) => state.intervention?.inValidBookingConsultation);
  const [teachingStaffMembers, setTeachingStaffMembers] = React.useState<StaffMemberOfIA[] | null>(null);

  const updateInvalidBookingConsultationStatus = (status) => {
    if (status) {
      setTeachingStaffMembers(null);
    }

    dispatch({ type: SET_INVALID_BOOKING_CONSULTATION_STATUS, payload: status });
  };

  useEffect(() => {
    setTeachingStaffMembers(null);
  }, [selectedAssignments]);

  const handleBookConsultationChange = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.consultationWith = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setSelectedBookConsultationValue(event.target.value);
    // --------------  Check the units for the selected teaching staff --------------
    if (event.target.value === 'RESPECTIVE ACADEMIC STAFF') {
      let selectedInterventionUnits = selectedAssignments.map((x) => x.code);
      const IAData: ImprovementArea = improvementAreasData.filter((x) => x.type === improvementAreas)[0];
      const teachingStaff = IAData.staffMembers.filter((x) => x.isTeachingStaff);
      data.teachingStaff = teachingStaff;
      setTeachingStaffMembers(teachingStaff);
      let teachingStaffUnits: string[] = [];
      teachingStaff.forEach((x) => {
        teachingStaffUnits = x.units ? [...teachingStaffUnits, ...x.units] : [...teachingStaffUnits];
      });
      teachingStaffUnits = distinctValues(teachingStaffUnits);
      updateInvalidBookingConsultationStatus(!isASubset(selectedInterventionUnits, teachingStaffUnits));
    } else {
      data.teachingStaff = null;
      setTeachingStaffMembers(null);
      updateInvalidBookingConsultationStatus(false);
    }
    // --------------  Check the units for the selected teaching staff --------------
    data.consultationWith = event.target.value;
    let bookingURL = consultationWith.filter((x) => x.name === event.target.value)[0].bookingURL;
    data.links = bookingURL;
    setLinks(bookingURL);
    data.description = 'Please book a consultation meeting with ';
  };

  const [improvementAreas, setImprovementAreas] = React.useState(
    data.improvementAreas || ''
  );

  const getConsultationWithData = (IA) => {
    const IAData: ImprovementArea = improvementAreasData.filter((x) => x.type === IA)[0];
    if(data.improvementAreas && !IAData){
      return [data.consultationWith]
    }
    const isUnitOriented = IAData.isUnitOriented;
    let lecObj: StaffMemberOfIA = {
      bookingURL: '',
      name: INTERVENTION_CONSULTATIONWITH.RESPECTIVE_ACADEMIC_STAFF,
      email: '',
      isTeachingStaff: true,
    };

    if (isUnitOriented) {
      let filtererdData = IAData.staffMembers.filter((x) => !x.isTeachingStaff);
      filtererdData.push(lecObj);
      console.log(filtererdData, selectedBookConsultationValue)
      return filtererdData;
    } else {
      return IAData.staffMembers;
    }
  };
  const [consultationWith, setConsultationWith] = React.useState(
    data.improvementAreas ? getConsultationWithData(data.improvementAreas || improvementAreas) : []
  );

  const handleImprovementArea = (event) => {
    setTeachingStaffMembers(null);
    updateInvalidBookingConsultationStatus(false);
    console.log(improvementAreas, improvementAreasData);
    let updateObj = { ...flowChartData };
    updateObj.improvementAreas = event.target.value;
    setConsultationWith(getConsultationWithData(event.target.value));
    console.log('----------- 1')
    setSelectedBookConsultationValue(null);
    dispatch(setFlowChartData(updateObj));
    setImprovementAreas(event.target.value);
    data.improvementAreas = event.target.value;
  };

  return (
    <MainDiv2>
      <CustomHandle2 type="target" id="actions_bookConsultation" position={Position.Top} />
      {/* <CustomHandle1 type="source" id="actions_bookConsultation" position={Position.Bottom} /> */}
      <CustomNodeTitle2>
        <BookmarkBorderIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Book Consultation
        <CloseNodeButton />
      </CustomNodeTitle2>
      <Divider />
      <Outer>
        <DivRows>
          <LabelSpanForSelect style={{ marginTop: '0px !important' }}>Improvement Areas: </LabelSpanForSelect>{' '}
          {!preview ? <Select
            style={{ width: 279, marginLeft: 11 }}
            labelId="demo-simple-select-labelConsultation"
            id="demo-simple-selectConsultation"
            value={improvementAreas}
            onChange={handleImprovementArea}
          >
            {improvementAreasData.map((x) => (
              <MenuItem value={x.type}>{x.type}</MenuItem>
            ))}
          </Select> : <div style={{ width: 279, marginLeft: 11 }}>{improvementAreas}</div>}
        </DivRows>

        {consultationWith.length > 0 ? (
          <DivRows>
            <LabelSpanForSelect>With: </LabelSpanForSelect>{' '}
           {!preview ?  <Select
              style={{ width: 279, marginLeft: 11 }}
              labelId="demo-simple-select-labelConsultation"
              id="demo-simple-selectConsultation"
              value={selectedBookConsultationValue}
              onChange={handleBookConsultationChange}
            >
              {consultationWith.map((x) => (
                <MenuItem value={x.name}>{`${x.name}`}</MenuItem>
              ))}
            </Select> : <div style={{ width: 279, marginLeft: 11 }}>{selectedBookConsultationValue}</div>}
          </DivRows>
        ) : (
          <DivRows>
            <div style={{ width: '100%', color: 'grey' }}>No Staff Member Present</div>
          </DivRows>
        )}

        {selectedBookConsultationValue && selectedBookConsultationValue !== 'RESPECTIVE ACADEMIC STAFF'
          ? !inValidBookingConsultation && (
              <DivRows>
                <LabelSpan>Description: </LabelSpan>{' '}
                <TextField
                  disabled
                  style={{ padding: '10px', width: 300 }}
                  id="custom-description"
                  type="text"
                  multiline
                  placeholder="Enter the description"
                  value={`Please book a consultation meeting with ${selectedBookConsultationValue} using given link: ${links}`}
                />
              </DivRows>
            )
          : null}

        {inValidBookingConsultation ? (
          <>
            {' '}
            <br />
            <br />{' '}
            <Alert severity="warning">
              <span>We don't have academic staff for all units selected in the configuration section.</span> <br />{' '}
              <span>Please either change the configuration or add required academic staff.</span>{' '}
            </Alert>
          </>
        ) : (
          <></>
        )}
      </Outer>
    </MainDiv2>
  );
};
