import { Button, Card, CardContent, CardHeader, colors, Divider, Modal, Snackbar, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { default as React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducer';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { clearDatabase, resetClearDatabaseResponse } from '../../../actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      flexWrap: 'wrap',
    },
    listItemText: {
      marginLeft: theme.spacing(2),
    },
    listItemTextPrimary: {
      color: colors.red[900],
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    errorText: {
      color: colors.red[900],
      fontWeight: 600,
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },

    filterButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      padding: 0,
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    inner: {
      minWidth: 1150,
    },
    title: {
      color: 'white',
    },
    snackbar: {},
    para: {
      fontSize: '19px',
      top: '8px',
      marginRight: '18px',
      position: 'relative',
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type ClearDbProps = {
  id: number;
  type: string;
  name: string;
};
const ClearDb: React.FC<ClearDbProps> = ({ id, type, name, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const deleteData = () => {
    setOpen(false);
    dispatch(clearDatabase(id, type));
  };

  const title =
    type === 'submission'
      ? `Delete submission data for Assignment - ${name}`
      : `Delete result data for Assignment - ${name}`;

  const close = () => {
    setOpen(false);
  };

  // Notify
  const response = useSelector((state: RootState) => state.units?.clearDbResponse);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpenSnackbar(false);
  };
  useEffect(() => {
    if (response) {
      setSuccessOpenSnackbar(true);
      dispatch(resetClearDatabaseResponse());
    }
  }, [response, dispatch]);
  //Notify

  const body = (
    <Card className="card1">
      <CardHeader
        title={title}
        classes={{
          title: classes.title,
        }}
        style={{ backgroundColor: '#c9696e' }}
      />
      <Divider />
      <br />
      <br />
      <CardContent className={classes.content} style={{ height: '100%', width: '100%' }}>
        <p className={classes.para}>Are you sure ?</p>
        <br />
        <br />

        <Button variant="contained" color="primary" size="small" style={{ marginLeft: 15 }} onClick={deleteData}>
          Yes
        </Button>
        <Button variant="contained" color="default" size="small" style={{ marginLeft: 5 }} onClick={close}>
          No
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <>
      <div className="float-right">
        {/* <Button  style={{marginRight: 5 }} onClick={submit}
                                color="secondary"
                                size="small"
                                variant="outlined">Delete</Button> */}

        <Modal
          open={open}
          onClose={close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          {body}
        </Modal>
      </div>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
        open={openSuccessSnackbar}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {type === 'submission'
            ? `Submission data is cleaned for assignment - ${name}`
            : `Result data is cleaned for assignment - ${name}`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClearDb;
