import { Button, Grid, IconButton, InputLabel, Switch, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addStaffForIA, updateStaffForIA } from '../../actions/improvementArea';
import { RootState } from '../../reducer';
import { roles } from '../../util/enums';
import CustomModal from '../CustomModal';

type AddStaffForIAProps = {
  buttonType?: 'ICON' | 'BUTTON';
  type: 'ADD' | 'UPDATE';
  improvementAreaId: number;
  improvementAreaName: string;
  isUnitOriented: boolean;
  data?: any;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  icon?: React.ReactNode;
};

const AddStaffForIA: React.FC<AddStaffForIAProps> = ({
  buttonType = 'BUTTON',
  improvementAreaId,
  improvementAreaName,
  type,
  data,
  color = 'primary',
  variant = 'contained',
  icon,
  isUnitOriented
}) => {
  const dispatch = useDispatch();

  const [open, setModalOpen] = useState(false);
  const [name, setName] = useState(type === 'ADD' ? '' : data.name);
  const [email, setEmail] = useState(type === 'ADD' ? '' : data.email);
  const [bookingURL, setBookingURL] = useState(type === 'ADD' ? '' : data.bookingURL);

  
  const interventionUnits = useSelector((state: RootState) => state.intervention?.interventionUnits);
  let units = data?.units &&  data?.units.map(x=>{return {code:x,label:''}})|| [{code:'ALL',label:''}]
  const [selectedAssignments, setSelectedAssignments] = React.useState<any[]>(units);
  const [isAcademicStaff, setIsAcademicStaff] = useState(data?.isTeachingStaff || false);
  const role = useSelector((state: RootState) => state.roles?.roles);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAddClick = () => {
    let unitArr: any = null;
    if(isUnitOriented) {
      unitArr = selectedAssignments.map(x=>x.code);
      if(role === roles.LECTURER && unitArr.includes("ALL")) {
        unitArr = interventionUnits.filter(x => x.code !== "ALL").map(x => x.code);
      }
    } else if(isAcademicStaff) {
      unitArr = [];
    }
    if (type === 'ADD') {
      dispatch(addStaffForIA({ name, email, bookingURL, improvementAreaId,isTeachingStaff: isAcademicStaff,units:isUnitOriented ?  selectedAssignments.map(x=>x.code) : (isAcademicStaff ? [] : null)}, improvementAreaId));
    } else {
      dispatch(updateStaffForIA({ name, email, bookingURL, isTeachingStaff: isAcademicStaff,units:isUnitOriented ?  selectedAssignments.map(x=>x.code) : (isAcademicStaff ? [] : null)  }, improvementAreaId, data.id));
    }
    handleModalClose();
  };

  const renderModal = () => {
    return (
      <CustomModal
        open={open}
        onClose={handleModalClose}
        buttonText={type === 'ADD' ? 'Save' : 'Update'}
        headerText={(type === 'ADD' ? 'Add' : 'Update') + ' Staff Member'}
        onSuccess={onAddClick}
      >
         <Grid container direction={'column'} spacing={1} alignItems="flex-end" justify="space-between">

           <Grid item>
           <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          Name:
        </InputLabel>
        <TextField
          style={{ width: 400, margin: 12 }}
          variant="outlined"
          id="standard-basic"
          value={name}
          placeholder="Enter name of staff member"
          onChange={(e) => setName(e.target.value)}
        />
           </Grid>
       
    {isUnitOriented &&       <Grid item>
          <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
            Select Units:
          </InputLabel>
          <Autocomplete
            style={{ width: 400, margin: 12 }}
            multiple
            limitTags={3}
            id="multiple-limit-tags"
            disableCloseOnSelect
            options={interventionUnits}
            getOptionLabel={(option) => option.code}
            value={selectedAssignments}
            defaultValue={[interventionUnits[0]]}
            onChange={(event, newValue) => {
              if (newValue.length === 0 || newValue[newValue.length - 1].code === 'ALL') {
                newValue = [interventionUnits[0]];
              } else {
                if (newValue[0].code === 'ALL') newValue.shift();
              }
              setSelectedAssignments(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="" placeholder="Type or select units" />
            )}
          />
        </Grid>}

<Grid item>
<InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          Email:
        </InputLabel>
        <TextField
          style={{ width: 400, margin: 12 }}
          variant="outlined"
          id="standard-basic"
          value={email}
          placeholder="Enter email ..."
          onChange={(e) => setEmail((e.target.value).toLowerCase())}
        />


<InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          Booking URL:
        </InputLabel>
        <TextField
          style={{ width: 400, margin: 12 }}
          variant="outlined"
          id="standard-basic"
          value={bookingURL}
          placeholder="Enter booking URL ..."
          onChange={(e) => setBookingURL(e.target.value)}
        />
</Grid>



       
        </Grid>
        {isUnitOriented &&     <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          is a academic staff:  <Switch
        checked={isAcademicStaff}
        onChange={()=>{setIsAcademicStaff(!isAcademicStaff)}}
        color="primary"
        name="isUnitOriented"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
        </InputLabel>}
      </CustomModal>
    );
  };

  return (
    <>
      {buttonType === 'BUTTON' ? (
        <Button startIcon={<Add />} onClick={() => setModalOpen(true)} color={color} variant={variant} size="small">
          {type + ' Staff Member'}
        </Button>
      ) : (
        <IconButton edge="end" aria-label={type} onClick={() => setModalOpen(true)}>
          {icon}
        </IconButton>
      )}

      {renderModal()}
    </>
  );
};

export default AddStaffForIA;
