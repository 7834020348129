import { Divider, MenuItem, Select, TextField } from '@material-ui/core';
import PollIcon from '@material-ui/icons/Poll';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect } from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { CloseNodeButton } from '../../components/closeNodeButton';
import { CustomHandle1, CustomNodeTitle1, DivRows, MainDiv1 } from '../../flowChartComponents/styled-framework-items';
import '../index.scss';


const Outer = styled.div`
  padding: 30px;
`;

export const Indices=({data})=> {
  const dispatch = useDispatch();
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);

  const [selectedIndicesValue, setSelectedIndicesValue] = React.useState(data.indexOperator || '<');
  const [selectedIndexTypeValue, setSelectedIndexTypeValue] = React.useState(data.indexType || 'attendance');

  const [indexValue, setIndexValue] = React.useState(data.indexValue || 0);
  const updateInitialState=()=>{
    let updateObj = { ...flowChartData };
    updateObj.indexOperator = selectedIndicesValue;
    updateObj.indexType = selectedIndexTypeValue;
    updateObj.indexValue = indexValue;
    data.indexOperator = selectedIndicesValue;
    data.indexType = selectedIndexTypeValue;
    data.indexValue = indexValue;
    dispatch(setFlowChartData(updateObj));
  }

  useEffect(() => {
    updateInitialState()
  }, []);

  const handleIndicesChange = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.indexOperator = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setSelectedIndicesValue(event.target.value);
data.indexOperator = event.target.value;

  };
  const handleIndexTypeChange = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.indexType = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setSelectedIndexTypeValue(event.target.value);
data.indexType = event.target.value;

  };
  const updateData = (e) => {
    let updateObj = { ...flowChartData };
    updateObj.indexValue = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setIndexValue(e.target.value);
data.indexValue = e.target.value;

  };

  const indexTypes = [
    {label:'Attendance Index',value:'attendance'},
    {label:'Assessment Index',value:'assessment'},
    {label:'Legacy Index',value:'legacy'},
    
    
  ];

  return (
    <MainDiv1>
            <CustomHandle1 type="source" id="condition_indices" position={Position.Bottom} />

      <CustomNodeTitle1>
        <PollIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Attendance
        <CloseNodeButton/>
      </CustomNodeTitle1>
      <Divider />
      <Outer>
        <DivRows>
          <span style={{ marginTop: '16px', marginRight: 40, fontWeight: 'bold', width: 50, fontSize: 15 }}>
            Index:{' '}
          </span>
          <div style={{ width: 300 }}>
            <Select
              style={{  width: 279,marginLeft: 11}}
              labelId="demo-simple-select-labelConsultation"
              id="demo-simple-selectConsultation"
              value={selectedIndexTypeValue}
              onChange={handleIndexTypeChange}
              disabled
            >
             
              {indexTypes.map((x) => (
                <MenuItem value={x.value}>{x.label}</MenuItem>
              ))}
            </Select>
          </div>
        </DivRows>
        <DivRows>
          <span style={{ marginTop: '10px', marginRight: 40, fontWeight: 'bold', width: 50, fontSize: 15 }}>is: </span>

          <div style={{ width: 300 }}>
            <ToggleButtonGroup
              value={selectedIndicesValue}
              exclusive
              onChange={handleIndicesChange}
              aria-label="text alignment"
              className={'toggleBtnGroupCustom'}
            >
              <ToggleButton value=">">
                <span style={{ fontSize: 13, width: 125 }}>greater than</span>
              </ToggleButton>
              <ToggleButton value="<" className="toggleBtnCustom">
                <span style={{ fontSize: 13, width: 125 }}>less than</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DivRows>
        <br />
        <DivRows>
          <span style={{ marginTop: '10px', marginRight: 40, fontWeight: 'bold', width: 50, fontSize: 15 }}>
            Value :{' '}
          </span>
          <div style={{ width: 300 }}>
            <TextField
              style={{ padding: '10px', width: 300 }}
              id="custom-grademarks"
              type="number"
              placeholder="Enter marks for the conditon"
              value={indexValue}
              onChange={(e: any) => {
                updateData(e);
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </DivRows>
      </Outer>{' '}
    </MainDiv1>
  );
}
