import React from 'react';
import { TableRow, TableCell, TableHead, TableBody, Button, Table } from '@material-ui/core';

export interface DataTableProps {
  columnKeys: string[];
  data: any[];
  actions?: string[];
  onAction?: (action: string, row: any) => void;
  columnLabels?: string[];
}
const DataTable = ({ columnKeys, columnLabels, data, actions, onAction }: DataTableProps) => {
  if (actions && actions.length > 0 && !onAction) {
    throw new Error('onActions handler must be provided when actions are given in the table');
  }

  const header = (
    <TableHead>
      <TableRow>
        {columnKeys.map((ck, index) => {
          let columnName = columnLabels && columnLabels[index];
          if (!columnName) columnName = ck;
          return (
            <TableCell align={index > 0 ? 'center' : 'left'} key={index}>
              {columnName}
            </TableCell>
          );
        })}
        {actions && actions.length > 0 && <TableCell align="center">Actions</TableCell>}
      </TableRow>
    </TableHead>
  );

  const body = (
    <TableBody>
      {data.map((row, index) => {
        return (
          <TableRow key={index}>
            {columnKeys.map((ck, index) => {
              return (
                <TableCell key={ck} align={index > 0 ? 'center' : 'left'}>
                  {row[ck] !== null || row[ck] !== undefined ? row[ck] : '-'}
                </TableCell>
              );
            })}
            {actions &&
              actions.length > 0 &&
              actions.map((a, i) => {
                return (
                  <TableCell key={i} align="center">
                    <Button variant="outlined" color="secondary" onClick={() => onAction && onAction(a, row)}>
                      {a}
                    </Button>
                  </TableCell>
                );
              })}
          </TableRow>
        );
      })}
    </TableBody>
  );

  return (
    <Table stickyHeader>
      {header}
      {body}
    </Table>
  );
};

export default DataTable;
