import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Card, CardContent, Grid, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { getIssuesByStudentId } from '../../actions';
import CustomExpansionPanel from '../../components/ExpansionPanels';
import { ticketStatus, ticketColors } from '../../util/enums';
import { TicketDetails } from '../../util/types';
import { capitalize } from '../../util/common';
import Chip from '../../components/Chip';
import { TicketDetails as Details } from '../../components/Modals';
import Loader from '../../components/Loader';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: { backgroundColor: 'red', color: 'white' },
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 1150,
    },
    progressCard: {
      margin: theme.spacing(2, 0),
    },
    noRecordsText: {
      textAlign: 'center',
      marginTop: '10px',
    },
  })
);

type progressProps = {
  id: string;
};

const Issues: React.FC<progressProps> = ({ id: studentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const issues = useSelector((state: RootState) => state.issues?.issues);
  const details = useSelector((state: RootState) => state.issues?.details);
  const loading = useSelector((state: RootState) => state.issues?.issuesLoader);

  useEffect(() => {
    dispatch(getIssuesByStudentId(studentId));
  }, [studentId, dispatch]);
  const studentIssues = issues[studentId] || {};

  const currentStatus = (created: Date, updated: Date, status: string) => {
    const numberOfDays = Math.round(moment(updated).diff(moment(created)) / (1000 * 60 * 60 * 24));
    if (status === ticketStatus.CLOSED || status === ticketStatus.RESOLVED) {
      return { type: ticketColors.GREEN, label: `Resolved in ${numberOfDays} ${numberOfDays === 1 ? 'day' : 'days'}` };
    }
    return { type: ticketColors.RED, label: `Unresolved for ${numberOfDays} ${numberOfDays === 1 ? 'day' : 'days'}` };
  };

  const closedTickets = (tickets: TicketDetails[]) => {
    const closedTickets = tickets.reduce((acc: number, x: TicketDetails) => {
      const { status = '' } = x;
      if (status === ticketStatus.CLOSED || status === ticketStatus.RESOLVED) {
        acc += 1;
      }
      return acc;
    }, 0);
    return closedTickets;
  };

  const chips = (closed: number, pending: number, total: number) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {' '}
          <Chip
            type={ticketColors.GREEN}
            avatarLabel={closed.toString()}
            label={capitalize(ticketStatus.RESOLVED)}
          />{' '}
        </Grid>
        <Grid item xs={4}>
          {' '}
          <Chip
            type={ticketColors.RED}
            avatarLabel={pending.toString()}
            label={capitalize(ticketStatus.UN_RESOLVED)}
          />{' '}
        </Grid>
        <Grid item xs={4}>
          {' '}
          <Chip type={ticketColors.TOTAL} avatarLabel={total.toString()} label={capitalize(ticketStatus.Total)} />{' '}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Card className={classes.progressCard}>
        <CardContent className={classes.content}>
          {!loading ? (
            <>
              {Object.keys(studentIssues).map((ticketType: string) => {
                const tickets: TicketDetails[] = studentIssues[ticketType] || [];
                return (
                  <CustomExpansionPanel
                    key={ticketType}
                    heading={ticketType}
                    teritaryHeading={chips(
                      closedTickets(tickets),
                      tickets.length - closedTickets(tickets),
                      tickets.length
                    )}
                  >
                    {tickets && tickets.length > 0 ? (
                      tickets.map((ticket, index) => {
                        const { created_at, updated_at, status, subject, id } = ticket;
                        const { type, label } = currentStatus(created_at, updated_at, status);
                        return (
                          <div key={id} className={classes.progressCard}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Chip avatar={false} label={label} type={type} />
                              </Grid>
                              <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Details
                                  studentId={studentId}
                                  id={id}
                                  subject={subject}
                                  ticketDetails={details[id] || []}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {subject}
                              </Grid>
                            </Grid>
                            {index !== tickets.length - 1 && <Divider style={{ margin: '10px 0px' }} />}
                          </div>
                        );
                      })
                    ) : (
                      <div key={ticketType} className={classes.noRecordsText}>
                        <span> There are no issues to display</span>
                      </div>
                    )}
                  </CustomExpansionPanel>
                );
              })}

              {Object.keys(studentIssues).length === 0 && (
                <div className={classes.noRecordsText}>
                  <span>There are no issues to display</span>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Issues;
