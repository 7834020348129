import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImprovementAreas, GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS } from '../../actions/improvementArea';
import Loader from '../../components/Loader';
import AddImprovementArea from '../../components/Modals/addImprovementArea';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import { dashboardUseStyles } from '../intervention/intervention_styles';
import ImprovementAreaItem from './improvementAreaItem';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { setInterventionUnits } from '../../actions';

const useStyles = dashboardUseStyles;

export default function ImprovementAreas() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.ImprovementArea?.data);
  const response = useSelector((state: RootState) => state.ImprovementArea?.response);
  const loader = useSelector((state: RootState) => state.ImprovementArea?.loader);
  const [openSnackBar, setOpenSnacbar] = useState(false);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const role = useSelector((state: RootState) => state.roles?.roles);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!_.isEmpty(response)) {
      setOpenSnacbar(true);
    }
  }, [response]);

  useEffect(() => {
    dispatch(getImprovementAreas());
  }, [dispatch]);

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      if (user.email) {
        dispatch(setInterventionUnits(selectedBuCode, selectedSemester, user?.email, role));
      }
    };
    getAvatar();
  }, [selectedBuCode, selectedSemester]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
      payload: null,
    });
   
    setOpenSnacbar(false);
  };

  return (<>
    <Page header={'Improvement Areas'} showBackButton={true}>
    <div className="float-left">
        <div style={{color:'grey'}}>Associated Staff Members with their respective booking URLs.</div>
      </div>
      <div className="float-right">
        <AddImprovementArea type="ADD" buttonType={'BUTTON'} variant={'outlined'} />
      </div>
      <br />
      <br />
      {!loader ? (
        data &&
        data.map((item) => {
          return <ImprovementAreaItem data={item} />;
        })
      ) : (
        <Loader />
      )}
    </Page>
    <Snackbar onClose={handleClose} open={openSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={response?.status === 'ERROR' ? "error" : 'success'}>
          {response?.message}
        </MuiAlert>
      </Snackbar>
     
    </>
  );
}
