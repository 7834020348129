import { Card, CardHeader, Divider } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentAssignmentRemindersDataById } from '../../../actions';
import { CustomTable, StyledContent, TotalCount } from '../../../components/Common';
import Loader from '../../../components/Loader';
import ShowEmail from '../../../components/Modals/showEmail';
import { RootState } from '../../../reducer';
import { filters } from '../../../util/enums';

type ResultsProps = {
  className?: string;
  logs: any[];
  setPage: (page: number) => void;
  setRowsPerPage: (rowPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  count: number;
  loader: boolean;
  onFilter: (query: any) => void;
  values: any;
  setValues: (state: any) => void;
};

const Result: React.FC<ResultsProps> = ({
  className,
  logs = [],
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loader,
  onFilter,
  values,
  setValues,
  ...rest
}) => {
    const dispatch = useDispatch();
const htmlMail = useSelector((state: RootState) => state.studentReminderLogs?.htmlMail);
  const contentLoader = useSelector((state: RootState) => state.studentReminderLogs?.contentLoader);
  
  const [sortStartDate, setIsExpandedStart] = useState(true);
  const [sortEngagementIndex, setIsExpandedEngagement] = useState(false);
  const [orderByDate, setOrderBy] = useState(false);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  const handleSortChange = (value: string) => {
    const sort = { sortStartDate, sortEngagementIndex, orderByDate };
    if (value === filters.startDate) {
      setOrderBy(true);
      setIsExpandedStart(!sortStartDate);
      sort.sortStartDate = !sortStartDate;
      sort.orderByDate = true;
    } else if (value === filters.engagementIndex) {
      setOrderBy(false);
      setIsExpandedEngagement(!sortEngagementIndex);
      sort.sortEngagementIndex = !sortEngagementIndex;
      sort.orderByDate = false;
    }
    setValues({
      ...values,
      sort,
    });
    onFilter({ ...values, sort });
  };

  const getEmailContent = (id)=>{
      dispatch(getStudentAssignmentRemindersDataById(id))
  }

//   {
//     id: 12,
//     email: '30118100@students.atmc.edu.au',
//     type: 'TASK_WISE_ASSESSMENT_RESULT_INTERVENTION',
//     status: 'YES',
//     buCode: 'FED',
//     triggerId: 27,
//     createdAt: '2021-02-23T15:47:39.544Z',
//     updatedAt: '2021-02-23T15:47:39.544Z'
//   }

  const decoratedTableData = logs.map((log: any) => {
    return [
      log.type.split("_").join(' '),
      log.assignmentDetails.unitCode,
      log.assignmentDetails.taskName,
      log.assignmentDetails.taskNumber,
      log.createdAt ? moment(log.createdAt).format('MMM D, YYYY hh:mm') : '-',
      <ShowEmail loader={contentLoader}
      title={'Reminder Email - '}
                              type={log.type}
                              onClick={()=>getEmailContent(log.id)}
                              htmlMail={htmlMail}
                              disabled={false}
                            />,
    ];
  });

  return (
    <>
      <TotalCount color="textSecondary" variant="body2">
        {count} Records found. Page {page + 1} of {Math.ceil(count / rowsPerPage)}
      </TotalCount>
      <Card>
        <CardHeader title="Search Results" />
        <Divider />
        <StyledContent>
          {logs && !loader ? (
            <CustomTable
              tableHead={[
                { text: 'Type' },
                { text: 'Unit Code' },
                { text: 'Task Name' },
                { text: 'Task Number' },
                {
                    text: 'Sent On',
                  displaySort: true,
                  onSortClick: () => handleSortChange(filters.startDate),
                  showUpArrow: sortStartDate,
                },
                // { text: 'End Time' },
                // {
                //   text: 'Engagement Index',
                //   displaySort: true,
                //   onSortClick: () => handleSortChange(filters.engagementIndex),
                //   showUpArrow: sortEngagementIndex,
                // },
                { text: 'Actions' },
              ]}
              tableData={decoratedTableData}
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <Loader />
          )}
        </StyledContent>
      </Card>
    </>
  );
};

export default Result;
