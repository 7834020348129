import { Divider, makeStyles, Snackbar, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { BuildSharp, VerticalAlignCenter } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import dagre from 'dagre';
import localforage from 'localforage';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  Elements,
  isNode,
  OnLoadParams,
  Position,
  ReactFlowProvider,
  removeElements
} from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  createIntervention,
  ON_INTERVENTION_CREATION_ERROR_RESPONSE,
  ON_INTERVENTION_CREATION_SUCCESS_RESPONSE,
  setInterventionStudents,
  setInterventionUnits
} from '../../actions';
import { getImprovementAreas } from '../../actions/improvementArea';
import Loader from '../../components/Loader';
import ShowInterventionPreview from '../../components/Modals/showInterventionPreview';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import './dnd.scss';
import {
  FlowChartUtlityBar,
  initialElements,
  nodeTypes,
  UtilityBarItems
} from './intervention_styles';
import './layouting.scss';
import Sidebar from './sidebar';
import { ConfigurationSidebar } from './sidebar_config';
import './validation.scss';
import { roles } from '../../util/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    snackbar: {
      // top: '-300px',
      // marginLeft: '175px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    pagination: {
      position: 'relative',
      fontSize: '14px',
      marginLeft: '10px',
      marginBottom: '9px',
      fontWeight: 'bold',
      marginTop: '14px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: '#04337824',
      color: '#494949',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
interface IElementsContextProps {
  elements: Elements;
  setElements: (state) => void;
  selectedAssignments:any;
}
export const SchedulerContext = React.createContext({ recurrenceValue: {cronExpression:'',cronText:'', endDate:new Date()}, setRecurrenceValue: (state) => { } });
export const ElementsContext = React.createContext({} as IElementsContextProps);



localforage.config({
  name: 'react-flow-docs',
  storeName: 'flows',
});

let id = 0;
let edgeId = 1;
const getId = (type) => `N_${type}_${id++}`;
const getEdgeId = () => `E_${edgeId++}`;

export default function AddIntervention1() {
  const inputData = {
    label: 'Poor Academic Progress',
    units: [{ code: 'ALL', label: 'Include all units' }],
    students: ['ALL'],
    trigger: 'INSTANT',
  };
  const location = useLocation();
  const classes = useStyles();
  const [selectedAssignments, setSelectedAssignments] = React.useState<any[]>(
    inputData.units ? inputData.units : []
  );
  const [selectedStudents, setSelectedStudents] = React.useState<any[]>(
    inputData.students ? inputData.students : []
  );
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState<string>(inputData.label ? inputData.label : '');
  const [selectedTriggerValue, setSelectedTriggerValue] = React.useState(
    inputData.trigger ? inputData.trigger : 'None'
  );
  const [recurrenceValue, setRecurrenceValue] = useState({});
  const errorResponse = useSelector((state: RootState) => state.intervention?.errorResponse);
  const successResponse = useSelector((state: RootState) => state.intervention?.successResponse);
  const onCreationLoader = useSelector((state: RootState) => state.intervention?.onCreationLoader);
  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [interventionId, setInterventionId] = useState(-1);
  const improvementAreas = useSelector((state: RootState) => state.ImprovementArea?.data);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: ON_INTERVENTION_CREATION_ERROR_RESPONSE,
      payload: null,
    });
    setErrorOpenSnackbar(false);
    dispatch({
      type: ON_INTERVENTION_CREATION_SUCCESS_RESPONSE,
      payload: null,
    });
    setSuccessOpenSnackbar(false);
  };

  useEffect(() => {
    if (!_.isEmpty(errorResponse)) {
      setErrorOpenSnackbar(true);
    }
  }, [errorResponse]);

  useEffect(() => {
    dispatch(getImprovementAreas());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(successResponse)) {
      setIsCreated(true);
      setInterventionId(successResponse?.data.id);
      setSuccessOpenSnackbar(true);
    }
  }, [successResponse]);

  const handleTriggerChange = (event) => {
    setSelectedTriggerValue(event.target.value);
  };
  

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAssignments(event.target.value as number[]);
  };

  // -------------- Node -------------
  const reactFlowWrapper: any = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | undefined>(undefined);
  const [elements, setElements] = useState<Elements>(initialElements);
  // const onElementClick = (event, element) => console.log('click', element);
  const onElementsRemove = (elementsToRemove: Elements) => {
    setElements((els) => removeElements(elementsToRemove, els));
  };
  const onConnect = (params: Edge | Connection) => {
    const newParams = { ...params, ...{ animated: true, label: getEdgeId() } };
    setElements((els) => addEdge(newParams, els));
  };

  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const flowKey = 'example-flow';
  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position: any = reactFlowInstance?.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(type),
      type,
      position,
      data: { label: `${type} node` },
    };

    setElements((es) => es.concat(newNode));
  };

  const onAdd = () => {
    const newNode = {
      id: getId('Dummy'),
      data: { label: 'Added node' },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight,
      },
    };
    setElements((els) => els.concat(newNode));
  };

  const onCreate = () => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localforage.setItem(flowKey, flow);
      let selectedUnits = selectedAssignments.map(x => x.code).join(', ');
      if(role === roles.LECTURER && selectedUnits.includes("ALL")) {
        selectedUnits = interventionUnits.filter(x => x.code !== "ALL").map(x => x.code).join(', ');
      }
      let studentList = selectedStudents.join(', ');
      if(role === roles.LECTURER && selectedStudents.includes("ALL")) {
        studentList = interventionStudents.filter(x => x !== "ALL").join(', ');
      }
      // Create intervention wiht the current flow state
      const inputData = {
        flowChart: flow,
        label,
        units: selectedUnits,
        students: studentList,
        trigger: selectedTriggerValue,
        recurrenceValue: selectedTriggerValue === 'SCHEDULED' ? recurrenceValue : null,
      };
      dispatch(createIntervention(inputData));
    }
  };

  const onLayout = (direction: string) => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: 450, height: 350 });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);

    const layoutedElements = elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? Position.Left : Position.Top;
        el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
        // we need to pass a slighltiy different position in order to notify react flow about the change
        // @TODO how can we change the position handling so that we dont need this hack?
        el.position = { x: nodeWithPosition.x + Math.random() / 1000, y: nodeWithPosition.y };
      }

      return el;
    });

    setElements(layoutedElements);
  };

  // -------------- Node -------------

  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const interventionUnits = useSelector((state: RootState) => state.intervention?.interventionUnits);
  const interventionStudents = useSelector((state: RootState) => state.intervention?.interventionStudents);
  const inValidBookingConsultation = useSelector((state: RootState) => state.intervention?.inValidBookingConsultation);
  const role = useSelector((state: RootState) => state.roles?.roles);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      if (user.email) {
        dispatch(setInterventionUnits(selectedBuCode, selectedSemester, user?.email, role, true));
      }
    };
    getAvatar();
  }, [selectedBuCode, selectedSemester]);


  return (
    <>
      <Page header={'Add Intervention'} showBackButton={true}>
        <ConfigurationSidebar label={label}
          setLabel={handleLabelChange}
          selectedAssignments={selectedAssignments}
          handleChange={handleChange}
          setSelectedAssignments={setSelectedAssignments}
          interventionUnits={interventionUnits}
          selectedTriggerValue={selectedTriggerValue}
          handleTriggerChange={handleTriggerChange}
          recurrenceValue={recurrenceValue}
          setRecurrenceValue={setRecurrenceValue}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          interventionStudents={interventionStudents}
        />
        <Divider />
        {onCreationLoader ? (
          <Loader />
        ) : (
          <div className="dndflow">
            <ElementsContext.Provider value={{ elements, setElements, selectedAssignments }}>
              <ReactFlowProvider>
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    elements={elements}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    onElementsRemove={onElementsRemove}

                    onLoad={onLoad}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    snapToGrid={true}
                  >
                    <Controls />
                    {/* <MiniMap /> */}
                    <Background />
                  </ReactFlow>
                </div>
                <Sidebar onLayout={onLayout} onAdd={onAdd} />
              </ReactFlowProvider>
            </ElementsContext.Provider>

          </div>
        )}
      </Page>
      <FlowChartUtlityBar>
        <UtilityBarItems
          color="default"
          label="ALIGN THE LAYOUT"
          onClick={() => onLayout('TB')}
          icon={<VerticalAlignCenter />}
        />

        <ShowInterventionPreview id={interventionId} disabled={!isCreated} />
        <UtilityBarItems color="primary" label="Create" disabled={inValidBookingConsultation} onClick={onCreate} icon={<BuildSharp />} />
      </FlowChartUtlityBar>
      <Snackbar className={classes.snackbar} onClose={handleClose} open={openErrorSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {errorResponse?.message}
        </MuiAlert>
      </Snackbar>
      <Snackbar className={classes.snackbar} onClose={handleClose} open={openSuccessSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
          {successResponse?.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
