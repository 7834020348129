import { Card, Divider, ListItemSecondaryAction } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { deleteImprovementArea, deleteStaffForIA } from '../../actions/improvementArea';
import { FlexRowDiv } from '../../components/Common';
import AddImprovementArea from '../../components/Modals/addImprovementArea';
import AddStaffForIA from '../../components/Modals/addStaffForIA';
import ButtonWithConfirmation from '../../components/Modals/confirmation';
import { convertActionLink } from '../../util/common';
import { getRandomMutedColor } from '../../util/constants';

const MAX_LENGTH = 50;
type ImprovementAreaItemProps = {
  data: any;
};

const CustomDiv = styled.div`
  adding: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomType = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const CustomList = styled(List)`
  width: 100%;
`;

const CustomIAActions = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomStaffActions = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomInfoLabel = styled.div`
width: 100%;
text-align: center;
color: grey;
margin: 20px;
`

const CustomTag = styled.div`
margin-left: 10px;
font-size: 11px;
background-color: #4242429c;
border-radius: 13px;
color: white;
font-weight: bold;
padding-left: 6px;
padding-right: 6px;
`

const ImprovementAreaItem: React.FC<ImprovementAreaItemProps> = ({ data }) => {
  const dispatch = useDispatch();

  const deleteStaff = (id, staffId) => {
    dispatch(deleteStaffForIA(id, staffId));
  };

  const deleteIA = (id) => {
    dispatch(deleteImprovementArea(id));
  };

  return (
    <>
      <Card style={{ padding: 20, marginBottom: 20 }}>
        <CustomDiv>
         <FlexRowDiv><CustomType>{data.type}</CustomType> {data.isUnitOriented && <CustomTag style={{padding: 4}}>Unit Specific</CustomTag>} </FlexRowDiv> 
          <CustomIAActions>
            <AddImprovementArea type="UPDATE" buttonType={'ICON'} data={data} id={data.id} icon={<Edit />} />
            <ButtonWithConfirmation
              buttonType={'ICON'}
              buttonText={'delete'}
              icon={<Delete />}
              onClickFunc={() => deleteIA(data.id)}
              question={'Are you sure you want to delete ?'}
              agree={'YES'}
              disagree={'NO'}
            />
            <AddStaffForIA improvementAreaId={data.id} improvementAreaName={data.type} type="ADD" variant="outlined" isUnitOriented={data.isUnitOriented}/>
          </CustomIAActions>
        </CustomDiv>

        <br />
        <Divider />
        <br />

        {data.staffMembers && data.staffMembers.length > 0 ? (
          <CustomList>
            {data.staffMembers.map((staff) => {
              return (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: getRandomMutedColor() }}>{staff.name.charAt(0)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ <div style={{display:'flex'}}><div style={{ fontWeight: 'bold' }}>{staff.name}</div>{staff.isTeachingStaff && <CustomTag>Academic Staff</CustomTag>}</div>}
                    secondary={staff.email}
                    style={{ width: '25%' }}
                  />
                  <ListItemText
                   style={{ width: '25%' }}
                    primary={ staff.units && staff.units.length > 0 && <div style={{padding:25}}> {staff.units.join(' ,')}</div>}
                  />
                  <ListItemText
                  style={{ width: '25%' }}
                    primary={
                      <a
                        href={convertActionLink(staff.bookingURL)}
                        target="_blank"
                        rel="noreferrer"
                        style={{ width: 100, textAlign: 'left' }}
                      >
                        {' '}
                        <div>
                          {staff.bookingURL.length > MAX_LENGTH ? (
                            <div>{`${staff.bookingURL.substring(0, MAX_LENGTH)}...`}</div>
                          ) : (
                            <p>{staff.bookingURL}</p>
                          )}
                        </div>
                      </a>
                    }
                  />

                  <ListItemSecondaryAction>
                    <CustomStaffActions>
                      <AddStaffForIA
                        improvementAreaId={data.id}
                        improvementAreaName={data.type}
                        isUnitOriented={data.isUnitOriented}
                        type="UPDATE"
                        variant="outlined"
                        buttonType="ICON"
                        icon={<Edit />}
                        data={staff}
                      />
                      <ButtonWithConfirmation
                        buttonType={'ICON'}
                        buttonText={'delete'}
                        icon={<Delete />}
                        onClickFunc={() => deleteStaff(data.id, staff.id)}
                        question={'Are you sure you want to delete ?'}
                        agree={'YES'}
                        disagree={'NO'}
                      />
                    </CustomStaffActions>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </CustomList>
        ) : (<CustomInfoLabel>No Staff Member Present</CustomInfoLabel>)}
      </Card>
    </>
  );
};

export default ImprovementAreaItem;
