import { Divider, MenuItem, Select, TextField } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { ImprovementAreas } from '../../../../util/constants';
import { CloseNodeButton } from '../../components/closeNodeButton';
import { CustomHandle2, CustomNodeTitle2, DivRows, LabelSpan, LabelSpanForSelect, MainDiv2 } from '../../flowChartComponents/styled-framework-items';


const Outer = styled.div`
  padding: 30px;
`;


export const ReferStudyMaterial=({data})=> {
  const dispatch = useDispatch();
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);
  const improvementAreasData = useSelector((state: RootState) => state.ImprovementArea?.data);

  const [referStudyMaterial, setReferStudyMaterial] = React.useState(data.links);
  const updateData = (e) => {
    let updateObj = { ...flowChartData };
    updateObj.links = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setReferStudyMaterial(e.target.value);
    data.links = e.target.value;
  };
  const [improvementAreas, setImprovementAreas] = React.useState(
    data.improvementAreas
  );

  const handleImprovementArea = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.improvementAreas = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setImprovementAreas(event.target.value);
    data.improvementAreas = event.target.value;
  };
  const [description, setDescription] = React.useState(data.description);
  const updateDescription = (e) => {
    let updateObj = { ...flowChartData };
    updateObj.description = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setDescription(e.target.value);
    data.description = e.target.value;
  };

  
  return (
    <MainDiv2>
       <CustomHandle2 type="target" id="actions_referStudyMaterial" position={Position.Top} />
       {/* <CustomHandle1 type="source" id="actions_referStudyMaterial" position={Position.Bottom} /> */}
      <CustomNodeTitle2>
        <LinkIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Attach Links
        <CloseNodeButton/>
      </CustomNodeTitle2>
      <Divider />
      <Outer>
        
      <DivRows>
          <LabelSpanForSelect style={{ marginTop: '0px !important' }}>Improvement Areas: </LabelSpanForSelect>{' '}
         <Select
            style={{ width: 279, marginLeft: 11 }}
            labelId="demo-simple-select-labelConsultation"
            id="demo-simple-selectConsultation"
            value={improvementAreas}
            onChange={handleImprovementArea}
          >
            {improvementAreasData.map((x) => (
              <MenuItem value={x.type}>{x.type}</MenuItem>
            ))}
          </Select> 
        </DivRows>

      <DivRows>
      <LabelSpan>Description: </LabelSpan>{' '}
          <TextField
            style={{ padding: '10px', width: 300 }}
            id="custom-description"
            type="text"
            multiline
            placeholder="Enter the description"
            value={description}
            onChange={(e: any) => {
              updateDescription(e);
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </DivRows>

        <DivRows>
        <LabelSpan>Link: </LabelSpan>{' '}
          <TextField
            style={{ padding: '10px', width: 300 }}
            id="custom-referStudyMaterial"
            type="text"
            placeholder="http://test-session-link.com"
            value={referStudyMaterial}
            onChange={(e: any) => {
              updateData(e);
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </DivRows>

       
        {/* <FormHelperText id="component-helper-text">Add more than one link separated with comma (,)</FormHelperText> */}
      </Outer>
    </MainDiv2>
  );
}
