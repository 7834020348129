import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Search from '../../../components/searchBar';
import { Student } from '../../../models/student';
import { theme } from '../../../theme';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Check, Clear } from '@material-ui/icons';
import { RootState } from '../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentByUnitId } from '../../../actions';

const StyledTablePaginator = styled(TablePagination)`
  margin-left: auto;
`;

const SearchContainer = styled.div`
  margin: ${theme.spacing(2)}px 0px;
`;

const SCFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1)}px ${theme.spacing(3)}px;
`;

const SCFilterContainer = styled.div`
  margin: ${theme.spacing(0.5)}px 0px;
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${theme.palette.background.default};
`;

interface FilterData {
  EAP: boolean;
  Intervention: boolean;
  Repeating: boolean;
}

const UnitStudents: React.FC = () => {
  const { unitId } = useParams<{ unitId: string; tab: string }>();
  const studentData = useSelector((state: RootState) => state.units?.studentData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState<Student[] | undefined>([]);
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState<FilterData>({ EAP: false, Intervention: false, Repeating: false });

  const dispatch = useDispatch();

  const setFilteredData = async () => {
    if (!studentData) return;
    let filtered: Student[] = Object.assign([], studentData);
    // Apply search filter
    filtered = filtered.filter((x) => {
      if (searchText === '') return true;
      return (x.firstName + x.familyName + x.providerStudentID).toLowerCase().includes(searchText.toLowerCase());
    });
    if (filterData.EAP) filtered = filtered.filter((x) => x.eap === true);
    if (filterData.Intervention) filtered = filtered.filter((x) => x.intervention === true);
    if (filterData.Repeating) filtered = filtered.filter((x) => x.repeating > 1);
    setTableData(filtered);
  };

  useEffect(() => {
    dispatch(getStudentByUnitId(unitId));
  }, [unitId]);

  useEffect(() => {
    setFilteredData();
  }, [studentData, filterData, searchText]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onFilterChanged = (checked: boolean, key: string) => {
    const newFilter = Object.assign({}, filterData);
    newFilter[key] = checked;
    setFilterData(newFilter);
  };

  const clearFilters = () => {
    setFilterData({ EAP: false, Intervention: false, Repeating: false });
  };

  if (!tableData) return <div />;

  const FilterItems = () => {
    return (
      <SCFormGroup>
        {Object.keys(filterData).map((key) => {
          return (
            <>
              <SCFilterContainer>
                <span>{key}</span>
                <Switch
                  checked={filterData[key]}
                  onChange={({}, checked) => {
                    onFilterChanged(checked, key);
                  }}
                  name={key}
                  key={key}
                />
              </SCFilterContainer>
            </>
          );
        })}
      </SCFormGroup>
    );
  };

  return (
    <>
      <SearchContainer>
        {FilterItems}
        <Search
          onSearchTextChanged={(value) => setSearchText(value)}
          searchText={searchText}
          searchPlaceHolder="Enter student name or ID to search"
          onSearch={() => {}}
          filter
          onFilterClear={clearFilters}
        >
          {<div>{FilterItems()}</div>}
        </Search>
      </SearchContainer>
      <TableContainer component={Paper} elevation={2}>
        <PerfectScrollbar>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">EAP</TableCell>
                {/* <TableCell align="center">Intervention</TableCell> */}
                <TableCell align="center">Academic Performance Index</TableCell>
                {/* <TableCell align="center">Submission Index</TableCell> */}
                <TableCell align="center">Engagement Index</TableCell>
                <TableCell align="center">Attendance Index</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                (rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((student: any) => {
                  return (
                    <TableRow key={student.providerStudentID}>
                      <TableCell align="center">{student.providerStudentID}</TableCell>
                      <TableCell align="center">
                        {(student.firstName + ' ' + student.familyName).toUpperCase()}

                        {student.repeating > 1 ? (
                          <div>
                            <Chip
                              avatar={<Avatar className="avatar-style-01">{student.repeating - 1}</Avatar>}
                              label="REPEATING"
                              className="chip1"
                              color="primary"
                              variant="outlined"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">
                        {student.eap ? <span className="flag-yes-01">YES</span> : '-'}
                      </TableCell>
                      {/* <TableCell align="center">
                        {student.intervention ? <span className="flag-yes-01">YES</span> : '-'}{' '}
                      </TableCell> */}
                      <TableCell align="center"> {student.academicIndex}</TableCell>
                      <TableCell align="center"> {student.materialIndex}</TableCell>
                      {/* <TableCell align="center"> {student.assessmentIndex}</TableCell> */}
                      {/* <TableCell align="center"> {student.assessmentIndex}</TableCell> */}
                      <TableCell align="center"> {student.attendanceIndex}</TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        <Link
                          className="noUnderLine"
                          to={{
                            pathname: `/student/${student.providerStudentID}/report-card/acad_progress`,
                          }}
                        >
                          <Button color="secondary" style={{ marginLeft: 10 }} size="small" variant="outlined">
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTablePaginator
                rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
    </>
  );
};

export default UnitStudents;
