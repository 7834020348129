import { AppBar, IconButton, Toolbar, MenuItem } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import AccountMenu from './account-menu';
import { TopbarProps } from './types';
import { theme } from '../../../theme';
import { StyledTypography, HeaderLink, WhiteTextField } from '../../Common/StyledComponents';
import { useState, useCallback } from 'react';
import { getLatestSemesterforBU, setBuCodeLocal } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducer';
import SemesterSelect from './semester-select';
import { getSemesters } from '../../../actions';
import { useHistory } from 'react-router';

const AccountMenuContainer = styled.div`
  margin-left: auto;
`;

const MenuButton = styled(IconButton)`
  margin-right: ${theme.spacing(1)}px;
  color: white;
  &:focus {
    outline: none;
  }
`;

const Topbar: React.FC<TopbarProps> = ({ toggleNavbar }) => {
  const dispatch = useDispatch();
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const buCodes = useSelector((state: RootState) => state.users?.buCodes);
  const [buCode, setBuCode] = useState(selectedBuCode);
  const history = useHistory();
  useEffect(() => {
    setBuCode(selectedBuCode);
  }, [selectedBuCode]);

  useEffect(() => {
    if(selectedBuCode) {
      dispatch(getSemesters(selectedBuCode));
    }
  }, [selectedBuCode, dispatch]);
  const onBuCodeChange = useCallback(
    async (e) => {
      setBuCode(e.target.value);
      dispatch(setBuCodeLocal(e.target.value));
      dispatch(getLatestSemesterforBU(e.target.value));
      history.push('/');
    },
    [dispatch, history]
  );
  return (
    <AppBar position="fixed" color="primary">
      <Toolbar color="primary">
        <MenuButton edge="start" onClick={toggleNavbar}>
          <Menu />
        </MenuButton>
        <StyledTypography variant="h3">
          <HeaderLink to="/">CAPRIS</HeaderLink>
        </StyledTypography>
        <div>
          {buCodes.length > 0 && (
            <WhiteTextField
              fullWidth
              label="BU"
              margin="dense"
              name="buCode"
              select
              SelectProps={{
                value: buCode,
                onChange: onBuCodeChange,
              }}
              value={buCode}
              variant="outlined"
            >
              {buCodes.map((option) => (
                <MenuItem key={option.buCode} value={option.buCode}>
                  {option.name}
                </MenuItem>
              ))}
            </WhiteTextField>
          )}
        </div>
        <SemesterSelect />
        <AccountMenuContainer>
          <AccountMenu />
        </AccountMenuContainer>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
