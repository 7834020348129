import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Button, Theme } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Search from './search';
import Filter from './filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    search: {
      flexGrow: 1,
      maxWidth: 480,
      flexBasis: 480,
    },
    filterButton: {
      marginLeft: 'auto',
    },
    filterIcon: {
      marginRight: theme.spacing(1),
    },
  })
);
type SearchBarProps = {
  onFilter?: (query: any) => void;
  onSearch: (isClear?: boolean) => void;
  onSearchTextChanged: (searchText: string) => void;
  className?: string;
  onFilterClear?: () => void;
  filter?: boolean;
  searchPlaceHolder: string;
  searchText: string;
  values?: any;
  setValues?: (state: any) => void;
  initialValues?: any;
};
const SearchBar: React.FC<SearchBarProps> = ({
  children,
  searchPlaceHolder,
  searchText,
  filter,
  onFilterClear,
  onFilter,
  onSearchTextChanged,
  values,
  setValues,
  initialValues,
  onSearch,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid {...rest} className={clsx(classes.root, className)} container spacing={8}>
      <Grid item xs={8}>
        <Search
          className={classes.search}
          onSearch={onSearch}
          onSearchTextChange={onSearchTextChanged}
          searchPlaceHolder={searchPlaceHolder}
          searchText={searchText}
        />
      </Grid>
      {filter && (
        <Grid item>
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterOpen}
            size="small"
            variant="outlined"
          >
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        </Grid>
      )}

      {filter && (
        <Filter
          onClear={onFilterClear ? onFilterClear : () => {}}
          onClose={handleFilterClose}
          onFilter={onFilter ? onFilter : () => {}}
          open={openFilter}
          values={values}
          setValues={setValues ? setValues : () => {}}
          initialValues={initialValues}
        >
          {children}
        </Filter>
      )}
    </Grid>
  );
};

export default SearchBar;
