import { Theme } from '@material-ui/core';
import { API_URL } from '../config/constants';
import { classTypes, feedbackTypes, indices } from './enums';

export const tabs = [
  { value: 'acad_progress', label: 'Acad Progress' },
  { value: 'issues_resolution', label: 'Issues / Resolution' },
  { value: 'indices', label: 'Indices' },
  { value: 'logs', label: 'Logs' },
];

export const logTabs = [
  { value: 'interventions', label: 'Interventions' },
  { value: 'reminders', label: 'Reminders' },
];

export const STUDENT_URL = async () => {
  return await API_URL() + '/student';
};

export const actions = {
  SUCCESS: 'SUCCESS',
};

export const personTypes = {
  AGENT: 'agent',
  CUSTOMER: 'customer',
  STUDENT: 'student',
};

export const uploadSamples = {
  ENGLISH: 'english',
  ASSIGNMENT_DUE_DATE: 'assignmentDueDate',
};

export const colorCodes = {
  mandatory: '#1890FF',
  nonMandatory: '#00AADF',
  employability: '#F1F3F6',
  assessment: '#42a057d9',
  material: '#FF4A6B',
  legacy: '#C54E57',
  red: '#EB5757',
  default: '#043378',
  materialIndex: '#8855e0',
};

export const checkList = [
  {
    name: 'Attend Online Orientation Session',
    dueDate: '2020/09/11 17:00:00',
    updatedDate: '2020/09/11 10:00:00',
    by: 'System',
    id: 1,
    checked: true,
  },
  {
    name: 'Complete Academic Integrity Module',
    dueDate: '2020/08/11 15:00:00',
    updatedDate: '',
    by: '',
    id: 2,
    checked: false,
    overdue: true,
  },
  {
    name: 'Complete Online Campus Tour',
    dueDate: '2020/10/11 16:00:00',
    updatedDate: '',
    by: '',
    id: 3,
    checked: false,
    overdue: false,
  },
  {
    name: 'Upload Photo For Student Id Card',
    dueDate: '2020/09/11 14:00:00',
    updatedDate: '2020/09/11 11:00:00',
    by: 'Student',
    id: 4,
    checked: true,
  },
];

export const interventionCsvData = {
  fields: [
    { label: 'ID', value: 'id' },
    { label: 'Date Raised', value: 'dateRaised' },
    { label: 'Category', value: 'category' },
    { label: 'Action Item', value: 'actionItem' },
    { label: 'Course', value: 'course' },
  ],
  data: [
    { id: 1, dateRaised: '31/12/2020', category: 'Cat 1', actionItem: 'Action 1', course: 'Course 1' },
    { id: 2, dateRaised: '31/12/2020', category: 'Cat 1', actionItem: 'Action 2', course: 'Course 1' },
    { id: 1, dateRaised: '31/12/2020', category: 'Cat 2', actionItem: 'Action 1', course: 'Course 2' },
    { id: 2, dateRaised: '31/12/2020', category: 'Cat 3', actionItem: 'Action 2', course: 'Course 2' },
  ],
};

export const feedbackCsvData = {
  fields: [
    { label: 'Course', value: 'course' },
    { label: '', value: 'lecType' },
    { label: '', value: 'type' },
    { label: 'Week 1', value: 'week1' },
    { label: 'Week 2', value: 'week2' },
  ],
  data: [
    { course: 'ITECH1000', lecType: 'LEC', type: 'Enjoyment', week1: '4', week2: '' },
    { course: '', lecType: '', type: 'Understanding', week1: '4', week2: '' },
    { course: '', lecType: 'TUT', type: 'Enjoyment', week1: '1', week2: '' },
    { course: '', lecType: '', type: 'Understanding', week1: '2', week2: '' },
    { course: '', lecType: 'CNS', type: 'Enjoyment', week1: '4', week2: '' },
    { course: '', lecType: '', type: 'Understanding', week1: '4', week2: '' },
    { course: '', lecType: 'CAM', type: 'Enjoyment', week1: '1', week2: '' },
    { course: '', lecType: '', type: 'Understanding', week1: '2', week2: '' },
  ],
};

export const weighting = [
  {
    [indices.ATTENDANCE]: 65,
    [indices.ASSESSMENT]: 20,
    [indices.LEGACY_INDEX]: 10,
    [indices.EMPLOYABILITY]: 0,
    date: '1',
  },
  {
    [indices.ATTENDANCE]: 55,
    [indices.ASSESSMENT]: 0,
    [indices.LEGACY_INDEX]: 10,
    [indices.EMPLOYABILITY]: 5,
    date: '2',
  },
  {
    [indices.ATTENDANCE]: 50,
    [indices.ASSESSMENT]: 15,
    [indices.LEGACY_INDEX]: 2.5,
    [indices.EMPLOYABILITY]: 2.5,
    date: '3',
  },
  {
    [indices.ATTENDANCE]: 50,
    [indices.ASSESSMENT]: 15,
    [indices.LEGACY_INDEX]: 2.5,
    [indices.EMPLOYABILITY]: 2.5,
    date: '4',
  },
  {
    [indices.ATTENDANCE]: 45,
    [indices.ASSESSMENT]: 25,
    [indices.LEGACY_INDEX]: 2.5,
    [indices.EMPLOYABILITY]: 2.5,
    date: '5',
  },
  {
    [indices.ATTENDANCE]: 45,
    [indices.ASSESSMENT]: 25,
    [indices.LEGACY_INDEX]: 2.5,
    [indices.EMPLOYABILITY]: 2.5,
    date: '6',
  },
];

export const data = [
  {
    id: indices.ATTENDANCE,
    color: colorCodes.mandatory,
    data: [
      {
        x: 1,
        y: 80,
      },
      {
        x: 2,
        y: 75,
      },
      {
        x: 3,
        y: 17,
      },
      {
        x: 4,
        y: 82,
      },
      {
        x: 5,
        y: 85,
      },
      {
        x: 6,
        y: 94,
      },
    ],
  },
  {
    id: indices.ASSESSMENT,
    color: colorCodes.assessment,
    data: [
      {
        x: 1,
        y: 70,
      },
      {
        x: 2,
        y: 65,
      },
      {
        x: 3,
        y: 40,
      },
      {
        x: 4,
        y: 48,
      },
      {
        x: 5,
        y: 92,
      },
      {
        x: 6,
        y: 97,
      },
    ],
  },
  {
    id: indices.LEGACY_INDEX,
    color: colorCodes.nonMandatory,
    data: [
      {
        x: 1,
        y: 75,
      },
      {
        x: 2,
        y: 75,
      },
      {
        x: 3,
        y: 75,
      },
      {
        x: 4,
        y: 75,
      },
      {
        x: 5,
        y: 75,
      },
      {
        x: 6,
        y: 75,
      },
    ],
  },
  {
    id: indices.EMPLOYABILITY,
    color: colorCodes.legacy,
    data: [
      {
        x: 1,
        y: 0,
      },
      {
        x: 2,
        y: 0,
      },
      {
        x: 3,
        y: 25,
      },
      {
        x: 4,
        y: 45,
      },
      {
        x: 5,
        y: 50,
      },
      {
        x: 6,
        y: 54,
      },
    ],
  },
  {
    id: indices.ACADEMIC_PROGRESSION,
    color: colorCodes.employability,
    data: [
      {
        x: 1,
        y: 74,
      },
      {
        x: 2,
        y: 49,
      },
      {
        x: 3,
        y: 17,
      },
      {
        x: 4,
        y: 52,
      },
      {
        x: 5,
        y: 51,
      },
      {
        x: 6,
        y: 80,
      },
    ],
  },
];

export const unitData = [
  {
    id: classTypes.LEC,
    color: colorCodes.mandatory,
    data: [
      {
        x: 1,
        y: 4.4,
      },
      {
        x: 2,
        y: 4.8,
      },
      {
        x: 3,
        y: 4.2,
      },
      {
        x: 4,
        y: 3.6,
      },
      {
        x: 5,
        y: 3.6,
      },
      {
        x: 6,
        y: 3.4,
      },
      {
        x: 6,
        y: 3.4,
      },
      {
        x: 7,
        y: 3.8,
      },
      {
        x: 8,
        y: 3.6,
      },
      {
        x: 9,
        y: 4.2,
      },
      {
        x: 10,
        y: 3.2,
      },
      {
        x: 11,
        y: 3.8,
      },
      {
        x: 12,
        y: 3.9,
      },
    ],
  },
  {
    id: classTypes.TUT,
    color: colorCodes.assessment,
    data: [
      {
        x: 1,
        y: 3.6,
      },
      {
        x: 2,
        y: 3.4,
      },
      {
        x: 3,
        y: 3.4,
      },
      {
        x: 4,
        y: 3.4,
      },
      {
        x: 5,
        y: 3.6,
      },
      {
        x: 6,
        y: 4,
      },
      {
        x: 7,
        y: 3.6,
      },
      {
        x: 8,
        y: 3.4,
      },
      {
        x: 9,
        y: 3.2,
      },
      {
        x: 10,
        y: 4.2,
      },
      {
        x: 11,
        y: 4.4,
      },
      {
        x: 12,
        y: 4.1,
      },
    ],
  },
  {
    id: classTypes.CNS,
    color: colorCodes.nonMandatory,
    data: [
      {
        x: 1,
        y: 3.7,
      },
      {
        x: 2,
        y: 3.4,
      },
      {
        x: 3,
        y: 3.6,
      },
      {
        x: 4,
        y: 3.6,
      },
      {
        x: 5,
        y: 4,
      },
      {
        x: 6,
        y: 3,
      },
      {
        x: 7,
        y: 2.6,
      },
      {
        x: 8,
        y: 3.6,
      },
      {
        x: 9,
        y: 3.4,
      },
      {
        x: 10,
        y: 3.2,
      },
      {
        x: 11,
        y: 3.6,
      },
      {
        x: 12,
        y: 4,
      },
    ],
  },
  {
    id: classTypes.CAM,
    color: colorCodes.employability,
    data: [
      {
        x: 1,
        y: 4,
      },
      {
        x: 2,
        y: 3.8,
      },
      {
        x: 3,
        y: 3.6,
      },
      {
        x: 4,
        y: 3,
      },
      {
        x: 5,
        y: 3.8,
      },
      {
        x: 6,
        y: 3.4,
      },
      {
        x: 7,
        y: 3.8,
      },
      {
        x: 8,
        y: 3.6,
      },
      {
        x: 9,
        y: 2.6,
      },
      {
        x: 10,
        y: 3.8,
      },
      {
        x: 11,
        y: 3.4,
      },
      {
        x: 12,
        y: 3.8,
      },
    ],
  },
];

export const barGraphData = {
  '1': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 5,
    },
  ],
  '2': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 2,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '3': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '4': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 2,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '5': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '6': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 5,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 2,
    },
  ],
  '7': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 5,
    },
  ],
  '8': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 2,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '9': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '10': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 2,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '11': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 3,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 3,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 5,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 3,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
  ],
  '12': [
    {
      type: classTypes.LEC,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 5,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 4,
    },
    {
      type: classTypes.TUT,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 5,
      [feedbackTypes.INFORMATIVE]: 4,
      [feedbackTypes.SIMULATED_DISCUSSION]: 4,
      [feedbackTypes.UNDERSTANDING]: 5,
    },
    {
      type: classTypes.CNS,
      [feedbackTypes.WELL_PREPARED]: 2,
      [feedbackTypes.INTERESTING]: 2,
      [feedbackTypes.INFORMATIVE]: 3,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 3,
    },
    {
      type: classTypes.CAM,
      [feedbackTypes.WELL_PREPARED]: 4,
      [feedbackTypes.INTERESTING]: 4,
      [feedbackTypes.INFORMATIVE]: 2,
      [feedbackTypes.SIMULATED_DISCUSSION]: 5,
      [feedbackTypes.UNDERSTANDING]: 2,
    },
  ],
};

export const onlyEngagement = [
  {
    id: indices.ATTENDANCE,
    color: colorCodes.mandatory,
    data: [
      {
        x: 1,
        y: 80,
      },
      {
        x: 2,
        y: 75,
      },
      {
        x: 3,
        y: 17,
      },
      {
        x: 4,
        y: 82,
      },
      {
        x: 5,
        y: 85,
      },
      {
        x: 6,
        y: 94,
      },
    ],
  },
];

export const SubmissionCategoryMenuItems = {
  USC: [
    { value: 'percentage', label: 'Percentage' },
    { value: 'taskWeightage', label: 'Task Weightage' },
  ],
  FED: [
    { value: 'none', label: 'None' },
    { value: 'turnitin', label: 'Turnitin' },
  ],
};

export const InterventionFrameworkColorCodes = {
  conditions: '#32375D',
  actions: '#043378',
};

export const ImprovementAreas = [
  { value: 'assessmentProgress', label: 'Assessment Progress' },
  { value: 'plagiarismAwareness', label: 'Plagiarism Awareness' },
  { value: 'englishImprovement', label: 'English Improvement' },
  { value: 'technicalSkills', label: 'Technical Skills' },
];

export const InterventionStatusColorCodes = {
  DRAFT: '#828282',
  ACTIVE: '#42a057d9',
  TERMINATED: '#d42121a3',
  COMPLETED: '#6b6b6bcf',
};

export const recurrenceData = {
  WEEKLY: [
    { label: 'Monday', value: 'MONDAY' },
    { label: 'Tuesday', value: 'TUESDAY' },
    { label: 'Wednesday', value: 'WEDNESDAY' },
    { label: 'Thursday', value: 'THURSDAY' },
    { label: 'Friday', value: 'FRIDAY' },
    { label: 'Saturday', value: 'SATURDAY' },
    { label: 'Sunday', value: 'SUNDAY' },
  ],
  MONTHLY: [
    { label: 'January', value: 'JANUARY' },
    { label: 'February', value: 'FEBRUARY' },
    { label: 'March', value: 'MARCH' },
    { label: 'April', value: 'APRIL' },
    { label: 'May', value: 'MAY' },
    { label: 'June', value: 'JUNE' },
    { label: 'July', value: 'JULY' },
    { label: 'August', value: 'AUGUST' },
    { label: 'September', value: 'SEPTEMBER' },
    { label: 'October', value: 'OCTOBER' },
    { label: 'November', value: 'NOVEMBER' },
    { label: 'December', value: 'DECEMBER' },
  ],
};

export const recurrenceDataLabels = {
  cronExpression: 'CRON Expression',
  cronText: 'CRON Explaination',
  endDate: 'Last Date of Trigger',
};

export const eventTypes = {
  ONLINE: 'ONLINE',
  BLENDED: 'BLENDED',
  PHYSICAL: 'PHYSICAL',
};
export const AssignmentLogTypes = {
  SUBMISSIONS: 'SUBMISSIONS UPLOAD',
  RESULTS: 'RESULTS UPLOAD',
  GRADES_FAIL: 'GRADES FAIL INTERVENTION',
  GRADES_PASS: 'GRADES PASS INTERVENTION',
  NON_SUBMISSION: 'NON-SUBMISSION INTERVENTION',
  COMMENTS: 'COMMENTS',
};
export const AssignmentLogLabelsColorCodes = {
  SUBMISSIONS: { background: '#F1F8E9', text: 'white' },
  RESULTS: { background: '#E8EAF6', text: 'white' },
  GRADES_FAIL: { background: '#FBE9E7', text: 'white' },
  GRADES_PASS: { background: '#C5E1A5', text: 'white' },
  NON_SUBMISSION: { background: '#E0F7FA', text: 'white' },
  COMMENTS: { background: '#EDE7F6', text: 'white' },
};

export const mutedColors = ['#EDC1BB', '#dac48beb', '#C1C2AD', '#B7A1A6', '#d5e2d5', '#c1b6d2', '#d0c5b9', '#8C9B88'];

export const getRandomMutedColor = () => {
  const min = 0;
  const max = mutedColors.length - 1;
  return mutedColors[Math.floor(Math.random() * (max - min) + min)];
};

export const teamTypeOptions = ['LEC', 'TUT', 'WKS', 'SUP', 'CAM', 'SGRP', 'LAB', 'USK', 'EMP', 'GRP', 'NET'];

export const plagiarismOptions = [
  { value: 'NO', label: 'NO' },
  { value: 'YES', label: 'YES' },
];
export const specialConsiderationOptions = [
  { value: 'NO', label: 'NO' },
  { value: 'YES', label: 'YES' },
];
export const submissionOptions = [
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'PEDNING', label: 'PENDING' },
  { value: 'NS', label: 'NOT SUBMITTED' },
];

export const assignmentFilterValueMapping = {
  NO: false,
  YES: true,
  SUBMITTED: true,
  PEDNING: null,
  NS: 'NS',
};

export const TriggerTypes = {
  INSTANT: 'INSTANT',
  SCHEDULED: 'SCHEDULED',
  ON_GRADES_UPLOAD: 'ON_GRADES_UPLOAD',
  ON_SUBMISSION_UPLOAD: 'ON_SUBMISSION_UPLOAD',
};

export const MainPreviewTypes = {
  NON_SUBMISSION: 'NON_SUBMISSION',
  UNSATISFACTORY_GRADES: 'UNSATISFACTORY_GRADES',
};

export const MAX_WEEKS_VISIBLE_IN_GRAPH = 14;
