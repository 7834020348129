// BUGEN2630
// BUACC1508
// BUMGT1501
// BUECO1509
// BUGEN1502
// BULAW1502
// BUMGT2621
// BUMKT1501
// BUACC5930
// BUACC5931
// BUACC5932
// BUACC5933
// BUACC5934
// BUACC5935
// BUECO5903
// BUACC5936
// BUGEN5930
// BUACC5937
// BULAW5914
// ITECH1100
// ITECH1101
// ITECH1001
// ITECH1102
// ITECH1104
// ITECH1103
// ITECH2000
// ITECH2002
// ITECH2001
// ITECH2003
// ITECH2250
// ITECH2004
// ITECH2300
// ITECH2301
// ITECH2304
// ITECH2305
// ITECH2306
// ITECH2309
// ITECH3001
// ITECH3002
// ITECH2300
// ITECH2301
// ITECH2304
// ITECH2305
// ITECH2306
// ITECH2309
// ITECH3001
// ITECH3002

import moment from 'moment';
import { getRandomForRange } from '../../../util/array';
export const interventionDataFromJson = [
  {
    id: '1',
    labels: 'IT1012 Writing Skills',
    units: 'IT1012',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 12,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'YES',
    trigger: 'Assignment Grades',
  },

  {
    id: 2,
    labels: 'IT1018 Writing Skills',
    units: 'IT1018',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 7,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Grades',
  },

  {
    id: 3,
    labels: 'IT1023 Writing Skills',
    units: 'IT1023',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 8,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Date',
  },

  {
    id: 4,
    labels: 'IT1024 Writing Skills',
    units: 'IT1024',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 23,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Grades',
  },
  {
    id: 5,
    labels: 'Late Submission Intervention',
    units: 'IT1015,IT1016,IT1017,IT1018,IT1019',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'YES',
    trigger: 'Assignment Submission',
  },

  {
    id: 6,
    labels: 'IT1025 Writing Skills',
    units: 'IT1025',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 14,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Submission',
  },

  {
    id: 7,
    labels: 'IT1011 Writing Skills',
    units: 'IT1011',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 10,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Grades',
  },
  {
    id: 8,
    labels: 'Less than 50% Assignment Performance',
    units: 'IT1015,IT1016,IT1017,IT1018,IT1026',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Submission',
  },

  {
    id: 9,
    labels: 'IT1022 Writing Skills',
    units: 'IT1022',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 5,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Grades',
  },

  {
    id: 10,
    labels: 'IT1013 Writing Skills',
    units: 'IT1013',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Submission',
  },

  {
    id: 11,
    labels: 'Late Submission Intervention',
    units: 'IT1016,IT1017,IT1018,IT1019,IT1020,',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Submission',
  },

  {
    id: 12,
    labels: 'Less than 50% Assignment Performance',
    units: 'IT1011,IT1014,IT1015,IT1016,IT1021',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Date',
  },
  {
    id: 13,
    labels: 'Semester Start Plagiarism Check',
    units: 'IT1019,IT1020,IT1021,IT1025',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'YES',
    trigger: 'Date',
  },
  {
    id: 14,
    labels: 'IT1026 Assignment 3 at Risk',
    units: 'IT1026',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'NO',
    trigger: 'Assignment Grades',
  },
  {
    id: 15,
    labels: 'Less than 50% Assignment Performance',
    units: 'IT1014,IT1015,IT1016,IT1020,IT1021,IT1022,IT1023',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'No',
    trigger: 'Date',
  },
  {
    id: 16,
    labels: 'Late Submission Intervention',
    units: 'IT1011,IT1012,IT1013,IT1022',
    createdAt: moment().subtract(getRandomForRange(5, 10), 'days'),
    studentsReached: 26,
    lastTriggered: moment().add(getRandomForRange(5, 10), 'days'),
    active: 'YES',
    trigger: 'Assignment Grades',
  },
];

// const units = [ IT1011,IT1012,IT1013,IT1014,IT1015,IT1016,IT1017,IT1018,IT1019,IT1020,IT1021,IT1022,IT1023,IT1024,IT1025,IT1026,]
export const units = [
  { code: 'IT1011', label: 'Intro to Programming' },
  { code: 'IT1012', label: 'Big Data' },
  { code: 'IT1013', label: 'Emerging Technology' },
  { code: 'IT1014', label: 'Communication & Technology' },
  { code: 'IT1015', label: 'Business Process Modelling' },
  { code: 'IT1016', label: 'Relational Database Management System' },
  { code: 'IT1017', label: 'Requirements Analysis' },
  { code: 'IT1018', label: 'System Design and Architecture' },
  { code: 'IT1019', label: 'Objected Oriented Programming' },
  { code: 'IT1020', label: 'Ethics in ICT' },
  { code: 'IT1021', label: 'Data Science Techniques' },
  { code: 'IT1022', label: 'Advanced programming' },
  { code: 'IT1023', label: 'IT Service Management' },
  { code: 'IT1024', label: 'Data Visualisation' },
  { code: 'IT1025', label: 'Agile Project Management' },
  { code: 'IT1026', label: 'Industry Project' },
];

export const studentsForMT5 = [
  {
    providerStudentID: '30000002',
    firstName: (`Rajat Sehgal`).trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: 3,
  },
  {
    providerStudentID: '30339071',
    firstName: ('Deepak DEUBA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30341859',
    firstName: ('Amaninder KAUR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30342316',
    firstName: (' PRIYA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30358922',
    firstName: (' KIRANDEEP KAUR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30348094',
    firstName: ('Timmy ANG').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30359919',
    firstName: ('Vishnu BASSI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30384259',
    firstName: ('Kapil SUBEDI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385920',
    firstName: ('Passang LAMA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30386396',
    firstName: ('Kristi SERPUJA MAGAR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30356430',
    firstName: (' AMAN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30348197',
    firstName: ('Hardev SINGH').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30348303',
    firstName: ('Monika NAIN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30366092',
    firstName: ('Sujan DHUNGANA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385674',
    firstName: ('Punam TAMANG').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385879',
    firstName: ('Bijan NEPAL').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30386479',
    firstName: ('Rojina BAYALKOTI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30387917',
    firstName: ('Vanshika SAINI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30344346',
    firstName: ('Cong Duy Phuc  PHAM\t').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30349749',
    firstName: ('Hargobind Singh SRAN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30352480',
    firstName: (' MANMEET KAUR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30375443',
    firstName: ('Jagpal Singh MADAHAR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30381640',
    firstName: ('Adil MOHAMMED').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385596',
    firstName: ('Sachin MAHARJAN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30367160',
    firstName: ('Sohail DULAL').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30354079',
    firstName: ('Mohit ANGRAL').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30354310',
    firstName: ('Prabhjot MALHOTRA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30361704',
    firstName: ('Syed Taha HUSSAIN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30355489',
    firstName: ('Mohammed Amair HUSSAIN').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385408',
    firstName: ('Puspa POKHAREL').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385419',
    firstName: ('Khyatiben Rajeshbhai GONDALIYA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30385923',
    firstName: ('Ashish ADHIKARI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30354807',
    firstName: (' HARDEEP KAUR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30354886',
    firstName: ('Ajay Kumar ANDAPALLY').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30386751',
    firstName: ('Rahil Khan MOHAMMED').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30386934',
    firstName: ('Yuvraj Singh RAINA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30387428',
    firstName: ('Sanjana NIMMAKAYALA').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30387420',
    firstName: ('Pallavi WADKAR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30354984',
    firstName: (' SUKHPAL KAUR').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
  {
    providerStudentID: '30387897',
    firstName: ('Ashish THAPA KSHETRI').trim(),
    courseCode: 'MT5',
    courseName: 'Master of Information Systems',
    index: getRandomForRange(25, 78),
    action: getRandomForRange(0, 4),
  },
];

export const graphData = [
  {
    id: 'Assessment Index',
    data: [
      {
        x: 'Week 1',
        y: getRandomForRange(45, 55),
      },
      {
        x: 'Week 2',
        y: getRandomForRange(40, 50),
      },
      {
        x: 'Week 3',
        y: getRandomForRange(30, 40),
      },
      {
        x: 'Week 4',
        y: getRandomForRange(50, 60),
      },
      {
        x: 'Week 5',
        y: getRandomForRange(45, 50),
      },
      {
        x: 'Week 6',
        y: getRandomForRange(40, 45),
      },
      {
        x: 'Week 7',
        y: getRandomForRange(45, 50),
      },
      {
        x: 'Week 8',
        y: getRandomForRange(35, 40),
      },
      {
        x: 'Week 9',
        y: getRandomForRange(38, 46),
      },
      {
        x: 'Week 10',
        y: 47,
      },
      {
        x: 'Week 11',
        y: 49,
      },
      {
        x: 'Week 12',
        y: 58,
      },
      {
        x: 'Week 13',
        y: 69,
      },
      {
        x: 'Week 14',
        y: 76,
      },
    ],
  },
];
