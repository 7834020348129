import { string } from 'prop-types';
import { PhysicalAttendance } from '../models/event';

export type EventSearchFilters = {
  startDate: string;
  endDate: string;
  teamType: string[];
  sort: {
    sortStartDate?: boolean;
    sortEngagementIndex?: boolean;
    orderByDate?: boolean;
  };
  unitCode: string;
};

export type CallbackSearchFilters = {
  startDate: string;
  endDate: string;
  sort: {
    sortStartDate?: boolean;
    orderByDate?: boolean;
  };
  timeZone: string;
  slots: string[];
};

export type AnalysisFilters = {
  unitCode: string;
  day: string;
  status: string;
};

export type Callback = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  callbackDate: Date;
  slot: string[];
  timeZone: string;
  reference: string;
  closed: boolean;
  id: number;
};

export type AssignmentDueData = {
  dueWeek: string;
  dueDate: string;
  comment: string;
  statusChange: string;
  time: string;
  submissionType: string;
  taskWeightage: string;
};

export type Comment = {
  text: string;
  id: number;
  name: string;
  createdAt: Date;
  statusChange?: string;
};

export type individualTableHead = {
  text: string;
  displaySort?: boolean;
  onSortClick?: () => void;
  showUpArrow?: boolean;
};

export interface RouteParams {
  id: string;
  tab: string;
}

export interface UnitRouteParams {
  unitId: string;
}

export type TicketDetails = {
  id: string;
  status: string;
  customer: string;
  group: string;
  agent: string;
  team: string;
  source: string;
  subject: string;
  reply: string;
  created_at: Date;
  updated_at: Date;
  raisedby: string;
};

export type AcadProgressType = {
  unitCode: string;
  id: number;
  moodleActivity: boolean;
  tasks: Task[];
  attendance: OnlineAttendance[];
  physicalAttendance: PhysicalAttendance[];
  camAttendance: OnlineAttendance[];
  dueDateArray: AssignmentDueDate[];
  unitId: string;
  engagement: string;
};

export type Task = {
  studentId: string;
  lastname: string;
  othername: string;
  assignmentId: number;
  unitCode: string;
  marks: number;
  grade: string;
  taskNumber: string;
  assignmentDetails: AssignmentDueDate;
  createdAt: Date;
};

export type AssignmentDueDate = {
  id: number;
  semester: string;
  unitCode: string;
  unitName: string;
  lecturerName: string;
  lecturerEmail: string;
  taskName: string;
  taskNumber: string;
  dueDate: string;
  interventions: Date[];
  type: string;
  submissions: Date[];
  results: Date[];
  dueWeek: string;
  taskWeightage: string;
  createdAt: Date;
  time: string;
  submissionType: string;
};

export type OnlineAttendance = {
  eventId: string;
  totalDuration: number;
  activityIndex: number;
  attendeeId: number;
  callRecordId: string;
  event: Event;
};

export type StudentIndex = {
  studentId: string;
  unitCode: string;
  index: number;
  sessionType: string;
  buCode: string;
};

export type Cam = {
  unitCode: string;
  events: CamEvent[];
};

export type CamEvent = {
  event: Event;
  activityIndex: number;
  eventId: string;
  unitCode: string;
  studentId: string;
  comment: string;
  satisfactory: boolean;
  id: number;
};

export type Event = {
  id: string;
  meetingId: string;
  meetingSubject: string;
  teamName: string;
  teamId: string;
  methodOfDelivery: string;
  startDateTime: Date;
  endDateTime: Date;
  totalDuration: number;
  organiserId: number;
  engagementIndex: number;
  callRecordId?: string;
};
export type CommLog = {
  id: number;
  week: number;
  comments: string;
  dateTime: string;
  onTrack: boolean;
};

export type Detail = {
  date: Date;
  by: string;
  name: string;
  message: string;
};

export type InterventionEmail = {
  id: number;
  email: string;
  type: string;
  status: string;
  buCode: string;
  triggerId: number;
  createdAt: Date;
  updatedAt: Date;
};
export interface InterventionReportRouteParams {
  id: string;
}

export interface AssignmentDetailsRouteParams {
  id: string;
  unitId: string;
}

export interface AssignmentDetailsInterface {
  studentId: string;
  displayName: string;
  marks: number;
  grade: string;
  plagiarism: boolean;
  plagiarismComments: string[];
  submissionDate: Date;
  newSubmissionDueDate: Date;
  specialConsideration: boolean;
  newDueDateComments: string[];
  academicMisconduct: boolean;
  academicMisconductComments: string[];
}

export type StudentInterventionSearchFilters = {
  startDate: string;
  endDate: string;
  interventionType: string[];
  sort: {
    sortStartDate?: boolean;
    orderByDate?: boolean;
  };
};

export type StudentRemindersSearchFilters = {
  startDate: string;
  endDate: string;
  type: string[];
  sort: {
    sortStartDate?: boolean;
    orderByDate?: boolean;
  };
};

export type TimeTableUnit = {
  id: number;
  unitId: number;
  unitCode: string;
  timeTableId: number;
  type: string;
  batchName: string;
  location: string;
  day: string;
  startTime: string;
  duration: number;
  capacity: number;
  priority: number;
  status: boolean;
  teachingStaff: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  teachingStaffName: string;
  enrolledStudents: string[];
  teachingStaffEmail: string;
  tracked?: boolean;
  deletedAt?: any;
  event?: Event;
};
export type StaffMemberOfIA = {
  id?: number;
  bookingURL: string;
  name: string;
  email: string;
  isTeachingStaff: boolean;
  units?: string[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ImprovementArea = {
  id?: number;
  type: string;
  isUnitOriented: boolean;
  staffMembers: StaffMemberOfIA[];
  createdAt?: Date;
  updatedAt?: Date;
};

export enum INTERVENTION_ACTIONS {
  REFERSTUDYMATERIAL = 'referStudyMaterial',
  BOOKCONSULTATION = 'bookConsultation',
  FREETEXT = 'freeText',
  ATTENDSESSION = 'attendSession',
}
export const INTERVENTION_TRIGGERS = {
  INSTANT: 'INSTANT',
  SCHEDULED: 'SCHEDULED',
  ON_GRADES_UPLOAD: 'ON_GRADES_UPLOAD',
  ON_SUBMISSION_UPLOAD: 'ON_SUBMISSION_UPLOAD',
};
export enum INTERVENTION_CONSULTATIONWITH {
  RESPECTIVE_ACADEMIC_STAFF = 'RESPECTIVE ACADEMIC STAFF',
}
