import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { AssignmentDueData } from '../util/types';
import { actions } from '../util/constants';
import { GET_UNIT_INFO_LOADING_START, GET_UNIT_INFO_LOADING_START_FINISHED } from '../actionTypes';

export const UNITS_URL_2 = async () => {
  return `${await API_URL()}/pace/unit`
};
export const UNIT_URL = async (unitCode: string) => {
  return `${await UNITS_URL_2()}/${unitCode}`
};
export const UNIT_STUDENTS_URL = async (unitId: string) => {
  return await UNIT_URL(unitId) + '/students';
};
export const GRAPH_URL_2 = async () => {
  return await API_URL() + '/graph';
};
export const UNIT_GRAPH_URL = async (unitCode: string) => {
  return await GRAPH_URL_2() + '/unit/' + unitCode;
};
export const ASSIGNMENT_URL = async (unitCode: string) => {
  return await API_URL() + '/assignments/find/due-date?units=' + unitCode;
};

export const GET_STUDENT_BY_UNITID = 'GET_STUDENT_BY_UNITID';
export const GET_UNIT_BY_ID = 'GET_UINIT_BY_ID';
export const GET_UNIT_GRAPH_DATA = 'GET_UNIT_GRAPH_DATA';
export const GET_ASSIGNMENT_DATA = 'GET_ASSIGNMENT_DATA';
export const SET_FILE_ASSIGNMENT_UPLOAD_STATUS = 'SET_FILE_ASSIGNMENT_UPLOAD_STATUS';
export const UPLOAD_SUBMISSION_SUCCESS = 'UPLOAD_SUBMISSION_SUCCESS';
export const UPLOAD_SUBMISSION_ERRORS = 'UPLOAD_SUBMISSION_ERRORS';
export const RESET_UPLOAD_ASSIGNMENT_DATA = 'RESET_UPLOAD_ASSIGNMENT_DATA';
export const ON_ASSIGNMENT_SELECTED = 'ON_ASSIGNMENT_SELECTED';
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';
export const SET_FILE_ASSIGNMENT_RESULT_UPLOAD_STATUS = 'SET_FILE_ASSIGNMENT_RESULT_UPLOAD_STATUS';
export const UPLOAD_ASSIGNMENT_RESULT_SUCCESS = 'UPLOAD_ASSIGNMENT_RESULT_SUCCESS';
export const UPLOAD_ASSIGNMENT_RESULT_ERRORS = 'UPLOAD_ASSIGNMENT_RESULT_ERRORS';
export const RESET_UPLOAD_ASSIGNMENT_RESULT_DATA = 'RESET_UPLOAD_ASSIGNMENT_RESULT_DATA';
export const SET_FILE_STUDENT_GROUPS_UPLOAD_STATUS = 'SET_FILE_STUDENT_GROUPS_UPLOAD_STATUS';
export const UPLOAD_STUDENT_GROUPS_SUCCESS = 'UPLOAD_STUDENT_GROUPS_SUCCESS';
export const UPLOAD_STUDENT_GROUPS_ERRORS = 'UPLOAD_STUDENT_GROUPS_ERRORS';
export const RESET_UPLOAD_STUDENT_GROUPS_DATA = 'RESET_UPLOAD_STUDENT_GROUPS_DATA';
export const SET_FILE_TURNITIN_MAPPING_UPLOAD_STATUS = 'SET_FILE_TURNITIN_MAPPING_UPLOAD_STATUS';
export const UPLOAD_TURNITIN_MAPPING_SUCCESS = 'UPLOAD_TURNITIN_MAPPING_SUCCESS';
export const UPLOAD_TURNITIN_MAPPING_ERRORS = 'UPLOAD_TURNITIN_MAPPING_ERRORS';
export const RESET_UPLOAD_TURNITIN_MAPPING_DATA = 'RESET_UPLOAD_TURNITIN_MAPPING_DATA';
export const GET_UNIT_REPORTS = 'GET_REPORTS';
export const Find_UNIT_REPORTS = 'Find_REPORTS';
export const ON_REPORT_LOADING_START = 'ON_REPORT_LOADING_START';
export const ON_REPORT_LOADING_FINISHED = 'ON_REPORT_LOADING_FINISHED';
export const GET_CLEAR_DB_RESPONSE = 'GET_CLEAR_DB_RESPONSE';
export const RESET_CLEAR_DB_RESPONSE = 'RESET_CLEAR_DB_RESPONSE';

interface ProgressEventType {
  loaded: number;
  total: number;
}

export const getStudentByUnitId =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_LOADING_START });
        const url = await UNIT_STUDENTS_URL(unitCode);
        const result = await get(url);
        dispatch({ type: GET_STUDENT_BY_UNITID, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_BY_UNITID,
          payload: null,
        });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

export const getUnitInfoById =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_UNIT_INFO_LOADING_START });
        const url = await UNIT_URL(unitCode);
        const result = await get(url);
        dispatch({ type: GET_UNIT_BY_ID, payload: result.data });
      } catch (error) {
        dispatch({
          type: GET_UNIT_BY_ID,
          payload: [],
        });
      }
      dispatch({ type: GET_UNIT_INFO_LOADING_START_FINISHED });
    };

export const getUnitGraphData =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = await UNIT_GRAPH_URL(unitCode);
        const result = await get(url);
        dispatch({ type: GET_UNIT_GRAPH_DATA, payload: result });
      } catch (error) {
        dispatch({
          type: GET_UNIT_GRAPH_DATA,
          payload: null,
        });
      }
    };

export const getAssignment =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = await ASSIGNMENT_URL(unitCode);
        const result = await get(url);
        dispatch({ type: GET_ASSIGNMENT_DATA, payload: result });
      } catch (error) {
        dispatch({
          type: GET_ASSIGNMENT_DATA,
          payload: [],
        });
      }
    };

export const uploadAssignment =
  (file: any, assignmentId, unitCode, category, unitId): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('category', category);
      try {
        const response: any = await post(
          `${await API_URL()}/assignments/upload/submission-date/${assignmentId}/unit/${unitId}/${unitCode}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent: ProgressEventType) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              dispatch({
                type: SET_FILE_ASSIGNMENT_UPLOAD_STATUS,
                payload: percent,
              });
            },
          }
        );
        if (response && response.status === 'SUCCESS') {
          dispatch(getAssignment(unitCode));
          dispatch({ type: UPLOAD_SUBMISSION_SUCCESS, payload: response.data });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_SUBMISSION_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const resetUploadAssignmentData = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: RESET_UPLOAD_ASSIGNMENT_DATA, payload: '' });
  dispatch({
    type: SET_FILE_ASSIGNMENT_UPLOAD_STATUS,
    payload: 0,
  });
  dispatch({ type: UPLOAD_SUBMISSION_ERRORS, payload: {} });
};

export const uploadAssignmentsResult =
  (file: any, assignmentId, unitId, dataFormat, unitCode): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('dataFormat', dataFormat);
      try {
        const response: any = await post(`${await API_URL()}/assignments/upload/result/${assignmentId}/${unitId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEventType) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch({
              type: SET_FILE_ASSIGNMENT_RESULT_UPLOAD_STATUS,
              payload: percent,
            });
          },
        });
        if (response && response.status === 'SUCCESS') {
          dispatch(getAssignment(unitCode));
          dispatch({ type: UPLOAD_ASSIGNMENT_RESULT_SUCCESS, payload: response.data });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_ASSIGNMENT_RESULT_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const resetUploadAssignmentsResultData =
  (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    dispatch({ type: RESET_UPLOAD_ASSIGNMENT_DATA, payload: '' });
    dispatch({
      type: SET_FILE_ASSIGNMENT_RESULT_UPLOAD_STATUS,
      payload: 0,
    });
    dispatch({ type: UPLOAD_ASSIGNMENT_RESULT_ERRORS, payload: {} });
  };

export const uploadStudentGroups =
  (file: any, assignmentIds, unitCode): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('body', assignmentIds);
      try {
        const response: any = await post(`${await API_URL()}/assignments/upload/student-groups/${unitCode}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEventType) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch({
              type: SET_FILE_STUDENT_GROUPS_UPLOAD_STATUS,
              payload: percent,
            });
          },
        });
        if (response && response.status === 'SUCCESS') {
          dispatch({ type: UPLOAD_STUDENT_GROUPS_SUCCESS, payload: response.data.message });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_STUDENT_GROUPS_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const resetStudentGroupsUploadData =
  (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    dispatch({ type: RESET_UPLOAD_ASSIGNMENT_DATA, payload: '' });
    dispatch({
      type: SET_FILE_STUDENT_GROUPS_UPLOAD_STATUS,
      payload: 0,
    });
    dispatch({ type: UPLOAD_STUDENT_GROUPS_ERRORS, payload: {} });
  };

export const onAssignmentSelected =
  (assignment): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({ type: ON_ASSIGNMENT_SELECTED, payload: assignment });
    };

export const updateAssignment =
  (data: AssignmentDueData, assignmentId: number, unitId: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const response: any = await post(`${await API_URL()}/assignments/update/due-date/${assignmentId}`, data);
        if (response.status === actions.SUCCESS) {
          dispatch(getAssignment(unitId));
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const uploadTurnitinMapping =
  (unitCode, file: any): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response: any = await post(`${await API_URL()}/assignments/upload/turnitin/${unitCode}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEventType) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch({
              type: SET_FILE_TURNITIN_MAPPING_UPLOAD_STATUS,
              payload: percent,
            });
          },
        });
        if (response && response.status === 'SUCCESS') {
          dispatch({ type: UPLOAD_TURNITIN_MAPPING_SUCCESS, payload: response.data });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_TURNITIN_MAPPING_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const resetTurnitinMappingUploadData =
  (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    dispatch({ type: RESET_UPLOAD_TURNITIN_MAPPING_DATA, payload: '' });
    dispatch({
      type: SET_FILE_TURNITIN_MAPPING_UPLOAD_STATUS,
      payload: 0,
    });
    dispatch({ type: UPLOAD_TURNITIN_MAPPING_ERRORS, payload: {} });
  };

// -------------------       Reports       ----------------------
export const getAtRiskReports =
  (unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_REPORT_LOADING_START });
        const url = `${await API_URL()}/report/at-risk-students/unit/${unitCode}`;
        const result = await get(url);
        if (result) {
          dispatch({ type: ON_REPORT_LOADING_FINISHED });
          dispatch(findAtRiskReports(result.id));
        }
        dispatch({ type: GET_UNIT_REPORTS, payload: result });
      } catch (error) {
        dispatch({
          type: GET_UNIT_REPORTS,
          payload: null,
        });
        dispatch({ type: ON_REPORT_LOADING_FINISHED });
      }
    };

export const findAtRiskReports =
  (id): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await API_URL()}/find/global-reports/${id}`;
        const result = await get(url);
        dispatch({ type: Find_UNIT_REPORTS, payload: result });
      } catch (error) {
        dispatch({
          type: Find_UNIT_REPORTS,
          payload: null,
        });
      }
    };
// -------------------       Reports       ----------------------

export const clearDatabase =
  (id: number, type: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await API_URL()}/assignments/clean-database`;
        const result = await post(url, { id, type });
        dispatch({ type: GET_CLEAR_DB_RESPONSE, payload: result });
      } catch (error) {
        dispatch({
          type: GET_CLEAR_DB_RESPONSE,
          payload: null,
        });
      }
    };

export const resetClearDatabaseResponse = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: RESET_CLEAR_DB_RESPONSE,
    payload: null,
  });
};
