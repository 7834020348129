import { ActionType } from './types';
import * as actionTypes from '../actions/studentLogs';

const initialState = {
  logs: [],
  interventionLogsLoader: false,
  htmlMail: '',
  contentLoader: false,
};

const StudentInterventionLogs = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_INTERVENTION_LOGS_DATA: {
      return {
        ...state,
        logs: action.payload,
      };
    }
    case actionTypes.ON_STUDENT_INTERVENTION_LOGS_LOADING_START: {
      return {
        ...state,
        interventionLogsLoader: true,
      };
    }
    case actionTypes.ON_STUDENT_INTERVENTION_LOGS_LOADING_FINISHED: {
      return {
        ...state,
        interventionLogsLoader: false,
      };
    }
    case actionTypes.GET_STUDENT_INTERVENTION_EMAIL_CONTENT_DATA: {
      return {
        ...state,
        htmlMail: action.payload,
      };
    }
    case actionTypes.ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_START: {
      return {
        ...state,
        contentLoader: true,
      };
    }
    case actionTypes.ON_STUDENT_INTERVENTION_EMAIL_CONTENT_LOADING_FINISHED: {
      return {
        ...state,
        contentLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default StudentInterventionLogs;
