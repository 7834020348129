import DateFnsUtils from '@date-io/date-fns';
import { Button, CardHeader, Grid, MenuItem, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { updateAssignmnetDetailsOfAStudent } from '../../actions';
import { PlagiarismStatus as PlagiarismStatusEnums } from '../../util/enums';
import CustomModal from '../CustomModal';

type EditAssignmentStudentInfoProps = {
  assignmentId: number;
  unitCode: string;
  studentId: string;
  disabled?: boolean;
  resultAvailable?: boolean;
  specialConsideration?: string;
  originalMarks?: string | number | null;
  originalSubmissionDate?: Date | string | null;
  newDueDate?: string | null | Date;
  newDueDateComments?: string | null;
  academicMisconduct?: string | null;
};

const CustomCardHeader = styled(CardHeader)`
  background-color: #eeeeee;
`;

const EditAssignmentStudentInfo: React.FC<EditAssignmentStudentInfoProps> = ({
  assignmentId,
  unitCode,
  studentId,
  disabled = false,
  resultAvailable = false,
  specialConsideration = '',
  originalMarks = '',
  originalSubmissionDate = null,
  newDueDate = null,
  newDueDateComments = '',
  academicMisconduct = 'NO',
}) => {
  const dispatch = useDispatch();

  const [dueDate, setDueDate] = useState(newDueDate ? new Date(newDueDate) : null);
  const [submissionDate, setSubmissionDate] = useState(
    originalSubmissionDate && originalSubmissionDate !== 'NS' ? new Date(originalSubmissionDate) : null
  );
  const [marks, setMarks] = useState(originalMarks as string);
  const [dueDateComments, setDueDateComments] = useState(
    newDueDateComments && newDueDateComments?.length > 0 ? newDueDateComments.toString().split('|').slice(-1)[0] : ''
  );
  const [currentAcademicMisconduct, setAcademicMisconduct] = useState(academicMisconduct);
  const [currentSpecialConsideration, setSpecialConsideration] = useState(specialConsideration);
  const [open, setModalOpen] = useState(false);
  const [showMarksField, setShowMarksField] = useState(originalMarks === null ? false : true);
  const handleModalOpen = () => {
    setDueDate(newDueDate ? new Date(newDueDate) : null);
    setSubmissionDate(
      originalSubmissionDate && originalSubmissionDate !== 'NS' ? new Date(originalSubmissionDate) : null
    );
    setMarks(originalMarks as string);
    setDueDateComments(
      newDueDateComments && newDueDateComments?.length > 0 ? newDueDateComments.toString().split('|').slice(-1)[0] : ''
    );
    setAcademicMisconduct(academicMisconduct);
    setSpecialConsideration(specialConsideration as string);
    setShowMarksField(originalMarks === null ? false : true);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setDueDate(newDueDate ? new Date(newDueDate) : null);
    setDueDateComments(
      newDueDateComments && newDueDateComments.length > 0 ? newDueDateComments.toString().split('|').slice(-1)[0] : ''
    );
    setAcademicMisconduct(academicMisconduct);
    setSpecialConsideration(specialConsideration);
    setModalOpen(false);
    setSubmissionDate(
      originalSubmissionDate && originalSubmissionDate !== 'NS' ? new Date(originalSubmissionDate) : null
    );
    setMarks(originalMarks as string);
  };

  const CustomKeyboardDatePicker = styled(KeyboardDatePicker)`
    width: 100% !important;
  `;

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const updateSpecialConsideration = (value) => {
    setSpecialConsideration(value);
    if (value === 'NO') {
      setDueDate(newDueDate ? new Date(newDueDate) : null);
    }
  };

  const handleSubmissionDateChange = (date) => {
    setSubmissionDate(date);
  };

  const onAddClick = () => {
    dispatch(
      updateAssignmnetDetailsOfAStudent(assignmentId, unitCode, studentId, {
        dueDate,
        dueDateComments,
        specialConsideration: !currentSpecialConsideration ? 'NO' : currentSpecialConsideration,
        academicMisconduct: currentAcademicMisconduct,
        originalMarks,
        marks,
        originalSubmissionDate,
        submissionDate,
      })
    );
    handleModalClose();
  };

  const renderModal = () => {
    return (
      <CustomModal
        disabled={
          !submissionDate ||
          (!dueDate && currentSpecialConsideration === 'YES') ||
          (!dueDateComments && currentSpecialConsideration !== '') ||
          (originalSubmissionDate && originalSubmissionDate !== 'NS' && !submissionDate) ||
          (showMarksField && !marks)
        }
        open={open}
        onClose={handleModalClose}
        buttonText="Save"
        headerText="Edit"
        onSuccess={onAddClick}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomKeyboardDatePicker
                id="date-picker-dialog"
                label="Submission Date"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={submissionDate}
                onChange={handleSubmissionDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <br />

        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Special Consideration"
                name="specialConsideration"
                select
                SelectProps={{
                  value: currentSpecialConsideration,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={currentSpecialConsideration}
                variant="outlined"
                onChange={(e) => updateSpecialConsideration(e.target.value)}
              >
                {Object.keys(PlagiarismStatusEnums).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <br />
        </>

        {showMarksField && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={1}
                  label="Marks"
                  variant="outlined"
                  name="marks"
                  value={marks}
                  onChange={(e) => setMarks(e.target.value)}
                />
              </Grid>
            </Grid>

            <br />
          </>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomKeyboardDatePicker
                id="date-picker-dialog"
                label="Due Date"
                disabled={currentSpecialConsideration !== 'YES'}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={dueDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Comment"
              disabled={!currentSpecialConsideration}
              variant="outlined"
              name="comment"
              value={dueDateComments}
              onChange={(e) => setDueDateComments(e.target.value)}
            />
          </Grid>
        </Grid>

        {!dueDateComments && dueDate && currentSpecialConsideration === 'YES' ? (
          <Alert severity="warning">Please add comment to update due date!</Alert>
        ) : (
          <></>
        )}
        {!dueDateComments && currentSpecialConsideration === 'NO' ? (
          <Alert severity="warning">Please add comment!</Alert>
        ) : (
          <></>
        )}

        <br />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Academic Misconduct"
              name="currentAcademicMisconduct"
              disabled={!resultAvailable}
              select
              SelectProps={{
                value: currentAcademicMisconduct,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={currentAcademicMisconduct}
              variant="outlined"
              onChange={(e) => setAcademicMisconduct(e.target.value)}
            >
              {Object.keys(PlagiarismStatusEnums).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </CustomModal>
    );
  };

  return (
    <>
      <Button
        disabled={disabled}
        startIcon={<Edit />}
        onClick={handleModalOpen}
        color="secondary"
        variant="outlined"
        size="small"
      >
        Edit
      </Button>
      {renderModal()}
    </>
  );
};

export default EditAssignmentStudentInfo;
