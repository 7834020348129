import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentAssignmentRemindersData, onEventsSearchChanged } from '../../../actions';
import { StyledDivider } from '../../../components/Common';
import SearchBar from '../../../components/searchBar';
import { RootState } from '../../../reducer';
import ReminderFilters from './reminderFilters';
import Result from './reminderResult';

const initialValues = {
  startDate: '',
  endDate: '',
  type: [],
  sort: { sortStartDate: true, orderByDate: true },
};

export default function InterventionLogs({ studentId }) {
  const [page, setPage] = useState<number>(0);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [values, setValues] = useState(initialValues);

  const searchLogsList = useSelector((state: RootState) => state.studentReminderLogs?.logs);
  const loader = useSelector((state: RootState) => state.studentReminderLogs?.reminderLogsLoader);
  const searchText = useSelector((state: RootState) => state.events?.searchText);

  useEffect(() => {
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, values, page + 1, rowsPerPage));
  }, [dispatch, studentId, searchText, values, page, rowsPerPage]);

  const onSearchTextChanged = (newSearchText: string) => {
    setPage(0);
    dispatch(onEventsSearchChanged(newSearchText));
  };

  const onSearch = () => {
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, values, page + 1, rowsPerPage));
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, values, newPage + 1, rowsPerPage));
  };

  const onRowPerPageChanged = (newRowPerPage: number) => {
    setPage(0);
    setRowsPerPage(newRowPerPage);
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, values, 1, newRowPerPage));
  };

  const onFilterClear = () => {
    setValues({ ...initialValues });
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, { ...initialValues }, 1, rowsPerPage));
  };

  const onFilter = (val = values) => {
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, { ...val }, page + 1, rowsPerPage));
  };

  const onFilterSort = (sortObj: any) => {
    dispatch(getStudentAssignmentRemindersData(studentId, searchText, { ...sortObj }, page + 1, rowsPerPage));
  };

  return (
    <>
      <SearchBar
        filter
        onSearch={onSearch}
        searchPlaceHolder={'Search by Unit Code ...'}
        onSearchTextChanged={onSearchTextChanged}
        searchText={searchText}
        onFilterClear={onFilterClear}
        onFilter={onFilter}
      >
        <ReminderFilters values={values} setValues={setValues} />
      </SearchBar>
      <StyledDivider />
      <Result
        logs={searchLogsList?.rows || []}
        count={searchLogsList?.count || 0}
        page={page}
        setPage={onPageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onRowPerPageChanged}
        loader={loader}
        onFilter={onFilterSort}
        setValues={setValues}
        values={values}
      />
    </>
  );
}
