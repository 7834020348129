import React from 'react';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { Card, CardHeader, CardContent, ListItem, ListItemText, colors, Typography, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      flexWrap: 'wrap',
    },
    listItemText: {
      marginLeft: theme.spacing(2),
    },
    listItemTextPrimary: {
      color: colors.red[900],
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    errorText: {
      color: colors.red[900],
      fontWeight: 600,
    },
  })
);

type ErrorDetailsProps = {
  className?: string;
  errorResponse: any;
  type: string;
};
const ErrorDetails: React.FC<ErrorDetailsProps> = ({ errorResponse, className, type, ...rest }) => {
  const classes = useStyles();

  const errorRows = errorResponse?.rows ? errorResponse?.rows : errorResponse?.missingHeaders;

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const record: any = errorRows[index] || { message: '' };
    return (
      <ListItem
        className={classes.listItem}
        disableGutters
        divider={index < errorRows.length - 1}
        key={index}
        style={style}
      >
        <ListItemText
          className={classes.listItemText}
          primary={<span className={classes.listItemTextPrimary}>{record}</span>}
        />
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Card style={{ boxShadow: 'none' }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title={<Typography className={classes.errorText}>Error in {type} :</Typography>}
        />
        {errorResponse?.missingHeaders && <CardContent className={classes.errorText}>Missing Header</CardContent>}
        <CardContent style={{ paddingTop: 0 }}>
          {errorRows ? (
            <PerfectScrollbar>
              <FixedSizeList
                height={60 * errorRows.length}
                width={'100%'}
                itemSize={65}
                itemCount={(_.isArray(errorRows) ? errorRows : []).length}
              >
                {renderRow}
              </FixedSizeList>
            </PerfectScrollbar>
          ) : (
            <div className={classes.errorText}>{errorResponse.message}</div>
          )}
        </CardContent>
        {errorResponse.required && (
          <CardContent style={{ paddingTop: 0, color: 'green' }}>
            Required : {errorResponse.required.toString()}
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ErrorDetails;
