import { Divider } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { CloseNodeButton } from '../../components/closeNodeButton';
import { CustomHandle1, CustomNodeTitle1, DivRows, MainDiv1 } from '../../flowChartComponents/styled-framework-items';
import '../index.scss';


const Outer = styled.div`
  padding: 30px;
`;


export const Plagiarism=({data})=> {
  const dispatch = useDispatch();
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);
  const [selectedPlagiarismValue, setSelectedPlagiarismValue] = React.useState(data.plagiarismValue);

  const handlePlagiarismChange = (event) => {
    let updateObj = { ...flowChartData };
    updateObj.plagiarismValue = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setSelectedPlagiarismValue(event.target.value);
    data.plagiarismValue = event.target.value;
  };

  return (
    <MainDiv1>
            <CustomHandle1 type="source" id="condition_plagiarism" position={Position.Bottom} />

      <CustomNodeTitle1>
        <FindInPageIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Plagiarism
        <CloseNodeButton/>
      </CustomNodeTitle1>
      <Divider />
      <Outer>
        <DivRows>
          <span style={{ marginTop: '10px', marginRight: 40, fontWeight: 'bold', width: 50, fontSize: 15 }}>
            Status:{' '}
          </span>

          <div style={{ width: 300 }}>
            <ToggleButtonGroup
              value={selectedPlagiarismValue}
              exclusive
              onChange={handlePlagiarismChange}
              aria-label="text alignment"
              className={'toggleBtnGroupCustom'}
            >
              <ToggleButton value="YES">
                <span style={{ fontSize: 13, width: 125 }}>YES</span>
              </ToggleButton>
              <ToggleButton value="NO" className="toggleBtnCustom">
                <span style={{ fontSize: 13, width: 125 }}>NO</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DivRows>
      </Outer>
    </MainDiv1>
  );
}
