import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { GET_STUDENT_ASSESSMENT_INDEX, GET_STUDENT_LEGACY_INDEX, GET_STUDENT_MATERIAL_INDEX, ON_STUDENT_ENGAGEMENT_INDEX_RECEIVED } from '../actionTypes/indices';



export const GET_STUDENT_INFO = 'GET_STUDENT_INFO';
export const GET_STUDENT_PROFILE_IMAGE = 'GET_STUDENT_PROFILE_IMAGE';
export const GET_UNITS_BY_STUDENTID = 'GET_UNITS_BY_STUDENTID';
export const GET_UNITS_GRAPH_DATA_BY_STUDENTID = 'GET_UNITS_GRAPH_DATA_BY_STUDENTID';
export const GET_UNITS_BAR_GRAPH_DATA_BY_STUDENTID = 'GET_UNITS_BAR_GRAPH_DATA_BY_STUDENTID';
export const ON_STUDENT_RECEIVED = 'ON_STUDENT_RECEIVED';
export const ON_STUDENT_SEARCH_CHANGED = 'ON_STUDENT_SEARCH_CHANGED';
export const RESET_STUDENT_DETAILS = 'RESET_STUDENT_DETAILS';


export const getStudents = (
  searchText: string,
  page: number,
  recordPerPage: number,
  filters: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    // Call API
    const result = await post(`${await API_URL()}/pace/students/search`, {
      limit: recordPerPage,
      offset: recordPerPage * page - recordPerPage,
      searchText,
      ...filters,
    });
    dispatch({ type: ON_STUDENT_RECEIVED, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: ON_STUDENT_RECEIVED,
      payload: [],
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const onStudentSearchChanged = (searchText: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: ON_STUDENT_SEARCH_CHANGED,
    payload: searchText,
  });
};

export const getStudentInfoById = (studentId: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    const STUDENTS_URL = `${await API_URL()}/pace/students`;
    const STUDENT_URL = (studentId: string) => `${STUDENTS_URL}/${studentId}`;
    const url = STUDENT_URL(studentId);
    const result = await get(url);
    dispatch({ type: GET_STUDENT_INFO, payload: result });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_INFO,
      payload: null,
    });
  }
};

export const getStudentProfileImageById = (
  studentId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const STUDENTS_URL = `${await API_URL()}/pace/students`;
    const STUDENT_PROFILE_IMAGE_URL = (studentId: string) => `${STUDENTS_URL}/${studentId}/profileImage`;
    const url = STUDENT_PROFILE_IMAGE_URL(studentId);
    const result = await get(url);
    dispatch({ type: GET_STUDENT_PROFILE_IMAGE, payload: { data: result, id: studentId } });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_PROFILE_IMAGE,
      payload: { data: '', id: studentId },
    });
  }
};

export const getUnitsByStudentId = (studentId: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const url = `${await API_URL()}/pace/student/${studentId}/units`;
    const result = await get(url);
    dispatch({ type: GET_UNITS_BY_STUDENTID, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_UNITS_BY_STUDENTID,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getUnitsBarGraphDataByStudentId = (
  studentId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const url = `${await API_URL()}/pace/student/${studentId}/historicalgrades`;
    const result = await get(url);
    dispatch({ type: GET_UNITS_BAR_GRAPH_DATA_BY_STUDENTID, payload: result || [] });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_UNITS_BAR_GRAPH_DATA_BY_STUDENTID,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getStudentAssessmentIndex = (
  studentIds: string[] | string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const url = `${await API_URL()}/assessment-indices?students=${studentIds}`;
    const result = await get(url);
    dispatch({ type: GET_STUDENT_ASSESSMENT_INDEX, payload: result });
  } catch (err) { }
  dispatch({ type: ON_LOADING_FINISHED });
};

export const getStudentLegacyIndex = (
  studentIds: string[] | string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const url = `${await API_URL()}/legacy-indices?students=${studentIds}`;
    const result = await get(url);
    dispatch({ type: GET_STUDENT_LEGACY_INDEX, payload: result });
  } catch (err) { }
  dispatch({ type: ON_LOADING_FINISHED });
};

export const getStudentEngagementIndex = (
  studentIds: string[] | string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const url = `${await API_URL()}/engagement-indices?students=${studentIds}`;
    const result = await get(url);
    dispatch({ type: ON_STUDENT_ENGAGEMENT_INDEX_RECEIVED, payload: result });
  } catch (err) { }
  dispatch({ type: ON_LOADING_FINISHED });
};

export const getStudentMaterialIndex = (
  studentIds: string[] | string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const url = `${await API_URL()}/material-indices?students=${studentIds}`;
    const result = await get(url);
    dispatch({ type: GET_STUDENT_MATERIAL_INDEX, payload: result });
  } catch (err) { }
  dispatch({ type: ON_LOADING_FINISHED });
};

export const getUnitsLineGraphDataByStudentId = (
  unitId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const GRAPH_URL = await API_URL() + '/graph';
    const UNIT_URL = (unitCode: string) => GRAPH_URL + '/unit/' + unitCode;
    const url = UNIT_URL(unitId);
    const result = await get(url);
    dispatch({ type: GET_UNITS_GRAPH_DATA_BY_STUDENTID, payload: result || [] });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_UNITS_GRAPH_DATA_BY_STUDENTID,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const resetStudentDetails = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: RESET_STUDENT_DETAILS,
  });
};

export const getTestEmail = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    await get(`${await API_URL()}/test-function-email`);
  } catch (error) { }
};
