import { Button, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from '../../../components/Loader';
import styled from 'styled-components';
import { CustomTable } from '../../../components/Common';
export interface CourseType {
  id: string;
  discipline: string;
  courseCode: string;
  courseName: string;
  location: string;
  isActive: boolean;
}

const StyledContent = styled(CardContent)`
  padding: 0;
`;

const TotalCount = styled(Typography)`
  margin: 8px 0px;
`;

type ResultsProps = {
  className?: string;
  data: CourseType[];
  setPage: (page: number) => void;
  setRowsPerPage: (rowPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  count: number;
  loader: boolean;
  onFilter: (query: any) => void;
  values: any;
  setValues: (state: any) => void;
};

const CourseResult: React.FC<ResultsProps> = ({
  className,
  data = [],
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loader,
  onFilter,
  values,
  setValues,
  ...rest
}) => {
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };
  const decoratedTableData = data.map((course: any) => {
    const { courseCode, courseName, location, discipline } = course;
    return [
      courseCode,
      courseName,
      discipline,
      location,
      <Button color="primary" component={RouterLink} size="small" to={`/courses/${courseCode}`} variant="outlined">
        View
      </Button>,
    ];
  });

  return (
    <>
      <TotalCount color="textSecondary" variant="body2">
        {count} Records found. Page {page + 1} of {Math.ceil(count / rowsPerPage)}
      </TotalCount>
      <Card>
        <CardHeader title="Search Results" />
        <Divider />
        <StyledContent>
          {data && loader ? (
            <CustomTable
              tableHead={[
                { text: 'Code' },
                { text: 'Name' },
                {
                  text: 'Discipline',
                },
                { text: 'Location' },
                { text: 'Actions' },
              ]}
              tableData={decoratedTableData}
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <Loader />
          )}
        </StyledContent>
      </Card>
    </>
  );
};

export default CourseResult;
