import { CircularProgress, Grid, IconButton, Input, InputLabel, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Close } from '@material-ui/icons';
import React from 'react';
import SchedulerForInterventionTrigger from '../../components/Modals/schedluerForInterventionTrigger';
import { SchedulerContext } from './addIntervention1';
import './dnd.scss';
import { addInterventionUseStyles } from './intervention_styles';
import './layouting.scss';
import './validation.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { setInterventionStudents } from '../../actions';
import { useEffect } from 'react';
import { TriggerTypes } from '../../util/constants';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: '#04337824',
      color: '#494949',
    },
  })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

type ConfigurationForNewInterventionProps = {
  label: string;
  setLabel: (label: any) => void;
  selectedAssignments: any[];
  handleChange: (handleChange: any) => void;
  setSelectedAssignments: (setSelectedAssignments: any) => void;
  interventionUnits: { code: string; label: string }[];
  selectedTriggerValue: string;
  handleTriggerChange: (handleTriggerChange: any) => void;
  recurrenceValue: any;
  setRecurrenceValue: (recurrenceValue: any) => void;
  selectedStudents: any[];
  setSelectedStudents: (selectedStudents: any) => void;
  interventionStudents: any[];
};

export const ConfigurationForNewIntervention: React.FC<ConfigurationForNewInterventionProps> = ({
  label,
  setLabel,
  selectedAssignments,
  handleChange,
  setSelectedAssignments,
  interventionUnits,
  selectedTriggerValue,
  handleTriggerChange,
  recurrenceValue,
  setRecurrenceValue,
  selectedStudents,
  setSelectedStudents,
  interventionStudents,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.roles?.roles);
  const interventionStudentsLoader = useSelector((state: RootState) => state.intervention?.interventionStudentsLoader);

  const updateInterventionStudents = () => {
    dispatch(setInterventionStudents(selectedAssignments.map((x) => x.code), role));
  };
  // useEffect(() => {
  //   if (selectedAssignments.length === 1 && selectedAssignments[0].code === 'ALL') {
  //     updateInterventionStudents();
  //   }
  // }, [selectedAssignments]);

  return (
    <div style={{ margin: '24px 40px 24px 14px' }}>
      <Grid container direction={'column'} spacing={1} alignItems="flex-end" justify="space-between">
        {/* <Grid item>
          
        </Grid> */}
        <Grid item>
          <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
            Label:
          </InputLabel>
       
            <Select
            style={{ width: 600, margin: 12 }}
            labelId="demo-simple-select-labelTrigger"
            id="demo-simple-selectTrigger"
            value={label}
            onChange={setLabel}
            variant={'outlined'}
          >
            <MenuItem value={'Poor Academic Progress'}>Poor Academic Progress</MenuItem>
            <MenuItem value={'Low Attendance'}>Low Attendance</MenuItem>
            {/* <MenuItem value={TriggerTypes.ON_GRADES_UPLOAD}>On Assignment Grades Upload</MenuItem>
            <MenuItem value={TriggerTypes.ON_SUBMISSION_UPLOAD}>On Assignment Submission Upload</MenuItem> */}
          </Select>
        </Grid>
        {/* <Grid item>
         
        </Grid> */}

        <Grid item>
          <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
            Select Units:
          </InputLabel>
          <Autocomplete
            style={{ width: 600, margin: 12 }}
            multiple
            limitTags={3}
            id="multiple-limit-tags"
            disableCloseOnSelect
            options={interventionUnits}
            getOptionLabel={(option) => option.code + ' - ' + option.label}
            value={selectedAssignments}
            onClose={updateInterventionStudents}
            defaultValue={[interventionUnits[0]]}
            onChange={(event, newValue) => {
              if (newValue.length === 0 || newValue[newValue.length - 1].code === 'ALL') {
                newValue = [interventionUnits[0]];
              } else {
                if (newValue[0].code === 'ALL') newValue.shift();
              }
              setSelectedAssignments(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="" placeholder="Type or select units" />
            )}
          />
        </Grid>

        <Grid item>
          <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
            Select Students:
          </InputLabel>
          <Autocomplete
            style={{ width: 600, margin: 12 }}
            multiple
            limitTags={6}
            id="multiple-limit-tags"
            disableCloseOnSelect
            options={interventionStudents}
            getOptionLabel={(option) => option}
            value={selectedStudents}
            loading={interventionStudentsLoader}
            // defaultValue={[interventionStudents[0]]}
            onChange={(event, newValue) => {
              if (newValue.length === 0 || newValue[newValue.length - 1] === 'ALL') {
                newValue = [interventionStudents[0]];
              } else {
                if (newValue[0] === 'ALL') newValue.shift();
              }
              setSelectedStudents(newValue);
            }}
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>
            //     <Checkbox
            //       icon={icon}
            //       checkedIcon={<CheckBoxIcon fontSize="small" />}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {option}
            //   </React.Fragment>
            // )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder="Type or select students"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {interventionStudentsLoader ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item style={{ marginLeft: 20 }}>
          <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-simple-select-labelTrigger">
            Trigger:
          </InputLabel>
          <Select
            style={{ width: 600, margin: 12 }}
            labelId="demo-simple-select-labelTrigger"
            id="demo-simple-selectTrigger"
            value={selectedTriggerValue}
            onChange={handleTriggerChange}
            variant={'outlined'}
          >
            <MenuItem value={TriggerTypes.INSTANT}>Instant</MenuItem>
            <MenuItem value={TriggerTypes.SCHEDULED}>Schedule</MenuItem>
            {/* <MenuItem value={TriggerTypes.ON_GRADES_UPLOAD}>On Assignment Grades Upload</MenuItem>
            <MenuItem value={TriggerTypes.ON_SUBMISSION_UPLOAD}>On Assignment Submission Upload</MenuItem> */}
          </Select>
        </Grid>

        {selectedTriggerValue === 'SCHEDULED' ? (
          <>
            {' '}
            <SchedulerContext.Provider value={{ recurrenceValue, setRecurrenceValue }}>
              <SchedulerForInterventionTrigger />
            </SchedulerContext.Provider>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};
