import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, TextField, Typography, Theme, MenuItem } from '@material-ui/core';
import { eventStatus } from '../../util/enums';
import { teamTypeOptions } from '../../util/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(0, 3),
      flexGrow: 1,
    },
    contentSection: {
      padding: theme.spacing(2, 0),
    },
    contentSectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    contentSectionContent: {},
    formGroup: {
      padding: theme.spacing(2, 0),
    },
    field: {
      marginTop: 0,
      marginBottom: 0,
    },
  })
);

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

type EventFiltersProps = {
  className?: string;
  values: any;
  setValues: (state: any) => void;
};

const EventFilters: React.FC<EventFiltersProps> = ({ values, setValues }) => {
  const classes = useStyles();

  const handleFieldChange = (event: any, field: any, value: any) => {
    if (event) {
      event.persist();
    }

    setValues((prevValues: any) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentSection}>
        <div className={classes.contentSectionHeader}>
          <Typography variant="h5">TImetable Filters</Typography>
        </div>
        <Divider />
        <div className={classes.contentSectionContent}>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Unit Code"
              margin="dense"
              name="unitCode"
              onChange={(event) => handleFieldChange(event, 'unitCode', event.target.value)}
              value={values.unitCode}
              variant="outlined"
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Day"
              margin="dense"
              name="day"
              select
              SelectProps={{
                value: values.day,
                onChange: (event) => handleFieldChange(event, 'day', event.target.value),
              }}
              variant="outlined"
            >
              {days.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Event Status"
              margin="dense"
              name="status"
              select
              SelectProps={{
                value: values.status,
                onChange: (event) => handleFieldChange(event, 'status', event.target.value),
              }}
              variant="outlined"
            >
              {Object.keys(eventStatus).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Class Type"
              margin="dense"
              name="teamType"
              select
              SelectProps={{
                multiple: true,
                value: values.teamType,
                onChange: (event) => handleFieldChange(event, 'teamType', event.target.value),
              }}
              variant="outlined"
            >
              {teamTypeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventFilters;
