import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: 'rgb(4, 51, 120)',
    main: 'rgba(4, 51, 120, 1)',
    light: 'rgba(4, 51, 120, 0.7)',
  },
  secondary: {
    contrastText: white,
    dark: 'rgb(4, 51, 120)',
    main: 'rgba(4, 51, 120, 1)',
    light: 'rgba(4, 51, 120, 0.7)',
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#3F4747',
    secondary: '#3F4747',
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F1F3F6',
    paper: white,
  },
  divider: colors.grey[200],
};
