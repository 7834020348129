import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import SearchBar from '../../../components/searchBar';
import Result from './result';
import { RootState } from '../../../reducer';
import { getStudents, onStudentSearchChanged, resetStudentDetails } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDivider, SearchDiv } from '../../../components/Common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);
const initialValues = {
  providerStudentID: '',
  firstName: '',
  lastName: '',
  nationality: '',
  gender: '',
  coeStatus: '',
  courseCode: '',
  courseName: '',
};

export default function Student() {
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const studentsResult = useSelector((state: RootState) => state.student?.studentsResult);
  const searchText = useSelector((state: RootState) => state.student?.searchText);
  const loader = useSelector((state: RootState) => state.student?.loader);

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      dispatch(resetStudentDetails());
      dispatch(getStudents(searchText, page + 1, rowsPerPage, values));
    }
  }, [page, rowsPerPage, dispatch, isMount, searchText, values]);

  const onFilter = (query: any) => {
    dispatch(getStudents(searchText, page + 1, rowsPerPage, query));
  };

  const onSearchTextChanged = (newSearchText: string) => {
    setPage(0);
    dispatch(onStudentSearchChanged(newSearchText));
  };

  const onSearch = () => {
    dispatch(getStudents(searchText, page + 1, rowsPerPage, values));
  };

  const onFilterClear = () => {
    dispatch(getStudents('', 1, rowsPerPage, {}));
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getStudents(searchText, newPage + 1, rowsPerPage, values));
  };

  const onRowPerPageChanged = (newRowPerPage: number) => {
    setPage(0);
    setRowsPerPage(newRowPerPage);
    dispatch(getStudents(searchText, 1, newRowPerPage, values));
  };

  return (
    <SearchDiv>
      <SearchBar
        onFilter={onFilter}
        onSearch={onSearch}
        searchPlaceHolder={'Search by Name, Provider Id..'}
        onSearchTextChanged={onSearchTextChanged}
        searchText={searchText}
        onFilterClear={onFilterClear}
        initialValues={initialValues}
        values={values}
        setValues={setValues}
      ></SearchBar>
      <StyledDivider />
      <Result
        students={studentsResult.rows || []}
        count={studentsResult.count || 0}
        className={classes.results}
        page={page}
        setPage={onPageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onRowPerPageChanged}
        loader={loader}
      />
    </SearchDiv>
  );
}
