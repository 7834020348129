import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Modal from '@material-ui/core/Modal';
import { Edit, GetApp, OpenInNewOutlined } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  onAssignmentSelected,
  resetStudentGroupsUploadData,
  resetTurnitinMappingUploadData,
  resetUploadAssignmentData,
  resetUploadAssignmentsResultData,
} from '../../../actions';
import { AvatarForDownload } from '../../../components/Common/StyledComponents';
import Loader from '../../../components/Loader';
import { Comments } from '../../../components/Modals';
import { RootState } from '../../../reducer';
import { theme } from '../../../theme';
import BulkUpload from '../BulkUpload';
import AssignmentsResultUpload from '../BulkUpload/resultUpload';
import StudentGroupsUpload from '../BulkUpload/studentGroups';
import TurnitinMapping from '../BulkUpload/turnitinMapping';
import EditAssignmentPopup from '../editAssignmentPopup';
import AssignmentActions, { AssignmentActionType } from './assignment-actions';

const ActionContainer = styled.div`
  display: flex;
  min-width: 250px;
  justify-content: space-space-between;
`;

const StyledTableFooter = styled(TableFooter)`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin: ${theme.spacing(2)}px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: ${theme.spacing(2)}px;
  }
`;

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    border-bottom: none;
  }
`;

const TableCellLeftBorder = styled(TableCell)`
  border-left: 1px solid #eeeeee;
`;

const TableCellRightBorder = styled(TableCell)`
  border-right: 1px solid #eeeeee;
`;

const TableCellBothBorder = styled(TableCell)`
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
`;

const Assignment: React.FC<any> = ({ data, id }) => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  let { unitId } = useParams<{ unitId: string }>();

  // State
  const [tableData, setTableData] = useState(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [assignmentId, setAssignmentId] = useState();
  const [taskName, setTaskName] = useState();
  const [assignmentResultModalOpen, setassignmentResultModalOpen] = useState(false);
  const [studentGroupsModalOpen, setStudentGroupsModalOpen] = useState(false);
  const [turnitinMappingOpen, setTurnitinMappingOpen] = useState(false);

  // Selectors
  const comments = useSelector((state: RootState) => state.assignmentDueDate?.comments);
  const commentsLoader = useSelector((state: RootState) => state.assignmentDueDate?.commentsLoader);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetUploadAssignmentData());
  };

  const onActionClick = (type: AssignmentActionType, assignment: any) => {
    switch (type) {
      case 'view':
        history.push(`/units/${unitId}/assignments/${assignment.id}/details`);
        break;
      case 'upload_submission':
        setOpen(true);
        setAssignmentId(assignment.id);
        setTaskName(assignment.taskName);
        break;
      case 'upload_result':
        setassignmentResultModalOpen(true);
        setAssignmentId(assignment.id);
        setTaskName(assignment.taskName);
        break;
      case 'edit':
        dispatch(onAssignmentSelected({ ...assignment }));
        break;
    }
  };

  const handleAssignmentResultModalClose = () => {
    setassignmentResultModalOpen(false);
    dispatch(resetUploadAssignmentsResultData());
  };

  const handleStudentGroupsModalOpen = () => {
    setStudentGroupsModalOpen(true);
  };

  const handleStudentGroupsModalClose = () => {
    setStudentGroupsModalOpen(false);
    dispatch(resetStudentGroupsUploadData());
  };

  const handleTurnitinMappingModalOpen = () => {
    setTurnitinMappingOpen(true);
  };

  const handleTurnitinMappingModalClose = () => {
    setTurnitinMappingOpen(false);
    dispatch(resetTurnitinMappingUploadData());
  };
  return (
    <>
      <ButtonContainer>
        <Button
          onClick={() => handleTurnitinMappingModalOpen()}
          color="secondary"
          style={{ marginLeft: 10 }}
          size="small"
          variant="outlined"
          className="float-right"
          hidden={selectedBuCode === 'USC'}
        >
          <PublishIcon />
          Turnitin Mapping
        </Button>
        <Button
          onClick={() => handleStudentGroupsModalOpen()}
          color="secondary"
          size="small"
          variant="outlined"
          className="float-right"
          disabled={data ? false : true}
          hidden={selectedBuCode === 'USC'}
        >
          <PublishIcon /> Student Groups
        </Button>
      </ButtonContainer>
      {tableData ? (
        <TableContainer component={Paper} elevation={2}>
          <PerfectScrollbar>
            <StyledTable stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                  <TableCellBothBorder align="center" colSpan={2}>
                    Due
                  </TableCellBothBorder>
                  <TableCellBothBorder colSpan={2} align="center">
                    Last uploaded
                  </TableCellBothBorder>
                  <TableCell colSpan={3}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Task Name</TableCell>
                  <TableCell align="center">Task Weightage</TableCell>
                  <TableCell align="center">Lecturer Name</TableCell>
                  <TableCellLeftBorder align="center">Date</TableCellLeftBorder>
                  <TableCellRightBorder align="center">Week</TableCellRightBorder>
                  <TableCellLeftBorder align="center">Submissions</TableCellLeftBorder>
                  <TableCellRightBorder align="center">Results</TableCellRightBorder>
                  <TableCell align="center">Submission Type</TableCell>
                  <TableCell align="center">Comments</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  (rowsPerPage > 0
                    ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : tableData
                  ).map((assignment: any) => {
                    const { id = '', assignmentResult = [] } = assignment;
                    const dd = moment(assignment.dueDate.trim(), 'DD/MM/YYYY');
                    return (
                      <TableRow key={id}>
                        <TableCell align="center">{assignment.taskNumber}</TableCell>
                        <TableCell align="center">
                          <b>{assignment.taskName}</b>
                        </TableCell>
                        <TableCell align="center">{assignment.taskWeightage}</TableCell>
                        <TableCell align="center">{assignment.lecturerName}</TableCell>
                        <TableCell align="center">
                          {assignment.dueDate + ' ' + assignment.time}
                          <br />
                          <b>{dd.format('dddd')}</b>
                        </TableCell>
                        <TableCell align="center">{assignment.dueWeek}</TableCell>
                        <TableCell align="center">
                          {assignment.submissions.length > 0
                            ? moment(assignment.submissions.slice(-1)[0]).utc().format('DD/MM/YYYY LT')
                            : '-'}
                          {assignment.submissions.length > 0 ? (
                            <div style={{ marginTop: '6px' }}>
                              <Chip
                                avatar={<Avatar className="avatar-style-01">{assignment.submissions.length}</Avatar>}
                                label={
                                  <div>
                                    <span style={{ fontSize: '13px' }}>{assignment.lastSubmissionUploadedBy} </span>
                                    <span></span>
                                    <Tooltip title="Download current version" aria-label="donwloadCurrentVersion">
                                      <CSVLink
                                        data={assignment.submissionData}
                                        filename={`SubmissionData_${assignment.taskNumber}_${assignment.taskName}_${unitId}.csv`}
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                      >
                                        <AvatarForDownload className="avatar-style-01">
                                          <GetApp fontSize="small" />
                                        </AvatarForDownload>
                                      </CSVLink>
                                    </Tooltip>
                                  </div>
                                }
                                className="chip1"
                                color="primary"
                                variant="outlined"
                                style={{ width: 'max-content' }}
                              />
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell align="center">
                          {assignmentResult.length > 0 ? (
                            <>
                              {assignment.results.length > 0
                                ? moment(assignment.results.slice(-1)[0]).utc().format('DD/MM/YYYY LT')
                                : '-'}
                              {assignment.results.length > 0 ? (
                                <div style={{ marginTop: '6px' }}>
                                  <Chip
                                    avatar={<Avatar className="avatar-style-01">{assignment.results.length}</Avatar>}
                                    label={
                                      <div>
                                        <span style={{ fontSize: '13px' }}>{assignment.lastResultUploadedBy}</span>
                                        <Tooltip title="Download current version" aria-label="donwloadCurrentVersion">
                                          <CSVLink
                                            data={assignmentResult}
                                            filename={`AssignmentResult${assignment.taskNumber}_${assignment.taskName}_${unitId}.csv`}
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <AvatarForDownload className="avatar-style-01">
                                              <GetApp fontSize="small" />
                                            </AvatarForDownload>
                                          </CSVLink>
                                        </Tooltip>
                                      </div>
                                    }
                                    className="chip1"
                                    color="primary"
                                    variant="outlined"
                                    style={{ width: 'max-content' }}
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="center">{assignment?.submissionType || 'YES'}</TableCell>
                        <TableCell align="center">
                          <Comments commentsLoader={commentsLoader} id={id} comments={comments[id] || []} />
                        </TableCell>
                        <TableCell align="center">
                          <AssignmentActions onClick={onActionClick} assignment={assignment} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </StyledTable>
          </PerfectScrollbar>
          <StyledTableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableFooter>
        </TableContainer>
      ) : (
        <Loader />
      )}
      <Modal
        open={studentGroupsModalOpen}
        onClose={handleStudentGroupsModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StudentGroupsUpload
          unitId={unitId}
          assignments={data}
          handleModalClose={handleStudentGroupsModalClose}
        ></StudentGroupsUpload>
      </Modal>
      <Modal
        open={turnitinMappingOpen}
        onClose={handleTurnitinMappingModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TurnitinMapping unitCode={unitId} handleModalClose={handleTurnitinMappingModalClose}></TurnitinMapping>
      </Modal>

      <Modal open={open} onClose={handleClose}>
        <BulkUpload
          unitCode={unitId}
          id={id}
          taskName={taskName}
          assignmentId={assignmentId}
          handleModalClose={handleClose}
        />
      </Modal>
      <Modal open={assignmentResultModalOpen} onClose={handleAssignmentResultModalClose}>
        <AssignmentsResultUpload
          unitCode={unitId}
          id={id}
          taskName={taskName}
          assignmentId={assignmentId}
          handleModalClose={handleAssignmentResultModalClose}
        />
      </Modal>
      <EditAssignmentPopup unitId={unitId} />
    </>
  );
};

export default Assignment;
