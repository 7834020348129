import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses, onCourseSearchTextChanged } from '../../../actions';
import { StyledDivider, SearchDiv } from '../../../components/Common';
import SearchBar from '../../../components/searchBar';
import { RootState } from '../../../reducer';
import Result from './result';

const initialValues = {
  courseCode: '',
  startDate: '',
  endDate: '',
  teamName: '',
  teamType: [],
  sort: {},
};

export default function CoursesSearch() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const searchCourseList = useSelector((state: RootState) => state.courses?.courseInfo);
  const loader = useSelector((state: RootState) => state.courses?.loader);
  const searchText = useSelector((state: RootState) => state.courses?.searchText);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const [values, setValues] = useState(initialValues);

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      dispatch(getCourses(searchText, page + 1, rowsPerPage, selectedBuCode, selectedSemester));
    }
  }, [dispatch, page, rowsPerPage, values, searchText, isMount, selectedBuCode, selectedSemester]);

  const onSearchTextChanged = (newSearchText: string) => {
    setPage(0);
    dispatch(onCourseSearchTextChanged(newSearchText));
  };

  const onSearch = () => {
    dispatch(getCourses(searchText, page + 1, rowsPerPage, selectedBuCode, selectedSemester));
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getCourses(searchText, newPage + 1, rowsPerPage, selectedBuCode, selectedSemester));
  };

  const onRowPerPageChanged = (newRowPerPage: number) => {
    setPage(0);
    setRowsPerPage(newRowPerPage);
    dispatch(getCourses(searchText, 1, newRowPerPage, selectedBuCode, selectedSemester));
  };

  const onFilterSort = (sortObj: any) => {
    dispatch(getCourses(searchText, page + 1, rowsPerPage, selectedBuCode, selectedSemester));
  };

  return (
    <SearchDiv>
      <SearchBar
        onSearch={onSearch}
        searchPlaceHolder={'Search by Code, Name, Discipline, Location..'}
        onSearchTextChanged={onSearchTextChanged}
        searchText={searchText}
      ></SearchBar>
      <StyledDivider />
      <Result
        data={searchCourseList?.rows || []}
        count={searchCourseList?.count || 0}
        page={page}
        setPage={onPageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onRowPerPageChanged}
        loader={loader}
        onFilter={onFilterSort}
        setValues={setValues}
        values={values}
      />
    </SearchDiv>
  );
}
