import { getAssessmentAverageForIndex, getAverageForIndex } from './array';
import { AssignmentDueDate, OnlineAttendance, StudentIndex } from './types';
import moment from 'moment';
import { attendanceTypes, mandatory, nonMandatory, employability, onTrack, storageCodes } from './enums';
import _ from 'lodash';
import { MAX_WEEKS_VISIBLE_IN_GRAPH } from './constants';
import { KEY_BU_CODE, KEY_SEMESTER } from '../config/constants';
import { PhysicalAttendance } from '../models/event';

export const sleep = async (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const isNumeric = (str: string) => {
  return !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getGradeAndColor = (marks: number, total: string) => {
  let color = '';
  let grade = '';
  grade = ((marks * 100) / parseFloat(total)).toFixed(2);
  if (parseFloat(grade) <= 50) {
    color = 'red';
  } else if (parseFloat(grade) <= 75) {
    color = '#FF8C00';
  } else {
    color = 'green';
  }
  return { color, grade };
};

export const getTodaysDateFormatted = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;

  var yyyy = today.getFullYear();
  return dd + '/' + mm + '/' + yyyy;
};

export const sortDataByDate = (data) => {
  data = data.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() <= dateB.getTime() ? -1 : 1;
  });
  return data;
};

export const isSunday = (date) => {
  return new Date(date).getDay() === 0;
};

export const isSaturday = (date) => {
  return new Date(date).getDay() === 6;
};

export const calculateAverageIndex = (data) => {
  let value = 0;
  let count = 0;
  data.forEach((x) => {
    const { y = '0', length = 1 } = x;
    value += parseFloat(y) * length;
    count += length;
  });
  return count === 0 ? 0 : (value / count).toFixed(2);
};

export const nextSunday = (date) => {
  const sunday = isSunday(date);
  if (sunday) {
    return date;
  }
  let isSun = false;
  let d = new Date(date);
  while (!isSun) {
    d.setDate(d.getDate() + 1);
    isSun = isSunday(d);
  }
  return d;
};

export const weekData = (startDate, endDate) => {
  let finalStartDate = startDate > new Date() ? new Date() : new Date(startDate);
  const finalEndDate = nextSunday(endDate > new Date() ? new Date() : new Date(endDate));
  finalStartDate = isSaturday(finalStartDate)
    ? new Date(new Date(finalStartDate).setDate(finalStartDate.getDate() + 1))
    : finalStartDate;
  let weekData = {};
  let weekNum = 1;
  let firstDayOfWeek = finalStartDate;
  for (var d = new Date(finalStartDate); d <= new Date(finalEndDate); d.setDate(d.getDate() + 1)) {
    const saturday = isSaturday(d);
    if (saturday) {
      weekData[weekNum] = { startDate: firstDayOfWeek, endDate: new Date(d) };
      weekNum += 1;
      firstDayOfWeek = new Date(new Date(d).setDate(d.getDate() + 1));
    }
  }
  return weekData;
};

export const indicesData = (data, startDate, endDate, preStartDate) => {
  const finalStartDate = (startDate > new Date() ? new Date() : new Date(startDate)).toISOString().split('T')[0];
  const finalPreStartDate = (preStartDate > new Date() ? new Date() : new Date(preStartDate))
    .toISOString()
    .split('T')[0];
  const finalEndDate = nextSunday(endDate > new Date() ? new Date() : new Date(endDate))
    .toISOString()
    .split('T')[0];
  const createdAtData = data.reduce((acc, x) => {
    const date = x.createdAt.split('T')[0];
    if (acc[date]) {
      acc[date] = [...acc[date], x];
    } else {
      acc[date] = [x];
    }
    return acc;
  }, {});
  let indexData: any[] = [];
  let weekNum = 1;
  let weekAverage: any[] = [...(createdAtData[finalPreStartDate] || [])];
  for (var d = new Date(finalStartDate); d <= new Date(finalEndDate); d.setDate(d.getDate() + 1)) {
    const formattedDate = moment(d).format('YYYY-MM-DD');
    const sunday = isSunday(formattedDate);
    const dayIndices = createdAtData[formattedDate] || [];
    const xText = new Date(formattedDate).toDateString().substr(4);
    if (sunday) {
      const index = getAverageForIndex(weekAverage);
      indexData.push({
        y: index.toFixed(2),
        x: sunday ? `${weekNum}` : xText,
        length: weekAverage.length,
      });
      weekNum += 1;
      weekAverage = [...dayIndices];
    } else {
      weekAverage = [...weekAverage, ...dayIndices];
    }
    if (weekNum > MAX_WEEKS_VISIBLE_IN_GRAPH) {
      break;
    }
  }
  return indexData;
};

export const assessmentIndicesData = (data, startDate, endDate) => {
  const finalStartDate = (startDate > new Date() ? new Date() : new Date(startDate)).toISOString().split('T')[0];
  const finalEndDate = nextSunday(endDate > new Date() ? new Date() : new Date(endDate))
    .toISOString()
    .split('T')[0];
  const createdAtData = data.reduce((acc, x) => {
    const date = x.createdAt.split('T')[0];
    if (acc[date]) {
      acc[date] = [...acc[date], x];
    } else {
      acc[date] = [x];
    }
    return acc;
  }, {});
  let indexData: any[] = [];
  let weekNum = 1;
  let weekAverage: any[] = [];
  for (var d = new Date(finalStartDate); d <= new Date(finalEndDate); d.setDate(d.getDate() + 1)) {
    const formattedDate = moment(d).format('YYYY-MM-DD');
    const sunday = isSunday(formattedDate);
    const dayIndices = createdAtData[formattedDate] || [];
    if (sunday) {
      const index = getAssessmentAverageForIndex(weekAverage);
      indexData.push({
        y: index.toFixed(2),
        x: weekNum,
        length: weekAverage.length,
      });
      weekNum += 1;
      weekAverage = [...dayIndices];
    } else {
      weekAverage = [...weekAverage, ...dayIndices];
    }
    if (weekNum > MAX_WEEKS_VISIBLE_IN_GRAPH) {
      break;
    }
  }
  return indexData;
};

export const materialIndicesData = (data, startDate, endDate) => {
  const finalStartDate = (startDate > new Date() ? new Date() : new Date(startDate)).toISOString().split('T')[0];
  const finalEndDate = nextSunday(endDate > new Date() ? new Date() : new Date(endDate))
    .toISOString()
    .split('T')[0];
  const createdAtData = data.reduce((acc, x) => {
    const { week } = x;
    if (acc[week]) {
      acc[week] = [...acc[week], x];
    } else {
      acc[week] = [x];
    }
    return acc;
  }, {});
  let indexData: any[] = [];
  let weekNum = 1;
  for (var d = new Date(finalStartDate); d <= new Date(finalEndDate); d.setDate(d.getDate() + 1)) {
    const formattedDate = moment(d).format('YYYY-MM-DD');
    const sunday = isSunday(formattedDate);
    if (sunday) {
      const weekAverage = createdAtData[weekNum] || []
      const index = getAverageForIndex(weekAverage);
      indexData.push({
        y: index.toFixed(2),
        x: weekNum,
        length: weekAverage.length,
      });
      weekNum += 1;
    }
    if (weekNum > MAX_WEEKS_VISIBLE_IN_GRAPH) {
      break;
    }
  }
  return indexData;
};

export const legacyIndicesData = (data, startDate, endDate) => {
  const finalStartDate = (startDate > new Date() ? new Date() : new Date(startDate)).toISOString().split('T')[0];
  const finalEndDate = nextSunday(endDate > new Date() ? new Date() : new Date(endDate))
    .toISOString()
    .split('T')[0];
  let indexData: any[] = [];
  let weekNum = 1;
  const index = getAverageForIndex(data);
  for (var d = new Date(finalStartDate); d <= new Date(finalEndDate); d.setDate(d.getDate() + 1)) {
    const formattedDate = moment(d).format('YYYY-MM-DD');
    const sunday = isSunday(formattedDate);
    if (sunday) {
      indexData.push({
        y: index.toFixed(2),
        x: weekNum,
        length: data.length,
      });
      weekNum += 1;
    }
    if (weekNum > MAX_WEEKS_VISIBLE_IN_GRAPH) {
      break;
    }
  }
  return indexData;
};

export const weekWiseData = (data: OnlineAttendance[], startDate, physicalAttendance: PhysicalAttendance[]) => {
  const weekWiseAttendance = {};
  const lecWeekWiseAttendance = {};
  const tutWeekWiseAttendance = {};
  const wksWeekWiseAttendance = {};
  const labWeekWiseAttendance = {};
  const supWeekWiseAttendance = {};
  physicalAttendance.forEach((x) => {
    const { event, timestamp } = x;
    const { startDateTime = new Date(), teamName = '' } = event;
    const week = Math.ceil(
      (new Date(startDateTime).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24 * 7)
    );
    if (weekWiseAttendance[week]) {
      const { activityIndex: prevIndex, count: prevCount } = weekWiseAttendance[week];
      weekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
    } else {
      weekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
    }

    if (teamName.includes(attendanceTypes.LEC)) {
      if (lecWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = lecWeekWiseAttendance[week];
        lecWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        lecWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.LAB)) {
      if (labWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = labWeekWiseAttendance[week];
        labWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        labWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.TUT)) {
      if (tutWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = tutWeekWiseAttendance[week];
        tutWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        tutWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.WKS)) {
      if (wksWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = wksWeekWiseAttendance[week];
        wksWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        wksWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.LAB)) {
      if (labWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = labWeekWiseAttendance[week];
        labWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        labWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.SUP)) {
      if (supWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = supWeekWiseAttendance[week];
        supWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 + prevIndex : prevIndex, count: prevCount + 1 };
      } else {
        supWeekWiseAttendance[week] = { activityIndex: timestamp ? 1 : 0, count: 1 };
      }
    }
  });
  data.forEach((x) => {
    const { event, activityIndex } = x;
    const { startDateTime = new Date(), teamName = '' } = event;
    const week = Math.ceil(
      (new Date(startDateTime).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24 * 7)
    );
    if (weekWiseAttendance[week]) {
      const { activityIndex: prevIndex, count: prevCount } = weekWiseAttendance[week];
      weekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
    } else {
      weekWiseAttendance[week] = { activityIndex, count: 1 };
    }

    if (teamName.includes(attendanceTypes.LEC)) {
      if (lecWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = lecWeekWiseAttendance[week];
        lecWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        lecWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.LAB)) {
      if (labWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = labWeekWiseAttendance[week];
        labWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        labWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.TUT)) {
      if (tutWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = tutWeekWiseAttendance[week];
        tutWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        tutWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.WKS)) {
      if (wksWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = wksWeekWiseAttendance[week];
        wksWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        wksWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.LAB)) {
      if (labWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = labWeekWiseAttendance[week];
        labWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        labWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
    if (teamName.includes(attendanceTypes.SUP)) {
      if (supWeekWiseAttendance[week]) {
        const { activityIndex: prevIndex, count: prevCount } = supWeekWiseAttendance[week];
        supWeekWiseAttendance[week] = { activityIndex: activityIndex + prevIndex, count: prevCount + 1 };
      } else {
        supWeekWiseAttendance[week] = { activityIndex, count: 1 };
      }
    }
  });
  return {
    weekWiseAttendance,
    lecWeekWiseAttendance,
    tutWeekWiseAttendance,
    wksWeekWiseAttendance,
    supWeekWiseAttendance,
    labWeekWiseAttendance,
  };
};

export const attendanceSplit = (data: StudentIndex[]) => {
  const mandatoryAttendance: StudentIndex[] = [];
  const nonMandatoryAttendance: StudentIndex[] = [];
  const employabilityAttendance: StudentIndex[] = [];
  data.forEach((x) => {
    const { sessionType = '' } = x;
    if (Object.keys(mandatory).includes(sessionType)) {
      mandatoryAttendance.push(x);
    } else if (Object.keys(nonMandatory).includes(sessionType)) {
      nonMandatoryAttendance.push(x);
    } else if (Object.keys(employability).includes(sessionType)) {
      employabilityAttendance.push(x);
    }
  });
  return { mandatoryAttendance, nonMandatoryAttendance, employabilityAttendance };
};
export const getSortOrder = (prop) => {
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const removeElementFromArr = (arr, ele) => {
  var index = arr.indexOf(ele);
  arr.splice(index, 1);
  return arr;
};

export const dateFormat = (str: string) => {
  return (str || '').replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
};

export const statusChangeForAssignment = (
  oldData: AssignmentDueDate,
  newWeek: string,
  newDate: string,
  newTime: string,
  newSubmissionType: string,
  newWeightage: string
) => {
  const { dueWeek = '', dueDate = '', time = '', submissionType = onTrack.YES, taskWeightage } = oldData;
  let statusChange = '';
  if (newWeek !== dueWeek) {
    statusChange += `Due Week Changed from ${dueWeek} to ${newWeek}.`;
  }
  if (moment(dateFormat(dueDate)).format('YYYY-MM-DD') !== moment(dateFormat(newDate)).format('YYYY-MM-DD')) {
    statusChange += ` Due Date changed from ${moment(dateFormat(dueDate)).format('DD/MM/YYYY')} to  ${moment(
      dateFormat(newDate)
    ).format('DD/MM/YYYY')}.`;
  }
  if (newTime !== time) {
    statusChange += ` Time changed from ${time} to ${newTime}.`;
  }
  if (newSubmissionType.length !== submissionType.length) {
    statusChange += ` Submission Type changed from ${submissionType} to ${newSubmissionType}.`;
  }
  if ((parseFloat(newWeightage) || 0) !== (parseFloat(taskWeightage) || 0)) {
    statusChange += ` Task Weightage changed from ${taskWeightage} to ${newWeightage}.`;
  }
  return statusChange;
};
export const convertActionLink = (link) => {
  if (link.includes('http')) {
    return link.split(':')[1];
  } else {
    return '//' + link;
  }
};

export const distinctValues = (arr: any[]) => {
  return arr.filter((v, i, a) => a.indexOf(v) === i);
};

export const isASubset = (arr1, arr2) => {
  return arr1.every(function (value) {
    return arr2.indexOf(value) >= 0;
  });
};

export const removeLocalStorage = () => {
  localStorage.removeItem(storageCodes.USER_ROLE);
  localStorage.removeItem(storageCodes.BU_CODES);
  localStorage.removeItem(storageCodes.BU_CODE_SELECTED);
  localStorage.removeItem(storageCodes.SEMESTER_SELECTED);
  localStorage.removeItem(storageCodes.PACE_API);
  localStorage.removeItem(storageCodes.CAPRI_API);
  localStorage.removeItem(KEY_BU_CODE);
  localStorage.removeItem(KEY_SEMESTER);
};
