import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Page from '../../../../components/PageV2';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { QRCode } from 'react-qrcode-logo';
import { theme } from '../../../../theme';
import PrintIcon from '@material-ui/icons/Print';
import { getDetails, resetEventCodeState } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  color: gray;
  font-weight: bolder;
  font-size: 2em;
  margin: ${theme.spacing(2)}px 0;
`;

const Instructions = styled.span`
  color: gray;
  font-style: italic;
  font-weight: bold;
  font-size: 1.5em;
  margin: ${theme.spacing(2)}px 0;
`;

const PrintButton = styled(Button)`
  position: absolute !important;
  top: 80px;
  right: 20px;
  @media print {
    visibility: hidden;
  }
`;

const GoBackButton = styled(Button)`
  position: absolute !important;
  top: 80px;
  left: 10px;
  @media print {
    visibility: hidden;
  }
`;

export default function AttendanceCode() {
  const dispatch = useDispatch();
  const code = useSelector((state: RootState) => state.events?.code);

  const [title, setTitle] = useState('Loading...');
  const [codeId, setCodeId] = useState<string | undefined>(undefined);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const goBack = () => {
    dispatch(resetEventCodeState());
    history.push('/events');
  };

  useEffect(() => {
    if (id) {
      dispatch(getDetails(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (code && code.eventSubject) {
      setTitle(code.eventSubject);
      setCodeId(code.id);
    }
  }, [code]);

  let qrSize = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
  qrSize -= qrSize * 0.6;

  return (
    <Page header="Mark Attendance">
      <GoBackButton variant="contained" color="primary" startIcon={<KeyboardBackspaceIcon />} onClick={() => goBack()}>
        Go Back
      </GoBackButton>
      <PrintButton color="primary" variant="contained" onClick={window.print} startIcon={<PrintIcon />}>
        Print
      </PrintButton>
      <Container>
        <Title>{title}</Title>
        {codeId && (
          <>
            <Instructions>Scan code below</Instructions>
            <QRCode
              value={codeId}
              size={qrSize}
              logoImage="/images/logos/edfibre-compact-transparent.png"
              logoHeight={80}
              logoWidth={80}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
