import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, Select, MenuItem, Button } from '@material-ui/core';
import { RoleType } from '../../dto/role';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { setNewRole } from '../../actions';

const StyleActions = styled(ListItemSecondaryAction)`
  > * {
    margin: 0 10px;
  }
`;

export default function RoleItem({ role }) {
  const [currentRole, setCurrentRole] = useState<RoleType>(role.roleType);
  const [desiredRole, setDesiredRole] = useState<RoleType>(role.roleType);
  const valueChanged = (newValue: RoleType) => {
    setDesiredRole(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(role)) {
      setCurrentRole(role.roleType);
      setDesiredRole(role.roleType);
    }
  }, [role]);
  const changeRole = async () => {
    dispatch(setNewRole({ email: role.email, roleType: desiredRole }));
  };
  return (
    <ListItem>
      <ListItemText primary={role.email} secondary={'Role: ' + currentRole}></ListItemText>
      <StyleActions>
        <Select value={desiredRole} onChange={(event) => valueChanged(event.target.value as RoleType)}>
          <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
          <MenuItem value={'LECTURER'}>LECTURER</MenuItem>
          <MenuItem value={'STUDENT'}>STUDENT</MenuItem>
        </Select>
        <Button variant="contained" color="primary" disabled={currentRole === desiredRole} onClick={() => changeRole()}>
          Update
        </Button>
      </StyleActions>
    </ListItem>
  );
}
