import React from 'react';
import { AssignmentLate, AssignmentTurnedIn, Assistant, BookmarkBorder, FindInPage, LinkSharp, Poll, TextFields } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { InterventionFrameworkColorCodes } from '../../util/constants';
import { INTERVENTION_ACTIONS } from '../../util/types';


const InterventionSidebar =  ({
  onAdd, onLayout}) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const colors = {
    conditionsSection : InterventionFrameworkColorCodes.conditions,
    actionsSection : InterventionFrameworkColorCodes.actions
  }

  const StyledDiv =  styled.div`
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 7px;
    position: relative;
    width: 100%;
    margin-bottom: 21px;
    `

    const SideBarItem = ({icon,title, type, textColor})=>{
    return (  <div className="dndnode input" onDragStart={(event) => onDragStart(event, type)} draggable>
      <span style={{float:'left', fill: '#70a3aa'}}>{icon}</span><span style={{color:textColor,}}>{title}</span>
    </div>)
    }
  

  return (
    <aside>
       <PerfectScrollbar>
      <div className="description">You can drag these nodes to the pane on the right.</div>
     
      <div>
        <StyledDiv style={{background:colors.conditionsSection}}  >
          Conditions
        </StyledDiv>
        
        <SideBarItem textColor={colors.conditionsSection} type={'gradeCheck'} icon={<AssignmentTurnedIn style={{fill:colors.conditionsSection}}/>} title={'Grades'} />
        <SideBarItem textColor={colors.conditionsSection} type={'indices'} icon={<Poll style={{fill:colors.conditionsSection}}/>} title={'Attendance'} />
        {/* <SideBarItem textColor={colors.conditionsSection} type={'nonSubmission'} icon={<AssignmentLate style={{fill:colors.conditionsSection}}/>} title={'Non-submission'} />
        <SideBarItem textColor={colors.conditionsSection} type={'plagiarism'} icon={<FindInPage style={{fill:colors.conditionsSection}}/>} title={'Plagiarism'} /> */}
        


     <br/><br/>
        </div>

        <div >
        <StyledDiv style={{background:colors.actionsSection}}  >
          Actions
        </StyledDiv>
         
       
         <SideBarItem type={INTERVENTION_ACTIONS.BOOKCONSULTATION} title="Book Consultation" textColor={colors.actionsSection}
                  icon={<BookmarkBorder style={{fill:colors.actionsSection}} />} />
                  
 <SideBarItem type={INTERVENTION_ACTIONS.ATTENDSESSION} title="Attend Session" textColor={colors.actionsSection}
                  icon={<Assistant style={{fill:colors.actionsSection}} />} />
                  
 <SideBarItem type={INTERVENTION_ACTIONS.REFERSTUDYMATERIAL} title="Attach Links" textColor={colors.actionsSection}
                  icon={<LinkSharp style={{fill:colors.actionsSection}} />} />
                  
 <SideBarItem type={INTERVENTION_ACTIONS.FREETEXT} title="Free Text" textColor={colors.actionsSection}
                  icon={<TextFields style={{fill:colors.actionsSection}} />} />
                  
    
        </div>
       
      
    
      {/* <div><Button  size="medium" startIcon={<Build />} style={{width: '85%'}} variant="contained" color="primary" >Create</Button>
        </div> */}
        </PerfectScrollbar>
    </aside>
  );
};

export default InterventionSidebar;