export const getAverage = (data: number[]) => {
  const sum = data.reduce((a, b) => a + b, 0);
  const avg = sum / data.length || 0;
  return avg;
};

export const getAverageForIndex = (data: any[]): number => {
  const indexSum = data.reduce((acc, b) => {
    return parseFloat(b.index || 0) + acc;
  }, 0);
  return data.length === 0 ? 0 : indexSum / data.length;
};
export const getAssessmentAverageForIndex = (data: any[]): number => {
  const indexSum = data.reduce((acc, b) => {
    if (b.index >= 50) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return data.length === 0 ? 0 : (indexSum * 100) / data.length;
};
export const getSum = (data: any[]): string => {
  var total = 0;
  data.forEach((d) => {
    total += parseFloat(d);
  });
  return total.toFixed(2);
};

export const sortGraphDataByDate = (data) => {
  data = data.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() <= dateB.getTime() ? -1 : 1;
  });
  return data;
};

export const sortEvents = (data: any) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.event.startDateTime);
    const dateB = new Date(b.event.startDateTime);
    return dateA.getTime() <= dateB.getTime() ? -1 : 1;
  });
};
export const getRandomForRange = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const chooseRandom = (arr: string[], num) => {
  let res: string[] = [];
  for (let i = 0; i < num; ) {
    const random: number = Math.floor(Math.random() * arr.length);
    if (res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    i++;
  }
  return res;
};
