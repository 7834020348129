import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';

export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const ON_LOADING_START = "ON_LOADING_START";
export const ON_LOADING_FINISHED = "ON_LOADING_FINISHED";

export interface LoggedInUserData {
  userId: string
}

export interface LoginPayload {

}


export const onUserLoggedIn = (loggedInUserData: any): ThunkAction<void, RootState, unknown, ActionType> => dispatch => {
  dispatch({ type: ON_LOADING_START });
  try {
    dispatch({
      type: SESSION_LOGIN,
      payload: loggedInUserData,
    });
  } catch (error) {
    console.log("Errror in loadingg user:", error);
  }
  dispatch({ type: ON_LOADING_FINISHED });
}
