import { ActionType } from './types';
import * as actionTypes from '../actions/improvementArea';

const initialState = {
  data: [],
  loader: false,
  response: null,
};

const ImprovementArea = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_IMPROVEMENT_AREA_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case actionTypes.GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS: {
      return {
        ...state,
        response: action.payload,
      };
    }

    case actionTypes.ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default ImprovementArea;
