
import { ActionType } from './types'
import * as actionTypes from "../actions";
import { Cam, CamEvent } from "../util/types"

const initialState = {
  cam: {}
};

const cam = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_CAM_BY_STUDENT_ID: {
      const { data, id } = action.payload
      const newCam = JSON.parse(JSON.stringify(state.cam))
      newCam[id] = data;
      return {
        ...state,
        cam: newCam
      };
    }

    case actionTypes.CREATE_CAM:
    case actionTypes.EDIT_CAM: {
      const { studentId = "", unitCode = "", eventId = "" } = action.payload
      const cam = JSON.parse(JSON.stringify(state.cam))
      if (unitCode) {
        const studentCam = cam[studentId] || []
        const unitData = studentCam.find((x: Cam) => x.unitCode === unitCode) || []
        const { events = [] } = unitData
        const newEvents = events.map((x: CamEvent) => x.eventId === eventId ? { ...x, ...action.payload } : x)
        const newCam = studentCam.map((x: Cam) => x.unitCode === unitCode ? { unitCode, events: newEvents } : x)
        cam[studentId] = newCam
      }
      return {
        ...state,
        cam: cam
      };
    }

    default: {
      return state;
    }
  }
};

export default cam;
