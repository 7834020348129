import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { STUDENT_URL } from "../util/constants"
import { get, post, put } from './common';

export const GET_CAM_BY_STUDENT_ID = "GET_CAM_BY_STUDENT_ID";
export const EDIT_CAM = "EDIT_CAM";
export const CREATE_CAM = "CREATE_CAM";

export const getCamByStudentId = (studentId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/cam`;
    dispatch({ type: ON_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_CAM_BY_STUDENT_ID, payload: { data: result.data, id: studentId } });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_CAM_BY_STUDENT_ID,
      payload: { data: [], id: studentId }
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const editCam = (studentId: string, comment: string, satisfactory: boolean, id: number): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/cam/${id}`;
    dispatch({ type: ON_LOADING_START });
    const result = await put(url, { id, comment, satisfactory });
    dispatch({ type: EDIT_CAM, payload: result.data });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: EDIT_CAM,
      payload: {}
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const createCam = (unitCode: string, studentId: string, eventId: string, comment: string, satisfactory: boolean): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/cam`;
    dispatch({ type: ON_LOADING_START });
    const result = await post(url, { unitCode, studentId, eventId, comment, satisfactory });
    dispatch({ type: CREATE_CAM, payload: result.data });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: CREATE_CAM,
      payload: {}
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};