import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, TextField, Typography, Theme, MenuItem } from '@material-ui/core';
import {
  plagiarismOptions,
  specialConsiderationOptions,
  submissionOptions,
  teamTypeOptions,
} from '../../../util/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(0, 3),
      flexGrow: 1,
    },
    contentSection: {
      padding: theme.spacing(2, 0),
    },
    contentSectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    contentSectionContent: {},
    formGroup: {
      padding: theme.spacing(2, 0),
    },
    fieldGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      marginTop: 0,
      marginBottom: 0,
    },
    flexGrow: {
      flexGrow: 1,
    },
    addButton: {
      marginLeft: theme.spacing(1),
    },
    tags: {
      marginTop: theme.spacing(1),
    },
    minAmount: {
      marginRight: theme.spacing(3),
    },
    maxAmount: {
      marginLeft: theme.spacing(3),
    },
    radioGroup: {},
    actions: {
      padding: theme.spacing(3),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

type AssignmentFiltersProps = {
  className?: string;
  values: any;
  setValues: (state: any) => void;
};

const AssignmentFilters: React.FC<AssignmentFiltersProps> = ({ values, setValues }) => {
  const classes = useStyles();

  const handleFieldChange = (event: any, field: any, value: any) => {
    if (event) {
      event.persist();
    }

    setValues((prevValues: any) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentSection}>
        <div className={classes.contentSectionHeader}>
          <Typography variant="h5">Assignment Filters</Typography>
        </div>
        <Divider />
        <div className={classes.contentSectionContent}>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Submission"
              margin="dense"
              name="submission"
              select
              SelectProps={{
                multiple: false,
                value: values.submission,
                onChange: (event) => handleFieldChange(event, 'submission', event.target.value),
              }}
              variant="outlined"
            >
              {submissionOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Academic Misconduct"
              margin="dense"
              name="academicMisconduct"
              select
              SelectProps={{
                multiple: false,
                value: values.academicMisconduct,
                onChange: (event) => handleFieldChange(event, 'academicMisconduct', event.target.value),
              }}
              variant="outlined"
            >
              {plagiarismOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Special Consideration"
              margin="dense"
              name="specialConsideration"
              select
              SelectProps={{
                multiple: false,
                value: values.specialConsideration,
                onChange: (event) => handleFieldChange(event, 'specialConsideration', event.target.value),
              }}
              variant="outlined"
            >
              {specialConsiderationOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentFilters;
