import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardHeader, colors, Divider } from '@material-ui/core';
import Label from '../../../components/Label';
import { StudentType } from '../../../reducer/types';
import Loader from '../../../components/Loader';
import { tabs } from '../../../util/constants';
import { CustomTable, StyledContent, TotalCount } from '../../../components/Common';

const paymentStatusColors: any = {
  canceled: colors.grey[600],
  pending: colors.orange[600],
  Studying: colors.green[600],
  rejected: colors.red[600],
};
type ResultsProps = {
  className?: string;
  students: StudentType[];
  setPage: (page: number) => void;
  setRowsPerPage: (rowPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  count: number;
  loader: boolean;
};
const Result: React.FC<ResultsProps> = ({
  className,
  students = [],
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loader,
  ...rest
}) => {
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  const decoratedTableData = students.map((student: StudentType) => {
    const { providerStudentID, familyName, firstName, nationality, courseName, coeStatus } = student;
    return [
      providerStudentID,
      `${firstName} ${familyName}`,
      nationality,
      courseName,
      <Button
        color="primary"
        component={RouterLink}
        size="small"
        to={`/student/${providerStudentID}/report-card/${tabs[0].value}`}
        variant="outlined"
      >
        View
      </Button>,
    ];
  });

  return (
    <>
      <TotalCount color="textSecondary" variant="body2">
        {count} Records found. Page {page + 1} of {Math.ceil(count / rowsPerPage)}
      </TotalCount>
      <Card>
        <CardHeader title="Search Results" />
        <Divider />
        <StyledContent>
          {students && loader ? (
            <CustomTable
              tableHead={[
                { text: 'Provider Id' },
                { text: 'Name' },
                {
                  text: 'Nationality',
                },
                { text: 'Course' },
                { text: 'Actions' },
              ]}
              tableData={decoratedTableData}
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <Loader />
          )}
        </StyledContent>
      </Card>
    </>
  );
};

export default Result;
