import React, { useState, useEffect } from 'react';
import { Card, Button, TextField, Grid, CardContent, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editClosure } from '../../actions';
import CustomModal from '../CustomModal';
import { onTrack as onTrackEnums } from '../../util/enums';
import { Edit } from '@material-ui/icons';

type editClosureProps = {
  id: number;
  closed: boolean;
};

const EditClosure: React.FC<editClosureProps> = ({ id, closed: oldClosed }) => {
  const dispatch = useDispatch();

  const [comments, setComments] = useState('');
  const [closed, setClosed] = useState('');
  const [open, setModalOpen] = useState(false);

  useEffect(() => {
    setDefault(oldClosed);
  }, [oldClosed]);

  const setDefault = (localClosed: boolean) => {
    setClosed(localClosed ? onTrackEnums.YES : onTrackEnums.NO);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDefault(oldClosed);
    setComments('');
  };

  const onEditClick = () => {
    dispatch(editClosure(id, comments, closed === onTrackEnums.YES));
    handleModalClose();
  };

  const renderModal = () => {
    return (
      <CustomModal
        disabled={!comments || !closed}
        open={open}
        onClose={handleModalClose}
        buttonText="Save"
        headerText="Edit Comment"
        onSuccess={onEditClick}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Closed"
                  name="closed"
                  select
                  SelectProps={{
                    value: closed,
                  }}
                  value={closed}
                  variant="outlined"
                  onChange={(e) => setClosed(e.target.value)}
                >
                  {Object.keys(onTrackEnums).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Comment"
                  variant="outlined"
                  name="comment"
                  value={comments}
                  rows={6}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CustomModal>
    );
  };

  return (
    <>
      <Button startIcon={<Edit />} size="small" color="primary" onClick={() => setModalOpen(true)} variant="outlined">
        Edit
      </Button>
      {renderModal()}
    </>
  );
};

export default EditClosure;
