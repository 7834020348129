import { ActionType } from './types';
import * as actionTypes from '../actions';
import {
  GET_UNIT_ASSESSMENT_INDEX,
  GET_UNIT_ENGAGEMENT_INDEX,
  GET_UNIT_INFO_LOADING_START,
  GET_UNIT_INFO_LOADING_START_FINISHED,
  GET_UNIT_MATERIAL_INDEX,
} from '../actionTypes';

const initialState = {
  unitData: {},
  graphData: [],
  studentData: null,
  loader: true,
  assignmentData: null,
  fileLoadPercentage: 0,
  assignmentUploadResponse: '',
  studentGroupsUploadResponse: '',
  turnitinMappingResponse: '',
  errorResponse: {},
  assignmentSelected: {},
  reportIdInfo: null,
  reportData: null,
  reportLoader: false,
  clearDbResponse: false,
  allUnits: [],
  unitResult: {},
  searchText: '',
  unitAssessmentIndex: {},
  unitEngagementIndex: {},
  unitMaterialIndex: {},
  unitInfoLoader: false,
};

const units = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_BY_UNITID: {
      return {
        ...state,
        studentData: action.payload,
        loader: false,
      };
    }
    case actionTypes.GET_UNIT_BY_ID: {
      return {
        ...state,
        unitData: action.payload[0],
      };
    }
    case actionTypes.GET_UNIT_GRAPH_DATA: {
      return {
        ...state,
        graphData: action.payload,
      };
    }
    case actionTypes.GET_ASSIGNMENT_DATA: {
      return {
        ...state,
        assignmentData: action.payload,
      };
    }
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_UNIT_INFO_LOADING_START: {
      return {
        ...state,
        unitInfoLoader: true,
      };
    }
    case GET_UNIT_INFO_LOADING_START_FINISHED: {
      return {
        ...state,
        unitInfoLoader: false,
      };
    }
    case actionTypes.SET_FILE_ASSIGNMENT_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.UPLOAD_SUBMISSION_SUCCESS: {
      return {
        ...state,
        assignmentUploadResponse: action.payload,
        error: false,
      };
    }
    case actionTypes.UPLOAD_SUBMISSION_ERRORS: {
      return {
        ...state,
        errorResponse: action.payload,
        error: true,
      };
    }
    case actionTypes.RESET_UPLOAD_ASSIGNMENT_DATA: {
      return {
        ...state,
        assignmentUploadResponse: '',
      };
    }
    case actionTypes.SET_FILE_ASSIGNMENT_RESULT_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.UPLOAD_ASSIGNMENT_RESULT_SUCCESS: {
      return {
        ...state,
        assignmentUploadResponse: action.payload,
        error: false,
      };
    }
    case actionTypes.UPLOAD_ASSIGNMENT_RESULT_ERRORS: {
      return {
        ...state,
        errorResponse: action.payload,
        error: true,
      };
    }
    case actionTypes.RESET_UPLOAD_ASSIGNMENT_RESULT_DATA: {
      return {
        ...state,
        assignmentUploadResponse: '',
      };
    }
    case actionTypes.SET_FILE_STUDENT_GROUPS_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.UPLOAD_STUDENT_GROUPS_SUCCESS: {
      return {
        ...state,
        studentGroupsUploadResponse: action.payload,
        error: false,
      };
    }
    case actionTypes.UPLOAD_STUDENT_GROUPS_ERRORS: {
      return {
        ...state,
        errorResponse: action.payload,
        error: true,
      };
    }
    case actionTypes.RESET_UPLOAD_STUDENT_GROUPS_DATA: {
      return {
        ...state,
        studentGroupsUploadResponse: '',
      };
    }
    case actionTypes.SET_FILE_TURNITIN_MAPPING_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.UPLOAD_TURNITIN_MAPPING_SUCCESS: {
      return {
        ...state,
        turnitinMappingResponse: action.payload,
        error: false,
      };
    }
    case actionTypes.UPLOAD_TURNITIN_MAPPING_ERRORS: {
      return {
        ...state,
        errorResponse: action.payload,
        error: true,
      };
    }
    case actionTypes.RESET_UPLOAD_TURNITIN_MAPPING_DATA: {
      return {
        ...state,
        turnitinMappingResponse: '',
      };
    }
    case actionTypes.ON_ASSIGNMENT_SELECTED: {
      return {
        ...state,
        assignmentSelected: action.payload,
      };
    }
    case actionTypes.GET_UNIT_REPORTS: {
      return {
        ...state,
        reportIdInfo: action.payload,
        reportLoader: false,
      };
    }
    case actionTypes.Find_UNIT_REPORTS: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case actionTypes.ON_REPORT_LOADING_START: {
      return {
        ...state,
        reportLoader: true,
      };
    }
    case actionTypes.ON_REPORT_LOADING_FINISHED: {
      return {
        ...state,
        reportLoader: false,
      };
    }
    case actionTypes.GET_CLEAR_DB_RESPONSE: {
      return {
        ...state,
        clearDbResponse: true,
      };
    }
    case actionTypes.RESET_CLEAR_DB_RESPONSE: {
      return {
        ...state,
        clearDbResponse: false,
      };
    }
    // case actionTypes.UPDATE_ASSIGNMENT: {
    //   return {
    //     ...state,
    //     assignmentSelected:action.payload
    //   };
    // }
    case actionTypes.ON_UNITS_RECEIVED: {
      return {
        ...state,
        unitResult: action.payload,
      };
    }
    case actionTypes.ON_UNIT_SEARCH_TEXT_CHANGED: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    case actionTypes.ON_ALL_UNITS_RECEIVED: {
      return {
        ...state,
        unitResult: action.payload,
      };
    }

    case GET_UNIT_ASSESSMENT_INDEX: {
      const { unitCode, data } = action.payload;
      const newData = JSON.parse(JSON.stringify(state.unitAssessmentIndex));
      newData[unitCode] = data;
      return {
        ...state,
        unitAssessmentIndex: newData,
      };
    }
    case GET_UNIT_ENGAGEMENT_INDEX: {
      const { unitCode, data } = action.payload;
      const newData = JSON.parse(JSON.stringify(state.unitEngagementIndex));
      newData[unitCode] = data;
      return {
        ...state,
        unitEngagementIndex: newData,
      };
    }
    case GET_UNIT_MATERIAL_INDEX: {
      const { unitCode, data } = action.payload;
      const newData = JSON.parse(JSON.stringify(state.unitMaterialIndex));
      newData[unitCode] = data;
      return {
        ...state,
        unitMaterialIndex: newData,
      };
    }

    default: {
      return state;
    }
  }
};

export default units;
