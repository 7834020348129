import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ResponsiveTable } from './styles';
import { individualTableHead } from '../../../util/types';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

type TypeOfProps = {
  tableHead: individualTableHead[];
  tableData: React.ReactNode[][];
  count?: number;
  onChangePage?: (arg: number) => void;
  page?: number;
  rowsPerPage?: number;
  onChangeRowsPerPage?: (arg: number) => void;
  stickyHeader?: boolean;
  noPagination?: boolean;
};

export const CustomTable: React.FC<TypeOfProps> = (props) => {
  const {
    tableHead = [],
    tableData = [],
    count = 0,
    onChangePage = () => {},
    page = 1,
    rowsPerPage = 10,
    onChangeRowsPerPage = () => {},
    stickyHeader = false,
    noPagination = false,
  } = props;

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(event.target.value);
  };
  return (
    <ResponsiveTable>
      <Table stickyHeader={stickyHeader} size="small">
        <TableHead>
          <TableRow>
            {tableHead.map((prop) => {
              const { text = '', displaySort = false, onSortClick = () => {}, showUpArrow = true } = prop;
              return (
                <TableCell key={text}>
                  {text}
                  {displaySort && (
                    <IconButton onClick={onSortClick}>
                      {showUpArrow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((data, i) => {
            return (
              <TableRow key={i}>
                {data.map((prop, index) => {
                  return <TableCell key={index}>{prop}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {!noPagination && (
          <TableFooter>
            <TablePagination
              count={count}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        )}
      </Table>
    </ResponsiveTable>
  );
};
