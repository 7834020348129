import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import CustomModal from '../CustomModal';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { BuildSharp, FindInPage } from '@material-ui/icons';
import { RootState } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getInterventionPreview } from '../../actions';
import Loader from '../Loader';
import { UtilityBarItems } from '../../views/intervention/intervention_styles';

type ShowInterventionPreviewProps = {
  id: number;
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));

const CustomDiv = styled.div`
  body {
    overflow: unset !important;
  }
`;

const ShowInterventionPreview: React.FC<ShowInterventionPreviewProps> = ({ id, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setModalOpen] = useState(false);
  const interventionPreviewLoader = useSelector((state: RootState) => state.intervention?.interventionPreviewLoader);
  const interventionPreviewData = useSelector((state: RootState) => state.intervention?.interventionPreviewData);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    // dispatch({
    //     type: ON_INTERVENTION_CREATION_ERROR_RESPONSE,
    //     payload: null,
    //   });
    dispatch(getInterventionPreview(id));
    setModalOpen(true);
  };
  

  const renderModal = () => {
    return (
      <CustomModal
        maxWidth={'md'}
        open={open}
        onClose={handleModalClose}
        headerText={'Preview of Intervention: ' + interventionPreviewData?.label}
      >
     {!interventionPreviewLoader ?   <CustomDiv> {ReactHtmlParser(interventionPreviewData?.preview)}</CustomDiv> : <Loader />}
      </CustomModal>
    );
  };

  return (
    <>
     <UtilityBarItems color="primary" label="Preview" onClick={handleModalOpen} disabled={disabled} icon={<FindInPage />} />
      {renderModal()}
    </>
  );
};

export default ShowInterventionPreview;
