import { Button, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCourseInfoById, getCourseUnitesById } from '../../actions';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import SearchBar from '../../components/search-bar';
import { UnitInfo } from '../../models/instituion-level';
import { RootState } from '../../reducer';
import './courses.scss';
import * as Layout from '../dashboard-commons/Layout';

export default function Course() {
  let { courseId } = useParams<{ courseId: string }>();
  const [tableData, setTableData] = useState<UnitInfo[] | undefined>([]);

  const dispatch = useDispatch();
  const courseInfo = useSelector((state: RootState) => state.courses?.courseInfo);
  const units = useSelector((state: RootState) => state.courses?.units);
  const loader = useSelector((state: RootState) => state.courses?.loader);
  useEffect(() => {
    dispatch(getCourseInfoById(courseId));
    dispatch(getCourseUnitesById(courseId));
  }, [dispatch, courseId]);

  useEffect(() => {
    setTableData(units);
  }, [units]);

  const onSearch = (searchText: string) => {
    let filtered_units: UnitInfo[] = [];
    if (units) {
      filtered_units = units.filter(
        (x) =>
          x.unitName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
          x.unitCode.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }
    setTableData(filtered_units);
  };

  const header = (!_.isEmpty(courseInfo) && courseInfo.courseCode + ' : ' + courseInfo.courseName) || '';
  const subHeader = (courseInfo?.discipline && 'Discipline: ' + courseInfo.discipline) || '';
  const title = 'Course Dashboard | ' + header;

  return (
    <Page header={header} alternateTitle={title} subHeader={subHeader} showBackButton>
      {/* <Layout.GraphContainer>{courseGraphData && <LineGraph data={courseGraphData} />}</Layout.GraphContainer> */}
      <Layout.Title>Units</Layout.Title>
      <Divider variant="fullWidth"></Divider>
      <Layout.SearchContainer>
        <SearchBar onSearch={onSearch} searchPlaceHolder="Search Units" />
      </Layout.SearchContainer>
      {tableData && loader && <Layout.DataCount>{tableData.length} Records found.</Layout.DataCount>}
      <Layout.TableContainer>
        {tableData && loader ? (
          <Layout.ScrollBar>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Unit Code</TableCell>
                  <TableCell align="center">Unit Name</TableCell>
                  <TableCell align="center">Academic Performance Index</TableCell>
                  <TableCell align="center">Engagement Index</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((unit: UnitInfo) => {
                  return (
                    <TableRow key={unit.unitCode}>
                      <TableCell align="center">{unit.unitCode}</TableCell>
                      <TableCell align="center">{unit.unitName}</TableCell>
                      <TableCell align="center">
                        {unit.indices?.academic ? unit.indices.academic.toFixed(2) : '-'}
                      </TableCell>
                      <TableCell align="center">
                        {unit.indices?.engagement ? unit.indices.engagement.toFixed(2) : '-'}
                      </TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        <Link
                          className="noUnderLine"
                          to={{
                            pathname: '/units/' + unit.unitCode,
                          }}
                        >
                          <Button color="secondary" style={{ marginLeft: 10 }} size="small" variant="outlined">
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Layout.ScrollBar>
        ) : (
          <Loader />
        )}
      </Layout.TableContainer>
    </Page>
  );
}
