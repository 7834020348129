import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import {
  GET_ASSIGNMENT_DETAIL_RESULT,
  GET_ASSIGNMENT_INFO,
  ON_ASSIGNMENT_DETAIL_FINISHED,
  ON_ASSIGNMENT_DETAIL_START,
  ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_START,
  ON_INTERVENTIONS_SENT,
  ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS,
  ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_FINISHED,
  ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_START,
  ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_FINISHED,
  ON_ASSIGNMENT_LOGS_FINISHED,
  GET_ASSIGNMENT_LOGS_RESULT,
  ON_ASSIGNMENT_LOGS_START,
  ON_ASSIGNMENT_TABLE_DETAIL_START,
  ON_ASSIGNMENT_TABLE_DETAIL_FINISHED,
  ON_ASSIGNMENT_TEMPLATE_PREVIEW_START,
  GET_ASSIGNMENT_TEMPLATE_PREVIEW,
  ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED,
} from '../actionTypes';
import { ASSIGNMENT_LEVEL_INTERVENTIONS } from '../util/enums';

export const getAssignmentDetails =
  (id: string, unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_ASSIGNMENT_DETAIL_START });
        const result = await get(`${await API_URL()}/assignments/${id}/unit/${unitCode}`);
        dispatch({ type: GET_ASSIGNMENT_INFO, payload: result.assignmentDueDate });
        dispatch({
          type: GET_ASSIGNMENT_DETAIL_RESULT,
          payload: { students: result.result, disableEdit: result.disableEdit || false },
        });
        dispatch({ type: ON_ASSIGNMENT_DETAIL_FINISHED });
      } catch (error) {
        dispatch({ type: GET_ASSIGNMENT_INFO, payload: null });
        dispatch({ type: GET_ASSIGNMENT_DETAIL_RESULT, payload: [] });
        dispatch({ type: ON_ASSIGNMENT_DETAIL_FINISHED });
      }
    };

export const updateAssignmnetDetailsOfAStudent =
  (id: number, unitCode: string, studentId: string, data): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_ASSIGNMENT_TABLE_DETAIL_START });
        const result = await post(`${await API_URL()}/assignments/${id}/unit/${unitCode}/student/${studentId}/update`, data);
        dispatch({
          type: GET_ASSIGNMENT_DETAIL_RESULT,
          payload: { students: result.result, disableEdit: result.disableEdit || false },
        });
        dispatch({ type: ON_ASSIGNMENT_TABLE_DETAIL_FINISHED });
      } catch (error) {
        dispatch({ type: ON_ASSIGNMENT_TABLE_DETAIL_FINISHED });
      }
    };

// ------------------------------ Send intervention assignment level ------------------------------

export const sendInterventionForAssignment =
  (id, type): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({
          type:
            type === ASSIGNMENT_LEVEL_INTERVENTIONS.NON_SUBMISSION
              ? ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_START
              : ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_START,
        });
        const url = `${await API_URL()}/assignments/${id}/send-intervention/${type}`;
        const result = await get(url);
        dispatch({ type: ON_INTERVENTIONS_SENT, payload: result });
        dispatch({
          type:
            type === ASSIGNMENT_LEVEL_INTERVENTIONS.NON_SUBMISSION
              ? ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_FINISHED
              : ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_FINISHED,
        });
      } catch (error) {
        dispatch({
          type: ON_INTERVENTIONS_SENT,
          payload: { status: 'ERROR', message: 'Something went wrong !' },
        });
      }
    };

export const getAssignmnetInterventionTriggers =
  (id): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await API_URL()}/assignments/${id}/intervention-triggers`;
        const result = await get(url);
        dispatch({ type: ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS, payload: result });
      } catch (error) {
        dispatch({
          type: ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS,
          payload: [],
        });
      }
    };

// ------------------------------ Send intervention assignment level ------------------------------

// Get Assignment Logs

export const getAssignmentLogs =
  (id: string, unitCode: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_ASSIGNMENT_LOGS_START });
        const result = await get(`${await API_URL()}/assignments/${id}/unit/${unitCode}/logs`);

        dispatch({
          type: GET_ASSIGNMENT_LOGS_RESULT,
          payload: result,
        });
        dispatch({ type: ON_ASSIGNMENT_LOGS_FINISHED });
      } catch (error) {
        dispatch({ type: GET_ASSIGNMENT_LOGS_RESULT, payload: [] });
        dispatch({ type: ON_ASSIGNMENT_LOGS_FINISHED });
      }
    };

export const getAssignmentTemplatePreview =
  (id: string, type: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_ASSIGNMENT_TEMPLATE_PREVIEW_START });
        const result = await get(`${await API_URL()}/unit/mail-preview/${type}/assignment/${id}`);

        dispatch({
          type: GET_ASSIGNMENT_TEMPLATE_PREVIEW,
          payload: result,
        });
        dispatch({ type: ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED });
      } catch (error) {
        dispatch({ type: GET_ASSIGNMENT_TEMPLATE_PREVIEW, payload: null });
        dispatch({ type: ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED });
      }
    };
