import { ActionType } from './types';
import * as actionTypes from '../actions';

const initialState = {
  interventionData: [],
  interventionDataById: [],
  interventionLoader: false,
  onCreationLoader: false,
  interventionByIdLoader: false,
  interventionPreviewLoader: false,
  interventionPreviewData: null,
  interventionTriggerData: { mails: [] },
  errorResponse: null,
  successResponse: null,
  interventionTriggerLoader: false,
  interventionUnits: [{ code: 'ALL', label: 'Include all units' }],
  interventionStudents: ['ALL'],
  interventionStudentsLoader: false,
  inValidBookingConsultation: false,
  flowChartData: {
    links: '',
    attendSession: '',
    consultationWith: 'None',
    freeText: '',
    indexValue: null,
    indexOperator: 'greater',
    indexType: 'None',
    nonSubmissionStatus: 'YES',
    plagiarismValue: 'YES',
  },
};

const intervention = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_INTERVENTION_DATA: {
      return {
        ...state,
        interventionData: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_LOADING_START: {
      return {
        ...state,
        interventionLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_LOADING_FINISHED: {
      return {
        ...state,
        interventionLoader: false,
      };
    }
    case actionTypes.GET_INTERVENTION_TRIGGER_DATA: {
      return {
        ...state,
        interventionTriggerData: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_TRIGGER_LOADING_START: {
      return {
        ...state,
        interventionTriggerLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_TRIGGER_LOADING_FINISHED: {
      return {
        ...state,
        interventionTriggerLoader: false,
      };
    }
    case actionTypes.SET_FLOWCHARTDATA_DATA: {
      return {
        ...state,
        flowChartData: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_CREATION_ERROR_RESPONSE: {
      return {
        ...state,
        errorResponse: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_CREATION_SUCCESS_RESPONSE: {
      return {
        ...state,
        successResponse: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_CREATION_LOADING_START: {
      return {
        ...state,
        onCreationLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_CREATION_LOADING_FINISHED: {
      return {
        ...state,
        onCreationLoader: false,
      };
    }

    case actionTypes.SET_INTERVENTION_UNITS: {
      return {
        ...state,
        interventionUnits: action.payload,
      };
    }
    case actionTypes.SET_INTERVENTION_STUDENTS: {
      return {
        ...state,
        interventionStudents: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_STUDENTS_LOADING_START: {
      return {
        ...state,
        interventionStudentsLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_STUDENTS_LOADING_FINISHED: {
      return {
        ...state,
        interventionStudentsLoader: false,
      };
    }
    case actionTypes.GET_INTERVENTION_BY_ID_DATA: {
      return {
        ...state,
        interventionDataById: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_BY_ID_LOADING_START: {
      return {
        ...state,
        interventionByIdLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_BY_ID_LOADING_FINISHED: {
      return {
        ...state,
        interventionByIdLoader: false,
      };
    }

    case actionTypes.GET_INTERVENTION_PREVIEW_DATA: {
      return {
        ...state,
        interventionPreviewData: action.payload,
      };
    }
    case actionTypes.ON_INTERVENTION_PREVIEW_LOADING_START: {
      return {
        ...state,
        interventionPreviewLoader: true,
      };
    }
    case actionTypes.ON_INTERVENTION_PREVIEW_LOADING_FINISHED: {
      return {
        ...state,
        interventionPreviewLoader: false,
      };
    }

    case actionTypes.SET_INVALID_BOOKING_CONSULTATION_STATUS: {
      return {
        ...state,
        inValidBookingConsultation: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default intervention;
