import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import GetAppIcon from '@material-ui/icons/GetApp';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DateRange, DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { generateInstitutionalReports, resetReports, resetResponseForInstitutionalReports } from '../../actions';
import Loader from '../../components/Loader';
import { RootState } from '../../reducer';
import { theme } from '../../theme';
import { reportTypes } from '../../util/enums';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledCard = styled(Card)`
  margin: ${theme.spacing(2)}px 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      padding: 0,
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    tableContainer: {
      padding: 0,
    },
    inner: {
      minWidth: '1150',
      width: '100%',
    },
    actions: {
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
    },
    searchSection: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    downloadClass: {
      '& span': {
        width: '100px',
      },
      '& a': {
        color: '#c9696e',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
          color: '#c9696e',
        },
      },
      margin: '5px',
      backgroundColor: '#ffff',
      border: '1px solid #c9696e80',
      '&:hover': {
        backgroundColor: '#ffff',
        borderColor: '#c9696e',
        boxShadow: 'none',
        textDecoration: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ffff',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
    refreshIcon: {
      marginLeft: '10px',
    },
    fieldGroup: {
      display: 'flex',
      alignItems: 'center',
      margin: 30,
    },
    formGroup: {
      padding: theme.spacing(2, 0),
    },
    field: {
      margin: 12,
    },
    labelRoot: {
      fontSize: 18,
    },
    snackbar: {},
    dialog: {
      maxWidth: 'none',
      width: 690,
    },
  })
);

export default function AttendanceReports() {
  const classes = useStyles();
  const dispatch = useDispatch();

  //Attendance Report
  const reportData_attendance_report_weekly = useSelector(
    (state: RootState) => state.institutionalReports?.reportData_attendance_report_weekly
  );
  const loader_attendance_report_weekly = useSelector(
    (state: RootState) => state.institutionalReports?.loader_attendance_report_weekly
  );

  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semesterData = semestersWithBuCodes[selectedBuCode] || [];
  const dateData = semesterData.find((x) => x.value === selectedSemester) || {};
  const response = useSelector((state: RootState) => state.institutionalReports?.response);
  const [openAttendanceReportDateRangePicker, setOpenAttendanceReportDateRangePicker] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<{ [name: string]: DateRange }>({
    [`${reportTypes.ATTENDANCE_REPORTS}`]: {
      startDate: dateData.startDate || new Date(),
      endDate: dateData.endDate || new Date(),
    },
  });
  const [reportType, setReportType] = React.useState('');
  const toggleForAttendanceReport = (type: string) => {
    setOpenAttendanceReportDateRangePicker(!openAttendanceReportDateRangePicker);
    setReportType(type);
  };

  const setRange = (range: DateRange) => {
    setDateRange({ ...dateRange, [reportType]: range });
  };

  useEffect(() => {
    setDateRange({
      [`${reportTypes.ATTENDANCE_REPORTS}`]: {
        startDate: dateData.startDate || new Date(),
        endDate: dateData.endDate || new Date(),
      },
    });
  }, [dateData]);
  //Attendance Report

  // Notify
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);
  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpenSnackbar(false);
  };
  useEffect(() => {
    if (response) {
      setSuccessOpenSnackbar(true);
      dispatch(resetResponseForInstitutionalReports());
    }
  }, [response, dispatch]);

  const attendanceFiles = [{ data: reportData_attendance_report_weekly, type: reportTypes.ATTENDANCE_REPORT_WEEKLY }];

  const generateReports = (category: string, data) => {
    dispatch(resetReports(category));
    dispatch(generateInstitutionalReports(category, data));
  };

  const onDateChange = (range) => {
    setRange(range);
    toggleForAttendanceReport('');
  };

  return (
    <>
      <StyledCard>
        <CardHeader title={reportTypes.ATTENDANCE_REPORTS.split('_').join(' ')} />
        <Divider />
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center" width={500}>
                    Filter
                  </TableCell>
                  <TableCell align="center" width={300}>
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceFiles.map((file: { data: any; type: string }) => {
                  return (
                    <TableRow key={file.type}>
                      <TableCell align="center">Attendance Report</TableCell>
                      <TableCell align="center">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="center" spacing={5}>
                            <TextField
                              onClick={() => toggleForAttendanceReport(reportTypes.ATTENDANCE_REPORTS)}
                              style={{ marginRight: 10 }}
                              label="Start Date"
                              value={moment(dateRange[reportTypes.ATTENDANCE_REPORTS].startDate).format('MMM DD, YYYY')}
                            ></TextField>
                            <TextField
                              onClick={() => toggleForAttendanceReport(reportTypes.ATTENDANCE_REPORTS)}
                              style={{ marginLeft: 10 }}
                              label="End Date"
                              value={moment(dateRange[reportTypes.ATTENDANCE_REPORTS].endDate).format('MMM DD, YYYY')}
                            ></TextField>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </TableCell>
                      <TableCell align="center">
                        {!loader_attendance_report_weekly ? (
                          <>
                            <Button
                              color="secondary"
                              style={{ marginRight: 5 }}
                              size="small"
                              variant="outlined"
                              startIcon={
                                <BuildIcon fontSize="small" style={{ color: '#c9696e' }}>
                                  Generate
                                </BuildIcon>
                              }
                              onClick={() => generateReports(file.type, dateRange[reportTypes.ATTENDANCE_REPORTS])}
                            >
                              Generate
                            </Button>
                            <Button
                              color="secondary"
                              size="small"
                              variant="outlined"
                              startIcon={
                                <GetAppIcon fontSize="small" style={{ color: '#c9696e' }}>
                                  Download
                                </GetAppIcon>
                              }
                              disabled={file.data && file.data.content ? false : true}
                              className={classes.downloadClass}
                            >
                              {file.data && file.data.content ? (
                                <CSVLink
                                  data={file.data && file.data.content ? file.data.content : []}
                                  filename={`Institutional_Report_${selectedBuCode}_${file.type}_between_${moment(
                                    dateRange[reportTypes.ATTENDANCE_REPORTS].startDate
                                  ).format('DD-MM-YYYY')}_&_${moment(
                                    dateRange[reportTypes.ATTENDANCE_REPORTS].endDate
                                  ).format('DD-MM-YYYY')}.csv`}
                                  target="_blank"
                                >
                                  Download
                                </CSVLink>
                              ) : (
                                'Download'
                              )}
                            </Button>
                          </>
                        ) : (
                          <Loader />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </StyledCard>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
        open={openSuccessSnackbar}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity="info">
          Generated report will also be sent you via mail. Thanks!
        </Alert>
      </Snackbar>
      <Dialog
        onClose={() => toggleForAttendanceReport('')}
        maxWidth={false}
        aria-labelledby="simple-dialog-title"
        open={openAttendanceReportDateRangePicker}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>

        <DateRangePicker
          minDate={new Date(dateData.startDate)}
          open={openAttendanceReportDateRangePicker}
          toggle={() => toggleForAttendanceReport('')}
          initialDateRange={dateRange[reportType]}
          onChange={onDateChange}
          maxDate={new Date(dateData.endDate)}
        />
      </Dialog>
    </>
  );
}
