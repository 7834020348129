import { Button, Card, CardHeader, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { filters } from '../../../util/enums';
import { CustomTable, StyledContent, TotalCount } from '../../../components/Common';
import { Event } from '../../../models/event';
import moment from 'moment';
import { Visibility } from '@material-ui/icons';

type ResultsProps = {
  className?: string;
  events: any[];
  setPage: (page: number) => void;
  setRowsPerPage: (rowPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  count: number;
  loader: boolean;
  onFilter: (query: any) => void;
  values: any;
  setValues: (state: any) => void;
};

const Result: React.FC<ResultsProps> = ({
  className,
  events = [],
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loader,
  onFilter,
  values,
  setValues,
  ...rest
}) => {
  const [sortStartDate, setIsExpandedStart] = useState(true);
  const [sortEngagementIndex, setIsExpandedEngagement] = useState(false);
  const [orderByDate, setOrderBy] = useState(false);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  const handleSortChange = (value: string) => {
    const sort = { sortStartDate, sortEngagementIndex, orderByDate };
    if (value === filters.startDate) {
      setOrderBy(true);
      setIsExpandedStart(!sortStartDate);
      sort.sortStartDate = !sortStartDate;
      sort.orderByDate = true;
    } else if (value === filters.engagementIndex) {
      setOrderBy(false);
      setIsExpandedEngagement(!sortEngagementIndex);
      sort.sortEngagementIndex = !sortEngagementIndex;
      sort.orderByDate = false;
    }
    setValues({
      ...values,
      sort,
    });
    onFilter({ ...values, sort });
  };

  const decoratedTableData = events.map((event: Event) => {
    const start = moment(event.startDateTime);
    const end = moment(event.endDateTime);
    const diff = end.diff(start);
    return [
      <b>{event.meetingSubject}</b>,
      <>
        {event.organiser.displayName}
        <br />
        <a href={`mailto:${event.organiser.email}`}> {event.organiser.email}</a>
      </>,
      <b>{event.methodOfDelivery}</b>,
      <>
        {start.format('hh:mm a, Do MMMM, yy')}
        <br />
        <b>{start.format('dddd')}</b>
      </>,
      <>
        {end.format('hh:mm a, Do MMMM, yy')}
        <br />
        <b>{end.format('dddd')}</b>
      </>,
      <>
        {moment.utc(diff).format('H') !== '0' && <span>{moment.utc(diff).format('H [hrs]')} </span>}
        <span>{moment.utc(diff).format('mm [mins]')}</span>
      </>,
      event.engagementIndex ? Math.floor(event.engagementIndex) : '0',
      <Button
        startIcon={<Visibility />}
        color="primary"
        component={RouterLink}
        size="small"
        to={`/attendance/${event.id}`}
        variant="outlined"
      >
        View
      </Button>,
    ];
  });

  return (
    <>
      <TotalCount color="textSecondary" variant="body2">
        {count} Records found. Page {page + 1} of {Math.ceil(count / rowsPerPage)}
      </TotalCount>
      <Card>
        <CardHeader title="Search Results" />
        <Divider />
        <StyledContent>
          {events && !loader ? (
            <CustomTable
              tableHead={[
                { text: 'Name' },
                { text: 'Organiser' },
                { text: 'Method Of Delivery' },
                {
                  text: 'Start Time',
                  displaySort: true,
                  onSortClick: () => handleSortChange(filters.startDate),
                  showUpArrow: !sortStartDate,
                },
                { text: 'End Time' },
                { text: 'Duration' },
                {
                  text: 'Attendance Index',
                  //displaySort: true,
                  //onSortClick: () => handleSortChange(filters.engagementIndex),
                  //showUpArrow: sortEngagementIndex,
                },
                { text: 'Actions' },
              ]}
              tableData={decoratedTableData}
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <Loader />
          )}
        </StyledContent>
      </Card>
    </>
  );
};

export default Result;
