import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export default function Loader() {
  return (
    <Container style={{ textAlign: 'center' }}>
      <CircularProgress thickness={5} />
    </Container>
  );
}
