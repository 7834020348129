import { post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
export const SET_FILE_UPLOAD_STATUS = 'SET_FILE_UPLOAD_STATUS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERRORS = 'UPLOAD_ERRORS';

interface ProgressEventType {
  loaded: number;
  total: number;
}

export const uploadEnglishLevel =
  (file: any): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response: any = await post(`${await API_URL()}/english-level/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEventType) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch({
              type: SET_FILE_UPLOAD_STATUS,
              payload: percent,
            });
          },
        });
        if (response && response.status === 'SUCCESS') {
          dispatch({ type: UPLOAD_SUCCESS, payload: response.data.message });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };

export const resetResultUploadProgress = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: SET_FILE_UPLOAD_STATUS, payload: 0 });
  dispatch({ type: UPLOAD_SUCCESS, payload: '' });
  dispatch({ type: UPLOAD_ERRORS, payload: {} });
};

export const uploadAssesmentDueDate =
  (file: any): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response: any = await post(`${await API_URL()}/assignments/upload/due-date`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEventType) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch({
              type: SET_FILE_UPLOAD_STATUS,
              payload: percent,
            });
          },
        });
        if (response && response.status === 'SUCCESS') {
          dispatch({ type: UPLOAD_SUCCESS, payload: response.data.message });
        } else if (response && response.status === 'ERROR') {
          dispatch({ type: UPLOAD_ERRORS, payload: response.data });
        }
      } catch (error) {
        // dispatch({type: ON_LOADING_FINISHED});
      }
    };
