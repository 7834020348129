import { CircularProgress, Divider, CardContent, Typography, Avatar, Card, TextField, Tab } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../theme';

export const StyledTab = styled(Tab)`
  button {
    outline: none;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(2)}px;
`;

export const SearchDiv = styled.div`
  margin-top: ${theme.spacing(1)}px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${theme.spacing(1)}px;
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  display: inline-block;
  position: relative;
`;

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const StyledContent = styled(CardContent)`
  padding: 0;
`;

export const TotalCount = styled(Typography)`
  margin: ${theme.spacing(1)}px 0px;
`;

export const CenterText = styled.div`
  text-align: center;
  margin-top: 10px;
`;
export const AvatarForDownload = styled(Avatar)`
  float: right;
  right: -10px;
  width: 24px;
  height: 24px;
  color: #ffffff !important;
  background-color: #043378 !important;
  cursor: pointer;
`;

export const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export const StyledTypography = styled(Typography)`
  flex-grow: 1;
  text-transform: none;
  color: 'white';
  font-weight: bold;
`;

export const Title = styled(Typography)`
  font-weight: bolder;
  flex-grow: 1;
  margin: 0 ${theme.spacing(2)}px;
  color: ${theme.palette.primary.main};
`;

export const TitleHeaderLink = styled(Link)`
  text-decoration: none;
  color: ${theme.palette.primary.main};
  &:hover {
    text-decoration: none;
    color: ${theme.palette.primary.main};
  }
`;

export const StyledCard = styled(Card)`
  margin: ${theme.spacing(2)}px;
`;

export const StyledDiv = styled.div`
  margin: ${theme.spacing(2)}px;
`;

export const GraphContainer = styled.div`
  display: flex;
`;

export const WhiteTextField = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    color: white;
  }

  label {
    color: white;
  }
`;

export const FlexRowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StrongDiv = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: grey;
  width: 387px;
  text-align: right;
`;

export const FlexRowDivCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCSVLink = styled(CSVLink)`
  text-decoration: none !important;
  color: white !important;
`;
