import { get, post } from './common';
import { API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { MarkAttendanceDto } from '../dto/attendance-code.dto';
import { AxiosError } from 'axios';
import { EventSearchFilters } from '../util/types';
import { reportTypes } from '../util/enums';
import { EVENT_REPORT_FINISHED, EVENT_REPORT_START, SET_EVENT_REPORT_RESPONSE } from '../actionTypes';
import { findInstitutionalReports } from './institutional-reports';
import moment from 'moment';

export const GET_UPCOMING_MEETING_RECEIVED = 'GET_UPCOMING_MEETING_RECEIVED';
export const GET_RESPONSE_FOR_MARK_ATTENDANCE = 'GET_RESPONSE_FOR_MARK_ATTENDANCE';
export const GET_MEETING_FOR_ATTENDANCE_RECEIVED = "GET_MEETING_FOR_ATTENDANCE_RECEIVED";
export const GET_ATTENDANCE_CODE_RECEIVED = 'GET_ATTENDANCE_CODE_RECEIVED';
export const ATTENDANCE_CODE_DETAILS_RECEIVED = 'GET_ATTENDANCE_LINK_RECEIVED';
export const MARK_ATTENDANCE_RECEIVED = 'MARK_ATTENDANCE_RECEIVED';
export const MARK_BULK_ATTENDANCE_RECEIVED = 'MARK_BULK_ATTENDANCE_RECEIVED';
export const GET_EVENTS_RECEIVED = 'GET_EVENTS_RECEIVED';
export const GET_EVENT_RECEIVED = 'GET_EVENT_RECEIVED';
export const RESET_EVENT_CODE_STATE = 'RESET_EVENT_CODE_STATE';
export const GET_EVENT_SEARCH_RECEIVED = 'GET_EVENT_SEARCH_RECEIVED';
export const ON_EVENTS_SEARCH_CHANGED = 'ON_EVENTS_SEARCH_CHANGED';
export const RESET_EVENT_DETAILS = 'RESET_EVENT_DETAILS';
export const SET_FEDUNI_EVENT = 'SET_FEDUNI_EVENT';

export const UPCOMING_MEETINGS_URL = async () => {
  return await API_URL() + '/users/me/upcomingMeetings'
};
export const EVENTS_FOR_ATTENDANCE_URL = async () => {
  return await API_URL() + '/events/period'
};
export const ATTENDANCE_CODE_URL = async () => {
  return await API_URL() + '/events/code'
};
export const MARK_ATTENDANCE_URL = async () => {
  return await API_URL() + '/events/mark-attendance';
};
export const MARK_BULK_ATTENDANCE_URL = async () => {
  return await API_URL() + '/events/mark-bulk-attendance';
};
export const EVENTS_URL = async () => {
  return await API_URL() + '/events';
};

export const EVENT_URL = async (id) => {
  return `${await EVENTS_URL()}/${id}`;
};


export const getUpcomingMeetings = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const result = await get(await UPCOMING_MEETINGS_URL());
    dispatch({ type: GET_UPCOMING_MEETING_RECEIVED, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_MEETING_RECEIVED,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getEventsForAttendace = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    let endDt = new Date();
    const endDtStr = moment(endDt).format('YYYY/MM/DD');
    const startDtStr = moment(endDt).subtract(14, 'days').format('YYYY/MM/DD');
    const result = await get(await EVENTS_FOR_ATTENDANCE_URL() +`?startDate=${startDtStr}&endDate=${endDtStr}`);
    dispatch({ type: GET_MEETING_FOR_ATTENDANCE_RECEIVED, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_MEETING_FOR_ATTENDANCE_RECEIVED,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const generateAttendanceCode =
  (meeting: any): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const data = {
          ... meeting,
          baseUrl: window.location.origin,
        };
        dispatch({ type: ON_LOADING_START });
        const result = await post(await ATTENDANCE_CODE_URL(), data);
        dispatch({ type: GET_ATTENDANCE_CODE_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
        return result;
      } catch (error) {
        dispatch({
          type: GET_ATTENDANCE_CODE_RECEIVED,
          payload: null,
        });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

export const generateEventReports =
  (searchText: string, filters: EventSearchFilters): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: EVENT_REPORT_START });
        const url = `${await API_URL()}/events/report/generate`;
        const result = await post(url, { searchText, filters });
        if (result) {
          dispatch({ type: SET_EVENT_REPORT_RESPONSE, payload: '' });
          dispatch(findInstitutionalReports(result.id, reportTypes.EVENTS));
        }
      } catch (error) {
        dispatch({
          type: SET_EVENT_REPORT_RESPONSE,
          payload: '',
        });
        dispatch({ type: EVENT_REPORT_FINISHED });
      }
    };

export const getEvents = (unit: string, searchText: string,
  filters: EventSearchFilters, page: number,
  recordPerPage: number): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    try {
      const url = `${await EVENTS_URL()}/unit`;
      dispatch({ type: ON_LOADING_START });
      const result = await post(url, {
        searchText,
        filters,
        unit,
        limit: recordPerPage,
        offset: recordPerPage * page - recordPerPage,
      });
      dispatch({ type: GET_EVENTS_RECEIVED, payload: result });
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      dispatch({
        type: GET_EVENTS_RECEIVED,
        payload: {},
      });
      dispatch({ type: ON_LOADING_FINISHED });
    }
  };

export const getDetails =
  (id: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_LOADING_START });
        const result = await get(`${await ATTENDANCE_CODE_URL()}/${id}`);
        dispatch({ type: ATTENDANCE_CODE_DETAILS_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: ATTENDANCE_CODE_DETAILS_RECEIVED,
          payload: null,
        });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

export const getEvent =
  (id: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_EVENT_RECEIVED, payload: undefined });
        const url = await EVENT_URL(id);
        dispatch({ type: ON_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_EVENT_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        // dispatch({
        //   type: GET_EVENT_RECEIVED,
        //   payload: {},
        // });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

export const resetEventCodeState = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: RESET_EVENT_CODE_STATE });
};

export const searchEvent =
  (
    searchText: string,
    filters: EventSearchFilters,
    page: number,
    recordPerPage: number
  ): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await EVENTS_URL()}/search`;
        dispatch({ type: ON_LOADING_START });
        const result = await post(url, {
          limit: recordPerPage,
          offset: recordPerPage * page - recordPerPage,
          searchText,
          filters,
        });
        dispatch({ type: GET_EVENT_SEARCH_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_EVENT_SEARCH_RECEIVED,
          payload: null,
        });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };


export const markAttendance =
  (id: string, email: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const data: MarkAttendanceDto = { id, email };
        dispatch({ type: ON_LOADING_START });
        const result = await post(await MARK_ATTENDANCE_URL(), data);
        dispatch({ type: MARK_ATTENDANCE_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status) {

          dispatch({
            type: MARK_ATTENDANCE_RECEIVED,
            payload: axiosError.response.data,
          });
        }
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

    export const markBulkAttendance =
      (eventId: string, emailIds: string[]): ThunkAction<void, RootState, unknown, ActionType> =>
      async (dispatch) => {
        try {
          if(emailIds.length < 1) {
            dispatch({
              type: GET_RESPONSE_FOR_MARK_ATTENDANCE,
              payload: { status: 'ERROR', message: `Please select the student(s)` },
            });
            return;
          }
          const data = { eventId , emailIds };
          dispatch({ type: ON_LOADING_START });
          const result = await post(await MARK_BULK_ATTENDANCE_URL(), data);
          dispatch({ type: GET_RESPONSE_FOR_MARK_ATTENDANCE, payload: result });
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({
            type: GET_RESPONSE_FOR_MARK_ATTENDANCE,
            payload: { status: 'ERROR', message: `Server error!` },
          });
          dispatch({ type: ON_LOADING_FINISHED });
        }
      };


export const onEventsSearchChanged =
  (searchText: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({
        type: ON_EVENTS_SEARCH_CHANGED,
        payload: searchText,
      });
    };

export const resetEventDetails = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: RESET_EVENT_DETAILS,
  });
};

export const setFeduniEvent =
  (state: boolean): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      dispatch({
        type: SET_FEDUNI_EVENT,
        payload: state,
      });
    };
