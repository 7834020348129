import React, { useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { CardContent, Card, Grid, TextField, Snackbar, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { onAssignmentSelected, updateAssignment } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import moment from 'moment';
import { dateFormat, statusChangeForAssignment } from '../../util/common';
import { onTrack } from '../../util/enums';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 500,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditAssignmentPopup({ unitId }) {
  const [errorOpen, setErrorOpen] = useState(false);
  const [week, setWeek] = useState('');
  const [weightage, setTaskWeightage] = useState('');
  const [newDate, setNewDate] = useState('');
  const [comment, setComment] = useState('');
  const [newTime, setTime] = useState('');
  const [submission, setSubmissionType] = useState('');
  const dispatch = useDispatch();

  const handleAlertClose = () => {
    setErrorOpen(false);
  };

  const handleClose = () => {
    dispatch(onAssignmentSelected({}));
    setComment('');
  };
  const assignment = useSelector((state: RootState) => state.units?.assignmentSelected);
  const { dueWeek = '', dueDate = '', time = '', submissionType = onTrack.YES, taskWeightage = '' } = assignment;
  useEffect(() => {
    setWeek(dueWeek);
    setNewDate(dueDate);
    setTime(time);
    setSubmissionType(submissionType);
    setTaskWeightage(taskWeightage);
  }, [assignment]);

  const handleSave = () => {
    dispatch(
      updateAssignment(
        {
          dueDate: newDate,
          dueWeek: week,
          comment,
          time: newTime,
          submissionType: submission,
          taskWeightage: weightage,
          statusChange: statusChangeForAssignment(assignment, week, newDate, newTime, submission, weightage),
        },
        assignment.id,
        unitId
      )
    );
    handleClose();
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={assignment && assignment.id ? true : false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Assignment
        </DialogTitle>
        <DialogContent dividers>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Semester"
                    name="Semester"
                    value={assignment ? assignment.semester : ''}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Lecturer Name"
                    name="lecturerName"
                    value={assignment ? assignment.lecturerName : ''}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="TaskName"
                    name="taskName"
                    value={assignment ? assignment.taskName : ''}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="TaskNumber"
                    name="taskNumber"
                    value={assignment ? assignment.taskNumber : ''}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Semester"
                    name="Semester"
                    value={assignment ? assignment.semester : ''}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Submission Type"
                    name="submissionType"
                    select
                    SelectProps={{
                      value: submission,
                    }}
                    value={submission}
                    variant="outlined"
                    onChange={(e) => setSubmissionType(e.target.value)}
                  >
                    {Object.keys(onTrack).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="date"
                    label="Due Date"
                    type="date"
                    variant="outlined"
                    defaultValue={moment(dateFormat(dueDate)).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(dateFormat(newDate)).format('YYYY-MM-DD')}
                    onChange={(e) => setNewDate(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Time"
                    name="time"
                    value={newTime}
                    variant="outlined"
                    onChange={(e) => setTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Due Week"
                    name="dueWeek"
                    value={week}
                    variant="outlined"
                    onChange={(e) => setWeek(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="Task Weightage"
                    name="taskWeightage"
                    value={weightage}
                    variant="outlined"
                    onChange={(e) => setTaskWeightage(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    label="Comment"
                    name="comment"
                    value={comment}
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={
              !week ||
              !weightage ||
              !time ||
              !comment ||
              (week === dueWeek &&
                moment(dateFormat(dueDate)).format('YYYY-MM-DD') === moment(dateFormat(newDate)).format('YYYY-MM-DD') &&
                time === newTime &&
                submissionType === submission &&
                taskWeightage === weightage)
            }
            onClick={() => {
              handleSave();
            }}
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      ></Snackbar>
    </>
  );
}
