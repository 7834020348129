import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from '../../components/Loader';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    filterButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 1150,
    },
    actions: {
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
    },
    pagination: {
      position: 'relative',
      marginBottom: '8px',
      marginLeft: '10px',
    },
    downloadClass: {
      '& span': {
        width: '100px',
      },
      '& a': {
        color: '#c9696e',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
          color: '#c9696e',
        },
      },
      margin: '5px',
      backgroundColor: '#ffff',
      border: '1px solid #c9696e80',
      '&:hover': {
        backgroundColor: '#ffff',
        borderColor: '#c9696e',
        boxShadow: 'none',
        textDecoration: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ffff',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })
);

type ResultsProps = {
  className?: string;
  reports: any[];
  setPage: (page: number) => void;
  setRowsPerPage: (rowPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  count: number;
  historyLoader: boolean;
};
const Result: React.FC<ResultsProps> = ({
  className,
  reports = [],
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  historyLoader,
  ...rest
}) => {
  const classes = useStyles();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(event.target.value));
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" variant="body2" className={classes.pagination}>
        {count} Records found.{' '}
        {count > 0 && (
          <span>
            Page {page + 1} of {Math.ceil(count / rowsPerPage)}
          </span>
        )}
      </Typography>
      <Card>
        <CardHeader title="History of Reports" />
        <Divider />
        <CardContent className={classes.content}>
          {!historyLoader ? (
            <>
              {reports && reports.length > 0 ? (
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Id</TableCell>
                          <TableCell align="center">Type</TableCell>
                          <TableCell align="center">Created At</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.map(reports, (report: any) => {
                          return (
                            <TableRow key={report.id}>
                              <TableCell align="center">{report.id}</TableCell>
                              <TableCell align="center">{report.type}</TableCell>
                              <TableCell align="center">{moment(report.createdAt).format('lll')}</TableCell>

                              <TableCell align="center">
                                <Button
                                  color="secondary"
                                  size="small"
                                  variant="outlined"
                                  startIcon={
                                    <GetAppIcon fontSize="small" style={{ color: '#c9696e' }}>
                                      Download
                                    </GetAppIcon>
                                  }
                                  className={classes.downloadClass}
                                >
                                  {report && report.content ? (
                                    <CSVLink
                                      data={report && report.content ? report.content : []}
                                      filename={`${report.type}_${moment(report.createdAt).format(
                                        'MMM_DD_YYYY_h_mm_ss_a'
                                      )}.csv`}
                                      target="_blank"
                                    >
                                      Download
                                    </CSVLink>
                                  ) : (
                                    'Download'
                                  )}
                                </Button>{' '}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              ) : (
                <div style={{ textAlign: 'center' }}>No Reports have been generated</div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          {!historyLoader && (
            <>
              {reports && reports.length > 0 ? (
                <TablePagination
                  
                  count={count}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              ) : (
                <div />
              )}
            </>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default Result;
