import { get, post } from './common';
import { API_URL, PACE_API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType, BasicUnitInfo } from '../reducer/types';
import { result } from 'validate.js';
import { getSortOrder } from '../util/common';
import { roles } from '../util/enums';
import axios from 'axios';


export const INTERVENTION_URL_2 = async () => {
  return `${await API_URL()}/intervention`
};
export const PACE_URL = async () => {
  return `${await API_URL()}/pace`
};
export const GET_INTERVENTION_DATA = 'GET_INTERVENTION_DATA';
export const SET_FLOWCHARTDATA_DATA = 'SET_FLOWCHARTDATA_DATA';
export const ON_INTERVENTION_LOADING_START = 'ON_INTERVENTION_LOADING_START';
export const ON_INTERVENTION_LOADING_FINISHED = 'ON_INTERVENTION_LOADING_FINISHED';
export const GET_INTERVENTION_TRIGGER_DATA = 'GET_INTERVENTION_TRIGGER_DATA';
export const ON_INTERVENTION_TRIGGER_LOADING_START = 'ON_INTERVENTION_TRIGGER_LOADING_START';
export const ON_INTERVENTION_TRIGGER_LOADING_FINISHED = 'ON_INTERVENTION_TRIGGER_LOADING_FINISHED';
export const CREATE_INTERVENTION = 'CREATE_INTERVENTION';
export const ON_INTERVENTION_CREATION_LOADING_START = 'ON_INTERVENTION_CREATION_LOADING_START';
export const ON_INTERVENTION_CREATION_LOADING_FINISHED = 'ON_INTERVENTION_CREATION_LOADING_FINISHED';
export const ON_INTERVENTION_CREATION_ERROR_RESPONSE = 'ON_INTERVENTION_CREATION_ERROR_RESPONSE';
export const SET_INTERVENTION_UNITS = 'SET_INTERVENTION_UNITS';
export const ON_INTERVENTION_CREATION_SUCCESS_RESPONSE = 'ON_INTERVENTION_CREATION_SUCCESS_RESPONSE';
export const GET_INTERVENTION_BY_ID_DATA = 'GET_INTERVENTION_BY_ID_DATA';
export const ON_INTERVENTION_BY_ID_LOADING_START = 'ON_INTERVENTION_BY_ID_LOADING_START';
export const ON_INTERVENTION_BY_ID_LOADING_FINISHED = 'ON_INTERVENTION_BY_ID_LOADING_FINISHED';
export const GET_INTERVENTION_PREVIEW_DATA = 'GET_INTERVENTION_PREVIEW_DATA';
export const ON_INTERVENTION_PREVIEW_LOADING_START = 'ON_INTERVENTION_PREVIEW_LOADING_START';
export const ON_INTERVENTION_PREVIEW_LOADING_FINISHED = 'ON_INTERVENTION_PREVIEW_LOADING_FINISHED';
export const SET_INTERVENTION_STUDENTS = 'SET_INTERVENTION_STUDENTS';
export const ON_INTERVENTION_STUDENTS_LOADING_START = 'ON_INTERVENTION_STUDENTS_LOADING_START';
export const ON_INTERVENTION_STUDENTS_LOADING_FINISHED = 'ON_INTERVENTION_STUDENTS_LOADING_FINISHED';
export const SET_INVALID_BOOKING_CONSULTATION_STATUS = 'SET_INVALID_BOOKING_CONSULTATION_STATUS';

export const getInterventionData = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  try {
    const url = `${await INTERVENTION_URL_2()}/all`;
    dispatch({ type: ON_INTERVENTION_LOADING_START });
    let unitCodes = [];
    if(getState().roles?.roles === roles.LECTURER) {
      unitCodes = getState().courses.units.map( x => x.unitCode );
    }
    const param = {unitCodes}
    const result = await post(url, param);
    dispatch({ type: GET_INTERVENTION_DATA, payload: result });
    dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_INTERVENTION_DATA,
      payload: [],
    });
    dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
  }
};

export const getInterventionDataById =
  (id: number): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/${id}`;
        dispatch({ type: ON_INTERVENTION_BY_ID_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_INTERVENTION_BY_ID_DATA, payload: result !== null ? result?.interventionTriggers : [] });
        dispatch({ type: ON_INTERVENTION_BY_ID_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_INTERVENTION_BY_ID_DATA,
          payload: [],
        });
        dispatch({ type: ON_INTERVENTION_BY_ID_LOADING_FINISHED });
      }
    };

export const getInterventionTriggerData =
  (id: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/triggers/${id}`;
        dispatch({ type: ON_INTERVENTION_TRIGGER_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_INTERVENTION_TRIGGER_DATA, payload: result });
        dispatch({ type: ON_INTERVENTION_TRIGGER_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_INTERVENTION_TRIGGER_DATA,
          payload: {},
        });
        dispatch({ type: ON_INTERVENTION_TRIGGER_LOADING_FINISHED });
      }
    };

export const getInterventionTriggerDownloadData = (
  id: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/download/triggers/${id}`;
        const result = await axios.get(url);
        resolve(result);
      }
      catch (e: any) {
        reject(e.response);
      }
    }
  );
};

export const createIntervention =
  (data): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/create`;
        dispatch({ type: ON_INTERVENTION_CREATION_LOADING_START });
        const result = await post(url, data);
        if (result.status === 'SUCCESS') {
          dispatch({ type: CREATE_INTERVENTION, payload: result });

          dispatch({ type: ON_INTERVENTION_CREATION_SUCCESS_RESPONSE, payload: result });
        } else {
          dispatch({ type: ON_INTERVENTION_CREATION_ERROR_RESPONSE, payload: result });
        }
        dispatch({ type: ON_INTERVENTION_CREATION_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: CREATE_INTERVENTION,
          payload: {},
        });

        dispatch({ type: ON_INTERVENTION_CREATION_ERROR_RESPONSE, payload: { message: `Server can't be reached!` } });

        dispatch({ type: ON_INTERVENTION_CREATION_LOADING_FINISHED });
      }
    };

export const setFlowChartData =
  (data: any): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_FLOWCHARTDATA_DATA,
          payload: data,
        });
      } catch (error) { }
    };

export const updateInterventionStatus =
  (id, status): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch, getState) => {
      try {
        let unitCodes = [];
        if (getState().roles?.roles === roles.LECTURER) {
          unitCodes = getState().courses.units.map((x) => x.unitCode);
        }
        const url = `${await INTERVENTION_URL_2()}/update-status/${id}`;
        dispatch({ type: ON_INTERVENTION_LOADING_START });
        const result = await post(url, { newStatus: status, unitCodes });
        dispatch({ type: GET_INTERVENTION_DATA, payload: result });
        dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_INTERVENTION_DATA,
          payload: [],
        });
        dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
      }
    };

export const setInterventionUnits =
  (buCode: string, selectedSemester: string, email: string, role: string, loadStudents = false): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
        //Call API
        const paceUrl = await PACE_API_URL();
        let url = `${paceUrl}/third-party/newapi-active?buCode=${buCode}&term=${selectedSemester}`;
        if (role === roles.LECTURER) {
          url = `${paceUrl}/third-party/search/allUnits/${email}?buCode=${buCode}&term=${selectedSemester}`;
        }
        const result: BasicUnitInfo[] = await get(url);
        const unitDetails = result.map((x) => {
          return { code: x.unitCode, label: x.unitName };
        });
        unitDetails.sort(getSortOrder('code'));
        unitDetails.unshift({ code: 'ALL', label: 'Include all units' });
        dispatch({ type: SET_INTERVENTION_UNITS, payload: unitDetails });
        if(loadStudents) {
          const unitCodes = role === roles.LECTURER ? result.map(x => x.unitCode) : ["ALL"]
          dispatch(setInterventionStudents(unitCodes, role));
        }
      });
    };

export const setInterventionStudents =
  (units, role): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch, getState) => {
      return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
        //Call API

        dispatch({ type: ON_INTERVENTION_STUDENTS_LOADING_START });
        try {
          units =
            role === roles.LECTURER && units.includes('ALL')
              ? getState().intervention.interventionUnits.map((x) => {
                  if (x.code !== 'ALL') {
                    return x.code;
                  }
                })
              : units;
          const url = `${await PACE_URL()}/students/perUnits`;
          const result = await post(url, { units });
          result.unshift('ALL');
          dispatch({ type: SET_INTERVENTION_STUDENTS, payload: result });
          dispatch({ type: ON_INTERVENTION_STUDENTS_LOADING_FINISHED });
        } catch (e) {
          dispatch({ type: SET_INTERVENTION_STUDENTS, payload: ['ALL'] });
          dispatch({ type: ON_INTERVENTION_STUDENTS_LOADING_FINISHED });
        }
      });
    };

export const getinterventionTriggerDetails =
  (interventionId: number): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/triggers/${interventionId}`;
        dispatch({ type: ON_INTERVENTION_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_INTERVENTION_DATA, payload: result });
        dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_INTERVENTION_DATA,
          payload: [],
        });
        dispatch({ type: ON_INTERVENTION_LOADING_FINISHED });
      }
    };

export const getInterventionPreview =
  (id: number): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await INTERVENTION_URL_2()}/${id}/preview`;
        dispatch({ type: ON_INTERVENTION_PREVIEW_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_INTERVENTION_PREVIEW_DATA, payload: result });
        dispatch({ type: ON_INTERVENTION_PREVIEW_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_INTERVENTION_PREVIEW_DATA,
          payload: null,
        });
        dispatch({ type: ON_INTERVENTION_PREVIEW_LOADING_FINISHED });
      }
    };
