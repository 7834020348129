import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../components/CustomModal';
import _ from 'lodash';
import SearchBar from '../../components/search-bar';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { getEvent, markBulkAttendance } from '../../actions';
import { RootState } from '../../reducer';
import { isNumeric } from '../../util/common';
import Loader from '../../components/Loader';

type StudentListProps = {
  eventId: string;
  eventObj: any;
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    cellPadding: {
      padding: 0,
    },
    contentDiv: {
      height: 400,
    },
    indeterminateColor: {
      color: '#f50057',
    },
    selectAllText: {
      fontWeight: 500,
    },
    eventInfo: {
      marginBottom: 15
    },
    eventInfoLabel: {
      paddingRight: 5
    },
    subject: {
      whiteSpace: 'nowrap',
      width: 300,
      overflow: 'hidden',
      fontWeight: 'bold',
      textOverflow: 'ellipsis'
    },
    eventInfoItems: {
      margin: theme.spacing(1),
      display: 'flex'
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSearchBar = styled(SearchBar)`
  width: calc(100% + 32px);
  margin-bottom: -15px;
`;

const StudentList: React.FC<StudentListProps> = ({ eventId, eventObj, open, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector((state: RootState) => state.events.loader);
  const eventDetails = useSelector((state: RootState) => state.events?.eventDetails);
  const [selectedStudents, setselectedStudents] = useState<any[]>([]);
  const [students, setStudents] = useState([] as any[]);
  const [studentsOriginalList, setStudentsOriginalList] = useState([] as any[]);

  useEffect(() => {
    if (open) {
      setStudents(Object.assign([], []));
      setStudentsOriginalList(Object.assign([], []));
      dispatch(getEvent(eventId));
    }
  }, [open]);

  useEffect(() => {
    if (eventDetails) {
      const studentData = eventDetails.physicalAttendances
        .sort((a, b) => {
          // Put organisers at the front
          const aId = a.attendee.email.split('@')[0];
          const bId = b.attendee.email.split('@')[0];
          return isNumeric(aId) ? 1 : isNumeric(bId) ? -1 : 0;
        })
        .filter((e) => !e.timestamp);
      setStudents(Object.assign([], studentData));
      setStudentsOriginalList(Object.assign([], studentData));
    }
  }, [eventDetails]);

  const markAttendance = () => {
    onClose();
    dispatch(markBulkAttendance(eventId, selectedStudents));
  };

  const onStudentSelect = (event: any, studentData: any) => {
    let clonedSelectedStudents = _.cloneDeep(selectedStudents);
    if (event.target.checked) {
      clonedSelectedStudents.push(studentData.attendee.email);
    } else {
      clonedSelectedStudents = clonedSelectedStudents.filter((e) => e !== studentData.attendee.email);
    }
    setselectedStudents(clonedSelectedStudents);
  };
  const onSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.attendee.email);
      setselectedStudents(newSelecteds);
      return;
    }
    setselectedStudents([]);
  };
  const onSearch = (searchText: string) => {
    let filtered_students: any[] = [];
    if (!searchText) {
      setStudents(studentsOriginalList);
      return;
    }
    if (students) {
      filtered_students = students.filter(
        (x) =>
          x.attendee.displayName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
          x.attendee.email.split('@')[0].toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }
    setStudents(filtered_students);
  };
  const renderModal = () => {
    const startDate: any = eventObj && eventObj.startDateTime ? moment(eventObj.startDateTime) : '';
    const endDate: any = startDate ? moment(eventObj.endDateTime) : '';
    const duration = startDate ? moment.duration(endDate.diff(startDate)).asMinutes() : 0;
    return (
      <CustomModal
        open={open}
        onClose={onClose}
        buttonText={'Save'}
        headerText={'Mark Attendance'}
        onSuccess={markAttendance}
      >
        <div className={classes.contentDiv}>
          {startDate && <div className={classes.eventInfo}>
            <div className={classes.eventInfoItems}><span className={classes.eventInfoLabel}>Subject :</span><div className={classes.subject}>{eventObj.meetingSubject}</div></div>
            <div className={classes.eventInfoItems}><span className={classes.eventInfoLabel}>Start Date :</span><b>{startDate.format('DD/MM/YY')}</b></div>
            <div className={classes.eventInfoItems}><span className={classes.eventInfoLabel}>Start Time :</span><b>{startDate.format('hh:mm a')}</b></div>
            <div className={classes.eventInfoItems}><span className={classes.eventInfoLabel}>Duration (min) :</span><b>{ Math.round( duration )}</b></div>
          </div>}
          <StyledSearchBar
            filter={false}
            onSearch={onSearch}
            searchPlaceHolder={'Search by Id, Name..'}
          ></StyledSearchBar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedStudents.length > 0 && selectedStudents.length < students.length}
                    checked={selectedStudents.length > 0 && selectedStudents.length === students.length}
                    onChange={onSelectAllClick}
                  />
                </TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(students, (student: any) => {
                const id = student.attendee.email.split('@')[0];
                const labelId = `enhanced-table-checkbox-${student.id}`;
                const checked = selectedStudents.includes(student.attendee.email);
                return (
                  <TableRow key={student.id}>
                    <TableCell classes={{ root: classes.cellPadding }}>
                      {' '}
                      <Checkbox
                        checked={checked}
                        onChange={(e: any) => onStudentSelect(e, student)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>{id}</TableCell>
                    <TableCell>{student.attendee.displayName}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {loading && <Loader />}
        </div>
      </CustomModal>
    );
  };
  return <>{renderModal()}</>;
};

export default StudentList;
