import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import CustomModal from '../CustomModal';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import Loader from '../Loader';
import { Visibility } from '@material-ui/icons';
type ShowEmailProps = {
    loader:boolean;
    title:string;
    type:string;
  htmlMail: string;
  disabled: boolean;
  onClick:any;
  buttonText?:string;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));

const CustomDiv = styled.div`
  body {
    overflow: unset !important;
  }
`;

const ShowEmail: React.FC<ShowEmailProps> = ({loader ,title,type, htmlMail,onClick, disabled, buttonText='View Mail' }) => {
  const classes = useStyles();

  const [open, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
      onClick()
    setModalOpen(true);
  };

  const renderModal = () => {
    return (
      <CustomModal
        maxWidth={'md'}
        open={open}
        onClose={handleModalClose}
        headerText={title + type}
      >
       {!loader ? <CustomDiv> {ReactHtmlParser(htmlMail)}</CustomDiv> : <Loader />}
      </CustomModal>
    );
  };

  return (
    <>
      <Button onClick={handleModalOpen} startIcon={<Visibility />} color="primary" variant="outlined" disabled={disabled}>
        <div className={classes.textMargin}>{buttonText}</div>
      </Button>
      {renderModal()}
    </>
  );
};

export default ShowEmail;
