import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getMyRole } from '../../actions';
import Dashboard from '../../components/Dashboard';
import { RootState } from '../../reducer';
import { roles } from '../../util/enums';
import AdminPage from '../admin';
import Error403 from '../auth/error-pages/403';
import Callbacks from '../callback/showCallbacks';
import Course from '../courses/courses';
import Events from '../events';
import AttendanceReport from '../events/attendance/attendance-report/attendance-report';
import LecturerEventsDashboard from '../events/lecturer-events-dashboard';
import ImprovementAreas from '../improvement-areas';
import Institution from '../institution/institution';
import Interventions from '../intervention';
import AddIntervention1 from '../intervention/addIntervention1';
import InterventionsReport from '../intervention/report';
import LecturerDashboard from '../lecturer/dashboard';
import Reports from '../reports';
import EventsSearch from '../search/events';
import Search from '../search/search';
import StudentSearch from '../search/student';
import StudentReport from '../studentReport';
import Analysis from '../time-table-analysis/TimetableDashboard';
import UnitDashboard from '../units';
import AssignmentDetailsTabs from '../units/assignmentDetailsTabs';
import BulkUpload from '../uploads/uploads';
import AuthorizedRoute from './authorized-route';
import { useOktaAuth } from '@okta/okta-react';

export default function DashboardRoutes({ ...rest }) {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.roles?.roles);

  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const getAvatar = async () => {
      const user = await oktaAuth.getUser();
      dispatch(getMyRole(user));
    };
    getAvatar();
  }, [dispatch]);

  return (
    <>
      <Dashboard {...rest}>
        <Switch>
          <Route path="/403" exact component={Error403} />
          <AuthorizedRoute requiredRoles={[roles.ADMIN, roles.LECTURER]} exact component={Events} path="/events" />
          <AuthorizedRoute requiredRoles={[roles.ADMIN]} exact component={Callbacks} path="/callbacks" />
          <AuthorizedRoute requiredRoles={[roles.ADMIN]} exact component={Analysis} path="/timetable/analysis" />
          <Route path="/courses/:courseId" component={Course} />
          <Route path="/student/:id/report-card" component={StudentReport} />
          <Route path="/lecturer/events" component={LecturerEventsDashboard} />

          {/* Unit dashboard */}
          <Route path="/units/:unitId/assignments/:id" component={AssignmentDetailsTabs} />
          <Route path="/units/:unitId" component={UnitDashboard} />

          <Route path="/uploads" component={BulkUpload} />
          <Route path={'/reports/:tab'} component={Reports} />
          <Route path="/search/:tab" component={Search} />
          <Route path="/interventions/add-new" component={AddIntervention1} />
          <Route path="/interventions/report/:id" component={InterventionsReport} />
          <Route path="/interventions" component={Interventions} />
          <Route path="/improvement-areas" component={ImprovementAreas} />

          <AuthorizedRoute requiredRoles={[roles.ADMIN]} path="/students/search" component={StudentSearch} />
          <AuthorizedRoute requiredRoles={[roles.ADMIN]} path="/events/search" component={EventsSearch} />
          <Route
            path="/dashboard"
            render={() => {
              if (!role) return <div>Loading...</div>;
              if (role === roles.STUDENT || role === roles.NO_ACCESS) return <Redirect to="/403" />;
              if (role === roles.ADMIN) return <Institution />;
              else return <Redirect to="/lecturer/dashboard" />;
            }}
          />
          <Route
            path="/lecturer/dashboard"
            render={() => {
              if (!role) return <div>Loading...</div>;
              if (role === roles.STUDENT || role === roles.NO_ACCESS) return <Redirect to="/403" />;
              if (role === roles.LECTURER) return <LecturerDashboard />;
              else return <Redirect to="/dashboard" />;
            }}
          />
          <AuthorizedRoute path="/admin" requiredRoles={[roles.ADMIN]} component={AdminPage} />
          <Route path="/attendance/:attendanceId" component={AttendanceReport} />
          <Route path="/" exact>
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </Dashboard>
    </>
  );
}
