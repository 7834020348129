import {
  GET_INSTITUTION_INDICES,
  ON_INSTITUTION_INDICES_LOADING_FINISHED,
  ON_INSTITUTION_INDICES_LOADING_START,
} from '../actionTypes';
import { ActionType } from './types';
const initialState = {
  indices: {},
  indicesLoader: false,
};

const institution = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_INSTITUTION_INDICES: {
      return {
        ...state,
        indices: action.payload,
      };
    }
    case ON_INSTITUTION_INDICES_LOADING_START: {
      return {
        ...state,
        indicesLoader: true,
      };
    }
    case ON_INSTITUTION_INDICES_LOADING_FINISHED: {
      return {
        ...state,
        indicesLoader: false,
      };
    }
    default:
      return { ...state };
  }
};

export default institution;
