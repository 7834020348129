import { get, post } from "./common";
import { API_URL, PACE_API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { GET_INSTITUTION_INDICES, ON_INSTITUTION_INDICES_LOADING_FINISHED, ON_INSTITUTION_INDICES_LOADING_START } from "../actionTypes";

export const ON_ALL_COURSES_RECEIVED = "ON_ALL_COURSES_RECEIVED";
export const ON_ALL_INSTITUTION_GRAPH_DATA_RECEIVED = "ON_ALL_INSTITUTION_GRAPH_DATA_RECEIVED";
export const GET_COURSE_INDICES_RECEIVED = "GET_COURSE_INDICES_RECEIVED";
export const GET_COURSE_UNITES_RECEIVED = "GET_COURSE_UNITES_RECEIVED";
export const GET_COURSE_INFO_RECEIVED = "GET_COURSE_INFO_RECEIVED";
export const GET_COURSE_GRAPH_DATA_RECEIVED = "GET_COURSE_GRAPH_DATA_RECEIVED";
export const ON_COURSES_RECEIVED = "ON_COURSES_RECEIVED";
export const ON_COURSE_SEARCH_TEXT_CHANGED = "ON_COURSE_SEARCH_TEXT_CHANGED";
export const SET_COURSES_UPLOAD_STATUS = "SET_COURSES_UPLOAD_STATUS";
export const ON_TAB_SELECTED = "ON_TAB_SELECTED";


export const COURSES_URL = async () => {
    return await API_URL() + '/pace/courses'
};

export const LECTURER_URL = async () => {
    return await API_URL() + '/pace/lecturer'
};

export const GRAPH_URL = async () => {
    return await API_URL() + '/graph';
};

export const getAllCourses = (): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
        dispatch({ type: ON_LOADING_START });
        const result = await get(await COURSES_URL());
        dispatch({ type: ON_ALL_COURSES_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
        dispatch({
            type: ON_ALL_COURSES_RECEIVED,
            payload: []
        });
        dispatch({ type: ON_LOADING_FINISHED });
    }
};


export const getInstitutionIndices = (unitCodes: any[] = []
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    dispatch({ type: ON_INSTITUTION_INDICES_LOADING_START });
    try {
        const param = {unitCodes}
        const url = `${await API_URL()}/institution/indices`;
        const result = await post(url, param);
        dispatch({ type: GET_INSTITUTION_INDICES, payload: result });
    } catch (err) {
        dispatch({
            type: GET_INSTITUTION_INDICES,
            payload: {}
        })
    }
    dispatch({ type: ON_INSTITUTION_INDICES_LOADING_FINISHED });
};


export const getCourseUnitesById = (courseId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
        const url = `${await COURSES_URL()}/${courseId}/units`;
        dispatch({ type: ON_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_COURSE_UNITES_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
        dispatch({
            type: GET_COURSE_UNITES_RECEIVED,
            payload: []
        });
        dispatch({ type: ON_LOADING_FINISHED });
    }
};

export const getUnitesByLecturer = (buCode: string,
    semesterCode: string, emailId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
        const url = `${await LECTURER_URL()}/${emailId}/units`;
        dispatch({ type: ON_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_COURSE_UNITES_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
        dispatch({
            type: GET_COURSE_UNITES_RECEIVED,
            payload: []
        });
        dispatch({ type: ON_LOADING_FINISHED });
    }
};

export const getCourseInfoById = (courseId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
        const url = `${await COURSES_URL()}/${courseId}`;
        dispatch({ type: ON_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_COURSE_INFO_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
        dispatch({
            type: GET_COURSE_INFO_RECEIVED,
            payload: []
        });
        dispatch({ type: ON_LOADING_FINISHED });
    }
};

export const getCourseGraphData = (courseId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    try {
        const url = `${await GRAPH_URL()}/course/${courseId}`;
        dispatch({ type: ON_LOADING_START });
        const result = await get(url);
        dispatch({ type: GET_COURSE_GRAPH_DATA_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
        dispatch({
            type: GET_COURSE_GRAPH_DATA_RECEIVED,
            payload: null
        });
        dispatch({ type: ON_LOADING_FINISHED });
    }
};



export const getCourses = (
    searchText: string,
    page: number,
    recordPerPage: number,
    buCode: string, selectedSemester: string
): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
        dispatch({ type: ON_LOADING_START });
        //Call API
        const paceUrl = await PACE_API_URL()
        const result = await post(`${paceUrl}/courses/find?buCode=${buCode}&term=${selectedSemester}`, {
            limit: recordPerPage, offset: (recordPerPage * page) - recordPerPage, searchText: searchText
        });
        dispatch({ type: ON_COURSES_RECEIVED, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
    });
};

export const onCourseSearchTextChanged = (searchText: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
    dispatch({ type: ON_COURSE_SEARCH_TEXT_CHANGED, payload: searchText });
}