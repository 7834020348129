import { GET_TIME_TABLE, ON_ANALYSIS_SEARCH_CHANGED, SET_SELECTED_WEEK, TIME_TABLE_REPORT_FINISHED, TIME_TABLE_REPORT_START, SET_TIME_TABLE_REPORT_RESPONSE } from '../actionTypes';
import { ActionType } from './types';

const initialState = {
  timetable: {},
  week: 1,
  searchText: '',
  reportLoader: false,
  reportData: "",
};

const timetable = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_TIME_TABLE: {
      const { result, week } = action.payload
      const newTimeTable = JSON.parse(JSON.stringify(state.timetable))
      newTimeTable[week] = result
      return {
        ...state,
        timetable: newTimeTable,
      };
    }
    case SET_SELECTED_WEEK: {
      return {
        ...state,
        week: action.payload,
      };
    }
    case ON_ANALYSIS_SEARCH_CHANGED: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case TIME_TABLE_REPORT_START: {
      return {
        ...state,
        reportLoader: true
      }
    }
    case TIME_TABLE_REPORT_FINISHED: {
      return {
        ...state,
        reportLoader: false
      }
    }
    case SET_TIME_TABLE_REPORT_RESPONSE: {
      return {
        ...state,
        reportData: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default timetable;