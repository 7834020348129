import { ThunkAction } from 'redux-thunk';
import { API_URL } from '../config/constants';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { get, post } from './common';

export const IMPROVEMENT_AREA_URL = async () => {
  return `${await API_URL()}/improvement-area`
};

export const GET_IMPROVEMENT_AREA_DATA = 'GET_IMPROVEMENT_AREA_DATA';
export const GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS = 'GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS';
export const ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START = 'ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START';
export const ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED = 'ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED';

export const getImprovementAreas = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = `${await IMPROVEMENT_AREA_URL()}/all`;
    dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result });
    dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_IMPROVEMENT_AREA_DATA,
      payload: [],
    });
    dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
  }
};

export const addImprovementArea =
  (data): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/create`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await post(url, data);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };

export const addStaffForIA =
  (data, id): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/${id}/staff-member/create`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await post(url, data);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };

export const deleteStaffForIA =
  (id, staffId): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/${id}/staff-member/${staffId}/delete`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await get(url);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };

export const updateStaffForIA =
  (data, id, staffId): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/${id}/staff-member/${staffId}/update`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await post(url, data);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };

export const updateImprovementArea =
  (data, id): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/${id}/update`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await post(url, data);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };

export const deleteImprovementArea =
  (id): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        const url = `${await IMPROVEMENT_AREA_URL()}/${id}/delete`;
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_START });
        const result = await get(url);
        if (result.status === 'SUCCESS') {
          dispatch({ type: GET_IMPROVEMENT_AREA_DATA, payload: result.data });
        }
        dispatch({ type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS, payload: result });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_RESPONSE_FOR_IMPROVEMENT_AREA_CRUD_OPERATIONS,
          payload: { status: 'ERROR', message: `Server can't be reached!` },
        });
        dispatch({ type: ON_FETCHING_IMPROVEMENT_AREAS_LOADING_FINISHED });
      }
    };
