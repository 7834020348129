import { ActionType } from './types';
import * as actionTypes from '../actions';

const initialState = {
  reportIdInfo: null,
  nonSubmission: null,
  reportData: null,
  loader: false,
  historicalReports: {},
  searchText: '',
  historyLoader: false,
  response: false,
};

const reports = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_REPORTS: {
      return {
        ...state,
        reportIdInfo: action.payload,
        loader: false,
      };
    }
    case actionTypes.Find_REPORTS: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }
    case actionTypes.ON_HISTORICAL_REPORTS_RECEIVED: {
      return {
        ...state,
        historicalReports: action.payload,
      };
    }
    case actionTypes.ON_HISTORICAL_REPORTS_SEARCH_CHANGED: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case actionTypes.ON_HISTORICAL_REPORTS_SEARCH_START: {
      return {
        ...state,
        historyLoader: true,
      };
    }
    case actionTypes.ON_HISTORICAL_REPORTS_SEARCH_FINISHED: {
      return {
        ...state,
        historyLoader: false,
      };
    }
    case actionTypes.SET_NON_SUBMISSION_REPORTS_RESPONSE: {
      return {
        ...state,
        response: true,
      };
    }

    case actionTypes.RESET_NON_SUBMISSION_REPORTS_RESPONSE: {
      return {
        ...state,
        response: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reports;
