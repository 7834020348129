import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, TextField, Typography, Theme, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(0, 3),
      flexGrow: 1,
    },
    contentSection: {
      padding: theme.spacing(2, 0),
    },
    contentSectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    contentSectionContent: {},
    formGroup: {
      padding: theme.spacing(2, 0),
    },
    fieldGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      marginTop: 0,
      marginBottom: 0,
    },
    flexGrow: {
      flexGrow: 1,
    },
    addButton: {
      marginLeft: theme.spacing(1),
    },
    tags: {
      marginTop: theme.spacing(1),
    },
    minAmount: {
      marginRight: theme.spacing(3),
    },
    maxAmount: {
      marginLeft: theme.spacing(3),
    },
    radioGroup: {},
    actions: {
      padding: theme.spacing(3),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);



const teamTypeOptions = [{value:'UNIT_WISE_ACADEMIC_INTERVENTION', label:'Academic Progression (Unit-Wise)'},
{value:'ACADEMIC_PROGRESSION_INTERVENTION', label:'Academic Progression'},
{value:'NON_SUBMISSION_INTERVENTION', label:'Non-Submission'},
{value:'ASSESSMENT_WISE_ACADEMIC_INTERVENTION', label:'Assignment Result (<50%)'}];


type InterventionFiltersProps = {
  className?: string;
  values: any;
  setValues: (state: any) => void;
};

const InterventionFilters: React.FC<InterventionFiltersProps> = ({ values, setValues }) => {
  const classes = useStyles();

  const handleFieldChange = (event: any, field: any, value: any) => {
    if (event) {
      event.persist();
    }

    setValues((prevValues: any) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentSection}>
        <div className={classes.contentSectionHeader}>
          <Typography variant="h5">Event Filters</Typography>
        </div>
        <Divider />
        <div className={classes.contentSectionContent}>
          {/* <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Course Code"
              margin="dense"
              name="courseCode"
              onChange={(event) => handleFieldChange(event, 'courseCode', event.target.value)}
              value={values.courseCode}
              variant="outlined"
            />
          </div> */}
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Start Date"
              margin="dense"
              name="startDate"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleFieldChange(event, 'startDate', event.target.value)}
              value={values.startDate}
              variant="outlined"
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="End Date"
              margin="dense"
              name="endDate"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleFieldChange(event, 'endDate', event.target.value)}
              value={values.endDate}
              variant="outlined"
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Intervention Type"
              margin="dense"
              name="interventionType"
              select
              SelectProps={{
                multiple: true,
                value: values.interventionType,
                onChange: (event) => handleFieldChange(event, 'interventionType', event.target.value),
              }}
              variant="outlined"
            >
              {teamTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionFilters;
