import { ActionType } from './types';
import * as actionTypes from '../actions';

const initialState = {
  fileLoadPercentage: 0,
  response: '',
  errorResponse: {},
  error:false
};

const bulkUpload = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_FILE_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.UPLOAD_SUCCESS: {
      return {
        ...state,
        response: action.payload,
        error:false
      };
    }
    case actionTypes.UPLOAD_ERRORS: {
      return {
        ...state,
        errorResponse: action.payload,
        error:true
      };
    }
    default: {
      return state;
    }
  }
};

export default bulkUpload;
