import { Card, Divider, Paper } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Event } from '../../../../models/event';
import { theme } from '../../../../theme';
import { sleep } from '../../../../util/common';
import { eventTypes } from '../../../../util/constants';

const Container = styled.div`
  /* min-width: 400px; */
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-content: center;

  .title {
    margin-bottom: 10px;
    color: ${theme.palette.primary.main};
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
  }
`;

const Date = styled(Paper)`
  min-width: 200px;
  height: 200px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.palette.primary.main};
  text-align: center;
`;

const CalendarSmall = styled.div`
  font-size: 20px;
  font-weight: bolder;
  color: white;
`;

const CalendarLarge = styled.div`
  font-size: 60px;
  height: 80px;
  font-weight: bolder;
  color: white;
`;

const TimeDisplay = styled(CalendarSmall)`
  font-size: 18px;
  width: 100%;
  background-color: white;
  color: ${theme.palette.primary.main};
`;

const GraphContainer = styled.div`
  position: relative;
  width: 400px;
  height: 200px;
`;

const Total = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bolder;
  color: ${theme.palette.primary.main};
  text-align: center;
  line-height: 20px;
  > span {
    font-size: 14px;
  }
`;

const Metadata = styled.div`
  /* flex-grow: 1; */
  display: flex;
  width: 200px;
  flex-direction: column;
  font-size: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 8px;
  & span {
    font-weight: bolder;
    font-size: 40px;
    color: ${theme.palette.primary.main};
  }
  > :last-child {
    margin-bottom: auto;
  }
`;

const StyledDiver = styled(Divider)`
  width: 100%;
  margin-bottom: auto;
`;

const AttendedCount = ({ event }: { event: Event }) => {
  const [data, setGraphData] = useState<any[]>([]);
  const [averageSession, setAverageSession] = useState<number>(0);
  const [mobileAttendance, setMobileAttendance] = useState(0);

  useEffect(() => {
    const setData = async (attended: number, absent: number) => {
      setGraphData([
        {
          id: 'Absent',
          label: 'Absent',
          value: absent,
          color: '#3f4747',
        },
        {
          id: 'Attended',
          label: 'Attended',
          value: attended,
          color: '#043278',
        },
      ]);
    };

    const setValues = async () => {
      let attendedCount: number = 0;
      let absentCount: number = 0;
      let avgSession = 0;
      let mblAtt = 0;
      let totalDur = 0;

      if (event.methodOfDelivery === eventTypes.ONLINE) {
        const attendedList = event.onlineAttendances.filter((oa) => oa.activityIndex > 0.2);
        attendedCount = attendedList.length;
        absentCount = event.onlineAttendances.length - attendedCount;
        event.onlineAttendances.forEach((x) => {
          avgSession += x.totalDuration;
          x.sessions.forEach((s) => {
            totalDur += moment(s.leftAt).diff(s.joinedAt);
            if (s.deviceType.toLowerCase() === 'ios' || s.deviceType.toLowerCase() === 'android') {
              mblAtt += moment(s.leftAt).diff(s.joinedAt);
            }
          });
        });
        avgSession /= event.onlineAttendances.length;
        setMobileAttendance(Math.floor((mblAtt / totalDur) * 100));
      } else if (event.methodOfDelivery === eventTypes.PHYSICAL) {
        attendedCount = event.physicalAttendances.filter((pa) => !!pa.timestamp).length;
        avgSession = (event.totalDuration * attendedCount) / event.physicalAttendances.length;
        absentCount = event.physicalAttendances.length - attendedCount;
      } else {
        const physicalAttendedCount = event.physicalAttendances.filter((pa) => !!pa.timestamp).length;
        attendedCount = physicalAttendedCount + event.onlineAttendances.filter((oa) => oa.activityIndex > 0.2).length;
        event.onlineAttendances.forEach((x) => {
          avgSession += x.totalDuration;
          x.sessions.forEach((s) => {
            totalDur += moment(s.leftAt).diff(s.joinedAt);
            if (s.deviceType.toLowerCase() === 'ios' || s.deviceType.toLowerCase() === 'android') {
              mblAtt += moment(s.leftAt).diff(s.joinedAt);
            }
          });
        });
        avgSession += event.totalDuration * physicalAttendedCount;
        avgSession /= event.physicalAttendances.length + event.onlineAttendances.length;
        absentCount = event.physicalAttendances.length + event.onlineAttendances.length - attendedCount;
        setMobileAttendance(Math.floor((mblAtt / totalDur) * 100));
      }
      setData(0, 0);
      await sleep(1000);
      setData(attendedCount, absentCount);
      setAverageSession(avgSession);
    };
    setValues();
  }, [event]);

  const total =
    event.methodOfDelivery === eventTypes.ONLINE
      ? event.onlineAttendances.length
      : event.methodOfDelivery === eventTypes.PHYSICAL
      ? event.physicalAttendances.length
      : event.physicalAttendances.length + event.onlineAttendances.length;
  const diff = moment(event.endDateTime).diff(moment(event.startDateTime));
  const eventDate = moment(event.startDateTime);
  const month = eventDate.format('MMMM');
  const dayOfMonth = eventDate.format('DD');
  const dayOfWeek = eventDate.format('dddd');
  const start = eventDate.format('H:mm a');
  const end = moment(event.endDateTime).format('H:mm a');

  return (
    <Container>
      <Date elevation={2}>
        <CalendarSmall>{month}</CalendarSmall>
        <CalendarLarge>{dayOfMonth}</CalendarLarge>
        <CalendarSmall>{dayOfWeek}</CalendarSmall>
        <TimeDisplay>
          {start} - {end}
        </TimeDisplay>
      </Date>
      <Metadata>
        Duration
        <StyledDiver />
        <div>
          {moment.utc(diff).format('H') !== '0' && <span>{moment.utc(diff).format('H [hrs]')} </span>}
          <span>{moment.utc(diff).format('mm [mins]')}</span>
        </div>
      </Metadata>

      <GraphContainer>
        <ResponsivePie
          colors={{ datum: 'data.color' }}
          data={data}
          margin={{ top: 30, left: 30, bottom: 30, right: 30 }}
          innerRadius={0.7}
          motionConfig={{ damping: 30 }}
          animate={true}
          activeOuterRadiusOffset={8}
          borderColor={'#f3f6f8'}
          startAngle={10}
          endAngle={370}
          padAngle={1}
          theme={{ fontSize: 14, fontFamily: 'Mullish' }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 15]] }}
        />
        <Total>
          {total}
          <br />
          <span>Invited</span>
        </Total>
      </GraphContainer>
      <Metadata>
        Avg. Attendance
        <StyledDiver />
        {moment.utc(averageSession).format('H') !== '0' && <span>{moment.utc(averageSession).format('H [hrs]')}</span>}
        <span>{moment.utc(averageSession).format('mm [mins]')}</span>
      </Metadata>
      {/* <Metadata>
        Using Smartphone
        <StyledDiver />
        <span>{mobileAttendance + '%'}</span>
      </Metadata> */}
    </Container>
  );
};

export default AttendedCount;
