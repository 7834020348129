import React from 'react';

import Risk from '../../components/reports/risk';
import Progression from '../../components/reports/progression';
import Interim from '../../components/reports/interim';
import Attendance from '../../components/reports/attendance';
import NonSubmissionReport from '../../components/reports/nonSubmission';
import Archives from '../../components/reports/archives';
import { RootState } from '../../reducer';
import { useSelector } from 'react-redux';

export default function InstitutionalReports() {
  const role = useSelector((state: RootState) => state.roles?.roles);
  return (
    <>
      {role === 'ADMIN' && (
        <>
          <Risk />
          <Attendance />
        </>
      )}
      <Progression />
      <NonSubmissionReport />
      {role === 'ADMIN' && (
        <>
          <Interim />
          <Archives />
        </>
      )}
    </>
  );
}
