import { Avatar, Divider, Paper, Table, TableCell, TableRow } from '@material-ui/core';
import { Book, Email, People, Phone } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const StyledContainer = styled(Paper)`
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2)};
`;

const StyledAvatar = styled(Avatar)`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: solid 1px ${theme.palette.primary.main};
  img {
    width: 105%;
    height: 105%;
  }
`;

const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(0, 2)};
  justify-content: space-between;
  height: 100%;
  margin-right: auto;
`;

const Name = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const ProviderId = styled.span`
  font-weight: bold;
`;

const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;

  table {
    font-size: 14px;
    min-width: 400px;
    tr {
      td {
        width: 100%;
        text-align: start;
        padding-left: ${theme.spacing(2)}px;
        vertical-align: top;
      }
      td:first-child {
        font-size: 14px;
        font-weight: bolder;
        min-width: 80px;
        width: 120px !important;
      }
    }
  }
`;

const IconAndText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: top;
  > * {
    margin-right: ${theme.spacing(1)}px;
  }
`;

const DetailsDivider = styled(Divider)`
  margin: ${theme.spacing(1, 4, 1, 0)};
`;

type detailsProps = {
  className?: string;
  providerId: string | number;
  name: string;
  profileSrc: string;
  coeType: string;
  location: string;
  program: string;
  studentType: string;
  studentCategory: string;
  studentStatus: string;
  mobileNumber: string;
  email: string;
};
const StudentDetails: React.FC<detailsProps> = ({
  className,
  providerId,
  name,
  profileSrc,
  coeType,
  location,
  program,
  studentCategory,
  studentStatus,
  studentType,
  mobileNumber,
  email,
  ...rest
}) => {
  return (
    <StyledContainer elevation={2}>
      <StyledAvatar variant="circular" alt="complex" src={profileSrc} />
      <ColumnOne>
        <Name>{name || '--'}</Name>
        <DetailsDivider />
        <IconAndText>
          <People />
          <ProviderId>{providerId || '--'}</ProviderId>
        </IconAndText>
        <IconAndText>
          <Book /> {program || '--'}
        </IconAndText>
        <IconAndText>
          <Phone /> {mobileNumber}
        </IconAndText>
        <IconAndText>
          <Email /> {email}
        </IconAndText>
      </ColumnOne>
      <DetailsCard>
        <Table size="small">
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell>{location ? location : '--'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>COE</TableCell>
            <TableCell>{coeType ? coeType : '--'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>{studentType ? studentType : '--'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>{studentStatus ? studentStatus : '--'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>{studentCategory ? studentCategory : '--'}</TableCell>
          </TableRow>
        </Table>
      </DetailsCard>
    </StyledContainer>
  );
};
export default StudentDetails;
