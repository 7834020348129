import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Page from '../../components/Page';
import _ from 'lodash';
import { RootState } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { uploadEnglishLevel, resetResultUploadProgress, uploadAssesmentDueDate } from '../../actions';
import Files from '../../components/Files';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorDetails from './ErrorDetails';
import Loader from '../../components/Loader';
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp';
import { indices, uploadTypes } from '../../util/enums';
import { uploadSamples } from '../../util/constants';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    snackbar: {
      top: '-432px',
      left: 'auto',
      right: '24px',
      position: 'absolute',
    },
    download: {
      '& a': {
        color: '#c9696e',
        '&:hover': {
          textDecoration: 'none',
        },
      },
      float: 'right',
      border: '1px solid',
      backgroundColor: '#ffff',
      borderColor: '#c9696e',
      '&:hover': {
        backgroundColor: '#ffff',
        borderColor: '#c9696e',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ffff',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })
);

export default function BulkUploadOptions() {
  const dispatch = useDispatch();
  const fileLoadPercentage = useSelector((state: RootState) => state.bulkUpload?.fileLoadPercentage);
  const response = useSelector((state: RootState) => state.bulkUpload?.response);
  const errorResponse = useSelector((state: RootState) => state.bulkUpload?.errorResponse);
  const classes = useStyles();
  const [uploadError, setUploadError] = useState('');
  const [noOfRowsSuccess, setNoOfRowsSuccess] = useState('');
  const [errorRows, setErrorRows] = useState({});
  const [selectedUploadType, setSelectedUploadType] = useState(indices.ENGLISH);
  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadFile = async (file: any) => {
    setIsUploading(true);
    if (selectedUploadType === indices.ENGLISH) {
      dispatch(uploadEnglishLevel(file));
    } else {
      dispatch(uploadAssesmentDueDate(file));
    }
  };
  useEffect(() => {
    if (response) {
      setIsUploading(false);
      setNoOfRowsSuccess(response);
      setErrorRows(0);
      setSuccessOpenSnackbar(true);
    }
    if (!_.isEmpty(errorResponse)) {
      setIsUploading(false);
      setErrorRows(errorResponse);
      setErrorOpenSnackbar(true);
    }
  }, [response, errorResponse]);
  const onFileSelected = () => {
    setNoOfRowsSuccess('');
    // @ts-ignore
    setErrorRows(0);
    dispatch(resetResultUploadProgress());
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUploadType(event.target.value as string);
  };

  const onRemoveFile = () => {
    setErrorRows({});
    onFileSelected();
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpenSnackbar(false);
    setErrorOpenSnackbar(false);
  };

  const onProcessComplete = () => {
    onFileSelected();
    setSuccessOpenSnackbar(false);
    setErrorOpenSnackbar(false);
  };

  return (
    <Page className={classes.root} title="Bulk Upload">
      <Container maxWidth={false} className={classes.container}>
        <Card style={{ boxShadow: 'none' }}>
          <CardHeader
            action={
              <FormControl style={{ width: 200 }}>
                <InputLabel id="demo-simple-select-label">Upload Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedUploadType}
                  onChange={handleChange}
                >
                  <MenuItem value={indices.ENGLISH}>English</MenuItem>
                  <MenuItem value={uploadTypes.ASSIGNMENT_DUE_DATE}>Assignment due date</MenuItem>
                </Select>
              </FormControl>
            }
            title="Bulk Upload"
            subheader={
              noOfRowsSuccess !== '' && (
                <Typography style={{ color: 'green', fontWeight: 600 }}>
                  {`${noOfRowsSuccess}`}
                  <Typography></Typography>
                </Typography>
              )
            }
          />
          <Divider />
          {isUploading && <Loader />}
          <CardContent className={classes.content}>
            <Files
              onUploadFile={onUploadFile}
              uploadError={uploadError}
              setUploadError={setUploadError}
              isUploading={isUploading}
              fileLoadPercentage={fileLoadPercentage}
              onFileSelected={onFileSelected}
              onRemoveFile={onRemoveFile}
            />
            <Button
              variant="contained"
              color="default"
              size="small"
              className={classes.download}
              endIcon={
                <ArrowDownwardSharp fontSize="small" style={{ color: '#c9696e' }}>
                  Sample Download
                </ArrowDownwardSharp>
              }
            >
              <a
                href={process.env.PUBLIC_URL + `/csv/${uploadSamples[selectedUploadType]}.csv`}
                download={`${uploadSamples[selectedUploadType]}.csv`}
              >
                Sample Download
              </a>
            </Button>
          </CardContent>
          {!_.isEmpty(errorRows) ? (
            <ErrorDetails type={selectedUploadType.split('_').join(' ')} errorResponse={errorResponse} />
          ) : null}
          <Snackbar className={classes.snackbar} onClose={handleClose} open={openErrorSnackbar}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
              Error in Uploaded File
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleClose}
            open={openSuccessSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleClose}
              severity="success"
              action={
                <Button color="primary" size="small" onClick={onProcessComplete}>
                  Done
                </Button>
              }
            >
              {noOfRowsSuccess}
            </MuiAlert>
          </Snackbar>
        </Card>
      </Container>
    </Page>
  );
}
