import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Button, Drawer, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    drawer: {
      width: 420,
      maxWidth: '100%',
    },
    header: {
      padding: theme.spacing(2, 1),
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    field: {
      marginTop: 0,
      marginBottom: 0,
    },
    flexGrow: {
      flexGrow: 1,
    },
    addButton: {
      marginLeft: theme.spacing(1),
    },
    tags: {
      marginTop: theme.spacing(1),
    },
    minAmount: {
      marginRight: theme.spacing(3),
    },
    maxAmount: {
      marginLeft: theme.spacing(3),
    },
    radioGroup: {},
    actions: {
      padding: theme.spacing(3),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

type FilterProps = {
  open: boolean;
  onClose: () => void;
  onFilter: (body: any) => void;
  className?: string;
  onClear: () => void;
  values: any;
  setValues: (state: any) => void;
  initialValues: any;
};

const Filter: React.FC<FilterProps> = ({
  open,
  onClose,
  onFilter,
  className,
  children,
  onClear,
  values,
  setValues,
  initialValues,
  ...rest
}) => {
  const classes = useStyles();

  const handleClear = () => {
    setValues({ ...initialValues });
    onClear();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (onFilter) {
      onFilter(values);
    }
  };

  return (
    <Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant="temporary">
      <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        {children}
        <div className={classes.actions}>
          <Button fullWidth onClick={handleClear} variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained" onClick={onClose}>
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default Filter;
