import { Button, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { EditOutlined, OpenInNewOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {  getEventsForAttendace, GET_RESPONSE_FOR_MARK_ATTENDANCE } from '../../actions';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import { MSGraphMeeting } from '../../models/ms-graph/meeting';
import MuiAlert from '@material-ui/lab/Alert';
import { RootState } from '../../reducer';
import moment from 'moment-timezone';
import StudentList from './StudentList';
import _ from 'lodash';

const ActionCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

export default function ManualAttendance() {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.events.loader);
  const response = useSelector((state: RootState) => state.events?.response);
  const [open, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({} as any);
  const [openSnackBar, setOpenSnacbar] = useState(false);
  const pastMeetings = useSelector((state: RootState) => state.events.eventsForAttendance);

  useEffect(() => {
    if (!_.isEmpty(response)) {
      setOpenSnacbar(true);
    }
  }, [response]);

  useEffect(() => {
    dispatch(getEventsForAttendace());
  }, [dispatch]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: GET_RESPONSE_FOR_MARK_ATTENDANCE,
      payload: null,
    });
    setOpenSnacbar(false);
  };

  const openStudentList = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  return (
    <Paper style={{ marginTop: 10 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Subject</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Duration (min)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {pastMeetings && pastMeetings.length > 0 ? (
          <TableBody>
            {pastMeetings && pastMeetings.map((m) => {
              const startDate = moment(m.startDateTime);
              const endDate = moment(m.endDateTime);
              const duration = moment.duration(endDate.diff(startDate)).asMinutes();
              return (
                <TableRow key={m.id}>
                  <TableCell>{m.meetingSubject}</TableCell>
                  <TableCell>{startDate.format('DD/MM/YY')}</TableCell>
                  <TableCell>{startDate.format('hh:mm a')}</TableCell>
                  <TableCell>{Math.round( duration )}</TableCell>
                  <ActionCell>
                  <Button onClick={()=>openStudentList(m)} size="small" color="primary" variant="outlined">
                      Attendance
                    </Button>
                  </ActionCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : null}
      </Table>
      <Snackbar onClose={handleClose} open={openSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={response?.status === 'ERROR' ? "error" : 'success'}>
          {response?.message}
        </MuiAlert>
      </Snackbar>
      <StudentList eventId={selectedEvent.id} eventObj={selectedEvent} open={open} onClose={handleModalClose} />
      {loading && !open && <Loader />}
    </Paper>
  );
}
