import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import PerfectScrollBar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import LinearProgressWithLabel from '../../../../components/linear-progress-with-label';
import Page from '../../../../components/Page';
import { Event, OnlineAttendance, PhysicalAttendanceModified } from '../../../../models/event';
import Session from '../../../../models/session';
import { theme } from '../../../../theme';
import { isNumeric } from '../../../../util/common';
import AttendanceGraph from '../attendance-graph/attendance-graph';
import './attendance-report.scss';

const StyledCard = styled(Card)`
  margin: ${theme.spacing(2, 0)};
`;

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none !important;
  color: white !important;
`;

const StyledContent = styled(CardContent)`
  padding: 0;
`;

const ScrollBar = styled(PerfectScrollBar)`
  max-height: 600px;
`;

interface Props {
  event: Event;
}

const BlendedAttendanceReport: React.FC<Props> = ({ event }: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<OnlineAttendance[]>([]);
  const [physicalAttendanceModified, setPhysicalAttendanceModified] = useState<PhysicalAttendanceModified[]>([]);
  const [csvData, setCsvData] = useState<any>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const { physicalAttendances = [], onlineAttendances = [], startDateTime, endDateTime } = event;
    const physicalAttendancesModified = physicalAttendances.map((x) => {
      return {
        ...x,
        activityIndex: x.timestamp ? 1 : 0,
        totalDuration: x.timestamp ? event.totalDuration : 0,
        sessions: x.timestamp
          ? [
              {
                joinedAt: new Date(startDateTime),
                leftAt: new Date(endDateTime),
                deviceType: 'physical',
                productType: '',
              },
            ]
          : ([] as Session[]),
      };
    });
    const allAttendances = [...onlineAttendances, ...physicalAttendancesModified];
    allAttendances
      .sort((a, b) => {
        return a.activityIndex < b.activityIndex ? 1 : b.activityIndex < a.activityIndex ? -1 : 0;
      })
      .sort((a, b) => {
        // Put organisers at the front
        const aId = a.attendee.email.split('@')[0];
        const bId = b.attendee.email.split('@')[0];
        return isNumeric(aId) ? 1 : isNumeric(bId) ? -1 : 0;
      });
    setData(Object.assign([], allAttendances));
    setPhysicalAttendanceModified(physicalAttendancesModified);
    const blendedCsvData = allAttendances.map((attendance) => {
      return {
        ID: attendance.attendee.email.split('@')[0],
        'Display Name': attendance.attendee.displayName,
        Duration: Math.floor(attendance.totalDuration / (1000 * 60)),
        'Activity Index': attendance.activityIndex.toFixed(2),
      };
    });
    setCsvData(blendedCsvData);
  }, []);

  if (!event) {
    return (
      <div>
        <h4>Attendance Report</h4>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: '100%' }}
          ></div>
        </div>
      </div>
    );
  }
  const startIndex = page * rowsPerPage;
  const attendees = data.slice(startIndex, startIndex + rowsPerPage);

  const participants = (
    <StyledCard>
      <CardHeader
        title="Participants"
        action={
          csvData && csvData.length ? (
            <StyledCSVLink data={csvData} filename={'Attendance_Report.csv'}>
              <Button startIcon={<CloudDownload />} color="primary" variant="contained">
                Export CSV
              </Button>
            </StyledCSVLink>
          ) : (
            <div />
          )
        }
      />
      <StyledContent>
        <ScrollBar>
          <Table stickyHeader size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Activity Index</TableCell>
                <TableCell align="center">Duration (minutes)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendees.map((ad) => {
                const id = ad.attendee.email.split('@')[0];
                const duration = Math.floor(ad.totalDuration / (1000 * 60));
                const activity_class_name =
                  ad.activityIndex < 0.5 ? 'bg-danger' : ad.activityIndex < 0.75 ? 'bg-warning' : 'bg-success';
                return (
                  <TableRow key={ad.attendee.id}>
                    <TableCell>{id}</TableCell>
                    <TableCell>{ad.attendee.displayName}</TableCell>
                    <TableCell align="center">
                      <LinearProgressWithLabel value={ad.activityIndex * 100} />
                      {/* <div className={'progress ' + activity_class_name}>
                        <div
                          className={'progress-bar'}
                          style={{
                            width: (ad.activityIndex * 100).toFixed(0) + '%',
                          }}
                        >
                          {(ad.activityIndex * 100).toFixed(0)}
                        </div>
                      </div> */}
                    </TableCell>
                    <TableCell align="center">{duration}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
               <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={event.onlineAttendances.length + event.physicalAttendances.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </TableRow>
            </TableFooter>
          </Table>
        </ScrollBar>
       
      </StyledContent>
    </StyledCard>
  );

  const title = (event.meetingSubject ? event.meetingSubject + ' | ' : '') + 'Attendance Report';
  return (
    <Page title={title}>
      <StyledCard>
        <CardHeader title="Attendance Graph" />
        <StyledContent>
          <AttendanceGraph event={event} physicalAttendanceModified={physicalAttendanceModified} />
        </StyledContent>
      </StyledCard>

      {participants}
    </Page>
  );
};

export default BlendedAttendanceReport;
