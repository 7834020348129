import { Button, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { useEffect } from 'react';
import FileDownload from "js-file-download";
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInterventionTriggerData, getInterventionTriggerDownloadData, getStudentAssignmentInterventionDataById } from '../../actions';
import Loader from '../../components/Loader';
import ShowEmail from '../../components/Modals/showEmail';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import { getRandomForRange } from '../../util/array';
import { InterventionReportRouteParams } from '../../util/types';
import { Data, EnhancedTableHead } from './components/report/tableHead_Sorting';
import { interventionReportStyles } from './intervention_styles';

const green_color = '#006300bf';
const red_color = '#ff9898';
const interventionActions = ['A1', 'A2', 'A3'];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const useStyles =interventionReportStyles

export default function InterventionsReport(props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const htmlMail = useSelector((state: RootState) => state.studentInterventionLogs?.htmlMail);
  const contentLoader = useSelector((state: RootState) => state.studentInterventionLogs?.contentLoader);
  const dispatch = useDispatch();
  const { id } = useParams<InterventionReportRouteParams>();

  const interventionTriggerData = useSelector((state: RootState) => state.intervention?.interventionTriggerData);

  const interventionTriggerLoader = useSelector((state: RootState) => state.intervention?.interventionTriggerLoader);
  useEffect(() => {
    dispatch(getInterventionTriggerData(id));
  }, [dispatch, id]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEmailContent = (id)=>{
    dispatch(getStudentAssignmentInterventionDataById(id))
}

const downloadReport = async() => {
  const studentAffected:any =  await dispatch(getInterventionTriggerDownloadData(id));
  const fileName = "Intervention_Report.csv";
  FileDownload(studentAffected.data, decodeURIComponent(fileName));
}


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, interventionTriggerData.mails?.length - page * rowsPerPage);

  return (
    <Page
      header={'Intervention Strategy Analysis Report'}
      subHeader={interventionTriggerData.intervention?.label}
      showBackButton={true}
    >
      <div style={{ display: 'grid', fontSize: '15px', color: 'grey' }}>
        <span>
          <b>Type:</b> {interventionTriggerData.intervention?.type}
        </span>
        <span>
          <b>Trigger:</b> {interventionTriggerData.intervention?.trigger}
        </span>
        <span>
          <b>Triggered On:</b> {moment(interventionTriggerData.triggeredOn).format('ddd, MMM DD YYYY')}
        </span>
        <span>
          <b>For following units:</b> {interventionTriggerData.intervention?.units.split(',').join(', ')}
        </span>
      </div>
      {/* <div style={{ margin: 10 }}>
        <Watermark textColor="#626060" text="Will be updated soon">
          <div style={{ backgroundColor: 'lightgrey' }}>
            <LineGraphForIntervention />
          </div>
        </Watermark>
      </div> */}
       <br />
      <br />
      <div style={{ display: 'flex' }}>
        <div style={{ fontWeight: 'bold', fontSize: '21px', color: '#444444' }}>
          Students effected by intervention
        </div>
        <Button
            color="secondary"
            style={{ marginLeft: 'auto', display: 'none' }}
            size="small"
            variant="outlined"
            startIcon={
              <DownloadIcon color={'secondary'}/>
            }
            onClick={() => downloadReport()}
          >
            Download
          </Button>
      </div>
      <Divider />
      <br />
      <br />
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {!interventionTriggerLoader ? (
          <>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={interventionTriggerData.mails.length}
                />
                <TableBody>
                  {stableSort(interventionTriggerData.mails, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      // const indexToShow = getRandomForRange(35, 65);
                     // const action = getRandomForRange(0, 4);
                      const action = getRandomForRange(0, 0);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">{row.email.split('@')[0]}</TableCell>
                          <TableCell align="center" scope="row" padding="none">
                            {row.status.toUpperCase() === 'YES' ? (
                              <span style={{ color: green_color }}>Delivered</span>
                            ) : (
                              <span style={{ color: red_color }}>
                                <b>Undelivered</b>
                              </span>
                            )}
                          </TableCell>

                          <TableCell align="center">
                          <ShowEmail loader={contentLoader}
      title={'Intervention Email - '}
                              type={row.email.split('@')[0]}
                              onClick={()=>getEmailContent(row.id)}
                              htmlMail={htmlMail}
                              disabled={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={interventionTriggerData.mails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Loader />
        )}
      </Paper>
    </Page>
  );
}
