
import { ActionType } from './types'
import * as actionTypes from "../actions";
import { ON_ISSUES_LOADING_START, ON_ISSUES_LOADING_FINISHED } from '../actionTypes/issues';

const initialState = {
  issues: {},
  details: {},
  issuesLoader: false
};

const issues = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_ISSUES_BY_STUDENT_ID: {
      const { data, id } = action.payload
      const newIssues = JSON.parse(JSON.stringify(state.issues))
      newIssues[id] = data;
      return {
        ...state,
        issues: newIssues
      };
    }
    case ON_ISSUES_LOADING_START: {
      return {
        ...state,
        issuesLoader: true
      };
    }
    case ON_ISSUES_LOADING_FINISHED: {
      return {
        ...state,
        issuesLoader: false
      };
    }
    case actionTypes.GET_TICKET_DETAILS_BY_ID: {
      const { data, id } = action.payload
      const newDetails = JSON.parse(JSON.stringify(state.details))
      newDetails[id] = data;
      return {
        ...state,
        details: newDetails
      };
    }

    default: {
      return state;
    }
  }
};

export default issues;
