import React, { useEffect, useState } from 'react';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  Theme,
} from '@material-ui/core';
import CustomModal from '../CustomModal';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DetailsOutlinedIcon from '@material-ui/icons/DetailsOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { getInterventionDataById } from '../../actions';
import Loader from '../Loader';
type ShowInterventionTriggerProps = {
  id: number;
  interventionDetails: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    pagination: {
      position: 'relative',
      fontSize: '14px',
      marginLeft: '10px',
      marginBottom: '9px',
      fontWeight: 'bold',
      marginTop: '14px',
    },
    textMargin: {
      marginLeft: '8px',
    },
  })
);

interface ITriggerDetails {
  id:number;
triggeredOn:Date;
mails:any
}

const ShowInterventionTrigger: React.FC<ShowInterventionTriggerProps> = ({
  id,
  interventionDetails,
}) => {
  const classes = useStyles();

  const [open, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const triggerDetails = useSelector((state: RootState) => state.intervention?.interventionDataById);

  const interventionByIdLoader = useSelector((state: RootState) => state.intervention?.interventionByIdLoader);

  useEffect(() => {
   
  }, [dispatch, id]);


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    dispatch(getInterventionDataById(id));
    setModalOpen(true);
  };

  const renderModal = () => {
    return (
      <CustomModal
        maxWidth={'md'}
        open={open}
        onClose={handleModalClose}
        headerText={`Trigger Details for Intervention: ${interventionDetails.type} (${interventionDetails.trigger})`}
      >
    { !interventionByIdLoader ?     (     <Table>
          <TableHead style={{ backgroundColor: '#eeeeee' }}>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Triggered On</TableCell>
              <TableCell align="center">Students Reached</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
    <TableBody>
            {triggerDetails.length > 0 ? triggerDetails.map((trigger:ITriggerDetails) => (
              <TableRow key={trigger.id}>
                <TableCell component="th" scope="row" align="center">
                  {trigger.id}
                </TableCell>
                <TableCell align="center">{moment(trigger.triggeredOn).format('ddd, MMM DD YYYY')}</TableCell>
                <TableCell align="center">{trigger.mails.length}</TableCell>
                <TableCell align="center">
                  <Link
                    className="noUnderLine"
                    to={{
                      pathname: `/interventions/report/${trigger.id}`,
                      state: {
                        labels: interventionDetails.label,
                        units: interventionDetails.units,
                        trigger: interventionDetails.trigger,
                        type: interventionDetails.type,
                        mails: trigger.mails,
                      },
                    }}
                  >
                    <Button
                      color="secondary"
                      className={classes.button}
                      startIcon={<MultilineChartIcon />}
                      size="small"
                      variant="outlined"
                    >
                      Report
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            )) : <><div className="text-align-center">No Records Found</div></>}
          </TableBody> 
        </Table>) : <div className="text-align-center"><Loader /></div>}
      </CustomModal>
    );
  };

  return (
    <>
      <Button
        onClick={handleModalOpen}
        color="secondary"
        variant="outlined"
        size="small"
        startIcon={<DetailsOutlinedIcon />}
      >
        <div className={classes.textMargin}>Details</div>
      </Button>
      {renderModal()}
    </>
  );
};

export default ShowInterventionTrigger;
