import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Theme,
  Typography,
  Snackbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Backspace from '@material-ui/icons/Backspace';
import Page from '../../../components/Page';
import { RootState } from '../../../reducer';

import MuiAlert from '@material-ui/lab/Alert';
import Files from '../../../components/Files';
import ErrorDetails from './ErrorDetails';
import { uploadAssignmentsResult, resetUploadAssignmentsResultData } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp';
import ClearDb from './clearDb';
import WarningDetails from './WarningDetails';
import { buCodes } from '../../../util/enums';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    snackbar: {
      top: '-300px',
      marginLeft: '175px',
    },
    download: {
      '& a': {
        color: '#c9696e',
        '&:hover': {
          textDecoration: 'none',
        },
      },
      margin: '5px',
      float: 'right',
      border: '1px solid',
      backgroundColor: '#ffff',
      borderColor: '#c9696e',
      '&:hover': {
        backgroundColor: '#ffff',
        borderColor: '#c9696e',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ffff',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })
);

export default function AssignmentsResultUpload({ taskName, id, assignmentId, handleModalClose, unitCode }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadError, setUploadError] = useState('');
  const [noOfRowsSuccess, setNoOfRowsSuccess] = useState('');
  const [errorRows, setErrorRows] = useState([]);
  const [warningRows, setWarningRows] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);

  const fileLoadPercentage = useSelector((state: RootState) => state.units?.fileLoadPercentage);
  const response = useSelector((state: RootState) => state.units?.assignmentUploadResponse);
  const errorResponse = useSelector((state: RootState) => state.units?.errorResponse);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const [dataFormat, setDataFormat] = useState('');

  const handleDataFormat = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDataFormat(event.target.value as string);
  };

  const onFileSelected = () => {
    // @ts-ignore
    setErrorRows([]);
    setWarningRows([]);
    dispatch(resetUploadAssignmentsResultData());
    setNoOfRowsSuccess('');
  };

  const onRemoveFile = () => {
    setErrorRows([]);
    setWarningRows([]);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpenSnackbar(false);
    setErrorOpenSnackbar(false);
    setNoOfRowsSuccess('');
  };

  const onUploadFile = async (file: any) => {
    setErrorRows([]);
    setWarningRows([]);
    try {
      setIsUploading(true);
      dispatch(uploadAssignmentsResult(file, assignmentId, id, dataFormat, unitCode));
    } catch (error) {
      if (error.errors) {
        setErrorRows(error.errors);
      }
      setIsUploading(false);
      setUploadError(error.reason || error.message);
    }
  };

  useEffect(() => {
    if (response) {
      setNoOfRowsSuccess(response.message);
      setWarningRows(response.rows);
      setSuccessOpenSnackbar(true);
      setIsUploading(false);
    }
    if (!_.isEmpty(errorResponse)) {
      setErrorRows(errorResponse);
      setErrorOpenSnackbar(true);
      setIsUploading(false);
    }
  }, [response, errorResponse]);

  return (
    <Page className={classes.root} title="Assignment">
      <Container maxWidth={false} className={classes.container}>
        <Card style={{ boxShadow: 'none' }}>
          <CardHeader
            action={
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<Backspace />}
                onClick={handleModalClose}
              >
                Back
              </Button>
            }
            title={`Upload assigment result for ${taskName}`}
            subheader={
              noOfRowsSuccess &&
              noOfRowsSuccess !== '' && (
                <Typography style={{ color: 'green', fontWeight: 600 }}>
                  {`${noOfRowsSuccess}`}
                  <Typography></Typography>
                </Typography>
              )
            }
          />
          <Divider />
          {isUploading && <Loader />}
          <CardContent className={classes.content}>
            <br />
            {selectedBuCode === 'USC' && (
              <FormControl component="fieldset" className={'justify-content-center'} style={{ width: '100%' }}>
                <FormLabel style={{ textAlign: 'center' }} component="legend">
                  Data in form of:
                </FormLabel>
                <RadioGroup
                  className={'justify-content-center'}
                  aria-label="quiz"
                  name="quiz"
                  value={dataFormat}
                  onChange={handleDataFormat}
                  style={{ flexDirection: 'row' }}
                >
                  <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                  <FormControlLabel value="taskWeightage" control={<Radio />} label="Task Weightage" />
                </RadioGroup>
              </FormControl>
            )}
            <br />
            <ClearDb id={assignmentId} type={'result'} name={taskName} />
            <br />
            <Files
              isUploading={isUploading}
              disableUpload={selectedBuCode === buCodes.USC && !dataFormat}
              onUploadFile={onUploadFile}
              onRemoveFile={onRemoveFile}
              uploadError={uploadError}
              setUploadError={setUploadError}
              fileLoadPercentage={fileLoadPercentage}
              onFileSelected={onFileSelected}
            />
            <Button
              variant="contained"
              color="default"
              size="small"
              className={classes.download}
              endIcon={
                <ArrowDownwardSharp fontSize="small" style={{ color: '#c9696e' }}>
                  Sample Download
                </ArrowDownwardSharp>
              }
            >
              <a
                href={
                  process.env.PUBLIC_URL + selectedBuCode === 'USC'
                    ? '/csv/assignment_result_USC.csv'
                    : '/csv/assignment_grades.csv'
                }
                download="assignment_grades.csv"
              >
                Sample Download
              </a>
            </Button>
          </CardContent>
        </Card>
        {!_.isEmpty(errorRows) ? <ErrorDetails errorResponse={errorResponse} /> : null}
        {!_.isEmpty(warningRows) ? <WarningDetails warningResponse={warningRows} /> : null}
        <Snackbar className={classes.snackbar} onClose={handleClose} open={openErrorSnackbar}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
            Error in Uploaded File
          </MuiAlert>
        </Snackbar>
        <Snackbar className={classes.snackbar} onClose={handleClose} open={openSuccessSnackbar}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
            {`${noOfRowsSuccess}`}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Page>
  );
}
