import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import Attendance from '../../../../models/attendance';
import { Event, PhysicalAttendanceModified } from '../../../../models/event';
import Session from '../../../../models/session';
import './attendance-graph.scss';
import Loader from '../loader';

interface GraphProps {
  event: Event;
  physicalAttendanceModified?: PhysicalAttendanceModified[];
}

const getTooltipHTML = (ad: Attendance, session: Session) => {
  return `<div class='graph-tooltip'>
      <span class='name'>${ad.attendee.displayName}</span>
      <span class='activity-index'>Activity Index: ${ad.activityIndex.toFixed(2)}</span>
      <span class='device-type'>Device: ${session.deviceType}</span>
    </div>`;
};

const AttendanceGraph: React.FC<GraphProps> = ({ event, physicalAttendanceModified = [] }: GraphProps) => {
  const [graphData, setGraphData] = useState<any>(undefined);

  const updateStates = ()=>{
    const data: any = [
      [
        { type: 'string', id: 'Name' },
        { type: 'string', id: 'Device' },
        { type: 'string', role: 'tooltip', p: { html: true } },
        { type: 'date', id: 'Joined' },
        { type: 'date', id: 'Left' },
      ],
    ];

    event.onlineAttendances.forEach((ad) => {
      ad.sessions.forEach((s) => {
        const userId = ad.attendee.email.split('@')[0];
        data.push([userId, s.deviceType, getTooltipHTML(ad, s), new Date(s.joinedAt), new Date(s.leftAt)]);
      });
    });
    setGraphData(data);
    physicalAttendanceModified.forEach((ad) => {
      ad.sessions.forEach((s) => {
        const userId = ad.attendee.email.split('@')[0];
        data.push([userId, s.deviceType, getTooltipHTML(ad, s), new Date(s.joinedAt), new Date(s.leftAt)]);
      });
    });

    setGraphData(data);
  }

  useEffect(() => {
    updateStates()
  }, []);

  if (!graphData) return <div></div>;

  let height = graphData.length * 42;
  const maxHeight = Math.floor(window.innerHeight * 0.4);
  if (height > maxHeight) height = maxHeight;
  return (
    <div className="graph-container">
      <Chart
        width={'100%'}
        height={height + 'px'}
        chartType="Timeline"
        loader={<Loader />}
        data={graphData}
        options={{
          timeline: {
            //   singleColor: '#8d8',
          },
          hAxis: {
            format: 'h:mm a',
          },
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default AttendanceGraph;
