import { Button, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllCourses, getInstitutionIndices } from '../../actions';
import Loader from '../../components/Loader';
import Page from '../../components/PageV2';
import SearchBar from '../../components/search-bar';
import { CourseInfo } from '../../models/instituion-level';
import { RootState } from '../../reducer';
import { LineGraph } from '../../components/LineGraph';
import './institution.scss';
import * as Layout from '../dashboard-commons/Layout';
import {
  assessmentIndicesData,
  attendanceSplit,
  indicesData,
  legacyIndicesData,
  materialIndicesData,
} from '../../util/common';
import { indices } from '../../util/enums';
import { colorCodes } from '../../util/constants';
import { getImprovementAreas } from '../../actions/improvementArea';

const Institution: React.FC = () => {
  const [tableData, setTableData] = useState<CourseInfo[] | undefined>([]);

  const dispatch = useDispatch();
  const courses = useSelector((state: RootState) => state.courses.allCourses);
  const loader = useSelector((state: RootState) => state.courses.loader);
  const indexData = useSelector((state: RootState) => state.institution?.indices);
  const indicesLoader = useSelector((state: RootState) => state.institution?.indicesLoader);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const buCodes = useSelector((state: RootState) => state.users?.buCodes);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const {
    startDate = new Date(),
    endDate = new Date(),
    preStartDate = new Date(),
    teachingEndDate = new Date(),
  } = semestersData;

  const { engagement = [], assessment = [], legacy = [], materialIndex = [] } = indexData;

  useEffect(() => {
    dispatch(getAllCourses());
    //dispatch(getInstitutionIndices());
  }, [dispatch]);

  useEffect(() => {
    setTableData(courses);
  }, [courses]);

  useEffect(() => {
    dispatch(getImprovementAreas());
  }, [dispatch]);

  const onSearch = (value: string) => {
    var filtered_courses: CourseInfo[] = [];
    if (courses) {
      filtered_courses = courses.filter(
        (x) =>
          x.courseName.toLowerCase().includes(value.toLowerCase().trim()) ||
          x.courseCode.toLowerCase().includes(value.toLowerCase().trim())
      );
    }
    setTableData(filtered_courses);
  };

  const engagementData = useMemo(() => {
    const { mandatoryAttendance, nonMandatoryAttendance, employabilityAttendance } = attendanceSplit(engagement);
    const mandatoryEngagementIndex =
      mandatoryAttendance.length > 0
        ? indicesData(mandatoryAttendance, startDate, teachingEndDate, preStartDate)
        : mandatoryAttendance;
    const nonMandatoryEngagementIndex =
      nonMandatoryAttendance.length > 0
        ? indicesData(nonMandatoryAttendance, startDate, endDate, preStartDate)
        : nonMandatoryAttendance;
    const employabilityEngagementIndex =
      employabilityAttendance.length > 0
        ? indicesData(employabilityAttendance, startDate, endDate, preStartDate)
        : employabilityAttendance;
    return {
      mandatoryEngagementIndex:
        mandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.MANDATORY_ATTENDANCE,
                color: colorCodes.mandatory,
                data: mandatoryEngagementIndex,
              },
            ]
          : [],
      nonMandatoryEngagementIndex:
        nonMandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.NON_MANDATORY_ATTENDANCE,
                color: colorCodes.nonMandatory,
                data: nonMandatoryEngagementIndex,
              },
            ]
          : [],
      employabilityEngagementIndex:
        employabilityEngagementIndex.length > 0
          ? [
              {
                id: indices.EMPLOYABILITY,
                color: colorCodes.employability,
                data: employabilityEngagementIndex,
              },
            ]
          : [],
    };
  }, [engagement, startDate, endDate, preStartDate]);

  const assessmentData = useMemo(() => {
    const assessmentIndex = assessment.length > 0 ? assessmentIndicesData(assessment, startDate, endDate) : assessment;
    return assessmentIndex.length > 0
      ? [
          {
            id: indices.ASSESSMENT,
            color: colorCodes.assessment,
            data: assessmentIndex,
          },
        ]
      : [];
  }, [assessment, startDate, endDate]);

  const materialData = useMemo(() => {
    const materialIndexData =
      materialIndex.length > 0 ? materialIndicesData(materialIndex, startDate, endDate) : materialIndex;
    return materialIndexData.length > 0
      ? [
          {
            id: indices.MATERIAL_INDEX,
            color: colorCodes.material,
            data: materialIndexData,
          },
        ]
      : [];
  }, [materialIndex, startDate, endDate]);

  const legacyData = useMemo(() => {
    const legacyIndex = legacy.length > 0 ? legacyIndicesData(legacy, startDate, endDate) : legacy;
    return legacyIndex.length > 0
      ? [
          {
            id: indices.LEGACY_INDEX,
            color: colorCodes.legacy,
            data: legacyIndex,
          },
        ]
      : [];
  }, [legacy, startDate, endDate]);
  const bu = buCodes.find((x) => x.buCode === selectedBuCode);
  return (
    <Page header="Institution Dashboard" subHeader={bu?.name}>
      <div>
        <div style={{ textAlign: 'center' }}>Watch this space for exciting new progression dashboard.</div>
      </div>
      <Layout.Title>Courses</Layout.Title>
      <Divider variant="fullWidth" />
      <Layout.SearchContainer>
        <SearchBar searchPlaceHolder={'Search for course'} onSearch={onSearch} />
      </Layout.SearchContainer>
      {tableData && loader && <Layout.DataCount>{tableData.length} Records found.</Layout.DataCount>}
      <Layout.TableContainer>
        {tableData && loader ? (
          <Layout.ScrollBar>
            <Table stickyHeader style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Course Code</TableCell>
                  <TableCell align="center">Course Name</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((course: CourseInfo) => {
                  return (
                    <TableRow key={course.courseCode}>
                      <TableCell align="center">{course.courseCode}</TableCell>
                      <TableCell align="center">{course.courseName}</TableCell>
                      <TableCell align="center" style={{ width: 100 }}>
                        <Link
                          className="noUnderLine"
                          to={{
                            pathname: '/courses/' + course.courseCode,
                          }}
                        >
                          <Button color="secondary" style={{ marginLeft: 10 }} size="small" variant="outlined">
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Layout.ScrollBar>
        ) : (
          <Loader />
        )}
      </Layout.TableContainer>
    </Page>
  );
};

export default Institution;
