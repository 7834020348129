import { CardHeader, Divider, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { generateCallbackReports, getAllCallbacks, onEventsSearchChanged } from '../../actions';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import Loader from '../../components/Loader';
import SearchBar from '../../components/searchBar';
import EventFilters from './CallbackFilters';
import {
  CustomTable,
  CenterText,
  StyledCard,
  StyledDiv,
  StyledContent,
  ButtonContainer,
  GenerateReportButton,
} from '../../components/Common';
import { Comments } from '../../components/Modals';
import { filters, onTrack } from '../../util/enums';
import { Callback } from '../../util/types';
import Closure from '../../components/Modals/Closure';

const initialValues = {
  startDate: '',
  endDate: '',
  timeZone: '',
  slots: [],
  sort: { sortStartDate: true, orderByDate: true },
};

export default function EventsDashboard() {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortStartDate, setIsExpandedStart] = useState(true);
  const [orderByDate, setOrderBy] = useState(false);

  const searchText = useSelector((state: RootState) => state.events?.searchText);
  const callbacks = useSelector((state: RootState) => state.callback?.callbacks);
  const reportLoader = useSelector((state: RootState) => state.callback?.reportLoader);
  const reportData = useSelector((state: RootState) => state.callback?.reportData);
  const comments = useSelector((state: RootState) => state.callback?.comments);
  const commentsLoader = useSelector((state: RootState) => state.assignmentDueDate?.commentsLoader);

  const loader = useSelector((state: RootState) => state.users.loader);
  const { rows = [], count = 0 } = callbacks;

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      dispatch(getAllCallbacks(searchText, values, page + 1, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage, values, isMount, searchText]);

  const onSearch = () => {
    dispatch(getAllCallbacks(searchText, values, page + 1, rowsPerPage));
  };

  const onSearchTextChanged = (newSearchText: string) => {
    dispatch(onEventsSearchChanged(newSearchText));
  };

  const onFilterClear = () => {
    setValues({ ...initialValues });
    dispatch(getAllCallbacks(searchText, { ...initialValues }, page + 1, rowsPerPage));
  };

  const onFilter = (val = values) => {
    dispatch(getAllCallbacks(searchText, val, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    dispatch(getAllCallbacks(searchText, values, newPage + 1, rowsPerPage));
  };

  const downloadReport = async () => {
    dispatch(generateCallbackReports());
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    dispatch(getAllCallbacks(searchText, values, 1, value));
  };

  const handleSortChange = (value: string) => {
    const sort = { sortStartDate, orderByDate };
    if (value === filters.startDate) {
      setOrderBy(true);
      setIsExpandedStart(!sortStartDate);
      sort.sortStartDate = !sortStartDate;
      sort.orderByDate = true;
    }
    setValues({
      ...values,
      sort,
    });
    onFilter({ ...values, sort });
  };

  const decoratedTableData = useMemo(() => {
    return rows.map((callback: Callback) => {
      const {
        email = '',
        firstName = '',
        lastName = '',
        slot = '',
        timeZone = '',
        callbackDate = '',
        phoneNumber = '',
        reference = '',
        closed = false,
        id,
      } = callback;
      return [
        `${firstName} ${lastName}`,
        email,
        new Date(callbackDate).toLocaleDateString(),
        reference,
        phoneNumber,
        slot.toString(),
        timeZone,
        closed ? onTrack.YES : onTrack.NO,
        <Comments changeAction commentsLoader={commentsLoader} key={id} id={id} comments={comments[id] || []} />,
        <Closure id={id} closed={closed} key={id} />,
      ];
    });
  }, [rows, comments, commentsLoader]);
  return (
    <>
      <Page header="Callbacks" subHeader="">
        <ButtonContainer>
          <GenerateReportButton
            reportName={'callbacks.csv'}
            downloadAction={downloadReport}
            loading={reportLoader}
            csvData={reportData}
          />
        </ButtonContainer>
        <StyledDiv>
          <SearchBar
            filter
            onSearch={onSearch}
            searchPlaceHolder={'Search by Name, Email, Phone Number'}
            onSearchTextChanged={onSearchTextChanged}
            searchText={searchText}
            onFilterClear={onFilterClear}
            onFilter={onFilter}
          >
            <EventFilters values={values} setValues={setValues} />
          </SearchBar>
        </StyledDiv>

        <StyledCard>
          <CardHeader title="List" />
          <Divider />
          <StyledContent>
            <PerfectScrollbar>
              {!loader ? (
                rows && rows.length > 0 ? (
                  <CustomTable
                    tableHead={[
                      { text: 'Name' },
                      { text: 'Email' },
                      {
                        text: 'Requested Call Back Date',
                        displaySort: true,
                        onSortClick: () => handleSortChange(filters.startDate),
                        showUpArrow: sortStartDate,
                      },
                      { text: 'Reason' },
                      { text: 'Phone Number' },
                      {
                        text: 'Slot',
                      },
                      { text: 'Time Zone' },
                      { text: 'Closed' },
                      { text: 'Comments' },
                      { text: 'Action' },
                    ]}
                    tableData={decoratedTableData}
                    count={count}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                ) : (
                  <CenterText>
                    <span> There are no callbacks to display</span>
                  </CenterText>
                )
              ) : (
                <Loader />
              )}
            </PerfectScrollbar>
          </StyledContent>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {count} Records found.
          </Typography>
        </StyledCard>
      </Page>
    </>
  );
}
