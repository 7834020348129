import axios from 'axios';
import { KEY_BU_CODE, KEY_SEMESTER } from '../config/constants';

export const setBusinessUnit = (code: string) => {
  localStorage.setItem(KEY_BU_CODE, code);
};

export const setSemester = (semester: string) => {
  localStorage.setItem(KEY_SEMESTER, semester);
};

export const get = async (url) => {
  const res = await axios.get(url);
  return res.data;
};

export const post = async (url, data, header?) => {
  const res = await axios.post(url, data, header);
  return res.data;
};

export const put = async (url, data, header?) => {
  const res = await axios.put(url, data, header);
  return res.data;
};

export const remove = async (url, data) => {
  const res = await axios.delete(url, data);
  return res.data;
};

export const patch = async (url, data) => {
  const res = await axios.patch(url, data);
  return res.data;
};
