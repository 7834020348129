import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import './403.scss';

const Error403: React.FC = () => {
  const history = useHistory();
  return (
    <div id='page-403'>
      <Button
        className='home-button'
        variant='contained'
        color='primary'
        onClick={() => history.push('/dashboard')}
      >
        Back Home
      </Button>
      <div className='hover'>
        <div className='background'>
          <div className='door'>
            403 <br /> <br /> You cannot perform this action
          </div>
          <div className='rug'></div>
        </div>
        <div className='foreground'>
          <div className='bouncer'>
            <div className='head'>
              <div className='neck'></div>
              <div className='eye left'></div>
              <div className='eye right'></div>
              <div className='ear'></div>
            </div>
            <div className='body'></div>
            <div className='arm'></div>
          </div>
          <div className='poles'>
            <div className='pole left'></div>
            <div className='pole right'></div>
            <div className='rope'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;
