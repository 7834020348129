import React from 'react';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { theme } from '../../../theme';

const StyledCard = styled(Card)`
  margin: ${theme.spacing(2)}px;
  padding: ${theme.spacing(2)}px;
`;

const Loader = () => {
  return (
    <StyledCard>
      <div>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: '100%' }}
          ></div>
        </div>
      </div>
    </StyledCard>
  );
};

export default Loader;
