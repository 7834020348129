import React, { useEffect, useState } from 'react';
import { Button, CardHeader } from '@material-ui/core';
import { Event, PhysicalAttendanceData } from '../../../../models/event';
import DataTable from '../../../dashboard-commons/data-table';
import { CloudDownload } from '@material-ui/icons';
import { StyledCard, StyledContent, StyledCSVLink } from '../../../../components/Common';

interface Props {
  event: Event;
}

const PhysicalAttendanceReport: React.FC<Props> = ({ event }: Props) => {
  const [data, setData] = useState<PhysicalAttendanceData[]>([]);
  const [csvData, setCsvData] = useState<any>([]);

  useEffect(() => {
    const modifiedData: PhysicalAttendanceData[] = [];
    const dataToDownload: any[] = [];
    event.physicalAttendances.forEach((attendance) => {
      modifiedData.push({
        displayName: attendance.attendee.displayName,
        email: attendance.attendee.email,
        attended: attendance.timestamp ? 'Yes' : 'No',
      });
      dataToDownload.push({
        Email: attendance.attendee.email.split('@')[0],
        Name: attendance.attendee.displayName,
        'Attended?': attendance.timestamp ? 'Yes' : 'No',
      });
    });
    setCsvData(dataToDownload);
    setData(modifiedData);
  }, []);

  const columnKeys = ['email', 'displayName', 'attended'];
  const columnLabels = ['Email', 'Name', 'Attended?'];
  return (
    <StyledCard>
      <CardHeader
        title={'Attendance Report'}
        action={
          csvData && csvData.length ? (
            <StyledCSVLink data={csvData} filename={'Attendance_Report.csv'}>
              <Button startIcon={<CloudDownload />} color="primary" variant="contained">
                Export CSV
              </Button>
            </StyledCSVLink>
          ) : (
            <div />
          )
        }
      />
      <StyledContent>
        <DataTable data={data} columnKeys={columnKeys} columnLabels={columnLabels} />
      </StyledContent>
    </StyledCard>
  );
};

export default PhysicalAttendanceReport;
