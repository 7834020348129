import React, { useEffect } from 'react';
import moment from 'moment';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Theme,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RootState } from '../../reducer';
import { getAcadProgressByStudentId } from '../../actions';
import { moodleActivity as moodleActivityEnums, attendanceTypes } from '../../util/enums';
import { EditProgress } from '../../components/Modals';
import CustomExpansionPanel from '../../components/ExpansionPanels';
import { AcadProgressType, Task, OnlineAttendance, AssignmentDueDate } from '../../util/types';
import { getGradeAndColor, weekWiseData } from '../../util/common';
import Loader from '../../components/Loader';
import { PhysicalAttendance } from '../../models/event';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: { backgroundColor: 'red', color: 'white' },
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 1150,
    },
    progressCard: {
      margin: theme.spacing(2, 0),
    },
    noRecordsText: {
      textAlign: 'center',
      marginTop: '10px',
    },
  })
);

type progressProps = {
  id: string;
  isStudent: boolean;
};

const AcadProgress: React.FC<progressProps> = ({ id, isStudent = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const acadProgress = useSelector((state: RootState) => state.acadProgress?.acadProgress);
  const loading = useSelector((state: RootState) => state.common?.loading);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);

  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const { preStartDate = new Date() } = semestersData;

  useEffect(() => {
    dispatch(getAcadProgressByStudentId(id));
  }, [id, dispatch]);
  const studentAcadProgress = acadProgress[id] || [];

  const calculateAverage = (tasks: Task[]) => {
    let totalMarks = 0;
    let totalWeightage = 0;
    tasks.forEach((x) => {
      const { assignmentDetails, marks = 0 } = x;
      const { taskWeightage = '0' } = assignmentDetails;
      totalMarks += marks;
      totalWeightage += parseFloat(taskWeightage);
    });
    return `${((totalMarks * 100) / totalWeightage).toFixed(2)}%`;
  };

  const calculateAttendance = (attendances: OnlineAttendance[], type: string, physicalAttendances: PhysicalAttendance[]) => {
    let totalAttendances = 0;
    let totalAttended = attendances.reduce((acc, x) => {
      const { event } = x;
      const { teamName = '' } = event;
      if (teamName.includes(type)) {
        const { activityIndex = 0 } = x;
        totalAttendances += 1;
        return acc + activityIndex;
      }
      return acc;
    }, 0);
    totalAttended += physicalAttendances.reduce((acc, x) => {
      const { event } = x;
      const { teamName = '' } = event;
      if (teamName.includes(type)) {
        const { timestamp } = x;
        totalAttendances += 1;
        return timestamp ? (acc + 1) : acc;
      }
      return acc;
    }, 0);
    return { totalAttendances, attendance: `${((totalAttended * 100) / totalAttendances).toFixed(2)}%` };
  };

  const calculateTotalAttendance = (attendances: OnlineAttendance[], physicalAttendances: PhysicalAttendance[]) => {
    const totalAttendances = attendances.length + physicalAttendances.length;
    let totalAttended = attendances.reduce((acc, x) => {
      const { activityIndex = 0 } = x;
      return acc + activityIndex;
    }, 0);
    totalAttended += physicalAttendances.reduce((acc, x) => {
      const { timestamp } = x;
      return timestamp ? (acc + 1) : acc;
    }, 0);
    return totalAttendances > 0 ? `${((totalAttended * 100) / totalAttendances).toFixed(2)}%` : 'N/A';
  };

  const totalMarks = (tasks: Task[]) => {
    return tasks.reduce((acc, x) => {
      const { marks = 0 } = x;
      return acc + marks;
    }, 0);
  };

  const totalWeightage = (tasks: Task[]) => {
    return tasks.reduce((acc, x) => {
      const { assignmentDetails } = x;
      const { taskWeightage = '0' } = assignmentDetails;
      return acc + parseFloat(taskWeightage);
    }, 0);
  };

  const checkLength = (tasks: any[]) => {
    return tasks.length === 0;
  };

  return (
    <>
      <Card className={classes.progressCard}>
        <CardHeader title="Progress Card" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              {!loading ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Unit</TableCell>
                      <TableCell align="center">Attendance</TableCell>
                       <TableCell align="center">Engagement</TableCell>
                      <TableCell align="center">Total Marks</TableCell>
                  
                      <TableCell align="center">Average Grade</TableCell>
                      {/* <TableCell align="center">Moodle Activity</TableCell>
                      <TableCell align="center">Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentAcadProgress.map((unitProgress: AcadProgressType) => {
                      const { unitCode = '', moodleActivity = false, tasks = [], attendance = [], physicalAttendance = [] } = unitProgress;
                      const lecAttendance = calculateAttendance(attendance, attendanceTypes.LEC, physicalAttendance);
                      const tutAttendance = calculateAttendance(attendance, attendanceTypes.TUT, physicalAttendance);
                      const wksAttendance = calculateAttendance(attendance, attendanceTypes.WKS, physicalAttendance);
                      const labAttendance = calculateAttendance(attendance, attendanceTypes.LAB, physicalAttendance);
                      const supAttendance = calculateAttendance(attendance, attendanceTypes.SUP, physicalAttendance);
                      return (
                        <TableRow key={unitCode}>
                          <TableCell align="center">{unitCode}</TableCell>
                          <TableCell align="center">
                            <div>{calculateTotalAttendance(attendance, physicalAttendance)}</div>
                            {lecAttendance.totalAttendances > 0 && <div>{`(Lec - ${lecAttendance.attendance})`}</div>}
                            {tutAttendance.totalAttendances > 0 && <div>{`(Tut - ${tutAttendance.attendance})`}</div>}
                            {wksAttendance.totalAttendances > 0 && <div>{`(Wks - ${wksAttendance.attendance})`}</div>}
                            {labAttendance.totalAttendances > 0 && <div>{`(Lab - ${labAttendance.attendance})`}</div>}
                            {supAttendance.totalAttendances > 0 && <div>{`(Sup - ${supAttendance.attendance})`}</div>}
                          </TableCell>
                             <TableCell align="center">{unitProgress.engagement}</TableCell>
                          <TableCell align="center">{checkLength(tasks) ? 'N/A' : `${totalMarks(tasks)}/${totalWeightage(tasks)}`}</TableCell>
                          <TableCell align="center">{checkLength(tasks) ? 'N/A' : calculateAverage(tasks)}</TableCell>
                          {/* <TableCell align="center">
                            {moodleActivity ? moodleActivityEnums.SAT : moodleActivityEnums.UNSAT}
                          </TableCell>
                          <TableCell align="center">
                            <EditProgress isStudent={isStudent} studentId={id} item={unitProgress} />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Loader />
              )}
            </div>
          </PerfectScrollbar>
          {!loading ? (
            <>
              {studentAcadProgress && studentAcadProgress.length === 0 && (
                <div className={classes.noRecordsText}>
                  <span>There are no units to display</span>
                </div>
              )}
            </>
          ) : (
            <div></div>
          )}
        </CardContent>
      </Card>
      {!loading ? (
        <Card className={classes.progressCard}>
          <CardHeader title="Unit Wise Tasks Breakdown" />
          <Divider />
          <CardContent className={classes.content}>
            {studentAcadProgress.map((unitProgress: AcadProgressType) => {
              const { unitCode = '', tasks = [], dueDateArray = [] } = unitProgress;
              const updatedTasks = tasks.sort((a, b) =>
                moment(a.assignmentDetails.dueDate, 'DD/MM/YYYY').toDate() <
                moment(b.assignmentDetails.dueDate, 'DD/MM/YYYY').toDate()
                  ? -1
                  : moment(a.assignmentDetails.dueDate, 'DD/MM/YYYY').toDate() >
                    moment(b.assignmentDetails.dueDate, 'DD/MM/YYYY').toDate()
                  ? 1
                  : 0
              );
              const updatedDueDateArray = dueDateArray
                .reduce((acc: AssignmentDueDate[], x) => {
                  const assignmentFound = tasks.find((y) => y.taskNumber === x.taskNumber);
                  if (!assignmentFound) {
                    acc.push(x);
                  }
                  return acc;
                }, [])
                .sort((a, b) =>
                  moment(a.dueDate, 'DD/MM/YYYY').toDate() < moment(b.dueDate, 'DD/MM/YYYY').toDate()
                    ? -1
                    : moment(a.dueDate, 'DD/MM/YYYY').toDate() > moment(b.dueDate, 'DD/MM/YYYY').toDate()
                    ? 1
                    : 0
                );
              return (
                <CustomExpansionPanel reduceHeight key={unitCode} heading={unitCode}>
                  {updatedTasks && updatedDueDateArray && [...updatedTasks, ...updatedDueDateArray].length > 0 ? (
                    <PerfectScrollbar>
                      {!loading ? (
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Task</TableCell>
                              <TableCell align="left">Due Date</TableCell>
                              <TableCell align="left">Marks</TableCell>
                              <TableCell align="left">Total</TableCell>
                              <TableCell align="left">Grade</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {updatedTasks.map((task: Task) => {
                              const { marks, assignmentDetails } = task;
                              const { taskWeightage, taskName, dueDate } = assignmentDetails;
                              const gradeColor = getGradeAndColor(marks, taskWeightage);
                              return (
                                <TableRow key={dueDate.toString()}>
                                  <TableCell align="left">{taskName}</TableCell>
                                  <TableCell align="left">{dueDate}</TableCell>
                                  <TableCell align="left">{marks}</TableCell>
                                  <TableCell align="left">{taskWeightage}</TableCell>
                                  <TableCell align="left" style={{ color: gradeColor.color }}>
                                    {parseFloat(taskWeightage) === 0 ? 'N/A' : `${gradeColor.grade}%`}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {updatedDueDateArray.map((due: AssignmentDueDate) => {
                              const { taskWeightage, taskName, dueDate } = due;
                              return (
                                <TableRow key={dueDate.toString()}>
                                  <TableCell align="left">{taskName}</TableCell>
                                  <TableCell align="left">{dueDate}</TableCell>
                                  <TableCell align="left">NA</TableCell>
                                  <TableCell align="left">{taskWeightage}</TableCell>
                                  <TableCell align="left">NA</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        <Loader />
                      )}
                    </PerfectScrollbar>
                  ) : (
                    <div key={unitCode} className={classes.noRecordsText}>
                      <span> There are no tasks to display</span>
                    </div>
                  )}
                </CustomExpansionPanel>
              );
            })}
            {!loading ? (
              <>
                {studentAcadProgress && studentAcadProgress.length === 0 && (
                  <div className={classes.noRecordsText}>
                    <span>There are no units to display</span>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </CardContent>
        </Card>
      ) : (
        <div />
      )}
      {!loading ? (
        <Card className={classes.progressCard}>
          <CardHeader title="Week Wise Attendance Breakdown" />
          <Divider />
          <CardContent className={classes.content}>
            {studentAcadProgress.map((unitProgress: AcadProgressType) => {
              const { attendance = [], unitCode, physicalAttendance = [] } = unitProgress;
              const {
                weekWiseAttendance,
                lecWeekWiseAttendance,
                tutWeekWiseAttendance,
                wksWeekWiseAttendance,
                supWeekWiseAttendance,
                labWeekWiseAttendance,
              } = weekWiseData(attendance, preStartDate, physicalAttendance) || {};
              return (
                <CustomExpansionPanel reduceHeight key={unitCode} heading={unitCode}>
                  {Object.keys(weekWiseAttendance).length > 0 ? (
                    <PerfectScrollbar>
                      {!loading ? (
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Week</TableCell>
                              <TableCell align="left">Attendance</TableCell>
                              {Object.keys(lecWeekWiseAttendance).length > 0 && (
                                <TableCell align="left">Lec Attendance</TableCell>
                              )}
                              {Object.keys(tutWeekWiseAttendance).length > 0 && (
                                <TableCell align="left">Tut Attendance</TableCell>
                              )}
                              {Object.keys(wksWeekWiseAttendance).length > 0 && (
                                <TableCell align="left">Wks Attendance</TableCell>
                              )}
                              {Object.keys(labWeekWiseAttendance).length > 0 && (
                                <TableCell align="left">Lab Attendance</TableCell>
                              )}
                              {Object.keys(supWeekWiseAttendance).length > 0 && (
                                <TableCell align="left">Sup Attendance</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(weekWiseAttendance).map((x) => {
                              const { activityIndex = '', count } = weekWiseAttendance[x] || {};
                              const { activityIndex: lecActivity = '', count: lecCount } =
                                lecWeekWiseAttendance[x] || {};
                              const { activityIndex: tutActivity = '', count: tutCount } =
                                tutWeekWiseAttendance[x] || {};
                              const { activityIndex: wksActivity = '', count: wksCount } =
                                wksWeekWiseAttendance[x] || {};
                              const { activityIndex: labActivity = '', count: labCount } =
                                labWeekWiseAttendance[x] || {};
                              const { activityIndex: supActivity = '', count: supCount } =
                                supWeekWiseAttendance[x] || {};
                              return (
                                <TableRow key={x}>
                                  <TableCell align="left">{x}</TableCell>
                                  <TableCell align="left">
                                    {activityIndex || activityIndex === 0
                                      ? `${((activityIndex * 100) / count).toFixed(2)}%`
                                      : activityIndex}
                                  </TableCell>
                                  {Object.keys(lecWeekWiseAttendance).length > 0 && (
                                    <TableCell align="left">
                                      {lecActivity || lecActivity === 0
                                        ? `${((lecActivity * 100) / lecCount).toFixed(2)}%`
                                        : lecActivity}
                                    </TableCell>
                                  )}
                                  {Object.keys(tutWeekWiseAttendance).length > 0 && (
                                    <TableCell align="left">
                                      {tutActivity || tutActivity === 0
                                        ? `${((tutActivity * 100) / tutCount).toFixed(2)}%`
                                        : tutActivity}
                                    </TableCell>
                                  )}
                                  {Object.keys(wksWeekWiseAttendance).length > 0 && (
                                    <TableCell align="left">
                                      {wksActivity || wksActivity === 0
                                        ? `${((wksActivity * 100) / wksCount).toFixed(2)}%`
                                        : wksActivity}
                                    </TableCell>
                                  )}
                                  {Object.keys(labWeekWiseAttendance).length > 0 && (
                                    <TableCell align="left">
                                      {labActivity || labActivity === 0
                                        ? `${((labActivity * 100) / labCount).toFixed(2)}%`
                                        : labActivity}
                                    </TableCell>
                                  )}
                                  {Object.keys(supWeekWiseAttendance).length > 0 && (
                                    <TableCell align="left">
                                      {supActivity || supActivity === 0
                                        ? `${((supActivity * 100) / supCount).toFixed(2)}%`
                                        : supActivity}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        <Loader />
                      )}
                    </PerfectScrollbar>
                  ) : (
                    <div key={unitCode} className={classes.noRecordsText}>
                      <span> There are no tasks to display</span>
                    </div>
                  )}
                </CustomExpansionPanel>
              );
            })}
            {!loading ? (
              <>
                {studentAcadProgress && studentAcadProgress.length === 0 && (
                  <div className={classes.noRecordsText}>
                    <span>There are no units to display</span>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </CardContent>
        </Card>
      ) : (
        <div />
      )}
    </>
  );
};

export default AcadProgress;
