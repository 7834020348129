import { ActionType } from './types';
import { CALLBACK_REPORT_FINISHED, CALLBACK_REPORT_START, EDIT_CALL_BACK, GET_ALL_CALLBACKS, ON_LOADING_FINISHED, ON_LOADING_START, SET_CALL_BACK_REPORT_RESPONSE, GET_COMMENTS_FOR_CALL_BACK } from "../actionTypes"

const initialState = {
  callbacks: {},
  callbackSubmitLoader: false,
  reportLoader: false,
  reportData: "",
  comments: {}
};

const callbacks = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_ALL_CALLBACKS: {
      return {
        ...state,
        callbacks: action.payload,
      };
    }

    case GET_COMMENTS_FOR_CALL_BACK: {
      const { id, data } = action.payload
      const newComments = JSON.parse(JSON.stringify(state.comments))
      newComments[id] = data.data
      return {
        ...state,
        comments: newComments,
      };
    }
    case EDIT_CALL_BACK: {
      const { id, closed } = action.payload
      const newCallbacks = JSON.parse(JSON.stringify(state.callbacks))
      const { rows = [] } = newCallbacks
      const editedCallbacks = rows.map(x => {
        if (x.id === id) {
          return { ...x, closed: closed }
        }
        return x
      })
      newCallbacks.rows = editedCallbacks
      return {
        ...state,
        callbacks: newCallbacks,
      };
    }
    case ON_LOADING_START: {
      return {
        ...state,
        callbackSubmitLoader: true,
      };
    }
    case ON_LOADING_FINISHED: {
      return {
        ...state,
        callbackSubmitLoader: false,
      };
    }
    case CALLBACK_REPORT_START: {
      return {
        ...state,
        reportLoader: true
      }
    }
    case CALLBACK_REPORT_FINISHED: {
      return {
        ...state,
        reportLoader: false
      }
    }
    case SET_CALL_BACK_REPORT_RESPONSE: {
      return {
        ...state,
        reportData: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default callbacks;
