import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import React from 'react';
import { CSVLink } from 'react-csv';
import { ButtonWrapper, ButtonProgress } from '../index';

export interface GenerateReportButtonProps {
  downloadAction: () => void;
  loading: boolean;
  csvData: string;
  reportName?: string;
}
export const GenerateReportButton: React.FC<GenerateReportButtonProps> = ({
  downloadAction,
  loading,
  csvData,
  reportName,
}) => {
  return (
    <ButtonWrapper>
      {!csvData ? (
        <Button
          color="primary"
          onClick={downloadAction}
          variant="contained"
          startIcon={<BuildIcon />}
          disabled={loading}
        >
          Generate Report
        </Button>
      ) : (
        <Button color="primary" variant="contained" endIcon={<GetApp />} disabled={loading}>
          <CSVLink
            style={{ color: 'white', textDecoration: 'none' }}
            target="_blank"
            filename={reportName}
            data={csvData}
          >
            Download Report
          </CSVLink>
        </Button>
      )}

      {loading && <ButtonProgress size={24} />}
    </ButtonWrapper>
  );
};
