import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Button, Drawer, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    drawer: {
      width: 420,
      maxWidth: '100%',
    },
    header: {
      padding: theme.spacing(2, 1),
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(0, 3),
      flexGrow: 1,
    },
    contentSection: {
      padding: theme.spacing(2, 0),
    },
    contentSectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    contentSectionContent: {},
    formGroup: {
      padding: theme.spacing(2, 0),
    },
    fieldGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      marginTop: 0,
      marginBottom: 0,
    },
    flexGrow: {
      flexGrow: 1,
    },
    addButton: {
      marginLeft: theme.spacing(1),
    },
    tags: {
      marginTop: theme.spacing(1),
    },
    minAmount: {
      marginRight: theme.spacing(3),
    },
    maxAmount: {
      marginLeft: theme.spacing(3),
    },
    radioGroup: {},
    actions: {
      padding: theme.spacing(3),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);
type FilterProps = {
  open: boolean;
  onClose: () => void;
  onFilter: () => void;
  className?: string;
  onClear: () => void;
};

const Filter: React.FC<FilterProps> = ({
  open,
  onClose,
  onFilter,
  className,
  children,
  onClear,
  ...rest
}) => {
  const classes = useStyles();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (onFilter) {
      onFilter();
    }
  };

  return (
    <Drawer
      anchor='right'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant='temporary'
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.header}>
          <Button onClick={onClose} size='small'>
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>{children}</div>
        <div className={classes.actions}>
          <Button fullWidth onClick={onClear} variant='contained'>
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color='primary'
            fullWidth
            type='submit'
            variant='contained'
            onClick={onClose}
          >
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default Filter;
