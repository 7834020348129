import { createStyles, Divider, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import Page from '../../../components/PageV2';
import { logTabs } from '../../../util/constants';
import InterventionLogs from './intervention';
import ReminderLogs from './reminder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: { backgroundColor: 'red', color: 'white' },
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 1150,
    },
    tab: {
      margin: theme.spacing(2, 0),
    },
    noRecordsText: {
      textAlign: 'center',
      marginTop: '10px',
    },
  })
);
type logsProps = {
  id: string;
  isStudent: boolean;
};

const Logs: React.FC<logsProps> = ({ id, isStudent = false }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(logTabs[0].value);

  const tabs = logTabs;

  const history = useHistory();

  const handleTabsChange = (event: any, value: string) => {
    console.log('Current Tab - ', currentTab);
    setCurrentTab(value);
    history.push(`/student/${id}/report-card/logs/${value}`);
  };

  return (
    <Page header={'Logs'} noPadding>
      <Tabs onChange={handleTabsChange} scrollButtons="auto" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>

      <Divider />
      <br />
      <br />
      <PerfectScrollbar>
        <div className={classes.inner}>
          <div>
            {currentTab === 'interventions' && <InterventionLogs studentId={id} />}
            {currentTab === 'reminders' && <ReminderLogs studentId={id} />}
          </div>
        </div>
      </PerfectScrollbar>
    </Page>
  );
};

export default Logs;
