import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';
import '../index.scss';

const CustomNodeTitle3 = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: #b0f60026;
  color: black;
  font-size: 20px;
  padding: 37px;
  text-align: center;
`;

export const WhatsAppDelivery=()=> {
 
 
  return (
    <div>
       <Handle type="target" id="delivery_whatsapp" position={Position.Top} />
          <CustomNodeTitle3>
            <WhatsAppIcon style={{ fontSize: '29px' }} />
            &nbsp;&nbsp;Delivery via WhatsApp
          </CustomNodeTitle3>
        </div>
  );
}
