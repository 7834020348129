import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, TextField, Grid, MenuItem, Button } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { slots, timeZones } from '../../util/enums';
import { createCallback, getUserDetails } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/PageV2';
import { RootState } from '../../reducer';
import Loader from '../../components/Loader';
import { Redirect, useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

const Callback: React.FC = () => {
  const loader = useSelector((state: RootState) => state.callback.callbackSubmitLoader);
  const userDetails = useSelector((state: RootState) => state.users.userDetails);
  const redirectTo = useSelector((state: RootState) => state.users.redirectTo);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [date, setDate] = useState(new Date());
  const [slot, setSlot] = useState([] as any);
  const [timeZone, setTimeZone] = useState('');
  const dispatch = useDispatch();

  const { oktaAuth } = useOktaAuth();
  let { reference } = useParams<{ reference: string }>();

  const getAvatar = useCallback(async () => {
    const user = await oktaAuth.getUser();
    dispatch(getUserDetails(user));
  }, [dispatch, oktaAuth]);
  useEffect(() => {
    const { email = '', family_name = '', given_name = '', phone_Number = '' } = userDetails;
    if (!email) {
      getAvatar();
    }
    setEmail(email);
    setFirstName(given_name);
    setLastName(family_name);
    setPhoneNumber(phone_Number);
  }, [userDetails, getAvatar]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const handleDateChange = (date) => {
    setDate(date);
  };

  const onSubmit = () => {
    dispatch(createCallback(email, firstName, lastName, phoneNumber, date, slot, timeZone, reference));
  };

  return (
    <Page subHeader="Please fill in the details" header="Request Callback">
      <Card style={{ width: '50%', margin: '100px auto' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="email"
                label="Email"
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="phoneNumber"
                required
                label="Phone Number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="firstName"
                required
                label="First Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="lastName"
                label="Last Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="dd/MM/yyyy"
                  value={date}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Time Zone"
                name="timeZone"
                select
                SelectProps={{
                  value: timeZone,
                }}
                value={timeZone}
                variant="outlined"
                onChange={(e) => setTimeZone(e.target.value)}
              >
                {Object.keys(timeZones).map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {timeZones[option]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Preferred Timings"
                name="slot"
                margin="dense"
                select
                SelectProps={{
                  multiple: true,
                  value: slot,
                  onChange: (event) => setSlot(event.target.value),
                }}
                variant="outlined"
              >
                {Object.keys(slots).map((option) => {
                  const val = slots[option];
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <div>* Select at least two slots from preferred timings</div>
            </Grid>
            <Grid item style={{ textAlign: 'center' }} xs={12}>
              {!!loader ? (
                <Loader />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!email || !phoneNumber || !date || slot.length < 2 || !timeZone || !firstName}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Callback;
