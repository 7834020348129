import {
    Button, Collapse, ListItemIcon, Menu, MenuItem, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip, Typography, Zoom
  } from '@material-ui/core';
  import Paper from '@material-ui/core/Paper';
  import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
  import { Add, Block, BlockOutlined, CheckCircleOutlined, DoneAllOutlined, InfoOutlined, PlayCircleOutline } from '@material-ui/icons';
  import moment from 'moment';
  import React, { useEffect } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { Link } from 'react-router-dom';
  import { getInterventionData, updateInterventionStatus } from '../../../actions';
  import Loader from '../../../components/Loader';
  import ShowInterventionTrigger from '../../../components/Modals/showInterventionTrigger';
  import ViewIntervention from '../../../components/Modals/viewIntervention';
  import Page from '../../../components/PageV2';
  import { RootState } from '../../../reducer';
  import { InterventionStatusColorCodes } from '../../../util/constants';
  import { INTERVENTION_TRIGGERS, STATUS } from '../../../util/enums';
import { dashboardUseStyles } from '../intervention_styles';
  import { interventionDataFromJson } from './../data/data';

  const green_color = '#006300bf';
  const lightGrey_color = '#b5b5b5';
  const priamry_color = '#ab3f43';

  const useStyles = dashboardUseStyles

  
export const Row =(props) =>{
    const { row, labelId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
   
  
    const HtmlTooltip = withStyles((theme: Theme) => ({
      tooltip: {
        backgroundColor: priamry_color,
        top: '100px',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);
  
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleStatus = (status, id) => {
      setAnchorEl(null);
      dispatch(updateInterventionStatus(id, status));
    };
  
    return (
      <>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} id={row.id}>
          <TableCell align="center">{row.id}</TableCell>
          <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
            {row.label}
          </TableCell>
          <TableCell align="center" scope="row" padding="none">
            {row.trigger.toUpperCase()}
          </TableCell>
          <TableCell align="center" scope="row" padding="none">
            {row.type.toUpperCase().split("_").join(' ')}
          </TableCell>
          {row.units.split(',').length <= 2 ? (
            <TableCell align="center">{row.units}</TableCell>
          ) : (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div>
                    {/* <h6 className="text-align-center" >Discipline </h6>
                <br/> */}
                    <span>
                      IT: {row.units.split(',').filter((x) => x.trim().substring(0, 2) === 'IT').length}, BUSINESS:{' '}
                      {row.units.split(',').filter((x) => x.trim().substring(0, 2) === 'BU').length}
                    </span>
                  </div>
                </React.Fragment>
              }
              placement="top"
              TransitionComponent={Zoom}
              interactive
            >
              <TableCell data-tip data-for={row.id} align="center">
                {row.units.split(',').slice(0, 2).join(',')} ...(
                <b>+{row.units.split(',').length - 2} others</b>)
              </TableCell>
            </HtmlTooltip>
          )}
          <TableCell align="center">{moment(row.createdAt).format('ddd, MMM DD YYYY')}</TableCell>
  
            <TableCell align="center">
              <span
                style={{
                  // border: `2px ${InterventionStatusColorCodes[row.status] || lightGrey_color} solid`,
                  backgroundColor : '#a1a1a121',
                  fontWeight: 'bold',
                  padding: '5px',
                  color: InterventionStatusColorCodes[row.status] || lightGrey_color,
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  borderRadius: '18px',
                  fontSize:12,
                  cursor:'pointer'
                }}
                onClick={handleClick}
              >
             {row.status === STATUS.ACTIVE ? <CheckCircleOutlined fontSize="small" style={{ top:5 , color:InterventionStatusColorCodes.ACTIVE}} className={'statusColIcons'} />
            : row.status === STATUS.DRAFT ? <PlayCircleOutline fontSize="small" style={{ top:5 , color:InterventionStatusColorCodes.DRAFT}} className={'statusColIcons'} /> 
            : row.status === STATUS.COMPLETED ? <DoneAllOutlined fontSize="small" style={{ top:5 , color:InterventionStatusColorCodes.COMPLETED}} className={'statusColIcons'} /> 
            : <BlockOutlined fontSize="small" style={{ top:5 ,color:InterventionStatusColorCodes.TERMINATED}} className={'statusColIcons'} />
            } {row.status}
              </span>
  
              <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={()=>handleStatus(STATUS.DRAFT)}>
          <ListItemIcon>
              <PlayCircleOutline fontSize="small" style={{color:InterventionStatusColorCodes.INACTIVE}}/>
            </ListItemIcon>
            <Typography variant="inherit">Inactive</Typography>
            </MenuItem> */}
       { row.trigger === INTERVENTION_TRIGGERS.AUTO ?(
         
         <MenuItem><ListItemIcon>
              <InfoOutlined fontSize="small" style={{color:InterventionStatusColorCodes.DRAFT}} />
            </ListItemIcon>
            <Typography variant="inherit">Can't update status for auto-triggered & scheduled interventions</Typography></MenuItem>
        ) : ( <><MenuItem onClick={()=>handleStatus(STATUS.ACTIVE, row.id)} disabled={row.status === STATUS.ACTIVE}>  <ListItemIcon>
              <CheckCircleOutlined fontSize="small" style={{color:InterventionStatusColorCodes.ACTIVE}} />
            </ListItemIcon>
            <Typography variant="inherit">Activate</Typography>
            </MenuItem>
          <MenuItem onClick={()=>handleStatus(STATUS.TERMINATED, row.id)} disabled={row.status === STATUS.COMPLETED || row.status === STATUS.DRAFT || row.status === STATUS.TERMINATED }><ListItemIcon>
              <Block fontSize="small" style={{color:InterventionStatusColorCodes.TERMINATED}} />
            </ListItemIcon>
            <Typography variant="inherit">Terminate</Typography></MenuItem></>)}
        </Menu>
        
            </TableCell>
         
  
          <TableCell align="center">
            {/* Remove the disable-link from the link to enable them and also onclick function */}
           {row.trigger !== 'AUTO' ?         <ViewIntervention id={row.id} /> : ''}
            <ShowInterventionTrigger interventionDetails={row}  id={row.id} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Collapse in={false} timeout="auto" unmountOnExit></Collapse>
          </TableCell>
        </TableRow>{' '}
      </>
    );
  }