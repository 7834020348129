import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import RoleItem from './role-item';
import styled from 'styled-components';
import { theme } from '../../theme';
import SearchBar from '../../components/search-bar';
import Page from '../../components/Page';
import { getAllUserRoles } from '../../actions';
import { RootState } from '../../reducer';

const CardStyled = styled(Card)`
  margin: ${theme.spacing(5)}px;
`;

const CardContentStyled = styled(CardContent)`
  padding: 0;
`;

const StyledSearchBar = styled(SearchBar)`
  margin: ${theme.spacing()}px;
`;

export default function AdminPage() {
  const performSearch = (value: string) => {
    dispatch(getAllUserRoles(value));
  };

  const dispatch = useDispatch();
  const allRoles = useSelector((state: RootState) => state.roles?.allRoles);

  useEffect(() => {
    dispatch(getAllUserRoles());
  }, [dispatch]);

  return (
    <Page title="CAPRIS: Admin dashboard">
      <StyledSearchBar searchPlaceHolder={'Search by email'} onSearch={performSearch} />
      <CardStyled>
        <CardHeader title="Users" />
        <Divider />
        <CardContentStyled>
          <List>
            {allRoles &&
              !!allRoles.length &&
              allRoles.map((role) => {
                return <RoleItem role={role} key={role.email} />;
              })}
          </List>
          <Divider />
        </CardContentStyled>
      </CardStyled>
    </Page>
  );
}
