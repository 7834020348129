import { Divider, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Page from '../../components/PageV2';
import HistoricalReports from './history';
import InstitutionalReports from './institutional-report';
export default function Reports() {
  const rootTitle = 'Reports';

  const tabs = [
    { value: 'institutional', label: 'Institution Level' },
    // { value: 'non_submissions', label: 'Assessment Non-Submission' },
    { value: 'history', label: 'History' },
  ];

  const { tab: currentTab } = useParams<{ tab: string }>();
  const history = useHistory();

  const handleTabsChange = (event: any, value: string) => {
    history.push(value);
  };

  const header = rootTitle + ' : ' + tabs.find((x) => x.value === currentTab)?.label || '';
  const title = header.replace(':', '|');

  return (
    <Page header={header} alternateTitle={title}>
      <Tabs onChange={handleTabsChange} scrollButtons="auto" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <div>
        {/* {currentTab === 'non_submissions' && <NonSubmissionReports />} */}
        {currentTab === 'institutional' && <InstitutionalReports />}
        {currentTab === 'history' && <HistoricalReports />}
      </div>
    </Page>
  );
}
