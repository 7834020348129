import { get } from './common';
import { PACE_API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { BasicUnitInfo } from "../reducer/types"

export const ON_UNITS_RECEIVED = "ON_UNITS_RECEIVED";
export const ON_UNIT_SEARCH_TEXT_CHANGED = "ON_UNIT_SEARCH_TEXT_CHANGED";
export const SET_UNIT_UPLOAD_STATUS = "SET_UNIT_UPLOAD_STATUS";
export const ON_ALL_UNITS_RECEIVED = "ON_ALL_UNITS_RECEIVED";


export const getUnits = (
  searchText: string,
  page: number,
  recordPerPage: number,
  buCode: string,
  semesterCode: string
): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  dispatch({ type: ON_LOADING_START });
  try {
    const paceUrl = await PACE_API_URL()
    const result: BasicUnitInfo[] = await get(`${paceUrl}/third-party/newapi-active?buCode=${buCode}&term=${semesterCode}`)
    const finalResult = result.filter(x => (x.unitCode.includes(searchText) || (x.unitCategory || "").includes(searchText) || x.unitName.includes(searchText)))
    const finalObj = { count: finalResult.length, rows: finalResult.slice(recordPerPage * page - recordPerPage, recordPerPage * page) }
    dispatch({ type: ON_UNITS_RECEIVED, payload: finalObj });

  }
  catch (error) {
    dispatch({
      type: ON_UNITS_RECEIVED,
      payload: { count: 0, rows: [] },
    });
  }
  dispatch({ type: ON_LOADING_FINISHED });
};
export const onUnitSearchTextChanged = (searchText: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  dispatch({ type: ON_UNIT_SEARCH_TEXT_CHANGED, payload: searchText });
}

export const resetUnitProgress = (): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  dispatch({ type: SET_UNIT_UPLOAD_STATUS, payload: 0 });
}