import React, { FunctionComponent } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type ModalProps = {
  buttonText?: string;
  headerText: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  disabled?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minWidth: '350px',
    },
    actionContainerPadding: {
      padding: '8px 4%',
    },
    button: {
      backgroundColor: '#ed8c0e',
      color: 'whiteColor',
      '&:hover': {
        color: 'whiteColor',
        boxShadow: 'none',
      },
    },
  })
);

const CustomModal: FunctionComponent<ModalProps> = ({
  buttonText,
  headerText,
  open,
  onClose,
  onSuccess,
  children,
  disabled = false,
  maxWidth,
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={maxWidth}>
      <DialogTitle style={{ textAlign: 'center' }} id="customized-dialog-title">
        {' '}
        <strong> {headerText} </strong>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions className={classes.actionContainerPadding}>
        {buttonText && (
          <Button autoFocus color="primary" onClick={onSuccess} disabled={disabled}>
            {buttonText}
          </Button>
        )}
        <Button autoFocus color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
