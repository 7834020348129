import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
    ReferStudyMaterial,
    AttendSession,
    BookConsultation,
    EmailDelivery,
    FreeText,
    GradesCheck,
    Indices,
    NonSubmission,
    Notes,
    Plagiarism,
    WhatsAppDelivery,
  } from './nodeTypes/index';
import { Button } from '@material-ui/core';

export const addInterventionUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    snackbar: {
      // top: '-300px',
      // marginLeft: '175px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    pagination: {
      position: 'relative',
      fontSize: '14px',
      marginLeft: '10px',
      marginBottom: '9px',
      fontWeight: 'bold',
      marginTop: '14px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: '#04337824',
      color: '#494949',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

export const dashboardUseStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  pagination: {
    position: 'relative',
    fontSize: '14px',
    marginLeft: '10px',
    marginBottom: '9px',
    fontWeight: 'bold',
    marginTop: '14px',
  },
})
);

export const interventionReportStyles =  makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  pagination: {
    position: 'relative',
    fontSize: '14px',
    marginLeft: '10px',
    marginBottom: '9px',
    fontWeight: 'bold',
    marginTop: '14px',
  },
})
);

export const UtilityBarItems = ({ label, onClick, icon, color , disabled=false}) => {
    return (
      <Button
        size="small"
        style={{ width: '30%' }}
        startIcon={icon}
        variant="contained"
        color={color}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </Button>
    );
  };
  
  export const nodeTypes = {
    notes: Notes,
    gradeCheck: GradesCheck,
    referStudyMaterial: ReferStudyMaterial,
    indices: Indices,
    attendSession: AttendSession,
    bookConsultation: BookConsultation,
    freeText: FreeText,
    plagiarism: Plagiarism,
    nonSubmission: NonSubmission,
    whatsapp: WhatsAppDelivery,
    email: EmailDelivery,
  };
  
  export const initialElements = [
    {
      id: '4',
      type: 'notes',
      data: { label: 'Notes' },
      position: { x: 100, y: 100 },
    },
  ];
  
export const FlowChartUtlityBar = styled.div`
padding: 15px;
display: flex;
justify-content: space-around;
background: #f4f6f7;
flex-direction: row;
position: fixed;
bottom: 0;
z-index: 10;
width: 100%;
`;

export const SidebarFooter = styled.div`
display: flex;
    bottom: 0px;
    background-color: white;
    padding: 17px;
    width: 100%;
    justify-content: center;
    position: relative;
`