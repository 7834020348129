import React, { useState } from 'react';
import { Card, Button, Grid, CardContent, Divider, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { getDetailsByTicketId } from '../../actions';
import CustomModal from '../CustomModal';
import { Detail } from '../../util/types';
import Chip from '../Chip';
import { personTypes } from '../../util/constants';
import moment from 'moment';

type ticketDetailsProps = {
  subject: string;
  ticketDetails: Detail[];
  id: string;
  studentId: string;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));

const TicketDetails: React.FC<ticketDetailsProps> = ({ subject = '', ticketDetails = [], studentId = '', id = '' }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAddClick = () => {
    dispatch(getDetailsByTicketId(studentId, id));
    setModalOpen(true);
  };

  const renderModal = () => {
    return (
      <CustomModal open={open} onClose={handleModalClose} headerText={`Ticket Details : ${subject}`}>
        <Card>
          <CardContent>
            {ticketDetails && ticketDetails.length > 0 ? (
              ticketDetails.map((x, index) => {
                return (
                  <div key={x.message}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <strong> {x.name} </strong>
                      </Grid>
                      <Grid item md={2}>
                        <Chip avatar={false} label={x.by} type={x.by === personTypes.AGENT ? 'green' : 'default'} />
                      </Grid>
                      <Grid md={6} item style={{ textAlign: 'right' }}>
                        {moment(x.date).format('MM/DD/YYYY H:mm:ss')}
                      </Grid>
                      <Grid item>{x.message.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ')}</Grid>
                    </Grid>

                    {index !== ticketDetails.length - 1 && <Divider style={{ margin: '20px 0px' }} />}
                  </div>
                );
              })
            ) : (
              <div>Fetching Details...</div>
            )}
          </CardContent>
        </Card>
      </CustomModal>
    );
  };

  return (
    <>
      <Button onClick={onAddClick} color="primary" variant="outlined">
        <Info /> <div className={classes.textMargin}>Details</div>
      </Button>
      {renderModal()}
    </>
  );
};

export default TicketDetails;
