/* eslint-disable no-empty */
import { UserClaims } from '@okta/okta-auth-js';
import { ThunkAction } from 'redux-thunk';
import { GET_USER_DETAILS, REDIRECT, SET_SELECTED_BU_CODE, SET_SELECTED_SEMESTER } from '../actionTypes';
import { API_URL } from '../config/constants';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { storageCodes } from '../util/enums';
import { EventSearchFilters } from '../util/types';
import { get, post } from './common';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';



export const USERS_URL = async () => {
  return `${await API_URL()}/users`
};
export const ME_URL = async () => {
  return `${await USERS_URL()}/me`
};
export const MY_EVENTS_URL = async () => {
  return `${await ME_URL()}/events`
};
export const MY_UNITS_URL = async () => {
  return `${await ME_URL()}/units`
};

export const GET_ME = 'GET_ME';
export const GET_MY_EVENTS = 'GET_MY_EVENTS';
export const GET_MY_UNITS = 'GET_MY_UNITS';

export const getMe = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const result = await get(await ME_URL());
    dispatch({ type: GET_ME, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_ME,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getMyEvents =
  (
    searchText: string,
    filters: EventSearchFilters,
    page: number,
    recordPerPage: number
  ): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: ON_LOADING_START });
        const result = await post(await MY_EVENTS_URL(), {
          searchText,
          filters,
          limit: recordPerPage,
          offset: recordPerPage * page - recordPerPage,
        });
        dispatch({ type: GET_MY_EVENTS, payload: result });
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (error) {
        dispatch({
          type: GET_MY_EVENTS,
          payload: {},
        });
        dispatch({ type: ON_LOADING_FINISHED });
      }
    };

export const getMyUnits = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${await MY_UNITS_URL()}`);
    dispatch({ type: GET_MY_UNITS, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_MY_UNITS,
      payload: [],
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getUserDetails =
  (user: UserClaims): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_USER_DETAILS, payload: user });
      } catch (error) {
        dispatch({
          type: GET_USER_DETAILS,
          payload: {},
        });
      }
    };

export const redirect = (link: string) => {
  return { type: REDIRECT, payload: link };
};

export const setBuCodeLocal =
  (code: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        localStorage.setItem(storageCodes.BU_CODE_SELECTED, code);
        dispatch({ type: SET_SELECTED_BU_CODE, payload: code });
      } catch (error) {
        console.log(error);
      }
    };
export const setSemesterLocal =
  (semester: string): ThunkAction<void, RootState, unknown, ActionType> =>
    async (dispatch) => {
      try {
        localStorage.setItem(storageCodes.SEMESTER_SELECTED, semester);
        dispatch({ type: SET_SELECTED_SEMESTER, payload: semester });
      } catch (error) { }
    };
