import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, makeStyles, Snackbar } from '@material-ui/core';
import CustomModal from '../../components/CustomModal';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import { Pageview, SendOutlined, ViewAgenda, Visibility } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ASSIGNMENT_LEVEL_INTERVENTIONS } from '../../util/enums';
import { getAssignmentLogs, getAssignmentTemplatePreview, getAssignmnetInterventionTriggers, sendInterventionForAssignment } from '../../actions';
import { RootState } from '../../reducer';
import InterventionTriggersTable from './assignments/interventionTriggers';
import MuiAlert from '@material-ui/lab/Alert';
import { ON_INTERVENTIONS_SENT } from '../../actionTypes';
import ButtonWithConfirmation from '../../components/Modals/confirmation';
import { FlexRowDiv } from '../../components/Common';
import ShowEmail from '../../components/Modals/showEmail';
import { MainPreviewTypes } from '../../util/constants';

type SendInterventionProps = {
  assignmentId: string;
  unitCode: string;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));

const CustomDiv = styled.div`
  body {
    overflow: unset !important;
  }
`;

const SendIntervention: React.FC<SendInterventionProps> = ({ assignmentId, unitCode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setModalOpen] = useState(false);
  const previousInterventionTriggers = useSelector(
    (state: RootState) => state.assignmentDetails?.previousInterventionTriggers
  );
  const sendInterventionLoader_Non_Submission = useSelector(
    (state: RootState) => state.assignmentDetails?.sendInterventionLoader_Non_Submission
  );
  const sendInterventionLoader_Grades_Fail = useSelector(
    (state: RootState) => state.assignmentDetails?.sendInterventionLoader_Grades_Fail
  );
  const sendInterventionResponse = useSelector((state: RootState) => state.assignmentDetails?.sendInterventionResponse);
    const templatePreviewLoader = useSelector((state: RootState) => state.assignmentDetails?.assignmentMailPreviewLoader);
    const templatePreview = useSelector((state: RootState) => state.assignmentDetails?.assignmentMailPreviewContent);

  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setSuccessOpenSnackbar] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: ON_INTERVENTIONS_SENT,
      payload: null,
    });
    setErrorOpenSnackbar(false);

    setSuccessOpenSnackbar(false);
  };

  // useEffect(() => {
  //   dispatch(getAssignmnetInterventionTriggers(assignmentId));
  // }, [assignmentId]);
  useEffect(() => {
    if (sendInterventionResponse && sendInterventionResponse?.status === 'SUCCESS') {
      setSuccessOpenSnackbar(true);
      dispatch(getAssignmentLogs(assignmentId, unitCode));
    } else if (sendInterventionResponse && sendInterventionResponse?.status === 'ERROR') {
      setErrorOpenSnackbar(true);
    }
  }, [sendInterventionResponse]);

  const submit = (type) => {
    if (type === ASSIGNMENT_LEVEL_INTERVENTIONS.NON_SUBMISSION) {
      dispatch(sendInterventionForAssignment(assignmentId, ASSIGNMENT_LEVEL_INTERVENTIONS.NON_SUBMISSION));
      // dispatch(getAssignmnetInterventionTriggers(assignmentId));
    } else if (type === ASSIGNMENT_LEVEL_INTERVENTIONS.GRADES_FAIL) {
      dispatch(sendInterventionForAssignment(assignmentId, ASSIGNMENT_LEVEL_INTERVENTIONS.GRADES_FAIL));
      // dispatch(getAssignmnetInterventionTriggers(assignmentId));
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    dispatch(getAssignmnetInterventionTriggers(assignmentId));
    setModalOpen(true);
  };

  const getPreview = async (assignmentId,type)=>{
    dispatch(getAssignmentTemplatePreview(assignmentId, type))
  }

  const renderModal = () => {
    return (
      <CustomModal maxWidth={'md'} open={open} onClose={handleModalClose} headerText={'Send Intervention'}>
        <div className={'justify-content-center text-align-center'}>
          {' '}
          <Card>
            <CardHeader title="Non-Submission" style={{backgroundColor:'#f6f6f6'}}/>
            <CardContent>
              <FlexRowDiv style={{width:470}}>
 <ShowEmail loader={templatePreviewLoader}
      title={'Template Preview: '}
                              type={'Non-Submission'}
                              onClick={()=>getPreview(assignmentId, MainPreviewTypes.NON_SUBMISSION)}
                              htmlMail={templatePreview}
                              disabled={false}
                              buttonText={'View Template'}
                            />
                <ButtonWithConfirmation
                  buttonText={'Send Intervention'}
                  icon={<SendOutlined />}
                  question={'Are you sure ?'}
                  onClickFunc={() => submit(ASSIGNMENT_LEVEL_INTERVENTIONS.NON_SUBMISSION)}
                  agree={'YES'}
                  disagree={'No'}
                />
              </FlexRowDiv>
            </CardContent>
          </Card>
          <br />
          <br />
          <Card>
            <CardHeader title="Unsatisfacotry Grades (<50%)" style={{backgroundColor:'#f6f6f6'}}/>
            <CardContent>
             <FlexRowDiv style={{width:470}}>
                <ShowEmail loader={templatePreviewLoader}
      title={'Template Preview: '}
                              type={'Unsatisfactory Grades'}
                              onClick={()=>getPreview(assignmentId, MainPreviewTypes.UNSATISFACTORY_GRADES)}
                              htmlMail={templatePreview}
                              disabled={false}
                              buttonText={'View Template'}
                            />
              <ButtonWithConfirmation
                buttonText={'Send Intervention'}
                icon={<SendOutlined />}
                question={'Are you sure ?'}
                onClickFunc={() => submit(ASSIGNMENT_LEVEL_INTERVENTIONS.GRADES_FAIL)}
                agree={'YES'}
                disagree={'No'}
              />
              </FlexRowDiv>
            </CardContent>
          </Card>
        </div>
      </CustomModal>
    );
  };

  return (
    <>
      <Button onClick={handleModalOpen} color="primary" variant="outlined" startIcon={<SendOutlined />}>
        <div className={classes.textMargin}>Send Intervention</div>
      </Button>
      {renderModal()}
      <Snackbar onClose={handleClose} open={openErrorSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {sendInterventionResponse?.message}
        </MuiAlert>
      </Snackbar>
      <Snackbar onClose={handleClose} open={openSuccessSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
          {sendInterventionResponse?.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default SendIntervention;
