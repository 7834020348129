import { Divider, MenuItem, Select, TextField } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import React from 'react';
import { Position } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setFlowChartData } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { ImprovementAreas } from '../../../../util/constants';
import { CloseNodeButton } from '../../components/closeNodeButton';
import {
  CustomHandle2,
  CustomNodeTitle2,
  DivRows,
  LabelSpan,
  LabelSpanForSelect,
  MainDiv2,
} from '../../flowChartComponents/styled-framework-items';

const Outer = styled.div`
  padding: 30px;
`;

export const FreeText = ({ data }) => {
  const dispatch = useDispatch();
  const flowChartData = useSelector((state: RootState) => state.intervention?.flowChartData);
  const [addText, setAddText] = React.useState(data.freeText);
  const updateData = (e) => {
    let updateObj = { ...flowChartData };
    updateObj.freeText = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setAddText(e.target.value);
    data.freeText = e.target.value;
  };
  const improvementAreasData = useSelector((state: RootState) => state.ImprovementArea?.data);

  const [improvementAreas, setImprovementAreas] = React.useState(data['improvementAreas']);

  const handleImprovementArea = (event) => {
    let updateObj = { ...flowChartData };
    updateObj['improvementAreas'] = event.target.value;
    dispatch(setFlowChartData(updateObj));
    setImprovementAreas(event.target.value);
    data['improvementAreas'] = event.target.value;
  };
  const [description, setDescription] = React.useState(data['description']);
  const updateDescription = (e) => {
    let updateObj = { ...flowChartData };
    updateObj['description'] = e.target.value;
    dispatch(setFlowChartData(updateObj));
    setDescription(e.target.value);
    data['description'] = e.target.value;
  };

  return (
    <MainDiv2>
      <CustomHandle2 type="target" id="actions_freeText" position={Position.Top} />
      {/* <CustomHandle1 type="source" id="actions_freeText" position={Position.Bottom} /> */}
      <CustomNodeTitle2>
        <TextFieldsIcon style={{ fontSize: '29px' }} />
        &nbsp;&nbsp;Add Text
        <CloseNodeButton/>
      </CustomNodeTitle2>
      <Divider />
      <Outer>
      <DivRows>
          <LabelSpanForSelect style={{ marginTop: '0px !important' }}>Improvement Areas: </LabelSpanForSelect>{' '}
         <Select
            style={{ width: 279, marginLeft: 11 }}
            labelId="demo-simple-select-labelConsultation"
            id="demo-simple-selectConsultation"
            value={improvementAreas}
            onChange={handleImprovementArea}
          >
            {improvementAreasData.map((x) => (
              <MenuItem value={x.type}>{x.type}</MenuItem>
            ))}
          </Select> 
        </DivRows>


        {/* <DivRows>
          <LabelSpan>Description: </LabelSpan>{' '}
          <TextField
            style={{ padding: '10px', width: 300 }}
            id="custom-description"
            type="text"
            multiline
            placeholder="Enter the description"
            value={description}
            onChange={(e: any) => {
              updateDescription(e);
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </DivRows> */}

        <DivRows>
          <LabelSpan>Text: </LabelSpan>{' '}
          <TextField
            style={{ padding: '10px', width: 300 }}
            id="custom-freeText"
            type="text"
            placeholder="Text here ..."
            value={addText}
            onChange={(e: any) => {
              updateData(e);
            }}
          />
        </DivRows>
      </Outer>
    </MainDiv2>
  );
};
