import { Divider, IconButton, InputBase, Paper, Theme } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { KeyboardEvent, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

type SearchProps = {
  onSearch: (value: string) => void;
  className?: string;
  searchPlaceHolder?: string;
};

const Search: React.FC<SearchProps> = ({ searchPlaceHolder, onSearch, className, ...rest }) => {
  const [value, setValue] = useState('');

  const search = () => {
    onSearch(value);
  };

  const clear = () => {
    setValue('');
    onSearch('');
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.keyCode === 13) {
      search();
    }
  };

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={searchPlaceHolder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        inputProps={{
          onKeyDown: onKeyDown,
        }}
      />
      <IconButton onClick={search} className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <Divider orientation="vertical" className={classes.divider} />
      <IconButton onClick={clear} className={classes.iconButton} disabled={!value || value === ''}>
        <HighlightOff />
      </IconButton>
    </Paper>
  );
};

export default Search;
