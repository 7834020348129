import React, { useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { History } from 'history';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as StoreProvider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import './assets/scss/main.scss';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import { CLIENT_ID, ISSUER, OktaCustomStorage, PKCE, REDIRECT_URI } from './config/okta.config';
import { configureStore } from './store';
import { theme } from './theme';
import AppRoutes from './views/app-routes/app-routes';
import axios from 'axios';
import { KEY_BU_CODE, KEY_SEMESTER } from './config/constants';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';

const store = configureStore();

interface AppProps {
  history: History<unknown>;
}

const App: React.FC<AppProps> = ({ history }) => {
  // Checks for app url

  const oktaAuth = new OktaAuth({
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: PKCE,
    storageManager: {
      token: {
        storageProvider: OktaCustomStorage,
      },
    },
  });

  axios.interceptors.request.use(async (config) => {
    const tokenData = oktaAuth.getAccessToken();
    if (tokenData) {
      config.headers.Authorization = tokenData;
    }
    const buCode = localStorage.getItem(KEY_BU_CODE);
    if (buCode) {
      config.headers.BuCode = `${buCode}`;
    }
    const semesterCode = localStorage.getItem(KEY_SEMESTER);
    if (semesterCode) {
      config.headers.SemesterCode = `${semesterCode}`;
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  });

  axios.interceptors.response.use(async (config) => {
    // Not authorized redirect to login
    if (config.status === 401) {
      onAuthRequired();
    }
    return config;
  });

  const onAuthRequired = () => {
    oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
                <Switch>
                  <Route path="/implicit/callback" exact component={LoginCallback} />
                  <AppRoutes />
                </Switch>

                <ScrollReset />
              </Security>
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
