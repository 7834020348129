
import { ActionType } from './types'
import * as actionTypes from "../actions";
import { CommLog } from "../util/types"

const initialState = {
  commLog: {}
};

const commLog = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_COMM_LOG_BY_STUDENT_ID: {
      const { data, id } = action.payload
      const newCommLog = JSON.parse(JSON.stringify(state.commLog))
      newCommLog[id] = data;
      return {
        ...state,
        commLog: newCommLog
      };
    }
    case actionTypes.CREATE_COMM_LOG: {
      const { studentId = "" } = action.payload
      const commLog = JSON.parse(JSON.stringify(state.commLog))
      if (studentId) {
        const studentCommLog = commLog[studentId] || []
        const newCommLog = [...studentCommLog, action.payload]
        commLog[studentId] = newCommLog
      }
      return {
        ...state,
        commLog: commLog
      };
    }
    case actionTypes.EDIT_COMM_LOG: {
      const { studentId = "", id = "" } = action.payload
      const commLog = JSON.parse(JSON.stringify(state.commLog))
      if (id) {
        const studentCommLog = commLog[studentId] || []
        const newCommLog = studentCommLog.map((x: CommLog) => x.id === id ? action.payload : x)
        commLog[studentId] = newCommLog
      }
      return {
        ...state,
        commLog: commLog
      };
    }
    case actionTypes.DELETE_COMM_LOG_BY_ID: {
      const { id = "", studentId = "" } = action.payload
      const commLog = JSON.parse(JSON.stringify(state.commLog))
      if (id) {
        const studentCommLog = commLog[studentId] || []
        const filteredCommLog = studentCommLog.filter((x: CommLog) => x.id !== id)
        commLog[studentId] = filteredCommLog
      }
      return {
        ...state,
        commLog: commLog
      };
    }
    default: {
      return state;
    }
  }
};

export default commLog;
