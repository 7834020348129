import { Button, IconButton, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useState } from 'react';
import styled from 'styled-components';

type ButtonWithConfirmationProps = {
  buttonType?: 'ICON' | 'BUTTON';
  buttonText: string;
  question: string;
  agree?: string;
  disagree?: string;
  onClickFunc: any;
  onClickFunctionParams?: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
  icon?: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));
const CustomTitle = styled.div`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
`;

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  buttonType = 'BUTTON',
  buttonText,
  color,
  variant,
  icon,
  question,
  agree,
  disagree,
  onClickFunc,
  onClickFunctionParams,
}) => {
  const classes = useStyles();

  const [open, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const submit = () => {
    if (onClickFunctionParams) {
      onClickFunc(onClickFunctionParams);
    } else {
      onClickFunc();
    }
    setModalOpen(false);
  };

  const renderModal = () => {
    return (
      <Dialog
        open={open}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CustomTitle>{question}</CustomTitle>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} color="primary" autoFocus>
            {agree ? agree : 'Agree'}
          </Button>
          <Button onClick={handleModalClose} color="primary" autoFocus>
            {disagree ? disagree : 'Disagree'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {buttonType === 'BUTTON' ? (
        <Button
          onClick={handleModalOpen}
          color={color ? color : 'primary'}
          variant={variant ? variant : 'outlined'}
          startIcon={icon}
        >
          <div className={classes.textMargin}>{buttonText}</div>
        </Button>
      ) : (
        <IconButton edge="end" aria-label={buttonText} onClick={handleModalOpen}>
          {icon}
        </IconButton>
      )}
      {renderModal()}
    </>
  );
};

export default ButtonWithConfirmation;
