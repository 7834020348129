import { get, post, } from './common';
import { API_URL, REPORT_REFRESH_TIME } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { reportTypes } from '../util/enums';
import {
  CALLBACK_REPORT_FINISHED,
  FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
  FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED,
  FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION,
  FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION,
  FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED,
  GET_INSTITUTIONAL_NON_SUBMISSION_REPORT,
  GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
  GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
  GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT,
  ON_FEEDBACK_REPORT_LOADING_FINISHED,
  ON_FEEDBACK_REPORT_LOADING_START,
  ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_START,
  ON_INSTITUTIONAL_REPORT_LOADING_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START,
  ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START,
  ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START,
  ON_INSTITUTIONAL_REPORT_LOADING_START,
  ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED,
  ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START,
  ON_INTERVENTION_REPORT_LOADING_FINISHED,
  ON_INTERVENTION_REPORT_LOADING_START,
  RESET_INSTITUTIONAL_REPORTS_RESPONSE,
  SET_CALL_BACK_REPORT_RESPONSE,
  SET_FEEDBACK_REPORT_RESPONSE,
  SET_INSTITUTIONAL_REPORTS_RESPONSE,
  SET_INTERVENTION_REPORT_RESPONSE,
  GET_INSTITUTIONAL_INTERIM_REPORT,
  SET_INSTITUTIONAL_INTERIM_RESPONSE,
  FIND_INSTITUTIONAL_REPORTS_INTERIM,
  GET_INSTITUTIONAL_ARCHIVES_REPORT,
  SET_INSTITUTIONAL_ARCHIVES_RESPONSE,
  FIND_INSTITUTIONAL_REPORTS_ARCHIVES,
  SET_EVENT_REPORT_RESPONSE,
  EVENT_REPORT_FINISHED,
  SET_TIME_TABLE_REPORT_RESPONSE,
  TIME_TABLE_REPORT_FINISHED
} from '../actionTypes';

export const generateInstitutionalReports = (
  type: string,
  data: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  if (type === reportTypes.STUDENT_AT_RISK) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_REPORTS_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_FINISHED });
    }
  } else if (type === reportTypes.ATTENDANCE_REPORT_WEEKLY) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_REPORTS_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_ATTENDANCE_REPORT_WEEKLY_FINISHED });
    }
  } else if (type === reportTypes.STUDENT_PROGRESSION) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_REPORTS_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_STUDENT_PROGRESSION_FINISHED });
    }
  } else if (type === reportTypes.NON_SUBMISSION_REPORTS) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_REPORTS_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_NON_SUBMISSION_REPORT, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_NON_SUBMISSION_REPORT,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_NON_SUBMISSION_FINISHED });
    }
  }
  else if (type === reportTypes.STUDENT_INTERIM) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_INTERIM_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_INTERIM_REPORT, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_INTERIM_REPORT,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_INTERIM_FINISHED });
    }
  }
  else if (type === reportTypes.ARCHIVES) {
    try {
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_START });
      const url = `${await API_URL()}/generate-reports/institutional/${type}`;
      const result = await post(url, data);
      if (result) {
        dispatch({ type: SET_INSTITUTIONAL_ARCHIVES_RESPONSE });
        dispatch(findInstitutionalReports(result.id, type));
      }

      dispatch({ type: GET_INSTITUTIONAL_ARCHIVES_REPORT, payload: result });
    } catch (error) {
      dispatch({
        type: GET_INSTITUTIONAL_ARCHIVES_REPORT,
        payload: null,
      });
      dispatch({ type: ON_INSTITUTIONAL_REPORT_LOADING_ARCHIVES_FINISHED });
    }
  }
};

export const generateStaticReports = (
  type: string,
  data: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  if (type === reportTypes.STUDENT_LEVEL_INTERVENTION_REPORT) {
    dispatch({ type: ON_INTERVENTION_REPORT_LOADING_START });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    dispatch({
      type: SET_INTERVENTION_REPORT_RESPONSE,
      payload: [],
    });
    dispatch({ type: ON_INTERVENTION_REPORT_LOADING_FINISHED });
  } else if (type === reportTypes.COURSE_LEVEL_FEEDBACK_REPORT) {
    dispatch({ type: ON_FEEDBACK_REPORT_LOADING_START });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    dispatch({
      type: SET_FEEDBACK_REPORT_RESPONSE,
      payload: [],
    });
    dispatch({ type: ON_FEEDBACK_REPORT_LOADING_FINISHED });
  }
};

export const findInstitutionalReports = (id, type): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    const url = await API_URL() + `/find/global-reports/${id}`;
    let result = await get(url);
    if (type === reportTypes.STUDENT_AT_RISK) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK, payload: result });
      }
    } else if (type === reportTypes.ATTENDANCE_REPORT_WEEKLY) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY, payload: result });
      }
    } else if (type === reportTypes.STUDENT_PROGRESSION) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION, payload: result });
      }
    } else if (type === reportTypes.STUDENT_INTERIM) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_INTERIM, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_INTERIM, payload: result });
      }
    } else if (type === reportTypes.ARCHIVES) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_ARCHIVES, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_ARCHIVES, payload: result });
      }
    } else if (type === reportTypes.NON_SUBMISSION_REPORTS) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION, payload: result });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION, payload: result });
      }
    } else if (type === reportTypes.CALLBACKS) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: SET_CALL_BACK_REPORT_RESPONSE, payload: result.content });
            dispatch({ type: CALLBACK_REPORT_FINISHED });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: SET_CALL_BACK_REPORT_RESPONSE, payload: result.content });
        dispatch({ type: CALLBACK_REPORT_FINISHED });
      }
    } else if (type === reportTypes.TIMETABLE) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: SET_TIME_TABLE_REPORT_RESPONSE, payload: result.content });
            dispatch({ type: TIME_TABLE_REPORT_FINISHED });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: SET_TIME_TABLE_REPORT_RESPONSE, payload: result.content });
        dispatch({ type: TIME_TABLE_REPORT_FINISHED });
      }
    } else if (type === reportTypes.EVENTS) {
      if (!result.content) {
        const id = setInterval(async () => {
          result = await get(url);
          if (result.content) {
            clearInterval(id);
            dispatch({ type: SET_EVENT_REPORT_RESPONSE, payload: result.content });
            dispatch({ type: EVENT_REPORT_FINISHED });
          }
        }, REPORT_REFRESH_TIME);
      } else {
        dispatch({ type: SET_EVENT_REPORT_RESPONSE, payload: result.content });
        dispatch({ type: EVENT_REPORT_FINISHED });
      }
    }
  } catch (error) {
    if (type === reportTypes.STUDENT_AT_RISK) {
      dispatch({
        type: FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK_FAILED,
        payload: null,
      });
    } else if (type === reportTypes.ATTENDANCE_REPORT_WEEKLY) {
      dispatch({
        type: FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY_FAILED,
        payload: null,
      });
    } else if (type === reportTypes.STUDENT_PROGRESSION) {
      dispatch({
        type: FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION_FAILED,
        payload: null,
      });
    } else if (type === reportTypes.NON_SUBMISSION_REPORTS) {
      dispatch({
        type: FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION_FAILED,
        payload: null,
      });
    }
  }
};

export const resetReports = (type): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  if (type === reportTypes.STUDENT_AT_RISK) {
    dispatch({
      type: FIND_INSTITUTIONAL_REPORTS_STUDENT_AT_RISK,
      payload: null,
    });
  } else if (type === reportTypes.ATTENDANCE_REPORT_WEEKLY) {
    dispatch({
      type: FIND_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
      payload: null,
    });
    dispatch({
      type: GET_INSTITUTIONAL_REPORTS_ATTENDANCE_REPORT_WEEKLY,
      payload: null,
    });
  } else if (type === reportTypes.STUDENT_PROGRESSION) {
    dispatch({
      type: FIND_INSTITUTIONAL_REPORTS_STUDENT_PROGRESSION,
      payload: null,
    });
    dispatch({
      type: GET_INSTITUTIONAL_STUDENT_PROGRESSION_REPORT,
      payload: null,
    });
  } else if (type === reportTypes.NON_SUBMISSION_REPORTS) {
    dispatch({
      type: FIND_INSTITUTIONAL_REPORTS_NON_SUBMISSION,
      payload: null,
    });
    dispatch({
      type: GET_INSTITUTIONAL_NON_SUBMISSION_REPORT,
      payload: null,
    });
  }
};

export const resetResponseForInstitutionalReports = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({ type: RESET_INSTITUTIONAL_REPORTS_RESPONSE });
};
