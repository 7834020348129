import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { remove } from './common';
import { STUDENT_URL } from '../util/constants';
import { get, post, put } from './common';

export const GET_COMM_LOG_BY_STUDENT_ID = 'GET_COMM_LOG_BY_STUDENT_ID';
export const CREATE_COMM_LOG = 'CREATE_COMM_LOG';
export const EDIT_COMM_LOG = 'EDIT_COMM_LOG';
export const DELETE_COMM_LOG_BY_ID = 'DELETE_COMM_LOG_BY_ID';

export const getCommLogByStudentId = (studentId: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/comm-log`;
    dispatch({ type: ON_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_COMM_LOG_BY_STUDENT_ID, payload: { data: result.data, id: studentId } });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_COMM_LOG_BY_STUDENT_ID,
      payload: { data: [], id: studentId },
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const createCommLog = (
  studentId: string,
  date: string,
  week: number,
  comments: string,
  onTrack: boolean
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/comm-log`;
    dispatch({ type: ON_LOADING_START });
    const result = await post(url, { studentId, date, week, comments, onTrack });
    dispatch({ type: CREATE_COMM_LOG, payload: result.data });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: CREATE_COMM_LOG,
      payload: {},
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const editCommLog = (
  id: number,
  date: string,
  week: number,
  comments: string,
  onTrack: boolean,
  studentId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/comm-log/${id}`;
    dispatch({ type: ON_LOADING_START });
    const result = await put(url, { id, date, week, comments, onTrack });
    dispatch({ type: EDIT_COMM_LOG, payload: result.data });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: EDIT_COMM_LOG,
      payload: {},
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const deleteCommLogById = (
  id: number,
  studentId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/comm-log/${id}`;
    dispatch({ type: ON_LOADING_START });
    const result = await remove(url, { data: { id } });
    dispatch({ type: DELETE_COMM_LOG_BY_ID, payload: result.data });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: DELETE_COMM_LOG_BY_ID,
      payload: {},
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};
