import { Button, Card, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import CustomModal from '../CustomModal';

type AssignmentEditCommentsProps = {
  comments:string[];
 
};


const AssignmentEditComments: React.FC<AssignmentEditCommentsProps> = ({ comments }) => {
 

  const [open, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAddClick = () => {
    
    setModalOpen(true);
  };

  const renderModal = () => {
    return (
      <CustomModal open={open} onClose={handleModalClose} headerText={`Comments`}>
        <Card>
          <CardContent>
           
              <Grid>
                {comments && comments.length > 0 ? (
                  comments.map((x, index) => {
                      const name = x.split('|')[0] || 'NA';
                      const dated = moment(x.split('|')[1]).format('MMM D, YYYY H:mm')
                      const msg = x.split('|')[2].trim()
                   
                    return (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item>{msg}</Grid>
                          
                         <Typography style={{width:'100%', color: '#aaaaaa',fontSize: '12px'}}>
                             <div style={{float:'left'}}>{name}</div>
                             <div  style={{float:'right'}}>{dated}</div>
                         </Typography>
                        </Grid>

                        {index !== comments.length - 1 && <Divider style={{ margin: '20px 0px' }} />}
                      </div>
                    );
                  })
                ) : (
                  <div>No Comments</div>
                )}
              </Grid>
           
          </CardContent>
        </Card>
      </CustomModal>
    );
  };

  return (
    <>
       <IconButton onClick={onAddClick}  style={{color:'#c8cce1'}}
                                size="small" >
        <Comment />
      </IconButton>
      {renderModal()}
    </>
  );
};

export default AssignmentEditComments;
