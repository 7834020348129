import { ActionType } from './types';
import * as actionTypes from '../actions';
import { EVENT_REPORT_FINISHED, EVENT_REPORT_START, SET_EVENT_REPORT_RESPONSE } from '../actionTypes';
import { ATTENDANCE_RESULTS } from '../util/enums';

interface EventState {
  upComingMeetings: any[];
  eventsForAttendance: any[];
  code: { id: string; eventSubject?: string } | undefined;
  eventDetails: any;
  attendanceResult: { stateInfo: string, title: string, info: string };
  eventList: any;
  searchEventList: any;
  response: any;
  searchText: string;
  loader: boolean;
  isFeduni: boolean;
  eventsReportLoader: boolean,
  eventsReportData: string
}

const initialState: EventState = {
  upComingMeetings: [],
  eventsForAttendance: [],
  code: undefined,
  eventDetails: undefined,
  attendanceResult: { stateInfo: ATTENDANCE_RESULTS.LOADING, title: "", info: 'Marking attendance...' },
  eventList: {},
  searchEventList: {},
  searchText: '',
  loader: false,
  isFeduni: true,
  response: null,
  eventsReportLoader: false,
  eventsReportData: ""
};

const events = (state = initialState, action: ActionType): EventState => {
  switch (action.type) {
    case actionTypes.GET_UPCOMING_MEETING_RECEIVED: {
      return {
        ...state,
        upComingMeetings: action.payload,
      };
    }
    case actionTypes.GET_RESPONSE_FOR_MARK_ATTENDANCE: {
      return {
        ...state,
        response: action.payload,
      };
    }
    case actionTypes.GET_MEETING_FOR_ATTENDANCE_RECEIVED: {
      return {
        ...state,
        eventsForAttendance: action.payload,
      };
    }
    case actionTypes.GET_ATTENDANCE_CODE_RECEIVED: {
      return {
        ...state,
        code: { id: action.payload },
      };
    }
    case actionTypes.GET_EVENT_RECEIVED: {
      return {
        ...state,
        eventDetails: action.payload,
      };
    }
    case actionTypes.ATTENDANCE_CODE_DETAILS_RECEIVED: {
      const code = { id: state.code!.id, eventSubject: action.payload.event.meetingSubject };
      return {
        ...state,
        code,
      };
    }
    case actionTypes.MARK_ATTENDANCE_RECEIVED: {
      const obj = { stateInfo: ATTENDANCE_RESULTS.LOADING, title: "", info: 'Marking attendance...' }
      const { statusCode = '', message = '' } = action.payload;
      obj.info = message
      switch (statusCode) {
        case 400:
        case 403:
        case 404:
        case 410:
        case 500:
          obj.stateInfo = ATTENDANCE_RESULTS.ERROR
          break;
        default:
          obj.stateInfo = ATTENDANCE_RESULTS.LOADED
          obj.title = ""
          obj.info = 'Attendance marked'
          break;
      }
      return {
        ...state,
        attendanceResult: obj,
      };
    }
    case actionTypes.GET_EVENTS_RECEIVED: {
      return {
        ...state,
        eventList: action.payload,
        loader: false,
      };
    }
    case actionTypes.RESET_EVENT_CODE_STATE: {
      return {
        ...state,
        code: undefined,
      };
    }
    case actionTypes.GET_EVENT_SEARCH_RECEIVED: {
      return {
        ...state,
        searchEventList: action.payload,
        loader: false,
      };
    }
    case actionTypes.ON_EVENTS_SEARCH_CHANGED: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }
    case actionTypes.RESET_EVENT_DETAILS: {
      return {
        ...state,
        // eventDetails: null,
      };
    }
    case actionTypes.SET_FEDUNI_EVENT: {
      return {
        ...state,
        isFeduni: action.payload,
      };
    }
    case EVENT_REPORT_START: {
      return {
        ...state,
        eventsReportLoader: true
      }
    }
    case EVENT_REPORT_FINISHED: {
      return {
        ...state,
        eventsReportLoader: false
      }
    }
    case SET_EVENT_REPORT_RESPONSE: {
      return {
        ...state,
        eventsReportData: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default events;
