/* eslint-disable no-empty */
import { get, post } from './common';
import { API_URL, PACE_API_URL } from '../config/constants';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { Role } from '../dto/role';
import { roles, storageCodes } from '../util/enums';
import { SET_BU_CODES, SET_SELECTED_BU_CODE, SET_SELECTED_SEMESTER } from '../actionTypes';
import { getBaseUrls } from '.';
export const ROLE_URL = async () => {
  return `${await API_URL()}/roles`
};
export const MY_ROLE_URL = async () => {
  return `${await ROLE_URL()}/me`
};
export const SEMESTER_CODE_URL = async (buCode: string) => {
  return `${await API_URL()}/pace/semester-code/${buCode}`
};

export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES';
export const GET_MY_ROLES = 'GET_MY_ROLES';
export const SET_NEW_ROLE = 'SET_NEW_ROLE';

export const getAllUserRoles = (emailFilter?: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  try {
    dispatch({ type: ON_LOADING_START });
    let url = await ROLE_URL();
    if (emailFilter) url += '?email=' + emailFilter;
    const result = await get(url);
    dispatch({ type: GET_ALL_USER_ROLES, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_ALL_USER_ROLES,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const getMyRole = (user: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    let currentRole = localStorage.getItem(storageCodes.USER_ROLE);
    if (user.email) {
      await dispatch(getBaseUrls(user?.email));
    }
    const url = await MY_ROLE_URL();
    const result = await get(url);
    currentRole = result.role;
    localStorage.setItem(storageCodes.USER_ROLE, result.role);
    dispatch({ type: GET_MY_ROLES, payload: currentRole });
  } catch (error) {
    dispatch({ type: GET_MY_ROLES, payload: roles.NO_ACCESS });
  }
};

export const getLatestSemesterforBU = (buCode: string): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const semesterCodeUrl = await SEMESTER_CODE_URL(buCode);
    const semesterCode = await get(semesterCodeUrl);
    localStorage.setItem(storageCodes.SEMESTER_SELECTED, semesterCode);
    dispatch({ type: SET_SELECTED_SEMESTER, payload: semesterCode });
  }
  catch (err) { }
}

export const setBuCodes = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const result = JSON.parse(localStorage.getItem(storageCodes.BU_CODES) || '') || [];
    const resultCode = localStorage.getItem(storageCodes.BU_CODE_SELECTED);
    dispatch({ type: SET_BU_CODES, payload: result });
    dispatch({ type: SET_SELECTED_BU_CODE, payload: resultCode });
  } catch (error) {
    dispatch({
      type: SET_BU_CODES,
      payload: [],
    });
  }
};

export const setSemesterFromLocal = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    const semester = localStorage.getItem(storageCodes.SEMESTER_SELECTED);
    dispatch({ type: SET_SELECTED_SEMESTER, payload: semester });
  } catch (error) { }
};

export const setNewSemesterCode = (buCode: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  console.log('---- setting new semester code');
  try {
    const semesterCodeUrl = await SEMESTER_CODE_URL(buCode);
    const semesterCode = await get(semesterCodeUrl);
    localStorage.setItem(storageCodes.SEMESTER_SELECTED, semesterCode);
    dispatch({ type: SET_SELECTED_SEMESTER, payload: semesterCode });
  } catch (error) { }
};

export const setNewRole = (role: Role): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING_START });
    const result = await post(await ROLE_URL(), role);
    dispatch({ type: SET_NEW_ROLE, payload: result });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: SET_NEW_ROLE,
      payload: null,
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const removeRole = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  localStorage.removeItem(storageCodes.USER_ROLE);
  localStorage.removeItem(storageCodes.BU_CODES);
  localStorage.removeItem(storageCodes.BU_CODE_SELECTED);
};
