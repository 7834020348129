import { Handle } from 'react-flow-renderer';
import styled from 'styled-components'
import { InterventionFrameworkColorCodes } from '../../../util/constants';

export const CustomNodeTitle1 = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: ${InterventionFrameworkColorCodes.conditions};
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  color: white;
  font-size: 20px;
  padding: 15px;
  text-align: center;
`;
export const MainDiv1 = styled.div`

border-radius: 8px;
`
export const CustomHandle1 = styled(Handle)`
border: 3px solid ${InterventionFrameworkColorCodes.conditions};
`

export const CustomNodeTitle2 = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: ${InterventionFrameworkColorCodes.actions};
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  color: white;
  font-size: 20px;
  padding: 15px;
  text-align: center;
`;
export const MainDiv2 = styled.div`
border-radius: 8px;
`
export const CustomHandle2 = styled(Handle)`
border: 3px solid ${InterventionFrameworkColorCodes.actions};
`

export const LabelSpan = styled.span`
margin-top: 24px; width:116px; font-weight: bold;
font-size:14px
`

export const LabelSpanForSelect = styled.span`
 width:116px; font-weight: bold;
font-size:14px
`

export const DivRows = styled.div`
text-align: center;
    display: flex;
    margin-top: 20px;
`