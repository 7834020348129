import { ActionType } from './types';
import * as actionTypes from '../actions';
import {
  GET_USER_DETAILS,
  REDIRECT,
  SET_BU_CODES,
  SET_SELECTED_BU_CODE,
  SET_SELECTED_SEMESTER,
  SET_SEMESTER,
  SET_SEMESTERS
} from '../actionTypes';
import { setBusinessUnit, setSemester } from '../actions';
import { weekData } from '../util/common';

const initialState = {
  eventList: {},
  myData: undefined,
  unitData: [],
  loader: false,
  userDetails: {},
  redirectTo: '',
  buCodes: [],
  selectedBuCode: '',
  selectedSemester: '',
  semestersWithBuCodes: {}
};

const users = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_MY_EVENTS: {
      return {
        ...state,
        eventList: action.payload,
        loader: false,
      };
    }
    case SET_SEMESTERS: {
      const { buCode = "", semesters = [] } = action.payload
      const oldSemestersList = JSON.parse(JSON.stringify(state.semestersWithBuCodes))
      const semestersWithlabels = semesters.map(x => {
        const { term = "", teaching_start_date = "", teaching_end_date = "", exam_end_date = "", result_date = "" } = x
        const convertedStartDate = new Date(teaching_start_date);
        const preStartDate = new Date(convertedStartDate.setDate(convertedStartDate.getDate() - 1))
        return { label: term, value: term, startDate: new Date(teaching_start_date), endDate: new Date(exam_end_date), teachingEndDate: new Date(teaching_end_date), preStartDate, weekWiseData: weekData(preStartDate, new Date(exam_end_date)), resultDate: new Date(result_date) }
      })
      oldSemestersList[buCode] = semestersWithlabels
      return {
        ...state,
        semestersWithBuCodes: oldSemestersList,
      };
    }
    case actionTypes.GET_ME: {
      return {
        ...state,
        myData: action.payload,
      };
    }
    case actionTypes.GET_MY_UNITS: {
      return {
        ...state,
        unitData: action.payload,
      };
    }
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loader: true,
      };
    }
    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }

    case SET_BU_CODES: {
      const codes = action.payload;
      return {
        ...state,
        buCodes: codes,
      };
    }

    case SET_SELECTED_BU_CODE: {
      const code = action.payload;
      setBusinessUnit(code);
      return {
        ...state,
        selectedBuCode: code,
      };
    }

    case SET_SEMESTER: {
      return {
        ...state,
        selectedSemester: action.payload,
      };
    }

    case SET_SELECTED_SEMESTER: {
      const semester = action.payload;
      setSemester(semester);
      return {
        ...state,
        selectedSemester: semester,
      };
    }

    case REDIRECT: {
      return { ...state, redirectTo: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default users;
