import { storageCodes } from '../util/enums';

export const REPORT_REFRESH_TIME = 10000;
export const USERMGMT_API = process.env.REACT_APP_USERMGMT_API || 'https://usermgmt.api.edfibre.net';
export const CAPRI_CALLBACK_API = process.env.REACT_APP_API_URL || 'https://capri.api.edfibre.net';

// Local storage
export const KEY_BU_CODE = 'key_bu_code';
export const KEY_SEMESTER = 'key_semester';

export const PACE_API_URL = async () => {
  const buCode = localStorage.getItem(KEY_BU_CODE);
  const PACE_API = localStorage.getItem(storageCodes.PACE_API);
  if (PACE_API) {
    const paceObj = JSON.parse(PACE_API || '') || {};
    return paceObj[buCode || ''] || '';
  } else {
    return '';
  }
};

export const API_URL = async () => {
  const buCode = localStorage.getItem(KEY_BU_CODE);
  const capriObj = JSON.parse(localStorage.getItem(storageCodes.CAPRI_API) || '') || {};
  return capriObj[buCode || '']
};
