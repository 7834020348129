import { Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getAssignmentDetails } from '../../actions';
import Loader from '../../components/Loader';
import AssignmentEditComments from '../../components/Modals/commentsForAStudentAssignment';
import EditAssignmentStudentInfo from '../../components/Modals/editAssignmnetStudentInfo';
import Page from '../../components/PageV2';
import SearchBar from '../../components/searchBar';
import { RootState } from '../../reducer';
import { assignmentFilterValueMapping } from '../../util/constants';
import { AssignmentDetailsRouteParams } from '../../util/types';
import AssignmentFilters from './assignments/assignmentFilters';
import { FlexRowDiv } from '../../components/Common';

interface Data {
  studentId: string;
  displayName: string;
  marks: number;
  grade: string;
  academicMisconduct: boolean;
  submissionDate: Date;
  newSubmissionDueDate: Date;
  specialConsideration: boolean;
  newDueDateComments: string[];
  actions: string;
}

const red_color = '#ff9898';

const NotSubmittedSpan = styled.span`
  font-size: 12px;
  color: #b05c5c;
  border: 1px solid #bd4747;
  padding: 4px;
  border-radius: 4px;
  background-color: #d2a3a321;
`;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

// const headCells: HeadCell[] = [
//   { id: 'providerStudentID', numeric: true, disablePadding: true, label: 'Student ID' },
//   { id: 'firstName', numeric: true, disablePadding: true, label: 'First Name' },
//   { id: 'courseCode', numeric: true, disablePadding: false, label: 'Course Code' },
//   { id: 'courseName', numeric: true, disablePadding: false, label: 'Course Name' },
//   { id: 'index', numeric: true, disablePadding: false, label: 'Assessment Index (%)' },
//   { id: 'action', numeric: true, disablePadding: false, label: 'Action Followed (Total: 3)' },
// ];

const headCells: HeadCell[] = [
  { id: 'studentId', numeric: true, disablePadding: true, label: 'Student ID' },
  { id: 'displayName', numeric: true, disablePadding: true, label: 'Name' },
  { id: 'specialConsideration', numeric: true, disablePadding: true, label: 'Special Consideration' },
  { id: 'submissionDate', numeric: true, disablePadding: true, label: 'Submission Date' },
  { id: 'marks', numeric: true, disablePadding: false, label: 'Marks' },
  { id: 'academicMisconduct', numeric: true, disablePadding: false, label: 'Academic Conduct' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableRow: {
      borderBottom: 'none',
      fontSize: '15px',
      color: 'grey',
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    pagination: {
      position: 'relative',
      fontSize: '14px',
      marginLeft: '10px',
      marginBottom: '9px',
      fontWeight: 'bold',
      marginTop: '14px',
    },
  })
);
const initialValues = {
  specialConsideration: '',
  submission: '',
  academicMisconduct: '',
};

export default function AssignmentDetails() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('displayName');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const { id, unitId } = useParams<AssignmentDetailsRouteParams>();

  const assignmentInfo = useSelector((state: RootState) => state.assignmentDetails?.assignmentInfo);
  const students = useSelector((state: RootState) => state.assignmentDetails?.students);
  const disableEdit = useSelector((state: RootState) => state.assignmentDetails?.disableEdit);

  const [data, setData] = useState(students);

  const loader = useSelector((state: RootState) => state.assignmentDetails?.loader);
  const assignmentTableLoader = useSelector((state: RootState) => state.assignmentDetails?.assignmentTableLoader);

  useEffect(() => {
    setData(students);
  }, [students]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [values, setValues] = useState(initialValues);
  const [searchText, setSearchText] = useState('');

  const onSearch = (clearSearch = false) => {};

  const onSearchTextChanged = (newSearchText: string, updatedValues = values) => {
    setPage(0);
    let newData = students;
    setSearchText(newSearchText);
    if (newSearchText.trim().length > 0) {
      newData = students.filter(
        (x) =>
          x.displayName.toUpperCase().includes(newSearchText.toUpperCase()) ||
          x.studentId.toUpperCase().includes(newSearchText.toUpperCase())
      );
    } else {
      newData = students;
    }
    if (newData.length > 0 && updatedValues.academicMisconduct.length) {
      newData = newData.filter(
        (x) => x.academicMisconduct === assignmentFilterValueMapping[updatedValues.academicMisconduct]
      );
    }
    if (newData.length > 0 && updatedValues.specialConsideration.length) {
      newData = newData.filter(
        (x) => x.specialConsideration === assignmentFilterValueMapping[updatedValues.specialConsideration]
      );
    }
    if (newData.length > 0 && updatedValues.submission.length) {
      const filterValue = assignmentFilterValueMapping[updatedValues.submission];
      newData = newData.filter((x) =>
        filterValue !== true ? x.submissionDate === filterValue : x.submissionDate !== 'NS' && x.submissionDate !== null
      );
    }
    setData(newData);
  };
  const onFilterClear = () => {
    setValues({ ...initialValues });
    onSearchTextChanged(searchText, initialValues);
  };

  const onFilter = (val = values) => {
    onSearchTextChanged(searchText);
  };

  return (
    <>
      {!loader ? (
        <Paper className={classes.paper}>
          <>
            <TableContainer>
              <div style={{ margin: 30 }}>
                <SearchBar
                  filter
                  onSearch={onSearch}
                  searchPlaceHolder={'Search by Student ID or Name ...'}
                  onSearchTextChanged={onSearchTextChanged}
                  searchText={searchText}
                  onFilterClear={onFilterClear}
                  onFilter={onFilter}
                >
                  <AssignmentFilters values={values} setValues={setValues} />
                </SearchBar>
              </div>

              {!assignmentTableLoader ? (
                <Table>
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.studentId}>
                            <TableCell align="center">{row.studentId}</TableCell>
                            <TableCell align="center">{row.displayName.toUpperCase()}</TableCell>
                            <TableCell align="center">
                              {row.specialConsideration === false ? (
                                <span style={{ color: 'grey' }}>NO</span>
                              ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <FlexRowDiv style={{ width: 170 }}>
                                    <div>
                                      {' '}
                                      <span style={{ color: red_color }}>
                                        <b>{moment(row.newSubmissionDueDate).format('MMM D, YYYY H:mm')}</b>
                                      </span>
                                    </div>
                                    <div>
                                      {' '}
                                      <AssignmentEditComments comments={row.newDueDateComments} />
                                    </div>
                                  </FlexRowDiv>
                                </div>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.submissionDate === 'NS' ? (
                                <NotSubmittedSpan>NOT SUBMITTED</NotSubmittedSpan>
                              ) : row.submissionDate !== null ? (
                                moment(row.submissionDate).format('MMM D, YYYY H:mm')
                              ) : (
                                '-'
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {row.marks !== null ? (
                                parseFloat(row.marks) < parseFloat(assignmentInfo.taskWeightage) / 2 ? (
                                  <span style={{ color: red_color }}>
                                    <b>{row.marks}</b>
                                  </span>
                                ) : (
                                  <span>{row.marks}</span>
                                )
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.academicMisconduct === false ? (
                                <span style={{ color: 'grey' }}>NO</span>
                              ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <FlexRowDiv style={{ maxWidth: 220, minWidth: 170 }}>
                                    <span style={{ color: red_color }}>
                                      <b>YES</b>
                                    </span>
                                  </FlexRowDiv>
                                </div>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <EditAssignmentStudentInfo
                                assignmentId={parseInt(id, 10)}
                                newDueDate={row.newSubmissionDueDate}
                                newDueDateComments={row.newDueDateComments}
                                academicMisconduct={row.academicMisconduct === false ? 'NO' : 'YES'}
                                unitCode={unitId}
                                studentId={row.studentId}
                                resultAvailable={row.resultAvailable}
                                disabled={disableEdit}
                                originalMarks={row.marks}
                                specialConsideration={row.specialConsideration === true ? 'YES' : row.newDueDateComments && row.newDueDateComments.length > 0 ? 'NO' : '' }
                                originalSubmissionDate={row.submissionDate}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <Loader />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        </Paper>
      ) : (
        <Loader />
      )}
    </>
  );
}
