import { MenuItem } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { setSemesterLocal } from '../../../actions';
import { getSemesters } from '../../../actions/institution';
import { RootState } from '../../../reducer';
import { WhiteTextField } from '../../Common/StyledComponents';

const MenuContainer = styled.div`
  margin-left: 10px;
`;

const SemesterSelect: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedsemesterYear = useSelector((state: RootState) => state.users?.selectedSemester);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);
  const [semesterYear, setSemesterYear] = useState(selectedsemesterYear);
  const buSemesters = semestersWithBuCodes[selectedBuCode] || [];
  useEffect(() => {
    setSemesterYear(selectedsemesterYear);
  }, [selectedsemesterYear]);

  useEffect(() => {
    if(selectedBuCode) {
      dispatch(getSemesters(selectedBuCode));
  }
  }, [selectedBuCode, dispatch]);

  const onSemesterChange = useCallback(
    (e) => {
      setSemesterYear(e.target.value);
      dispatch(setSemesterLocal(e.target.value));
      history.push('/');
    },
    [dispatch, history]
  );
  return (
    <MenuContainer>
      <div>
        {buSemesters && buSemesters.length > 0 && (
          <WhiteTextField
            fullWidth
            label="Semester"
            margin="dense"
            name="semesterYear"
            select
            SelectProps={{
              value: semesterYear,
              onChange: onSemesterChange,
            }}
            value={semesterYear}
            variant="outlined"
          >
            {buSemesters.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </WhiteTextField>
        )}
      </div>
    </MenuContainer>
  );
};

export default SemesterSelect;
