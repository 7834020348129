import { GET_COMMENTS_FOR_ASSIGNMENT_DUE_DATE, GET_COMMENTS_LOADING_START, GET_COMMENTS_LOADING_FINISHED } from '../actionTypes';
import { ActionType } from './types';

const initialState = {
  comments: {},
  commentsLoader: false
};

const users = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_COMMENTS_FOR_ASSIGNMENT_DUE_DATE: {
      const { id, data } = action.payload
      const newComments = JSON.parse(JSON.stringify(state.comments))
      newComments[id] = data.data
      return {
        ...state,
        comments: newComments,
      };
    }
    case GET_COMMENTS_LOADING_START: {
      return {
        ...state,
        commentsLoader: true,
      };
    }
    case GET_COMMENTS_LOADING_FINISHED: {
      return {
        ...state,
        commentsLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default users;
