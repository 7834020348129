
import {ActionType} from './types'
import * as actionTypes from "../actions";

const initialState = {
    allCourses: null ,
    instituteGraphData: [] ,
    units: null ,
    courseInfo : {},
    courseGraphData: [],
    loader:false,
    searchText:"",
};

const courses = (state = initialState, action:ActionType) => {
    switch (action.type) {
        case actionTypes.ON_ALL_COURSES_RECEIVED: {
            return {
                ...state,
                allCourses: action.payload
            };
        }
        case actionTypes.ON_ALL_INSTITUTION_GRAPH_DATA_RECEIVED: {
            return {
                ...state,
                instituteGraphData: action.payload
            };
        }
        case actionTypes.GET_COURSE_UNITES_RECEIVED: {
            return {
                ...state,
                units: action.payload
            };
        }
        case actionTypes.GET_COURSE_INFO_RECEIVED: {
            return {
                ...state,
                courseInfo: action.payload[0]
            };
        }
        case actionTypes.GET_COURSE_GRAPH_DATA_RECEIVED: {
            return {
                ...state,
                courseGraphData: action.payload
            };
        }
        case actionTypes.ON_LOADING_START: {
            return {
              ...state,
              loader:false
            };
          }
        case actionTypes.ON_LOADING_FINISHED: {
            return {
              ...state,
              loader:true
         }
        }
        case actionTypes.ON_COURSES_RECEIVED: {
            return {
                ...state,
                courseInfo: action.payload,
            };
        }
        case actionTypes.ON_COURSE_SEARCH_TEXT_CHANGED: {
            return {
                ...state,
                searchText: action.payload,
               
            };
        }
        default: {
            return state;
        }
    }
};

export default courses;
