import { Button, Container, Divider } from '@material-ui/core';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
/* eslint-disable no-undef */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { theme } from '../../theme';

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${theme.spacing(2)}px;
  padding-left: ${theme.spacing(3)}px;
  padding-right: ${theme.spacing(3)}px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  width: 100%;
  font-weight: bolder;
  font-size: 1.6em;
  color: #444444;
`;

const BackButton = styled(Button)`
  margin-left: auto;
`;

const SubHeader = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
  color: gray;
`;

const StyledDivider = styled(Divider)`
  margin: ${theme.spacing(2)}px 0;
`;

interface PageProps {
  header: string;
  subHeader?: React.ReactNode | string;
  showBackButton?: boolean;
  alternateTitle?: string;
  noPadding?: boolean;
}

const Page: React.FC<PageProps> = ({
  header,
  subHeader,
  showBackButton,
  alternateTitle,
  noPadding,
  children,
  ...rest
}) => {
  const history = useHistory();
  const title = alternateTitle || header;

  let styles: any;
  if (noPadding) {
    styles = {
      padding: 0,
    };
  }

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TopContainer style={styles}>
        <HeaderContainer>
          <Header>{header}</Header>
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
        </HeaderContainer>
        {showBackButton && (
          <BackButton
            size="small"
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<KeyboardBackspaceRounded />}
          >
            Go Back
          </BackButton>
        )}
      </TopContainer>
      {(header || subHeader) && <StyledDivider variant="fullWidth" />}
      <Container maxWidth={false} style={styles}>
        <div>{children}</div>
      </Container>
    </div>
  );
};

export default Page;
