import { SecureRoute } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBuCodes, setSemesterFromLocal } from '../../actions';
import Callback from '../callback';
import Success from '../callback/success';
import AttendanceCode from '../events/attendance/physical/attendance-code';
import MarkAttendance from '../events/attendance/physical/mark-attendance';
import './app-routes.scss';
import DashboardRoutes from './dashboard-routes';

const AppRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBuCodes());
    dispatch(setSemesterFromLocal());
  }, [dispatch]);

  return (
    <>
      <SecureRoute exact path="/callback_success" component={Success} />
      <SecureRoute exact path="/callback/:reference" component={Callback} />
      <SecureRoute path="/mark_attendance/:id" component={MarkAttendance} />
      <SecureRoute path="/events/attendance_code/:id" component={AttendanceCode} />
      <SecureRoute path="/" component={DashboardRoutes} />
    </>
  );
};

export default AppRoutes;
