import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavItemProps } from './types';

const NavItem: React.FC<NavItemProps> = ({ title, href, children, onClose }) => {
  const history = useHistory();
  const navigate = () => {
    history.push(href);
    onClose()
  };

  return (
    <ListItem button key={title} onClick={navigate}>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default NavItem;
