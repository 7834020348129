import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getEvents, onEventsSearchChanged } from '../../actions';
import { StyledDivider } from '../../components/Common';
import SearchBar from '../../components/searchBar';
import { RootState } from '../../reducer';
import EventFilters from './EventFilters';
import Result from '../search/events/result';

const initialValues = {
  unitCode: '',
  startDate: '',
  endDate: '',
  teamType: [],
  sort: { sortStartDate: true, sortEngagementIndex: false, orderByDate: true },
};

export default function EventsDashboard() {
  const dispatch = useDispatch();
  const { unitId } = useParams<{ unitId: string }>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [values, setValues] = useState(initialValues);

  const unitEventList = useSelector((state: RootState) => state.events.eventList);
  const searchText = useSelector((state: RootState) => state.events?.searchText);
  const loader = useSelector((state: RootState) => state.users.loader);

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      dispatch(getEvents(unitId, searchText, values, page + 1, rowsPerPage));
    }
  }, [unitId, dispatch, searchText, values, page, rowsPerPage]);

  const onSearch = (clearSearch = false) => {
    dispatch(getEvents(unitId, clearSearch ? "" : searchText, values, page + 1, rowsPerPage));
  };

  const onSearchTextChanged = (newSearchText: string) => {
    setPage(0);
    dispatch(onEventsSearchChanged(newSearchText));
  };

  const onFilterClear = () => {
    setValues({ ...initialValues });
    dispatch(getEvents(unitId, searchText, { ...initialValues }, 1, rowsPerPage));
  };

  const onFilter = (val = values) => {
    dispatch(getEvents(unitId, searchText, { ...val }, page + 1, rowsPerPage));
  };

  const onFilterSort = (sortObj: any) => {
    dispatch(getEvents(unitId, searchText, { ...sortObj }, page + 1, rowsPerPage));
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getEvents(unitId, searchText, values, newPage + 1, rowsPerPage));
  };

  const onRowPerPageChanged = (newRowPerPage: number) => {
    setPage(0);
    setRowsPerPage(newRowPerPage);
    dispatch(getEvents(unitId, searchText, values, 1, newRowPerPage));
  };

  return (
    <div>
      <SearchBar
        filter
        onSearch={onSearch}
        searchPlaceHolder={'Search by Name..'}
        onSearchTextChanged={onSearchTextChanged}
        searchText={searchText}
        onFilterClear={onFilterClear}
        onFilter={onFilter}
      >
        <EventFilters values={values} setValues={setValues} />
      </SearchBar>
      <StyledDivider />
      <Result
        events={unitEventList?.rows || []}
        count={unitEventList?.count || 0}
        page={page}
        setPage={onPageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onRowPerPageChanged}
        loader={loader}
        onFilter={onFilterSort}
        setValues={setValues}
        values={values}
      />
    </div>
  );
}
