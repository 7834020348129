import React, { useEffect, useMemo } from 'react';
import { Route, useHistory, useParams, Switch, useRouteMatch, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteParams } from '../../util/types';
import StudentDetails from '../../components/studentDetails';
import {
  getStudentInfoById,
  getStudentProfileImageById,
  getStudentEngagementIndex,
  getStudentAssessmentIndex,
  getStudentLegacyIndex,
  getStudentMaterialIndex,
} from '../../actions';
import { RootState } from '../../reducer';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Divider, colors } from '@material-ui/core';
import { tabs, personTypes, colorCodes } from '../../util/constants';
import {
  assessmentIndicesData,
  attendanceSplit,
  indicesData,
  legacyIndicesData,
  materialIndicesData,
} from '../../util/common';
import AcadProgress from './acadProgress';
import Issues from './issues';
import Page from '../../components/PageV2';
import { LineGraph } from '../../components/LineGraph';
import { indices } from '../../util/enums';
import Loader from '../../components/Loader';
import Logs from './logs/logs';
import { CenterText } from '../../components/Common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      flexGrow: 1,
      margin: theme.spacing(2, 0),
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
    tab: {
      paddingLeft: '10px',
    },
  })
);

const StudentReport: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const studentDetails = useSelector((state: RootState) => state.student?.studentDetails);
  const studentProfileImage = useSelector((state: RootState) => state.student?.studentProfileImage);
  const engagementIndexData = useSelector((state: RootState) => state.student?.engagementIndexData);
  const assessmentIndexData = useSelector((state: RootState) => state.student?.assessmentIndexData);
  const materialIndexData = useSelector((state: RootState) => state.student?.materialIndexData);
  const legacyIndexData = useSelector((state: RootState) => state.student?.legacyIndexData);
  const loading = useSelector((state: RootState) => state.common?.loading);
  const selectedBuCode = useSelector((state: RootState) => state.users?.selectedBuCode);
  const selectedSemester = useSelector((state: RootState) => state.users?.selectedSemester);
  const semestersWithBuCodes = useSelector((state: RootState) => state.users?.semestersWithBuCodes);

  const semestersData = (semestersWithBuCodes[selectedBuCode] || []).find((x) => x.value === selectedSemester) || {};
  const {
    startDate = new Date(),
    endDate = new Date(),
    preStartDate = new Date(),
    teachingEndDate = new Date(),
  } = semestersData;

  const { id, tab: currentTab = tabs[0].value } = useParams<RouteParams>();
  const isStudent = (window.location.href || '').split('/').pop() === personTypes.STUDENT;
  useEffect(() => {
    dispatch(getStudentInfoById(id));
    dispatch(getStudentEngagementIndex([id]));
    dispatch(getStudentAssessmentIndex([id]));
    dispatch(getStudentMaterialIndex([id]));
    dispatch(getStudentLegacyIndex([id]));
    dispatch(getStudentProfileImageById(id));
  }, [id, dispatch]);
  const {
    providerStudentID = '',
    firstName = '',
    familyName = '',
    coeType = '',
    courseName = '',
    locationName = '',
    studentCategory = '',
    studentStatus = '',
    studentType = '',
    personalEmail = '',
    mobileNumber = '',
  } = studentDetails || {};
  const profileImage = studentProfileImage[id] || {};
  const { profileSrc = '' } = profileImage;
  const { path, url } = useRouteMatch();
  const location = useLocation();

  const handleTabsChange = ({}, tabUrl: string) => {
    history.push(tabUrl);
  };

  const finalTabs = isStudent ? tabs.slice(2, 6) : tabs;

  const engagementData = useMemo(() => {
    const { mandatoryAttendance, nonMandatoryAttendance, employabilityAttendance } =
      attendanceSplit(engagementIndexData);
    const mandatoryEngagementIndex =
      mandatoryAttendance.length > 0
        ? indicesData(mandatoryAttendance, startDate, teachingEndDate, preStartDate)
        : mandatoryAttendance;
    const nonMandatoryEngagementIndex =
      nonMandatoryAttendance.length > 0
        ? indicesData(nonMandatoryAttendance, startDate, endDate, preStartDate)
        : nonMandatoryAttendance;
    const employabilityEngagementIndex =
      employabilityAttendance.length > 0
        ? indicesData(employabilityAttendance, startDate, endDate, preStartDate)
        : employabilityAttendance;
    return {
      mandatoryEngagementIndex:
        mandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.MANDATORY_ATTENDANCE,
                color: colorCodes.mandatory,
                data: mandatoryEngagementIndex,
              },
            ]
          : [],
      nonMandatoryEngagementIndex:
        nonMandatoryEngagementIndex.length > 0
          ? [
              {
                id: indices.NON_MANDATORY_ATTENDANCE,
                color: colorCodes.nonMandatory,
                data: nonMandatoryEngagementIndex,
              },
            ]
          : [],
      employabilityEngagementIndex:
        employabilityEngagementIndex.length > 0
          ? [
              {
                id: indices.EMPLOYABILITY,
                color: colorCodes.employability,
                data: employabilityEngagementIndex,
              },
            ]
          : [],
    };
  }, [engagementIndexData, startDate, endDate, preStartDate]);

  const assessmentData = useMemo(() => {
    const assessmentIndex =
      assessmentIndexData.length > 0
        ? assessmentIndicesData(assessmentIndexData, startDate, endDate)
        : assessmentIndexData;
    return assessmentIndex.length > 0
      ? [
          {
            id: indices.ASSESSMENT,
            color: colorCodes.assessment,
            data: assessmentIndex,
          },
        ]
      : [];
  }, [assessmentIndexData, startDate, endDate]);

  const materialData = useMemo(() => {
    const materialIndex =
      materialIndexData.length > 0 ? materialIndicesData(materialIndexData, startDate, endDate) : materialIndexData;
    return materialIndex.length > 0
      ? [
          {
            id: indices.MATERIAL_INDEX,
            color: colorCodes.material,
            data: materialIndex,
          },
        ]
      : [];
  }, [materialIndexData, startDate, endDate]);

  const legacyData = useMemo(() => {
    const legacyIndex =
      legacyIndexData.length > 0 ? legacyIndicesData(legacyIndexData, startDate, endDate) : legacyIndexData;
    return legacyIndex.length > 0
      ? [
          {
            id: indices.LEGACY_INDEX,
            color: colorCodes.legacy,
            data: legacyIndex,
          },
        ]
      : [];
  }, [legacyIndexData, startDate, endDate]);
  return (
    <Page header="Student Progression" subHeader={'Student ID: ' + providerStudentID} showBackButton>
      <StudentDetails
        profileSrc={profileSrc}
        providerId={providerStudentID}
        name={`${firstName} ${familyName}`}
        coeType={coeType}
        program={courseName}
        location={locationName}
        studentCategory={studentCategory}
        studentStatus={studentStatus}
        studentType={studentType}
        email={personalEmail}
        mobileNumber={mobileNumber}
      />
      <div className={classes.tabs}>
        <Tabs onChange={handleTabsChange} scrollButtons="auto" value={location.pathname} textColor="primary">
          {finalTabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={url + '/' + tab.value} />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
      </div>
      <Switch>
        <Route path={path + '/acad_progress'}>
          <AcadProgress id={id} isStudent={isStudent} />
        </Route>
        <Route path={path + '/issues_resolution'}>
          <Issues id={id} />
        </Route>
        <Route path={path + '/indices'}>
          {!loading ? (
            (engagementData.mandatoryEngagementIndex || []).length +
              (engagementData.mandatoryEngagementIndex || []).length +
              (engagementData.mandatoryEngagementIndex || []).length +
              (engagementData.mandatoryEngagementIndex || []).length +
              (materialData || []).length +
              (legacyData || []).length >
            0 ? (
              <LineGraph
                min={0}
                max={100}
                data={[
                  ...(engagementData.mandatoryEngagementIndex || []),
                  ...(engagementData.nonMandatoryEngagementIndex || []),
                  ...(engagementData.employabilityEngagementIndex || []),
                  ...(assessmentData || []),
                  ...(legacyData || []),
                  ...(materialData || []),
                ]}
                dataText="%"
                xText="Week"
                yText="Index %"
              />
            ) : (
              <CenterText>
                <span> There are no indicies to display</span>
              </CenterText>
            )
          ) : (
            <Loader />
          )}
        </Route>
        <Route path={path + '/logs'}>
          <Logs id={id} isStudent={isStudent} />
        </Route>
      </Switch>
    </Page>
  );
};

export default StudentReport;
