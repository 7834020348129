import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, Edit, OpenInNewOutlined, Publish } from '@material-ui/icons';
import React, { useState } from 'react';
import moment from 'moment';

export type AssignmentActionType = 'view' | 'edit' | 'upload_submission' | 'upload_result';
interface Props {
  onClick: (type: AssignmentActionType, assignment: any) => void;
  assignment: any;
}

const AssignmentActions: React.FC<Props> = ({ assignment, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { taskWeightage = '', dueDate = '' } = assignment;

  let parsedDueDate = moment(dueDate, 'DD/MM/YYYY');
  const isDue = moment().isSameOrBefore(parsedDueDate);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClicked = (type: AssignmentActionType) => {
    handleClose();
    onClick(type, assignment);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        size="small"
      >
        Actions
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => onMenuItemClicked('view')}>
          <ListItemIcon>
            <OpenInNewOutlined />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onMenuItemClicked('edit')}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onMenuItemClicked('upload_submission')} disabled={isDue}>
          <ListItemIcon>
            <Publish />
          </ListItemIcon>
          <ListItemText>Assignment Submission</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => onMenuItemClicked('upload_result')}
          disabled={isDue || parseFloat(taskWeightage || 0) === 0}
        >
          <ListItemIcon>
            <Publish />
          </ListItemIcon>
          <ListItemText>Assignment Result</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AssignmentActions;
