export const teamNames = {
  ALL: '',
  FEDUNI: 'FEDUNI',
  USC: 'USC',
  'STUDENT ENRICHMENT': 'STUDENT ENRICHMENT',
};

export const storageCodes = {
  USER_ROLE: 'USER_ROLE',
  BU_CODES: 'BU_CODES',
  BU_CODE_SELECTED: 'BU_CODE_SELECTED',
  SEMESTER_SELECTED: 'SEMESTER_SELECTED',
  PACE_API: 'PACE_API',
  CAPRI_API: 'CAPRI_API',
};

export const eventStatus = {
  CAPTURED: 'CAPTURED',
  UNCAPTURED: 'UNCAPTURED',
};

export const roles = {
  STUDENT: 'STUDENT',
  LECTURER: 'LECTURER',
  ADMIN: 'ADMIN',
  NO_ACCESS: 'NO_ACCESS',
};

export const unitReportTypes = {
  STUDENTS_AT_RISK: 'Students_at_risk',
  ATTENDANCE_REPORT: 'Attendance_Report',
};

export const filters = {
  startDate: 'startDate',
  endDate: 'endDate',
  engagementIndex: 'engagementIndex',
};

export const timeTableFilters = {
  unitCode: 'unitCode',
  day: 'day',
  startTime: 'startTime',
  eventStatus: 'eventStatus',
};

export const onTrack = {
  YES: 'YES',
  NO: 'NO',
};

export const moodleActivity = {
  SAT: 'Sat',
  UNSAT: 'Unsat',
};

export const ticketStatus = {
  CLOSED: 'closed',
  RESOLVED: 'resolved',
  UN_RESOLVED: 'unresolved',
  Total: 'total',
};

export const ticketColors = {
  GREEN: 'green',
  RED: 'red',
  TOTAL: 'total',
  DEFAULT: 'default',
};

export const reportTypes = {
  STUDENT_LEVEL_INTERVENTION_REPORT: 'Student_Level_Intervention_Report',
  COURSE_LEVEL_FEEDBACK_REPORT: 'Course_Level_Feedback_Report',
  INTERVENTION_REPORTS: 'Intervention_Reports',
  FEEDBACK_REPORTS: 'Feedback_Reports',
  ATTENDANCE_REPORTS: 'Attendance_Reports',
  PROGRESSION_REPORTS: 'Progression_Reports',
  INTERIM_REPORTS: 'Interim_Reports',
  ARCHIVES_REPORTS: 'Archive_Reports',
  STUDENT_INTERIM: 'STUDENT_INTERIM',
  ARCHIVES: 'ARCHIVES',
  STUDENT_AT_RISK: 'student_at_risk',
  STUDENT_PROGRESSION: 'student_progression',
  ATTENDANCE_REPORT_WEEKLY: 'ATTENDANCE_REPORT_WEEKLY',
  NON_SUBMISSION_REPORTS: 'non_submission_reports',
  CALLBACKS: 'CALLBACKS',
  EVENTS: 'EVENTS',
  TIMETABLE: 'TIMETABLE',
};

export const indices = {
  ATTENDANCE: 'Attendance',
  ASSESSMENT: 'Assessment',
  LEGACY_INDEX: 'Legacy',
  EMPLOYABILITY: 'Employability',
  ACADEMIC_PROGRESSION: 'Academic_Progression',
  MANDATORY_ATTENDANCE: 'Attendance (Academic Sessions)',
  NON_MANDATORY_ATTENDANCE: 'Attendance (Collaborative Sessions)',
  ENGLISH: 'English',
  MATERIAL_INDEX: 'Engagement',
};

export const employability = {
  EMP: 'EMP',
};

export const mandatory = {
  LEC: 'LEC',
  TUT: 'TUT',
  WKS: 'WKS',
  SUP: 'SUP',
  LAB: 'LAB',
};

export const nonMandatory = {
  CAM: 'CAM',
  USK: 'USK',
  GRP: 'GRP',
};

export const uploadTypes = {
  ASSIGNMENT_DUE_DATE: 'ASSIGNMENT_DUE_DATE',
};

export const classTypes = {
  LEC: 'Lecture',
  TUT: 'Tutorial',
  CNS: 'Consultations',
  CAM: 'CAM (Academic Mentor)',
};

export const attendanceTypes = {
  CAM: 'CAM',
  LEC: 'LEC',
  TUT: 'TUT',
  WKS: 'WKS',
  SUP: 'SUP',
  LAB: 'LAB',
};

export const feedbackTypes = {
  WELL_PREPARED: 'Well-Prepared',
  INTERESTING: 'Interesting',
  INFORMATIVE: 'Informative',
  SIMULATED_DISCUSSION: 'Stimulated Discussion',
  UNDERSTANDING: 'Understanding',
};

export const slots = {
  morning: '10AM - 12PM',
  afternoon1: '12PM - 2PM',
  afternoon2: '2PM - 4PM',
  evening: '4PM - 6PM',
};

export const timeZones = {
  IST: 'IST - India',
  AEDT: 'AEDT - Australia',
};

export const PlagiarismStatus = {
  YES: 'YES',
  NO: 'NO',
};

export enum STATUS {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  DRAFT = 'DRAFT',
}

export const buCodes = {
  USC: 'USC',
};

export enum ASSIGNMENT_LEVEL_INTERVENTIONS {
  NON_SUBMISSION = 'NON_SUBMISSION',
  GRADES_PASS = 'GRADES_PASS',
  GRADES_FAIL = 'GRADES_FAIL',
}

export enum ATTENDANCE_RESULTS {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
}

export enum INTERVENTION_TRIGGERS {
  AUTO = 'AUTO',
  INSTANT = 'INSTANT',
  SCHEDULED = 'SCHEDULED',
}
