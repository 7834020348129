
import { ActionType } from './types'
import * as actionTypes from "../actions";
import { AcadProgressType } from "../util/types"

const initialState = {
  acadProgress: {}
};

const acadProgress = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_ACAD_PROGRESS_BY_STUDENT_ID: {
      const { data, id } = action.payload
      const newAcadProgress = JSON.parse(JSON.stringify(state.acadProgress))
      newAcadProgress[id] = data;
      return {
        ...state,
        acadProgress: newAcadProgress
      };
    }

    case actionTypes.CREATE_ACAD_PROGRESS:
    case actionTypes.EDIT_ACAD_PROGRESS: {
      const { studentId = "", unitCode = "" } = action.payload
      const acadProgress = JSON.parse(JSON.stringify(state.acadProgress))
      if (unitCode) {
        const studentAcadProgress = acadProgress[studentId] || []
        const newAcadProgress = studentAcadProgress.map((x: AcadProgressType) => x.unitCode === unitCode ? { ...x, ...action.payload } : x)
        acadProgress[studentId] = newAcadProgress
      }
      return {
        ...state,
        acadProgress: acadProgress
      };
    }

    default: {
      return state;
    }
  }
};

export default acadProgress;
