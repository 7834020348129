import { Button, IconButton, InputLabel, Switch, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import 'date-fns';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addImprovementArea, updateImprovementArea } from '../../actions/improvementArea';
import CustomModal from '../CustomModal';

type AddImprovementAreaProps = {
  buttonType: 'ICON' | 'BUTTON';
  type: 'ADD' | 'UPDATE';
  data?: any;
  id?: number;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  icon?: React.ReactNode;
};

const AddImprovementArea: React.FC<AddImprovementAreaProps> = ({
  buttonType,
  data,
  type,
  id,
  color = 'primary',
  variant = 'contained',
  icon,
}) => {
  const dispatch = useDispatch();

  const [open, setModalOpen] = useState(false);
  const [typeOfIA, setTypeOfIA] = useState(type !== 'ADD' ? data?.type || '' : '');
  const [isUnitOriented, setIsUnitOriented] = useState(type !== 'ADD' ? data?.isUnitOriented || false : false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAddClick = () => {
    if (type === 'ADD') {
      dispatch(addImprovementArea({ type: typeOfIA ,isUnitOriented}));
    } else {
      dispatch(updateImprovementArea({ type: typeOfIA ,isUnitOriented}, id));
    }
    handleModalClose();
  };

  const renderModal = () => {
    return (
      <CustomModal
        open={open}
        onClose={handleModalClose}
        buttonText={type === 'ADD' ? 'Save' : 'Update'}
        headerText={(type === 'ADD' ? 'Add' : 'Update') + ' Improvement Area'}
        onSuccess={onAddClick}
      >
        <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          Name:
        </InputLabel>
        <TextField
          style={{ width: 400, margin: 12 }}
          variant="outlined"
          id="standard-basic"
          value={typeOfIA}
          placeholder="Enter name of improvement area"
          onChange={(e) => setTypeOfIA((e.target.value).toUpperCase())}
        />
         <InputLabel style={{ margin: 12, fontWeight: 'bold' }} id="demo-mutiple-chip-label">
          is Unit Oriented:  <Switch
        checked={isUnitOriented}
        onChange={()=>{setIsUnitOriented(!isUnitOriented)}}
        color="primary"
        name="isUnitOriented"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
        </InputLabel>
       
      </CustomModal>
    );
  };

  return (
    <>
      {buttonType === 'BUTTON' ? (
        <Button startIcon={<Add />} onClick={() => setModalOpen(true)} color={color} variant={variant} size="small">
          {type + ' Improvement Area'}
        </Button>
      ) : (
        <IconButton edge="end" aria-label={type} onClick={() => setModalOpen(true)}>
          {icon}
        </IconButton>
      )}
      {renderModal()}
    </>
  );
};

export default AddImprovementArea;
