export const GET_ASSIGNMENT_INFO = 'GET_ASSIGNMENT_INFO';
export const GET_ASSIGNMENT_DETAIL_RESULT = 'GET_ASSIGNMENT_DETAIL_RESULT';
export const ON_ASSIGNMENT_DETAIL_START = 'ON_ASSIGNMENT_DETAIL_START';
export const ON_ASSIGNMENT_DETAIL_FINISHED = 'ON_ASSIGNMENT_DETAIL_FINISHED';
export const ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_START =
  'ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_START';
export const ON_INTERVENTIONS_SENT = 'ON_INTERVENTIONS_SENT';
export const ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS = 'ON_RECEIVING_PREVIOUS_INTERVENTION_TRIGGERS';
export const ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_FINISHED =
  'ON_SENDING_INTERVENTION_NON_SUBMISSION_LOADING_FINISHED';
export const ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_START = 'ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_START';
export const ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_FINISHED =
  'ON_SENDING_INTERVENTION_GRADES_FAIL_LOADING_FINISHED';
export const GET_ASSIGNMENT_LOGS_RESULT = 'GET_ASSIGNMENT_LOGS_RESULT';
export const ON_ASSIGNMENT_LOGS_START = 'ON_ASSIGNMENT_LOGS_START';
export const ON_ASSIGNMENT_LOGS_FINISHED = 'ON_ASSIGNMENT_LOGS_FINISHED';
export const ON_ASSIGNMENT_TABLE_DETAIL_START = 'ON_ASSIGNMENT_TABLE_DETAIL_START';
export const ON_ASSIGNMENT_TABLE_DETAIL_FINISHED = 'ON_ASSIGNMENT_TABLE_DETAIL_FINISHED';
export const GET_ASSIGNMENT_TEMPLATE_PREVIEW = 'GET_ASSIGNMENT_TEMPLATE_PREVIEW';
export const ON_ASSIGNMENT_TEMPLATE_PREVIEW_START = 'ON_ASSIGNMENT_TEMPLATE_PREVIEW_START';
export const ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED = 'ON_ASSIGNMENT_TEMPLATE_PREVIEW_FINISHED';
