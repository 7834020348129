import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import OnlineAttendanceReport from './online-attendance-report';
import { getEvent } from '../../../../actions';
import { RootState } from '../../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import PhysicalAttendanceReport from './physical-attendance-report';
import Loader from '../../../../components/Loader';
import AttendedCount from './attended-count';
import styled from 'styled-components';
import Page from '../../../../components/PageV2';
import { Event } from '../../../../models/event';
import { eventTypes } from '../../../../util/constants';
import BlendedAttendanceReport from './blended-attendance-reports';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AttendanceReport = () => {
  const dispatch = useDispatch();
  const eventDetails: Event = useSelector((state: RootState) => state.events?.eventDetails);
  const { attendanceId } = useParams<{ attendanceId: string }>();

  useEffect(() => {
    if (attendanceId) {
      dispatch(getEvent(attendanceId));
    }
  }, [dispatch, attendanceId]);

  if (eventDetails) {
    return (
      <Page
        header={`${eventDetails.meetingSubject || eventDetails.teamName} [${eventDetails.methodOfDelivery}]`}
        showBackButton
        subHeader={
          <div>
            Organiser:{' '}
            <b>
              {eventDetails.organiser.displayName}
              <a style={{ marginLeft: 8 }} href={'mailto:' + eventDetails.organiser.email}>
                ({eventDetails.organiser.email})
              </a>
            </b>
          </div>
        }
      >
        <HeaderContainer>
          <AttendedCount event={eventDetails} />
        </HeaderContainer>
        {eventDetails.methodOfDelivery === eventTypes.ONLINE && <OnlineAttendanceReport event={eventDetails} />}
        {eventDetails.methodOfDelivery === eventTypes.PHYSICAL && <PhysicalAttendanceReport event={eventDetails} />}
        {eventDetails.methodOfDelivery === eventTypes.BLENDED && <BlendedAttendanceReport event={eventDetails} />}
      </Page>
    );
  } else {
    return <Loader />;
  }
};

export default AttendanceReport;
