import React, { useState } from 'react';
import { Card, Button, Grid, CardContent, Divider, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import CustomModal from '../CustomModal';
import { getComments, getCommentsForCallback } from '../../actions';
import { Comment } from '../../util/types';
import Loader from '../Loader';

type CommentsProps = {
  comments: Comment[];
  id: number;
  commentsLoader: boolean;
  changeAction?: boolean;
};

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginLeft: '8px',
  },
}));

const Comments: React.FC<CommentsProps> = ({ id, comments = [], commentsLoader = false, changeAction = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onAddClick = () => {
    dispatch(changeAction ? getCommentsForCallback(id) : getComments(id));
    setModalOpen(true);
  };

  const renderModal = () => {
    return (
      <CustomModal open={open} onClose={handleModalClose} headerText={`Comments`}>
        <Card>
          <CardContent>
            {!commentsLoader ? (
              <Grid>
                {comments && comments.length > 0 ? (
                  comments.map((x, index) => {
                    const { text = '', id, name = '', createdAt = '', statusChange = '' } = x;
                    return (
                      <div key={id}>
                        <Grid container spacing={2}>
                          <Grid item>{`${text} -`}</Grid>
                          <Grid item>{<strong>{name}</strong>}</Grid>
                          <Grid xs={12} item>{`Changed Date - ${new Date(createdAt).toLocaleString()}`}</Grid>
                          {statusChange && (
                            <Grid xs={12} item>
                              {<strong>{statusChange}</strong>}
                            </Grid>
                          )}
                        </Grid>

                        {index !== comments.length - 1 && <Divider style={{ margin: '20px 0px' }} />}
                      </div>
                    );
                  })
                ) : (
                  <div>No Comments have been added yet</div>
                )}
              </Grid>
            ) : (
              <Loader />
            )}
          </CardContent>
        </Card>
      </CustomModal>
    );
  };

  return (
    <>
      <Button onClick={onAddClick} color="secondary" size="small" variant="outlined">
        <Info /> <div className={classes.textMargin}>View</div>
      </Button>
      {renderModal()}
    </>
  );
};

export default Comments;
