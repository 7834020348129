import { Divider, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Page from '../../components/PageV2';
import Event from '../search/events/index';
import Students from '../search/student/index';
import Units from '../search/units/index';
import Courses from '../search/courses/index';
import { useDispatch } from 'react-redux';
import {
  onCourseSearchTextChanged,
  onEventsSearchChanged,
  onStudentSearchChanged,
  onUnitSearchTextChanged,
} from '../../actions';

export default function Search() {
  const rootTitle = 'Search';

  const tabs = [
    { value: 'courses', label: 'Courses' },
    { value: 'units', label: 'Units' },
    { value: 'students', label: 'Students' },
    { value: 'events', label: 'Events' },
  ];
  const dispatch = useDispatch();
  const { tab: currentTab } = useParams<{ tab: string }>();
  const history = useHistory();

  const handleTabsChange = (event: any, value: string) => {
    dispatch(onUnitSearchTextChanged(''));
    dispatch(onCourseSearchTextChanged(''));
    dispatch(onStudentSearchChanged(''));
    dispatch(onEventsSearchChanged(''));
    history.push(value);
  };

  const subTitle = tabs.find((x) => x.value === currentTab)?.label || '';
  const alternateTitle = (rootTitle + ' : ' + subTitle).replace(':', '|');

  return (
    <Page header={rootTitle} subHeader={subTitle} alternateTitle={alternateTitle}>
      <Tabs onChange={handleTabsChange} scrollButtons="auto" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>

      <Divider />
      <div>
        {currentTab === 'units' && <Units />}
        {currentTab === 'courses' && <Courses />}
        {currentTab === 'students' && <Students />}
        {currentTab === 'events' && <Event />}
      </div>
    </Page>
  );
}
