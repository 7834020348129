import { Divider } from '@material-ui/core';
import { PointTooltip, PointTooltipProps } from '@nivo/line';
import React from 'react';
import styled from 'styled-components';
import { weighting } from '../../util/constants';
import { indices } from '../../util/enums';

const TooltipContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
`;

const TooltipHeading = styled.span`
  font-weight: bold;
`;

const TooltipPoint = styled.span`
  font-size: 0.9em;
`;

const IndicesGraphTooltip: PointTooltip = (prop: PointTooltipProps) => {
  const dotColor = {
    backgroundColor: prop.point.serieColor,
  };
  const color = {
    color: prop.point.serieColor,
  };
  const border = {
    border: `1px solid ${prop.point.serieColor}`,
  };

  if (prop.point.serieId === indices.ACADEMIC_PROGRESSION) {
    const weights = weighting.find((x) => parseFloat(x.date) === prop.point.data.x) || {};
    return (
      <div className="toolTip1" style={border}>
        <span className="toolTip1Heading" style={color}>
          {prop.point.serieId.toString().split('_').join(' ')}
        </span>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="dot" style={dotColor}></span>&nbsp;&nbsp;
            <span className="toolTip1Points">
              Index: <b>{prop.point.data.y}</b>, Week: <b>{prop.point.data.x}</b>
            </span>
          </div>
          <span className="tooltip-divider"></span>
          <span className="toolTip1Points">
            {`${indices.ATTENDANCE.split('_').join(' ')}`}: <b>{weights[indices.ATTENDANCE]}%</b>
          </span>
          <span className="toolTip1Points">
            {`${indices.ASSESSMENT.split('_').join(' ')}`}: <b>{weights[indices.ASSESSMENT]}%</b>
          </span>
          <span className="toolTip1Points">
            {`${indices.LEGACY_INDEX.split('_').join(' ')}`}: <b>{weights[indices.LEGACY_INDEX]}%</b>
          </span>
          <span className="toolTip1Points">
            {`${indices.EMPLOYABILITY.split('_').join(' ')}`}: <b>{weights[indices.EMPLOYABILITY]}%</b>
          </span>
        </div>
      </div>
    );
  }

  return (
    <TooltipContainer style={border}>
      <TooltipHeading>{prop.point.serieId.toString().split('_').join(' ')}</TooltipHeading>
      <Divider />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="dot" style={dotColor}></span>&nbsp;&nbsp;
        <TooltipPoint>
          Index: <b>{prop.point.data.y}</b>, Week: <b>{prop.point.data.x}</b>
        </TooltipPoint>
      </div>
    </TooltipContainer>
  );
};

// export default LineGraphTooltip;
export default IndicesGraphTooltip;
