
import { ActionType, RolesState } from './types'
import * as actionTypes from "../actions";

const initialState: RolesState = {
    allRoles: [],
    roles: '',
    loader: false
};

const roles = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USER_ROLES: {
            return {
                ...state,
                allRoles: action.payload
            };
        }
        case actionTypes.SET_NEW_ROLE: {
            const data = action.payload;
            const updatedRoles = state.allRoles.map(x => x.email === data.email ? data : x);
            return {
                ...state,
                allRoles: updatedRoles
            };
        }
        case actionTypes.GET_MY_ROLES: {
            return {
                ...state,
                roles: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default roles;