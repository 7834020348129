import React, { HtmlHTMLAttributes, useState } from 'react';
import { IndicesPoint } from '../../models/graphData';
import { Avatar, Chip, Grid, Paper, Switch } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { calculateAverageIndex } from '../../util/common';
import { Check } from '@material-ui/icons';

interface detailsProps {
  id: string;
  data: IndicesPoint[];
  color: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggle: { [name: string]: boolean };
  dataText: string;
}

const useStyles = (color: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
        border: `1px solid ${color}`,
        borderRadius: '5px',
        alignItems: 'center',
        // justifyContent: 'space-between',
        minWidth: 400,
      },
      label: {
        color: color,
        flexGrow: 1,
        fontWeight: 'bolder',
        fontSize: '14px',
        marginLeft: theme.spacing(1),
        transition: 'color 0.2s ease-out',
      },
      chip: {
        border: `solid 2px ${color}`,
        marginLeft: 'auto',
        backgroundColor: color,
        fontWeight: 'bolder',
        color: 'white',
        minWidth: 70,
      },
    })
  );

const Details: React.FC<detailsProps> = ({ id, data, color, handleChange, toggle, dataText }) => {
  const [checked, setChecked] = useState(toggle[id] || false);
  const percent = calculateAverageIndex(data);
  const colorIndicator = checked ? color : '#afafaf';

  const classes = useStyles(colorIndicator)();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked);
    handleChange(event);
  };

  return (
    <Paper className={classes.container} elevation={2}>
      {/* <span className="dot" style={{ backgroundColor: color }}></span> */}
      <Switch
        style={{ color: colorIndicator }}
        checked={toggle[id] || false}
        onChange={onChange}
        size="small"
        name={id}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <span className={classes.label}>{id.split('_').join(' ')}</span>
      <Chip className={classes.chip} size="small" label={`${percent}${dataText}`} />
    </Paper>
  );
};

export default Details;
