import React, { FC } from 'react';
import Page from '../../components/PageV2';
import TickAnimation from '../../components/animated/tick-animation';
import { Container } from './styles';

const Success: FC = () => {
  return (
    <Page header="Request Callback" subHeader="Request Successfull">
      <Container>
        <TickAnimation />
        Callback scheduled successfully, you may close this window now.
      </Container>
    </Page>
  );
};

export default Success;
