import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducer'
import { ActionType } from '../reducer/types';
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { post, get, put } from "./common";
import { STUDENT_URL } from "../util/constants"

export const GET_ACAD_PROGRESS_BY_STUDENT_ID = "GET_ACAD_PROGRESS_BY_STUDENT_ID";
export const EDIT_ACAD_PROGRESS = "EDIT_ACAD_PROGRESS";
export const CREATE_ACAD_PROGRESS = "CREATE_ACAD_PROGRESS";

export const getAcadProgressByStudentId = (studentId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/acad-progress`;
    dispatch({ type: ON_LOADING_START });
    const result = await get(url);
    dispatch({ type: GET_ACAD_PROGRESS_BY_STUDENT_ID, payload: { data: result.data, id: studentId } });
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({
      type: GET_ACAD_PROGRESS_BY_STUDENT_ID,
      payload: { data: [], id: studentId }
    });
    dispatch({ type: ON_LOADING_FINISHED });
  }
};

export const editAcadProgress = (id: number, studentId: string, moodleActivity: boolean, unitCode: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/acad-progress/${id}`;
    const result = await put(url, { id, moodleActivity });
    dispatch({ type: EDIT_ACAD_PROGRESS, payload: result.data });
  } catch (error) {
    dispatch({
      type: EDIT_ACAD_PROGRESS,
      payload: {}
    });
  }
};

export const createAcadProgress = (unitCode: string, studentId: string, moodleActivity: boolean, unitId: string): ThunkAction<void, RootState, unknown, ActionType> => async dispatch => {
  try {
    const url = `${await STUDENT_URL()}/${studentId}/acad-progress`;
    const result = await post(url, { unitCode, studentId, moodleActivity, unitId });
    dispatch({ type: CREATE_ACAD_PROGRESS, payload: result.data });
  } catch (error) {
    dispatch({
      type: CREATE_ACAD_PROGRESS,
      payload: {}
    });

  }
};