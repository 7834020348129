import { ActionType } from './types';
import * as actionTypes from '../actions';
import { GET_STUDENT_ASSESSMENT_INDEX, GET_STUDENT_LEGACY_INDEX, GET_STUDENT_MATERIAL_INDEX, ON_STUDENT_ENGAGEMENT_INDEX_RECEIVED } from '../actionTypes';

const initialState = {
  studentDetails: {},
  unitGraphData: [],
  unitResult: null,
  unitBarGraphData: [],
  unitLineGraphData: [],
  assessmentIndexData: [],
  legacyIndexData: [],
  engagementIndexData: [],
  materialIndexData: [],
  studentsResult: {},
  searchText: '',
  loader: false,
  studentProfileImage: {}
};

const student = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_STUDENT_RECEIVED: {
      return {
        ...state,
        studentsResult: action.payload,
      };
    }
    case actionTypes.ON_STUDENT_SEARCH_CHANGED: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case actionTypes.GET_STUDENT_INFO: {
      return {
        ...state,
        studentDetails: action.payload[0],
      };
    }
    case actionTypes.GET_STUDENT_PROFILE_IMAGE: {
      const { data, id } = action.payload
      const finalStudentProfileImage = JSON.parse(JSON.stringify(state.studentProfileImage));
      finalStudentProfileImage[id] = data
      return {
        ...state,
        studentProfileImage: finalStudentProfileImage,
      };
    }
    case actionTypes.GET_UNITS_BY_STUDENTID: {
      return {
        ...state,
        unitResult: action.payload,
      };
    }
    case actionTypes.GET_UNITS_GRAPH_DATA_BY_STUDENTID: {
      return {
        ...state,
        unitLineGraphData: action.payload,
      };
    }
    case actionTypes.GET_UNITS_BAR_GRAPH_DATA_BY_STUDENTID: {
      return {
        ...state,
        unitBarGraphData: action.payload,
      };
    }
    case actionTypes.RESET_STUDENT_DETAILS: {
      return {
        ...state,
        studentDetails: {},
        unitBarGraphData: [],
        unitLineGraphData: [],
        engagementIndexData: [],
        legacyIndexData: [],
      };
    }
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loader: false,
      };
    }
    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_STUDENT_ASSESSMENT_INDEX: {
      return {
        ...state,
        assessmentIndexData: action.payload,
      };
    }
    case ON_STUDENT_ENGAGEMENT_INDEX_RECEIVED: {
      return {
        ...state,
        engagementIndexData: action.payload,
      };
    }
    case GET_STUDENT_MATERIAL_INDEX: {
      return {
        ...state,
        materialIndexData: action.payload,
      };
    }
    case GET_STUDENT_LEGACY_INDEX: {
      return {
        ...state,
        legacyIndexData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default student;
